var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-contact-card-list" },
    [
      _c("div", { staticClass: "property-section-header" }, [
        _c("h4", [_vm._v(_vm._s(_vm.title))])
      ]),
      _vm._v(" "),
      !_vm.isLoading && !_vm.hasError
        ? _c(
            "div",
            { staticClass: "property-section-content" },
            _vm._l(_vm.accountTeamMembers, function(row, idx) {
              return _c("dcxa-contact-card", {
                key: idx,
                attrs: { user: row.User, role: row.TeamMemberRole }
              })
            }),
            1
          )
        : _vm.hasError
        ? _vm._t("has-error", function() {
            return [
              _c("dcxa-special-state-container", {
                attrs: {
                  icon: "far fa-frown",
                  text: "Unable to load contacts. Please try again."
                }
              })
            ]
          })
        : _vm.isLoading
        ? _c(
            "div",
            [
              _c("dcxa-content-loader", {
                attrs: { "number-of-columns": 4, type: "AdvisorySection" }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6f2e37b4", { render: render, staticRenderFns: staticRenderFns })
  }
}