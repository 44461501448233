import { Configs } from '@dcxa/dcxa-core'

// initial state
const state = {
    modalDialogConfig: {},
    lastOpenDialogType: ''
}

// getters
const getters = {
    getModalDialogConfig: state => state.modalDialogConfig,
    getLastOpenDialogType: state => state.lastOpenDialogType
}

const DEFAULT_OPEN_TIMEOUT = 100;
let openTimeoutHandler = null;

// actions
const actions = {
    OPEN_LOADING_DIALOG({ commit }, config) {
        const modalDialogConfig = {
            text: config.text
        };

        openTimeoutHandler = setTimeout(() => {
            if (openTimeoutHandler) {
                commit('OPEN_LOADING_MODAL_DIALOG', modalDialogConfig);
            }
        }, DEFAULT_OPEN_TIMEOUT);
    },

    OPEN_SUCCESS_DIALOG({ commit }, config) {
        const modalDialogConfig = {
            text: config.text
        };

        openTimeoutHandler = setTimeout(() => {
            if (openTimeoutHandler) {
                commit('OPEN_SUCCESS_MODAL_DIALOG', modalDialogConfig);
            }
        }, DEFAULT_OPEN_TIMEOUT);
    },

    OPEN_ERROR_DIALOG({ commit }, config) {
        const errorInfo = Configs.Errors[config.clientErrorCode];
        const modalDialogConfig = {
            title: config.clientErrorCode + ((errorInfo.title && errorInfo.title.length > 0 ) ? ' - ' + errorInfo.title : ''),
            message: errorInfo.message,
            buttons: errorInfo.buttons,
            buttonActionArgs: config.buttonActionArgs
        };

        commit('OPEN_ERROR_MODAL_DIALOG', modalDialogConfig);
    },

    CLOSE_DIALOG({ commit, getters }) {
        if (openTimeoutHandler) {
            clearInterval(openTimeoutHandler);
            openTimeoutHandler = null;
        }

        commit('CLOSE_MODAL_DIALOG', getters.getLastOpenDialogType !== 'error');
    },

    CLOSE_ERROR_DIALOG({ commit }) {
        commit('CLOSE_MODAL_DIALOG', true);
    }
}

// mutations
const mutations = {
    OPEN_LOADING_MODAL_DIALOG(state, config) {
        state.lastOpenDialogType = 'loading';
        state.modalDialogConfig = {
            ...config,
            type: 'loading',
            visible: true
        }
    },

    OPEN_SUCCESS_MODAL_DIALOG(state, config) {
        state.lastOpenDialogType = 'success';
        state.modalDialogConfig = {
            ...config,
            type: 'success',
            visible: true
        }
    },

    OPEN_ERROR_MODAL_DIALOG(state, config) {
        state.lastOpenDialogType = 'error';
        state.modalDialogConfig = {
            ...config,
            type: 'error',
            visible: true
        }
    },

    CLOSE_MODAL_DIALOG(state, isDialogClosable) {
        if (isDialogClosable) {
            state.modalDialogConfig = {
                visible: false
            }
            state.lastOpenDialogType = '';
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}