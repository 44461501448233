var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c("div", { class: _vm.classes }, [
        _c("span", { staticClass: "info-icon" }, [
          _c("i", { class: _vm.icon == "" ? _vm.defaultInfoIcon : _vm.icon })
        ]),
        _vm._v(" "),
        _vm.isDismissable
          ? _c(
              "div",
              { staticClass: "controls" },
              [
                !_vm.isNewAdmonition
                  ? _c("dcxa-text-link", { on: { click: _vm.dismiss } }, [
                      _vm._v("Dismiss")
                    ])
                  : _c("dcxa-text-link", { on: { click: _vm.dismiss } }, [
                      _c("i", {
                        staticClass: "far fa-times",
                        attrs: { "aria-hidden": "true" }
                      })
                    ])
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [
            _vm.message
              ? [
                  _vm.title
                    ? _c("p", [_c("strong", [_vm._v(_vm._s(_vm.title))])])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { domProps: { innerHTML: _vm._s(_vm.message) } })
                ]
              : _vm._e(),
            _vm._v(" "),
            !_vm.message
              ? [
                  _vm._t("default", function() {
                    return [_vm._v(_vm._s(_vm.defaultMessage))]
                  })
                ]
              : _vm._e()
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f4d91488", { render: render, staticRenderFns: staticRenderFns })
  }
}