var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-content-loader" },
    [
      _vm.isSimpleTable || _vm.isCardList
        ? _c("dcxa-content-title", {
            attrs: { title: _vm.title, titleSize: _vm.titleSize }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$screensFrom("md")
        ? _c(
            "div",
            [
              _vm.isSimpleTable
                ? _vm._l(_vm.numberOfRows, function(currentRow) {
                    return _c(
                      "div",
                      {
                        staticClass:
                          "loading-element loading-desktop animated-element",
                        class: ["element-" + currentRow]
                      },
                      _vm._l(_vm.numberOfColumns, function(currentColumn) {
                        return _c(
                          "div",
                          {
                            key: currentColumn,
                            style: _vm.simpleTableDesktopColumnStyle
                          },
                          [_c("div", { staticClass: "loading-item" })]
                        )
                      }),
                      0
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isAdvisorySection
                ? [
                    _c(
                      "div",
                      { staticClass: "loading-element loading-mobile" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "animated-element element-1",
                            style: _vm.advisorySectionLongerStyle
                          },
                          [_c("div", { staticClass: "loading-item" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "animated-element element-2",
                            style: _vm.advisorySectionShorterStyle
                          },
                          [_c("div", { staticClass: "loading-item" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "animated-element element-3",
                            style: _vm.advisorySectionLongerStyle
                          },
                          [_c("div", { staticClass: "loading-item" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "animated-element element-4",
                            style: _vm.advisorySectionShorterWithoutMarginStyle
                          },
                          [_c("div", { staticClass: "loading-item" })]
                        )
                      ]
                    )
                  ]
                : _vm.isCardList
                ? _vm._l(3, function(n, i) {
                    return _c(
                      "div",
                      {
                        staticClass:
                          "loading-element loading-desktop animated-element",
                        class: ["element-" + (i + 1)]
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "loading-card-list-title",
                            style: _vm.fullWidthStyle
                          },
                          [_c("div", { staticClass: "loading-item" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { style: _vm.partialWidthStyle },
                          _vm._l(_vm.numberOfColumns, function(currentColumn) {
                            return _c(
                              "div",
                              {
                                key: currentColumn,
                                style: _vm.cardListDesktopColumnStyle
                              },
                              [
                                _c("div", {
                                  staticClass: "loading-item loading-metadata"
                                })
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  })
                : _vm.isContent
                ? [
                    _c(
                      "div",
                      { staticClass: "content-loader-container" },
                      [
                        _c("dcxa-loading-spinner"),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.title))])
                      ],
                      1
                    )
                  ]
                : _vm.isCommentList
                ? _vm._l(3, function(n, i) {
                    return _c(
                      "div",
                      {
                        staticClass:
                          "loading-element loading-desktop loading-desktop-comment animated-element",
                        class: ["element-" + (i + 1)]
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "loading-comment-title1",
                            style: _vm.commentDesktopTitle1Style
                          },
                          [_c("div", { staticClass: "loading-item" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "loading-comment-title2",
                            style: _vm.commentDesktopTitle2Style
                          },
                          [_c("div", { staticClass: "loading-item" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { style: _vm.fullWidthStyle },
                          [
                            _vm._l(_vm.numberOfColumns - 1, function(
                              currentColumn
                            ) {
                              return _c(
                                "div",
                                {
                                  key: currentColumn,
                                  style: _vm.fullWidthStyle
                                },
                                [
                                  _c("div", {
                                    staticClass: "loading-item loading-comment"
                                  })
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c("div", { style: _vm.partialWidthStyle }, [
                              _c("div", {
                                staticClass: "loading-item loading-comment"
                              })
                            ])
                          ],
                          2
                        )
                      ]
                    )
                  })
                : _vm._e()
            ],
            2
          )
        : _c("div", [
            _c(
              "div",
              { staticClass: "loading-element loading-mobile" },
              [
                _vm.isSimpleTable
                  ? _vm._l(_vm.numberOfColumns, function(currentColumn) {
                      return _c(
                        "div",
                        {
                          key: currentColumn,
                          staticClass:
                            "loading-item-container animated-element",
                          class: ["element-" + currentColumn]
                        },
                        [_vm._m(0, true), _vm._v(" "), _vm._m(1, true)]
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAdvisorySection
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "animated-element element-1",
                          style: _vm.advisorySectionLongerStyle
                        },
                        [_c("div", { staticClass: "loading-item" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "animated-element element-2",
                          style: _vm.advisorySectionShorterStyle
                        },
                        [_c("div", { staticClass: "loading-item" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "animated-element element-3",
                          style: _vm.advisorySectionLongerStyle
                        },
                        [_c("div", { staticClass: "loading-item" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "animated-element element-4",
                          style: _vm.advisorySectionShorterWithoutMarginStyle
                        },
                        [_c("div", { staticClass: "loading-item" })]
                      )
                    ]
                  : _vm.isCardList
                  ? [
                      _c(
                        "div",
                        {
                          staticClass:
                            "animated-element loading-card-list-title1",
                          staticStyle: { "{margin-bottom": "8px" },
                          style: _vm.fullWidthStyle
                        },
                        [_c("div", { staticClass: "loading-item" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "animated-element loading-card-list-title",
                          style: _vm.partialWidthStyle
                        },
                        [_c("div", { staticClass: "loading-item" })]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.numberOfColumns - 1, function(currentColumn) {
                        return _c(
                          "div",
                          {
                            key: currentColumn,
                            staticClass: "animated-element",
                            class: ["element-" + currentColumn]
                          },
                          [
                            _c("div", {
                              staticClass: "loading-item loading-metadata"
                            })
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "animated-element",
                          class: ["element-" + _vm.numberOfColumns],
                          style: _vm.partialWidthStyle
                        },
                        [
                          _c("div", {
                            staticClass: "loading-item loading-metadata"
                          })
                        ]
                      )
                    ]
                  : _vm.isContent
                  ? [
                      _c(
                        "div",
                        { staticClass: "content-loader-container" },
                        [
                          _c("dcxa-loading-spinner"),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.title))])
                        ],
                        1
                      )
                    ]
                  : _vm.isCommentList
                  ? [
                      _c(
                        "div",
                        {
                          staticClass:
                            "animated-element loading-comment-title1",
                          style: _vm.commentMobileTitle1Style
                        },
                        [_c("div", { staticClass: "loading-item" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "animated-element loading-comment-title2",
                          style: _vm.commentMobileTitle2Style
                        },
                        [_c("div", { staticClass: "loading-item" })]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.numberOfColumns - 1, function(currentColumn) {
                        return _c(
                          "div",
                          {
                            key: currentColumn,
                            staticClass: "animated-element loading-comment",
                            class: ["element-" + currentColumn]
                          },
                          [_c("div", { staticClass: "loading-item" })]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "animated-element loading-comment",
                          class: ["element-" + _vm.numberOfColumns],
                          style: _vm.partialWidthStyle
                        },
                        [_c("div", { staticClass: "loading-item" })]
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loading-item-prop" }, [
      _c("div", { staticClass: "loading-item" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loading-item-value" }, [
      _c("div", { staticClass: "loading-item" })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-554307f4", { render: render, staticRenderFns: staticRenderFns })
  }
}