var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-text-label" },
    [
      _vm.clamp
        ? [
            _c(
              "el-popover",
              {
                attrs: {
                  placement: "bottom",
                  trigger: "hover",
                  disabled: !_vm.textIsClamped,
                  "popper-class": "dcxa-tooltip"
                }
              },
              [
                _vm._t("default", function() {
                  return [_vm._v("Tooltip")]
                }),
                _vm._v(" "),
                _c(
                  "v-clamp",
                  {
                    attrs: {
                      slot: "reference",
                      "max-lines": _vm.lines,
                      autoresize: ""
                    },
                    on: { clampchange: _vm.onClampChange },
                    slot: "reference"
                  },
                  [
                    _vm._t("default", function() {
                      return [_vm._v("Text")]
                    })
                  ],
                  2
                )
              ],
              2
            )
          ]
        : _vm._t("default", function() {
            return [_vm._v("Text")]
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3351064f", { render: render, staticRenderFns: staticRenderFns })
  }
}