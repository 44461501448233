<template>
  <div class="myc-search-result-item">
    <h4 class="title" @click.exact.prevent="open">
        <a class="titleLink" :href="target">{{data.title}}</a>
    </h4>
    <p class="teaser">
        <v-clamp :max-lines="3" autoresize="">
            {{truncatedTeaser}}
        </v-clamp></p>
       <div class="metadata">
        <template v-if="hasContentTypeMeta">
            <div class="doc-type-icon fal" :class="contentTypeClasses"/>
            {{contentTypeName}}
        </template>

        <!-- <template v-if="hasContentTypeMeta && (hasLastUpdatedMeta || hasPublishedMeta)"> 
            <span class="meta-separator"></span>
        </template> -->

        <!-- <template v-if="hasLastUpdatedMeta">
            Last updated: <span class="meta-strong">{{data.lastUpdated.toLocaleString()}}</span>
        </template> -->
        <!-- <template v-if="hasPublishedMeta && hasLastUpdatedMeta"> 
            <span class="meta-separator"></span>
        </template> -->
        <!-- <template v-if="hasPublishedMeta">
            Published: <span class="meta-strong">{{data.published.toLocaleString()}}</span>
        </template> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VClamp from 'vue-clamp'

const dcxaCore = require('@dcxa/dcxa-core');
const { Utilities, Search } = dcxaCore;

const TEASER_CHAR_LIMIT = 180;

export default {
  name: 'ResultItem',
  components: {
      VClamp
  },
  props: {
      data: {
          type: Object,
          default: {
              title: '',
              teaser: '',
              published: '01-02-2016 10:00 AM',
              lastUpdated: '01-02-2016 10:00 AM',
              type: 'kb'
          }
      },
      target: {
          type: String
      }
  },
  computed: {
    
    truncatedTeaser() {
          return Utilities.object.getDeepValue(this.data, 'teaser') || ''
    },

    hasContentTypeMeta() {
        return this.data.type && this.data.type.length > 0;
    },

    contentTypeUiSettings() {
        return Search.consts.collectionUiSettings[this.data.type] || {};
    },

    contentTypeName() {
        return this.contentTypeUiSettings.visibleName || '';
    },

    contentTypeClasses() {
        const icon = this.contentTypeUiSettings.iconClass;
        
        const res = {};
        if (icon) {
            res[icon] = true;

            if (icon === 'community-icon') {
                res['fa-globe'] = true;
            }
            else if (icon === 'knowledge-base-icon') {
                res['fa-book'] = true;
            }
            else if (icon === 'documentation-icon') {
                res['fa-file-alt'] = true;
            }
        }

        return res;
    },

    hasLastUpdatedMeta() {
        return this.data.lastUpdated && this.data.lastUpdated != '';
    },
    hasPublishedMeta() {
        return this.data.published && this.data.published != '';
    }
  },
  methods: {
      open() {
          /* develblock:start */
          console.log('result clicked');
          /* develblock:end */
          this.$emit('open');
      }
  }
}
</script>

<style lang="scss">

@import "../../../../styles/_colors.scss";

.myc-search-result-item {
    width: 100%;
    position: relative;
    border-radius: 3px;
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
    border: solid 1px tint($black,10);
    margin-bottom: 16px;
    padding: 16px;

    .title {
        color: shade($mint,120);
        margin-bottom: 16px;
        cursor: pointer;
        overflow: hidden;
        line-height: normal;
    }

    .titleLink {
            color: $mint;
            &:hover {
              color: shade($mint,120);
          }
        
    }

    .teaser {
        line-height: 1.33;
    }

    .metadata {
        position: relative;
        height: 24px;
        font-size: 12px;
        line-height: 1.71;
        text-align: left;
        color: $slate;
        text-transform : uppercase;

        .meta-separator {
            margin: 0 16px;
            border-left: 1px solid tint($black,10);
        }

        .meta-strong {
            font-weight: 500;
        }

        .doc-type-icon {
            height: 14px;
            font-size: 14px;
            display: inline-block;
            width: 14px;
            vertical-align: text-bottom;
        }

        .documentation-icon {
            color: tint($mint,80);
        }

        .knowledge-base-icon {
            color: shade($mint,120);
        }

        .community-icon {
            color: $purple;
        }
    }

}

</style>
