var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card",
      class: { "alternate-coloring": _vm.alternateColoring }
    },
    [
      _c(
        "div",
        {
          staticClass: "simple-table-row",
          on: {
            click: function($event) {
              return _vm.$emit("click", { row: _vm.data, event: $event })
            }
          }
        },
        [_vm._t("columns", null, { row: _vm.data })],
        2
      ),
      _vm._v(" "),
      _vm.expanded
        ? _c(
            "div",
            { staticClass: "expansion-panel" },
            [_vm._t("expansion-panel", null, { row: _vm.data })],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-55b648fb", { render: render, staticRenderFns: staticRenderFns })
  }
}