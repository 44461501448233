<template>
    <div class="dcxa-arc-progress">
       <dcxa-loading-spinner v-if="loading" />
    </div>
</template>

<script>
import Vue from 'vue'
export default {
    name: 'DcxaArcProgress',
    props: {

        value: {
            type: String,
            default: 0
        },
        percentage: {
            type: Number,
            default: 0
        },

        valueDesc: {
            type: String,
            default: ''
        },

        mainColor: {
            type: String,
            default: '#00a3af'
        },
        secondColor: {
            type: String,
            default: '#b3e3e7'
        },
        loading: {
            type: Boolean,
            default: false
        }

    },
    data() {
        return {
           
        }
    },
    watch: {
        'percentage': function(newVal, oldVal) {
            this.draw();
        },
        'value': function(newVal, oldVal) {
            this.draw();
        },
        'loading': function(newVal, oldVal) {
            this.draw();
        }

    },
    computed: {
       
    },

    methods: {

        handleResize () {
        },

        draw() {
            let targetDiv = this.$el;

            // clear previous SVG graphics
            d3.select(targetDiv).select('svg').remove();
            // this.$el.innerHtml = '';
    
            //defines size of chart
            const chartWidth = 191;
            const chartHeight = 191;
            const chartRadius = chartWidth / 2;
    
            const calculatedPercentage = isNaN(this.percentage) ? 0 : this.percentage;

            let mainValue = Math.min(calculatedPercentage, 100);
            let secondValue = Math.max(100 - calculatedPercentage, 0);

            const chartData = [
                {
                    count: mainValue,
                    color: this.mainColor,
                },
                {
                    count: secondValue,
                    color: this.secondColor,
                }
            ];
            //draws the chart
            const arc = d3.arc()
                            .outerRadius(chartRadius)
                            .innerRadius(chartRadius - 16)
    
            const pie = d3.pie()
                            .sort(null)
                            .value(function(d) { return d.count })
                            .startAngle(-0.75 * Math.PI)
                            .endAngle(.75 * Math.PI)
    
            // appends svg to scorecard
            const svg = d3.select(targetDiv)
                            .append('svg')
                            .attr('width', chartWidth)
                            .attr('height', chartHeight)
                            .append('g')
                            .attr('transform', `translate(${chartWidth/2},${chartHeight/2})`)
    
            const g = svg.selectAll('.arc')
                            .data(pie(chartData))
                            .enter()
                            .append('g')
                            .attr('class', 'arc')
    
            g.append('path')
                .attr('d', arc)
                .style('fill', function(d, i) { return chartData[i].color });

            g.append('text')
                .text(this.valueDesc)
                .attr('text-anchor', 'middle')
                .attr('y', 20)
                .attr('font-size', '16px')
                .attr('font-weight', '300')
                .attr('fill', '#808080');
            if(!this.loading) {
                g.append('text')
                    .text(this.value)
                    .attr('class', 'value-text')
                    .attr('text-anchor', 'middle')
                    .attr('fill', this.mainColor)
                    .attr('font-size', '40px');
            }
            

        }

    },



    mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
        if(this.loading) {
            this.draw();
        }

    },

    beforeDestroy: function () {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

<style lang="scss">
    @import '~@dcxa/dcxa-core/src/sass/mixins/mixins';
    @import "../../../../../styles/_utilities.scss";
    @import '../../../../../styles/_colors.scss';

.dcxa-arc-progress {
      position: relative;
  .dcxa-loading-spinner{
      position: absolute;
      top: calc(50% - 20px);
      left: calc(50% - 10px);
  }
  .value-text {
      font-size: 36px;
  }
  .value-desc {
      font-size: 14px;
      line-height: 1.13;
  }

    
}

</style>
