<template>
    <div class="dcxa-toggle-button">
        <el-radio-group v-model="selected" v-on:change="updateValue" :class="{ multirow: isMultirow }">
            <el-radio-button v-for="(opt, i) in options" :key="i" :label="opt[valueKey]">{{opt[titleKey]}}</el-radio-button>
        </el-radio-group>
        <label class="floating-label-element" :class="{'visible': floatingLabel}" v-html="floatingTitleText">
        </label>
    </div>
</template>

<script>
export default {
    name: 'DcxaToggleButton',
    data () {
        return {
            selected: '',
        };
    },
    props: {
        value: {
            
        },
        options: {
            default: () => []
        },
        mode: {
            default: () => 'normal'
        },
        placeholder: {
            type: String
        },
        floatingLabel: {
            type: Boolean
        },
        floatingTitle: {
            type: String
        },
        valueKey: {
            type: String,
            default: () => 'id'
        },
        titleKey: {
            type: String,
            default: () => 'title'
        }

    },
    watch: {
    },
    computed: {
        isMultirow() {
            return this.mode == 'multirow' && this.options.length == 4;
        },
        floatingTitleText() {
            return this.floatingTitle || this.placeholder;
        }
    },
    methods: {
        select: function(value) {
            this.selected = value;
        },
        updateValue: function (value) {
            this.$emit('input', this.selected);
            this.$emit('change', this.selected);
        }
    }
}
</script>

<style lang="scss">
@import "../../../../styles/_utilities.scss";
@import "../../../../styles/_colors.scss";
@import "../../styles/_mixins.scss";

.dcxa-toggle-button {
    @include floating-label();
    width: 100%;

    .el-radio-group {
        display: block;
    }

    .el-radio-button {
        /*display: block;*/
        width: 100%;

        
        
        .el-radio-button__inner {
            background: $white;
            color: $mint;
            font-size: 16px;

            border: 1px solid tint($black, 20);
            border-bottom-width: 0;

            width: 100%;
            height: 48px;
            padding: 0 24px;
            line-height: 48px;
       }

        &:first-of-type {
            .el-radio-button__inner {
                border-radius: 3px 3px 0 0;
            }
        }
        &:last-of-type {
            .el-radio-button__inner {
                border-radius: 0 0 3px 3px;
                border-bottom-width: 1px;
            }
        }
       

       &.is-active {
            .el-radio-button__orig-radio:checked + .el-radio-button__inner {
                color: $white;
                background-color: $mint;
                border-color: $mint;
                box-shadow: -1px 0 0 0 $mint;
            }

            &:hover {
                .el-radio-button__orig-radio:checked + .el-radio-button__inner {
                    background-color: tint($mint, 70);
                    border-color: tint($mint, 70);
                    box-shadow: -1px 0 0 0 tint($mint, 70);
                }
            }
       }
   }

   @include breakpoint(md) {
       .el-radio-button {
           display: inline-block;
           width: auto;

            .el-radio-button__inner {
                border-bottom-width: 1px;
                border-right-width: 0;
            }

            &:first-of-type {
                .el-radio-button__inner {
                    border-radius: 3px 0 0 3px;
                }
            }
            &:last-of-type {
                .el-radio-button__inner {
                    border-radius: 0 3px 3px 0;
                    border-right-width: 1px;
                }
            }

       }

       & .multirow {
           .el-radio-button {
               width: 50%;

                .el-radio-button__inner {
                    border-right-width: 0;
                    border-bottom-width: 0;
                }

                &:first-of-type {
                    .el-radio-button__inner {
                        border-radius: 3px 0 0 0;
                    }
                }
                &:last-of-type {
                    .el-radio-button__inner {
                        border-radius: 0 0 3px 0;
                        border-bottom-width: 1px;
                    }
                }
                &:nth-of-type(even) {
                    .el-radio-button__inner {
                        border-right-width: 1px;
                    }
                }
                &:nth-last-of-type(2) {
                    .el-radio-button__inner {
                        border-radius: 0 0 0 3px;
                        border-bottom-width: 1px;
                    }
                }
           }
       }
   }

}


</style>
