<template>
    <div class="dcxa-label" :id="id" :name="name">
        <span>
            {{value}}
        </span>
        <label class="floating-label-element" :class="{'visible': floatingLabel}">
            {{title}}
        </label>
    </div>
</template>

<script>
    export default {
        name: 'DcxaInput',
        props: [
            'id',
            'name',
            'title',
            'value',
            'floatingLabel'
        ],
    }
</script>

<style lang="scss">
    @import "../../../../styles/_colors.scss";
    @import "../../styles/_mixins.scss";

    .dcxa-label {
        @include floating-label();
    }

</style>
