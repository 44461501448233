<template>
    <div ref="pieChartRoot" class="dcxa-pie-chart">
        <div ref="pieChartRoot" class="dcxa-pie-chart-root" />
    </div>
</template>

<script>
    import echarts from 'echarts';

    export default {
        name: "Pie",
        props: {
            data: {
                type: Array,
                default: () => [{value: 100}]
            },
            hasError: {
                type: Boolean,
                default: () => false
            }
        },
        data() {
            return {
                chartInstance: null,
                defaultData: [{value: 100}],
                defaultColor: ['#e6e6e6'],
                withSeriesColor: ['#008cff', '#e6e6e6']
            }
        },
        watch: {
            data(val) {
                if (val > -1) {
                    this.chartInstance.setOption({
                        color: this.withSeriesColor,
                        series: [
                            {
                                data: [
                                    {value: val},
                                    {value: 100 - val}
                                ],
                                label: {
                                    show: true,
                                    formatter: [
                                        '{percentage|' + val + '%}',
                                        '{remaining|Remaining}'
                                    ].join('\n'),
                                }
                            }
                        ]
                    });
                }
                else {
                    this.chartInstance.setOption({
                        color: this.defaultColor,
                        series: [
                            {
                                data: this.defaultData,
                                label: {
                                    show: false
                                }
                            }
                        ]
                    });
                }
            },
            hasError(val) {
                if (val === true) {
                    this.chartInstance.setOption({
                        color: this.defaultColor,
                        series: [
                            {
                                data: this.defaultData,
                                label: {
                                    show: false
                                }
                            }
                        ]
                    });
                }
            }
        },
        mounted() {
            this.chartInstance = echarts.init(this.$refs.pieChartRoot);

            var options = {
                color: this.defaultColor,
                textStyle: {
                    fontFamily: 'NeuePlak, Calibre, Calibri, Helvetica Neue, Helvetica, Arial, sans-serif'
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['60%', '100%'],
                        data: this.defaultData,
                        label: {
                            show: false,
                            position: 'center',
                            rich: {
                                percentage: {
                                    color: '#5a656d',
                                    fontSize: 16,
                                    fontWeight: 'bold'
                                },
                                remaining: {
                                    color: '#5a656d',
                                    fontSize: 10
                                }
                            }
                        },
                        showTooltips: false,
                        hoverAnimation: false,
                        silent: true,
                        animation: false
                    }
                ]
            };

            this.chartInstance.setOption(options);
        }
    }
</script>

<style scoped lang="scss">
    .dcxa-pie-chart {
        width: 98px;
        height: 98px;
        min-width: 98px;
        min-height: 98px;

        .dcxa-pie-chart-root {

        }
    }
</style>