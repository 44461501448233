<template>
    <div class="info-row" :class="{'enable-multiline': enableMultiline}" >
        <span class="property">{{title}}</span><slot name="custom-value"><div class="value"><span :class="{'missing': !isProvided}">{{valueToShow}}</span></div></slot>
    </div>
</template>

<script>
export default {
  name: 'FormInfoRow',
  data() {
    return {
    }
  },
  props: [
      'title',
      'value',
      'missingText',
      'valueFormatter',
      'notProvidedText',
      'enableMultiline'
  ],
  computed: {
      isProvided() {
          return (this.value && (typeof this.value == 'string' ? this.value.length > 0: true));
      },
      valueToShow() {
          if (this.isProvided) {
              if (this.valueFormatter) {
                  return this.valueFormatter(this.value);
              } else {
                  return this.value;
              }
          } else {
              return this.missingText || this.notProvidedText || "-";
          }
      }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../../styles/_colors.scss";

.info-row {
    .value {
        vertical-align: middle;
    }
    
    .value {
        span {
            overflow: hidden;
            white-space:nowrap;
            text-overflow:ellipsis;
            max-width: 320px;
            display:inline-block;
            vertical-align: text-top;
        }
    }
    span.missing {
        
        vertical-align: text-bottom;
        font-size: 12px;
        color: tint($black,30);
    }

    &.enable-multiline {
        .value {
            span {
                white-space: unset;
                word-break: break-word;
            }
        }
    }
}

</style>
