<template>
    <div class="dcxa-property-value-editor"
         :class="{'inline': inline, 'has-error': hasValidationMessage, 'floating-label': floatingLabel}">

        <span class="property"  v-if="$screensFrom('md') && !forceFloatingLabel && !hideLabel" data-dd-privacy="mask-user-input">{{title}}
            <span class="asterisk" v-if="isRequired">*</span>
            <dcxa-icon-with-tooltip v-if="hasTooltip" :classes="infoIconSize" placement="bottom">
                {{ tooltipContent }}
            </dcxa-icon-with-tooltip>
        </span>

        <span class="value">

            <!-- Text editor -->
            <div class="secondary-label-container">
              <slot name="secondaryLabel">
                <dcxa-icon-with-tooltip v-if="floatingLabel && hasTooltip && type != 'custom'" :classes="infoIconSize" placement="bottom">
                  {{ tooltipContent }}
                </dcxa-icon-with-tooltip>
              </slot>
              <div class="char-counter" ref="charCounter" :style="charCounterStyles" v-if="charCounterVisible" :class="{'error': calculatedLength > textAreaMaxChars}">
                  Length: {{calculatedLength }} / {{textAreaMaxChars}}
              </div>
            </div>

            <dcxa-label v-if="type == 'label'"
                :id="id"
                :value="input"
                :title="title"
                :floatingLabel="floatingLabel">
            </dcxa-label>

            <dcxa-input :class="appliedClasses" v-model="input" v-if="type == 'text' || type=='textarea'"
                :id="id"
                :name="name"
                :type="type"
                :popover="hasValidationHint"
                :disabled="config.disabled || disabled"
                :floatingLabel="floatingLabel"
                :rows="inputRows"
                :prefixIcon="prefixIcon"
                resize="none"
                :placeholder="placeholder"
                :floatingTitle="floatingTitle">
                <template slot="popoverContent">
                    <div v-html="validationHint"></div>
                </template>
            </dcxa-input>

            <!-- Password editor -->
            <dcxa-input :class="appliedClasses" v-model="input" v-if="type == 'password'"
                :id="id"
                :name="name"
                :popover="hasValidationHint"
                type="password"
                :disabled="config.disabled || disabled"
                :enabledValidationPopover="config.enabledValidationPopover"
                :floatingLabel="floatingLabel"
                :prefixIcon="prefixIcon"
                :placeholder="placeholder"
                :floatingTitle="floatingTitle">
                <template slot="popoverContent">
                    <div v-html="validationHint"></div>
                </template>
            </dcxa-input>

            <!-- Checkbox editor -->
            <dcxa-checkbox :class="appliedClasses" v-model="input" v-if="type == 'checkbox'"
                :id="id"
                :name="name"
                :popover="hasValidationHint"
                :disabled="config.disabled || disabled || config.progressing"
                :floatingLabel="floatingLabel"
                :floatingTitle="floatingTitle"
                :contentLabel="contentLabel"
                :classes="{'progressing': config.progressing}">
                <template slot="popoverContent">
                    <div v-html="validationHint"></div>
                </template>
            </dcxa-checkbox>

            <!-- DatePicker editor -->
            <dcxa-datepicker :class="appliedClasses" v-model="input" v-if="type == 'datepicker'"
                :id="id"
                :name="name"
                :popover="hasValidationHint"
                :disabled="config.disabled || disabled"
                :clearable="config.clearable"
                :pickerOptions="config.pickerOptions"
                :floatingLabel="floatingLabel"
                :placeholder="placeholder"
                :floatingTitle="floatingTitle">
                <template slot="popoverContent">
                    <div v-html="validationHint"></div>
                </template>
            </dcxa-datepicker>

            <!-- TimePicker editor -->
            <dcxa-timepicker :class="appliedClasses" v-model="input" v-if="type == 'timepicker'"
                :id="id"
                :name="name"
                :popover="hasValidationHint"
                :disabled="config.disabled || disabled"
                :clearable="config.clearable"
                :pickerOptions="config.pickerOptions"
                :floatingLabel="floatingLabel"
                :placeholder="placeholder"
                :floatingTitle="floatingTitle">
                <template slot="popoverContent">
                    <div v-html="validationHint"></div>
                </template>
            </dcxa-timepicker>

            <!-- Dropdown editor -->

            <dcxa-select v-model="input" :class="appliedClasses" v-if="type == 'select'"
                         :id="id"
                         :name="name"
                         :disabled="config.disabled || disabled"
                         :popover="hasValidationHint"
                         :clearable="config.clearable"
                         :filterable="config.filterable"
                         :remote="config.remote"
                         :remoteMethod="inlineEditorRemoteMethod"
                         :multiselect="config.enableMultiselect"
                         :floatingLabel="floatingLabel"
                         :floatingTitle="floatingTitle"
                         :placeholder="placeholder"
                         :num-of-options="options.length">
              <el-option v-for="item in options"
                         :key="item[optionItem.value || 'id']"
                         :label="item[optionItem.title || 'title']"
                         :value="item[optionItem.value || 'id']"
                         :disabled="item['disabled']">
                  <slot name="customOptionTemplate" :item="item" />
              </el-option>
              <template slot="popoverContent">
                  <div v-html="validationHint"></div>
              </template>
            </dcxa-select>

            <!-- Toggle button list editor -->
            <template v-if="type == 'toggle'">
                <dcxa-toggle-button v-model="input" :class="appliedClasses" v-if="options.length <= 4"
                                    :id="id"
                                    :name="name"
                                    :disabled="config.disabled || disabled"
                                    :popover="hasValidationHint"
                                    :mode="mode"
                                    :options="options"
                                    :floatingLabel="!config.hideFloatingLabel && floatingLabel"
                                    :floatingTitle="floatingTitle"
                                    :placeholder="placeholder">
                    <template slot="popoverContent">
                        <div v-html="validationHint"></div>
                    </template>
                </dcxa-toggle-button>
                <dcxa-select v-model="input" :class="appliedClasses" v-else
                             :id="id"
                             :name="name"
                             :disabled="config.disabled || disabled"
                             :popover="hasValidationHint"
                             :clearable="config.clearable"
                             :filerable="config.filterable"
                             :remote="config.remote"
                             :remoteMethod="inlineEditorRemoteMethod"
                             :multiselect="config.enableMultiselect"
                             no-data-text="No selectable item."
                             :floatingLabel="floatingLabel"
                             :floatingTitle="floatingTitle"
                             :placeholder="placeholder" :num-of-options="options.length">
                <el-option v-for="item in options"
                           :key="item[optionItem.value || 'id']"
                           :label="item[optionItem.title || 'title']"
                           :value="item[optionItem.value || 'id']"
                           :disabled="item['disabled']">
                    <slot name="customOptionTemplate" :item="item" />
                </el-option>
                <template slot="popoverContent">
                    <div v-html="validationHint"></div>
                </template>
            </dcxa-select>
            </template>

            <!-- Custom editor -->
            <dcxa-custom-property-value-editor v-if="type == 'custom'"
                 :class="appliedClasses"
                 :id="id" :name="name"
                 :floatingLabel="floatingLabel"
                 :tooltipContent="tooltipContent"
                 :placeholder="placeholder"
                 :floatingTitle="floatingTitle">
              <slot />
            </dcxa-custom-property-value-editor>

            <div v-if="inline" class="inline-editor-buttons">
                <dcxa-button type="primary" :class="inlineButtonClasses" :disabled="!state.result" @click="onInlineSubmit"><i class="far fa-check submit" aria-hidden="true"></i></dcxa-button>
                <dcxa-button @click="onInlineCancel" :class="appliedWithOuterClasses({})" :disabled="inlineProgressing"><i class="far fa-times cancel" aria-hidden="true"></i></dcxa-button>
            </div>

            <span class="inline-error-message" :class="validationClasses" v-if="inline && hasInlineError">
                There was an error with saving your changes. Please try again.
            </span>

            <span class="validation-message" :class="validationClasses" v-if="hasValidationMessage">
                <i class="fal fa-exclamation-circle"></i> {{validationMessage}}
            </span>

        </span>
    </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'

const dcxaCore = require('@dcxa/dcxa-core');
const { ValidationFramework, Utilities } = dcxaCore;

export default {
  name: 'PropertyValueEditor',
  data() {
    return {
        input: null,
        validationRound: 0,

        state: {},
        originalOptions: [],
        remoteFilterTerm: '',
        charCounterStyles:{}
    }
  },
  props: {
      'title': String,
      'id': {
          default: function() {
              return ""
          }
      },
      'name': {
          default: function() {
              return ""
          }
      },
      'source': {
          
      },
      'config': Object,
      'textAreaMaxChars': {
          default: 0
      },
      'classes': {
          default: function() {
              return ['medium']
          }
      },
      'inputRows': {

      },
      'disabled': {
          default: function() {
              return false;
          }
      },
      'prefixIcon': {
          default: function() {
              return ''
          }
      },
      'forceFloatingLabel': {
          default: () => false
      },
      'hideLabel': {
          default: () => false
      },
      'inline': {
          default: function() {
              return false;
          }
      },
      'inlineProgressing': {
          default: function() {
              return false;
          }
      },
      'hasInlineError': {
          default: function() {
              return false;
          }
      },
      'tooltipContent': {
          default: function() {
              return ""
          }
      },
      'infoIconSize': {
          default: function() {
              return "medium"
          }
      }
  },
  computed: {
      calculatedLength() {
        //   var length = (input || '').trim().length;
          var length = (new TextEncoder().encode(this.input || '')).length;

          return length;
      },
      floatingLabel() {
          return !this.hideLabel && (this.$screensUpTo('xs') || this.forceFloatingLabel);
      },
      floatingTitle() {
        let floatingTitleHtml = this.title;
        if(this.isRequired ){
          floatingTitleHtml = floatingTitleHtml + '<span class="asterisk">*</span>'
        }
        return floatingTitleHtml;
      },
      placeholder() {
          let placeholderHtml = '';

          if (!this.isRequired) {
            placeholderHtml = 'Optional';
          }

          if (this.config && this.config.placeholder) {
            placeholderHtml = this.config.placeholder;
          }

          return placeholderHtml;
      },
      contentLabel() {
          return (this.config) ? this.config.contentLabel || 'Yes' : 'Yes';
      },
      type() {
          return (this.config) ? this.config.type || 'text' : 'text';
      },
      options() {
          if (this.config) {
              if (this.config.remote) {
                  if (this.remoteFilterTerm !== '' && this.config.remoteMethodCallback && typeof this.config.remoteMethodCallback == 'function') {
                      return _.filter(this.originalOptions, (item) => {
                          return this.config.remoteMethodCallback(this.remoteFilterTerm, item);
                      });
                  }
                  else {
                      return this.originalOptions;
                  }
              }
              else {
                  return this.config.options || [];
              }
          }
          else {
              return [];
          }
      },
      mode() {
          return (this.config) ? this.config.mode || 'normal' : 'normal';
      },
      optionItem() {
          return (this.config) ? this.config.optionItem || {} : {};
      },
      validationConfig() {
          if (!this.config) return {};
          return this.config.validation || {};
      },
      inlineEditorRemoteMethod() {
          if (this.config.remote) {
              return (query) => {
                  this.remoteFilterTerm = query;
              }
          }
      },
      inlineButtonClasses() {
          return this.appliedWithOuterClasses({'loading': this.inlineProgressing});
      },
      appliedWithOuterClasses() {
          return (startingClasses) => {
              const ret = startingClasses ? startingClasses : {};

              this.classes.forEach(reqClass => {
                  ret[reqClass] = true;
              });

              return ret;
          };
      },
      appliedClasses() {
        const ret = {
            'validation-error': this.hasValidationError, 
            'validation-success': this.validationSuccess,
            'secondary-label': this.charCounterVisible,
            'has-validation-icons': this.type == 'password'
        }

        return this.appliedWithOuterClasses(ret);
      },
       validationClasses() {
        return {
            'validation-error': this.hasValidationError, 
            'validation-success': this.validationSuccess
        }
      },
      supressingError() {
          return this.validationConfig.supressErrorOnFirstRound && this.validationRound < (this.validationConfig.supressErrorUntil || 2);
      },
      hasValidationError() {
          return !this.state.result && !this.supressingError;
      },
      validationSuccess() {
        //   console.log(this.validationRound);
        //   console.log((this.validationRound > 0 && !this.hasValidationError));
          return (this.validationRound > ((this.validationConfig.supressErrorUntil || 2) - 1) && !this.hasValidationError);
      },
      hasValidationMessage() {
          return this.state.message && this.state.message.length > 0 && !this.supressingError;
      },
      validationMessage() {
        //   console.log(this.state);
          return this.state.message;
      },
      hasValidationHint() {
          return !!this.state.hint && this.state.hint.length > 0 && !this.supressingError;
      },
      validationHint() {
          return this.state.hint;
      },
      charCounterVisible() {
          return (this.type == 'textarea' || this.type == 'text') && this.textAreaMaxChars > 0;
      },
      isRequired() {
            const rules = Utilities.object.getDeepValue(this.config, 'validation.rules') || [];
            return _.some(rules, r => r.rule == 'required');
      },
      hasTooltip() {
          return this.tooltipContent && this.tooltipContent.length > 0;
      }
  },
  methods: {
    async validate(config, triggeredDelayed) {
        // Call without parameter from this Vue component. If called with params, we assume it's called from
        // custom validation logic. Triggered delay if true add an extra flag to the end state to cancel infinite loop.
        // For usage see: dcxa-apps-sso/register micro app component

        config = config || this.config;

        const validationResult = await ValidationFramework.validate(this.input, config);

        this.state = validationResult;

        if (this.validationRound == 0) {
            // do some crazy stuff if required on the first validation round (upon initialization)
            this.state.firstRound = true;
        }

        this.validationRound++;        

        if (triggeredDelayed) {
            this.state.delayedResult = true;
        }

        // console.log('validating ' + this.title)
        this.$emit('stateChanged', this.state)
    },
    clear(resetValidationRound) {
        if (resetValidationRound) {
            this.validationRound = 0;
        }
        this.input = '';
    },
      onInlineSubmit() {
          this.$emit('inlineSubmit');
      },
      onInlineCancel() {
          this.$emit('inlineCancel');
      },
      getCharCounterStyles() {
        try {
            if(this.floatingLabel) {
                let counterHeight = this.$refs.charCounter.parentElement.querySelector('.floating-label-element').offsetHeight;
                this.charCounterStyles =  {
                    "top": (counterHeight - 14) + 'px'
                }
            } else {
                this.charCounterStyles =  {"top": '-20px'}
            }
        } catch(e) {
            this.charCounterStyles =  {"top": '0px'}
        }
      }
  },
  watch: {
      source: function(val, oldVal) {
        //   console.log('source changed for ' + this.title);
          this.input = this.source;
      },
      input: function(val, oldVal) {
        //   console.log('input changed: '  + val);
          this.validate();
      },
      'config.validation': function(val, oldVal) {
        //   console.log('validation config change for ' + this.title)
        //   this.validate();
      }
  },
  mounted() {
    this.validationRound = 0;
    this.input = this.source;

    if (this.config) {
        if (this.config.remote) {
            this.originalOptions = this.config.options;
        }
    }
    Vue.nextTick(()=>{     
        if(this.charCounterVisible) {
            window.addEventListener('resize', this.getCharCounterStyles)
            this.getCharCounterStyles()
        }
    })
  },
  beforeDestroy: function () {
      if(this.charCounterVisible) {
        window.removeEventListener('resize', this.getCharCounterStyles)
      }
    }
}
</script>

<style scoped lang="scss">
@import "../../../../../styles/utilities";
@import "../../../../../styles/colors";

.dcxa-property-value-editor {
    margin-bottom: 40px;

    &.has-error {
        align-items: baseline;
        margin-bottom: 25px;

        @include breakpoint(md) {
            &.floating-label {
              margin-bottom: 25px;
            }
        }

        .value {
            display: block;
        }
    }
    position: relative;

    &:last-child {
        margin-bottom: 0;
    }

    &.inline {
        margin-bottom: 0;
    }

    .asterisk {
        color: $red;
    }

    .property {
        font-size: 16px;
        color: tint($black, 50);
        word-break: break-word;
    }

    .value {
        display: flex;
        .inline-editor-buttons {
            display: flex;

            .dcxa-button {
                min-width: 40px;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;

                &.large {
                    min-width: 48px;
                    width: 48px;
                    height: 48px;
                }

                &.el-button--default {
                    border: 1px solid tint($black, 20);
                }

                &.el-button--primary {
                    margin-left: 10px;
                }

                i {
                    font-size: 16px;
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .validation-message,
        .inline-error-message {
            font-size: 12px;
            color: $red-light;
            line-height: 12px;
            vertical-align: top;
            padding-top: 2px;
            display: inline-block;
        }
        .secondary-label-container {
          .char-counter {
            position: absolute;
            top: -20px;
            right: 0;
            font-size: 12px;
            color: $slate;

            user-select: none;

            &.error {
              color: $red-light;
            }
          }

          .dcxa-icon-with-tooltip {
            + .char-counter {
              right: 16px;
            }
          }
        }
    }

    @include breakpoint(md) {
        display: flex;
        align-items: flex-start;

        &.inline {
            margin-bottom: 0;
        }

        .property {
            width: 100%;
            max-width: 35%;
            padding-right: 16px;
            padding-top: 12px;
            align-self: flex-start;
        }
        .value {
            width: 100%;
        }
    }

}




</style>
