<template>
    <div class="editor-row" >

        <span class="property" v-if="mode == 'tableStyle' && !floatingLabel">{{title}}</span>


        <span class="value">


            <!-- Text editor -->


            <slot name="secondaryLabel">
                <div class="char-counter" v-if="charCounterVisible" :class="{'error': (input || '').trim().length > textAreaMaxChars}">
                    Length: {{(input || '').trim().length }} / {{textAreaMaxChars}}
                </div>
            </slot>

            <dcxa-input :class="appliedClasses" v-model="input" v-if="type == 'text' || type=='textarea'"
                :id="id"
                :name="name"
                :type="type"
                :popover="hasValidationHint"
                :disabled="config.disabled || disabled" 
                :floatingLabel="floatingLabel"
                :rows="inputRows"
                :prefixIcon="prefixIcon"
                resize="none"
                :placeholder="title">
                <template slot="popoverContent">
                    <div v-html="validationHint"></div>
                </template>
            </dcxa-input>


            <!-- Password editor -->
            <dcxa-input :class="appliedClasses" v-model="input" v-if="type == 'password'"
                :id="id"
                :name="name"
                :popover="hasValidationHint"
                type="password"
                :disabled="config.disabled || disabled" 
                :floatingLabel="floatingLabel"
                :prefixIcon="prefixIcon"
                :placeholder="title">
                <template slot="popoverContent">
                    <div v-html="validationHint"></div>
                </template>
            </dcxa-input>

            <!-- Checkbox editor -->
            <dcxa-checkbox :class="appliedClasses" v-model="input" v-if="type == 'checkbox'"
                :id="id"
                :name="name"
                :popover="hasValidationHint"
                :disabled="config.disabled || disabled" 
                :floatingLabel="floatingLabel"
                :placeholder="title"
                contentLabel="Yes">
                <template slot="popoverContent">
                    <div v-html="validationHint"></div>
                </template>
            </dcxa-checkbox>

            <!-- DatePicker editor -->
            <dcxa-datepicker :class="appliedClasses" v-model="input" v-if="type == 'datepicker'"
                :id="id"
                :name="name"
                :popover="hasValidationHint"
                :disabled="config.disabled || disabled" 
                :clearable="config.clearable"
                :floatingLabel="floatingLabel"
                :placeholder="title">
                <template slot="popoverContent">
                    <div v-html="validationHint"></div>
                </template>
            </dcxa-datepicker>

            <!-- Dropdown editor -->
            <dcxa-select v-model="input" :class="appliedClasses" v-if="type == 'select'"
                :id="id"
                :name="name"
                :disabled="config.disabled || disabled" 
                :popover="hasValidationHint"
                :clearable="config.clearable"
                :filterable="config.filterable"
                :multiselect="config.enableMultiselect"
                :floatingLabel="floatingLabel"
                :placeholder="title" :num-of-options="options.length">
                <el-option v-for="item in options"
                    :key="item[optionItem.value || 'id']"
                    :label="item[optionItem.title || 'title']"
                    :value="item[optionItem.value || 'id']"
                    :disabled="item['disabled']">
                </el-option>
                <template slot="popoverContent">
                    <div v-html="validationHint"></div>
                </template>
            </dcxa-select>

            <span class="validation-message" :class="validationClasses" v-if="hasValidationMessage">
                {{validationMessage}}
            </span>
        </span>
    </div>
</template>

<script>
import _ from 'lodash'

const dcxaCore = require('@dcxa/dcxa-core');
const { ValidationFramework } = dcxaCore;

export default {
  name: 'FormEditRow',
  data() {
    return {
        input: null,
        validationRound: 0,

        state: {}
    }
  },
  props: {
      'title': String,
      'id': {
          default: function() {
              return ""
          }
      },
      'name': {
          default: function() {
              return ""
          }
      },
      'source': {
          
      },
      'config': Object,
      'mode': {
          default: function() {
              return "tableStyle"
          }
      },
      'textAreaMaxChars': {
          default: 0
      },
      'classes': {
          default: function() {
              return ['medium']
          }
      },
      'floatingLabel': {
          default: function() {
              return false;
          }
      },
      'inputRows': {

      },
      'disabled': {
          default: function() {
              return false;
          }
      },
      'prefixIcon': {
          default: function() {
              return ''
          }
      }
  },
  computed: {
      type() {
          return (this.config) ? this.config.type || 'text' : 'text';
      },
      options() {
          return (this.config) ? this.config.options || [] : [];
      },
      optionItem() {
          return (this.config) ? this.config.optionItem || {} : {};
      },
      validationConfig() {
          if (!this.config) return {};
          return this.config.validation || {};
      },
      appliedClasses() {
        const ret = {
            'validation-error': this.hasValidationError, 
            'validation-success': this.validationSuccess
        }

        this.classes.forEach(reqClass => {
            ret[reqClass] = true;
        });

        return ret;
      },
       validationClasses() {
        return {
            'validation-error': this.hasValidationError, 
            'validation-success': this.validationSuccess
        }
      },
      supressingError() {
          return this.validationConfig.supressErrorOnFirstRound && this.validationRound < (this.validationConfig.supressErrorUntil || 2);
      },
      hasValidationError() {
          return !this.state.result && !this.supressingError;
      },
      validationSuccess() {
        //   console.log(this.validationRound);
        //   console.log((this.validationRound > 0 && !this.hasValidationError));
          return (this.validationRound > ((this.validationConfig.supressErrorUntil || 2) - 1) && !this.hasValidationError);
      },
      hasValidationMessage() {
          return this.state.message && this.state.message.length > 0 && !this.supressingError;
      },
      validationMessage() {
        //   console.log(this.state);
          return this.state.message;
      },
      hasValidationHint() {
          return this.state.hint && this.state.hint.length > 0 && !this.supressingError;
      },
      validationHint() {
          return this.state.hint;
      },
      charCounterVisible() {
          return this.type == 'textarea' && this.textAreaMaxChars > 0;
      }
  },
  methods: {
    async validate(config, triggeredDelayed) {
        // Call without parameter from this Vue component. If called with params, we assume it's called from
        // custom validation logic. Triggered delay if true add an extra flag to the end state to cancel infinite loop.
        // For usage see: dcxa-apps-sso/register micro app component

        config = config || this.config;

        const validationResult = await ValidationFramework.validate(this.input, config);
        
        if (this.validationRound == 0) {
            // do some crazy stuff if required on the first validation round (upon initialization)    
        }

        this.state = validationResult;

        this.validationRound++;        

        if (triggeredDelayed) {
            this.state.delayedResult = true;
        }

        if (this.supressingError && typeof (this.validationConfig.supressErrorState) !== 'undefined')  {
            this.state.result = this.validationConfig.supressErrorState;            
        }
        
        this.$emit('stateChanged', this.state)
    },
    clear(resetValidation) {
        this.input = '';
        if (resetValidation) {
            this.validationRound = 0;
        }
    }
  },
  watch: {
      source: function(val, oldVal) {
        //   console.log('source changed for ' + this.title);
          this.input = this.source;
      },
      input: function(val, oldVal) {
        //   console.log('input changed: '  + val);
          this.validate();
      },
      'config.validation': function(val, oldVal) {
        //   console.log('validation config change for ' + this.title)
        //   this.validate();
      }
  },
  mounted() {
    this.validationRound = 0;
    this.input = this.source;
  }
}
</script>

<style lang="scss">

@import "../../../../../styles/_colors.scss";

.editor-row {
    margin: 0 0 24px 0;
    line-height: 40px;
    font-size: 16px;

    display: flex;
    justify-content: space-between;

    width: 100%;
    height: auto;
}

.editor-row .property {
    font-weight: 500;
    color: $slate;
    display: inline-block;
    width: 230px;
}

.editor-row .property.float {
    display: block;
    position: absolute;
    top: -30px;
    font-size: 12px;
    color: $slate;
}


.editor-row {
    .value {
        width: 100%;
        position: relative;
        display: inline-block;

        .char-counter {
            position: absolute;
            top: -30px;
            right: 0;
            font-size: 12px;
            color: $slate;

            user-select: none;

            &.error {
                color: $red-light;
            }
        }
    }
}

.validation-message {
    font-size: 12px;
    color: $red-light;
    line-height: 12px;
    vertical-align: top;
    padding-top: 2px;
    display: inline-block;
    position: absolute;
}

</style>
