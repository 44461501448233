<template>
    <dcxa-responsive-table :data="tableSource"
                           :is-loading="!isLoaded"
                           :has-error="hasError"
                           :title="tableTitle"
                           titleSize="h6"
                           :show-filter-box="false"
                           :special-states="specialStates"
                           :show-pagination="false">
        <dcxa-simple-table slot-scope="{context}" :context="context" :row-selection="false" :wrap-column-headers="true">
            <template slot="columns" slot-scope="{row}">
                <dcxa-responsive-table-column prop="Quarter" label="Quarter" width="calc((100% - 86px) / 3)" :sortable="false" />
                <dcxa-responsive-table-column prop="Amount" label="Amount" width="calc((100% - 86px) / 3)" type="Number" :sortable="false">
                    {{row.Amount | dcxa-currency-filter('USD', true) }}
                </dcxa-responsive-table-column>
                <dcxa-responsive-table-column prop="Balance" label="Balance" width="calc((100% - 86px) / 3)" type="Number" :sortable="false">
                    {{row.Balance | dcxa-currency-filter('USD', true) }}
                </dcxa-responsive-table-column>
                <dcxa-responsive-table-column prop="ExpirationDateFormatted" label="Expiration" width="86px" type="Date" :sortable="false" />
            </template>
        </dcxa-simple-table>
        <template slot="has-error">
            <dcxa-special-state-container v-if="!isAuthorized" icon="far fa-user-lock" >
                <span>Please sign in to see your accrual amounts.</span>
            </dcxa-special-state-container>
            <dcxa-special-state-container v-else-if="!isEligible" icon="far fa-frown">
                <span>
                    This account is not eligible for the MDF program.<br/>
                    For more information on eligibility, please see the <dcxa-text-link :is-target-blank="true" :secondary-target="programOverviewLinkTarget">Program Overview</dcxa-text-link>.
                </span>
            </dcxa-special-state-container>
            <dcxa-special-state-container v-else icon="far fa-frown">
                <span>
                    Our systems returned an error. Please try again.<br/>
                    <dcxa-text-link :secondary-target="contactUsLinkTarget">Contact us</dcxa-text-link> or <dcxa-text-link :secondaryTarget="callUsNumberLink">call us</dcxa-text-link> if the problem persists.
                </span>
            </dcxa-special-state-container>
        </template>
    </dcxa-responsive-table>
</template>

<script>
    import _ from "lodash";
    import {mapGetters} from "vuex";

    export default {
        name: "MdfOrgLevelActorAccrualInfoTable",
        props: {
            specialStates: {
                type: Object
            },
            tableSource: {
                type: Array
            },
            tableTitle: {
                type: String
            },
            isLoaded: {
                type: Boolean
            },
            hasError: {
                type: Boolean
            },
            isAuthorized: {
                type: Boolean
            },
            isEligible: {
                type: Boolean
            }
        },
        computed: {
            ...mapGetters({
                programOverviewLinkTarget: 'getProgramOverviewLinkTarget',
                contactUsLinkTarget: 'getContactUsLinkTarget',
                callUsNumberLink: 'getCallUsNumberLink'
            })
        }
    }
</script>