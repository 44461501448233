<template>
    <el-popover :placement="placement" trigger="hover" class="dcxa-icon-with-tooltip" :class="classes" popper-class="dcxa-tooltip">
        <slot>
            Tooltip
        </slot>
        <i slot="reference" class="tooltip-opener-element" :class="icon" aria-hidden="true"></i>
    </el-popover>
</template>

<script>
    export default {
        name: 'DcxaIconWithTooltip',
        props: {
            'classes': {
                default: () => 'large'
            },
            'icon': {
                type: String,
                default: () => 'far fa-info-circle'
            },
            'placement': {
                type: String,
                default: () => 'right'
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../../../styles/_colors.scss";
    @import "../../styles/_mixins.scss";
    .floating-label {
        .dcxa-icon-with-tooltip{
            position: absolute;
            right: 0;
        }
    }
    .dcxa-icon-with-tooltip {
       line-height: 14px;

        &.large {
            font-size: 16px;
        }

        &.medium {
            font-size: 14px;
        }

        &.small {
            font-size: 12px;
        }

        .tooltip-opener-element {
            color: $orange;
            cursor: pointer;
        }
    }

</style>
