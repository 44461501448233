import Vue from 'vue';
import VueMq from 'vue-mq';
import BREAKPOINTS from './breakpoints.json';

Vue.use(VueMq, {
    breakpoints: BREAKPOINTS
});

// Exmaple usage of the helper functions: 
// <h4 v-if="$screensUpTo('md')">Subtitle shown up until md screen</h4>
// <h4 v-if="$screensFrom('lg')">Different subtitle shown from lg screens</h4>
// <h4 v-if="$screenIs('xl')">This is shown only on xl screen</h4>
// <h5 v-if="$screensBetween('sm', 'lg')">Subsubtitle between sm and lg</h5>


Vue.prototype.$screensUpTo = function(screenSize) {
    const maxSize = BREAKPOINTS[screenSize];
    const currentScreen = this.$mq;
    if (!maxSize) {
        console.warn("Using unknown screensize '" + screenSize + "'. Please fix usage.");
        return false;
    }
    if (BREAKPOINTS[currentScreen] <= maxSize) {
        return true;
    } else {
        return false;
    }
}

Vue.prototype.$screensFrom = function(screenSize) {
    const minSize = BREAKPOINTS[screenSize];
    const currentScreen = this.$mq;
    if (!minSize) {
        console.warn("Using unknown screensize '" + screenSize + "'. Please fix usage.");
        return false;
    }
    if (BREAKPOINTS[currentScreen] >= minSize) {
        return true;
    } else {
        return false;
    }
}

Vue.prototype.$screensBetween = function(lowerScreen, upperScreen) {
    return this.$screensFrom(lowerScreen) && this.$screensUpTo(upperScreen);
}

Vue.prototype.$screenIs = function(screenSize) {
    if (!BREAKPOINTS[screenSize]) {
        console.warn("Using unknown screensize '" + screenSize + "'. Please fix usage.");
        return false;
    }
    if (screenSize == this.$mq) {
        return true;
    } else {
        return false;
    }
}