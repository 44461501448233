<template>
    <div class="dcxa-case-card-list">

        <dcxa-case-watched-changing-error-dialog :is-watch-dialog="!isWatchedById(caseIdOfWatchedChangingError)" :visible="caseIdOfWatchedChangingError !== null" :in-progress="caseIdOfWatchedChangingProgress !== null" :case-id="caseIdOfWatchedChangingError"
                                         @onCancelDialog="cancelWatchedChangingErrorDialog" @onRetryWatching="retryWatchingCase" @onRetryUnwatching="retryUnwatchingCase" />

        <dcxa-responsive-table :data="cases" :titleId="titleId" :title="title" filter-box-text="Filter cases" filter-box-id="i_filter" :page-size="pageSize" ref="responsiveTable"
                               :show-page-size-options="showPageSizeOptions" :show-sort-dropdown-for-desktop="true" :show-pagination="showPagination"
                               :has-error="hasError" :is-loading="isLoading" :special-states="specialStates" :show-tools="showTools"
                               :default-sort-by-field="defaultSortByField" :default-sort-direction="defaultSortDirection">

            <dcxa-card-list slot-scope="{context}" :context="context" :id="cardListId">

                <template slot="tools" slot-scope="{row}">
                    <template>
                        <dcxa-loading-spinner class="iswatched-changing-indicator" v-if="isWatchedChangingForCase(row)" />
                        <i class="fal fa-eye" v-else-if="isWatchedIconVisible(row)" />
                    </template>

                    <el-dropdown trigger="click" @command="actionCommandHandler">
                        <div class="el-dropdown-link">
                            <i class="dcxa-fa fal fa-ellipsis-h" aria-hidden="true"></i>
                        </div>
                        <template v-slot:dropdown>
                            <el-dropdown-menu class="actions-dropdown">
                                <template v-for="(action, i) in caseActions">
                                    <el-popover placement="left" trigger="hover" :content="action.disabledTooltip" :disabled="!action.isDisabled(row)">
                                        <span slot="reference">
                                            <el-dropdown-item v-if="action.isVisible(row)"
                                                              :class="action.style"
                                                              :disabled="action.isDisabled(row)"
                                                              :key="i"
                                                              :command="{ handler: action.clickHandler, context: row, }">
                                                <i :class="action.icon" aria-hidden="true"></i>
                                                <p data-dd-privacy="mask-user-input">{{ action.title }}</p>
                                            </el-dropdown-item>
                                        </span>
                                    </el-popover>
                                </template>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </template>

                <template slot="title" slot-scope="{row}">
                    <dcxa-text-link @click="goToCase(row)" :useCustomHandler="true" :secondaryTarget="getCaseTargetUrl(row)" class="large">
                        <v-clamp :max-lines="3" autoresize="">
                            <template slot="before">
                                <span class="card-list-title-first-part" data-dd-privacy="mask-user-input">#{{row.CaseNumber}}</span><span class="dcxa-pipe"></span>
                            </template><!--
                        -->{{row.Subject}}
                        </v-clamp>
                    </dcxa-text-link>
                </template>

                <template slot="metadata" slot-scope="{row}">

                    <dcxa-card-metadata prop="CaseNumber" label="Case #" :visible="false" />
                    <dcxa-card-metadata prop="Subject" label="Subject" :visible="false" />

                    <slot :row="row" />

                </template>

            </dcxa-card-list>

        </dcxa-responsive-table>


    </div>
</template>
<script>
    import { mapGetters } from 'vuex'

    const dcxaCore = require('@dcxa/dcxa-core');
    const { Configs, Utilities, Utils  } = dcxaCore;

    import VClamp from 'vue-clamp';
    import moment from 'moment';

    export default {
        name: 'DcxaCaseCardList',
        components: {
            VClamp
        },
        props: [
            'cases',
            'title',
            'titleId',
            'tableId',
            'hasError',
            'isLoading',
            'pageSize',
            'showTools',
            'showPageSizeOptions',
            'showPagination',
            'caseUrl',
            'specialStates',
            'hideReopenCase',
            'hideWatchCase',
            'hideUnwatchCase',
            'hideCreateSubcase',
            'hideCloseCase',
            'defaultSortByField',
            'defaultSortDirection'
        ],
        data() {
            return {
                paginationInfo: {
                    pageSize: this.pageSize,
                    pageSizes: [10, 25, 50],
                    currentPage: 1
                },
                caseActions: [
                    {
                        title: 'Watch',
                        icon: 'fal fa-eye',
                        isDisabled: () => false,
                        isVisible: (caseObj) => !this.hideWatchCase && !this.isWatched(caseObj) && !this.isClosed(caseObj),
                        clickHandler: this.watchCase
                    },
                    {
                        title: 'Unwatch',
                        icon: 'fal fa-eye-slash',
                        isDisabled: () => false,
                        isVisible: (caseObj) => !this.hideUnwatchCase && this.isWatched(caseObj) && !this.isClosed(caseObj),
                        clickHandler: this.unwatchCase
                    },
                    {
                        title: 'Reopen Case',
                        icon: 'fal fa-folder',
                        id:'b_reopen_case',
                        disabledTooltip: 'Cases closed for more than 15 days cannot be reopened.',
                        isDisabled: (caseObj) => !this.canBeReopened(caseObj),
                        isVisible: (caseObj) => !this.hideReopenCase && this.isClosed(caseObj),
                        clickHandler: this.reopenCase
                    },
                    {
                        title: 'Create Subcase',
                        icon: 'fal fa-plus',
                        id:' b_create_subcase',
                        isDisabled: () => false,
                        isVisible: (caseObj) => !this.hideCreateSubcase && !this.isUpgradeCaseType(caseObj),
                        clickHandler: this.createSubcase
                    },
                    {
                        title: 'Close Case',
                        icon: 'fal fa-times-square',
                        style: 'red',
                        id:' b_close_case',
                        isDisabled: () => false,
                        isVisible: (caseObj) => !this.hideCloseCase && !this.isClosed(caseObj),
                        clickHandler: this.closeCase
                    },
                ],
                caseIdOfWatchedChangingError: null,
                caseIdOfWatchedChangingProgress: null
            }
        },
        computed: {
            ...mapGetters({
                closingCaseActionDialog: 'getClosingCaseActionDialog',
                reopeningCaseActionDialog: 'getReopeningCaseActionDialog',
                contactId: 'getContactId'
            }),
            getCaseTargetUrl() {
                return (caseObj) => {
                    let target = caseObj.url;
                    if (this.caseUrl) {
                        target = this.caseUrl;
                        target = target.replace('CASE_ID', caseObj.Id);
                        target = target.replace('CASE_NUMBER', caseObj.CaseNumber);
                    }
                    return target;
                }
            },
            isWatchedIconVisible() {
                return (caseObj) => {
                    return this.isWatched(caseObj) && !this.isWatchedChangingForCase(caseObj);
                }
            },
            isWatchedChangingForCase() {
                return (caseObj) => {
                    return this.caseIdOfWatchedChangingProgress === caseObj.Id;
                }
            },
            isWatchedById() {
                return (caseId) => {
                    const caseObj = _.filter(this.cases, (c) => { return c.Id === caseId })[0] || {};
                    return this.isWatched(caseObj);
                }
            },
            isWatched() {
                return (caseObj) => {
                    return caseObj.IsWatched === true;
                }
            },
            isClosed() {
                return (caseObj) => {
                    return caseObj.IsClosed === true;
                }
            },
            canBeReopened() {
                return (caseObj) => {
                    if (caseObj.ClosedDate !== null) {
                        var in15Days = moment().add(-16, 'days');
                        var dateToCheck = moment(caseObj.ClosedDate);

                        return dateToCheck.isAfter(in15Days, 'days', true);
                    }

                    return false;
                }
            },
            isUpgradeCaseType() {
                return (caseObj) => {
                    return caseObj.IsUpgradeType === true;
                }
            },
            cardListId() {
                if(!this.tableId) {
                    return 'd_case_list'
                }
                return this.tableId
            }
        },
        methods: {
            cancelWatchedChangingErrorDialog() {
                this.caseIdOfWatchedChangingError = null;
            },

            retryWatchingCase(caseId) {
                this.watchCase({Id: caseId});
            },

            retryUnwatchingCase(caseId) {
                this.unwatchCase({Id: caseId});
            },

            goToCase(caseObj) {
                this.$emit('onCaseOpen', caseObj);
            },

            watchCase(caseObj) {
                this.caseIdOfWatchedChangingProgress = caseObj.Id;

                const params = {
                    contactId: this.contactId,
                    caseId: caseObj.Id
                };

                this.$store.dispatch('WATCH_CASE', params).then(response => {
                    this.caseIdOfWatchedChangingError = null;
                    this.$emit('onIsWatchedChange', response);
                }, errorCaseId => {
                    this.caseIdOfWatchedChangingError = errorCaseId;
                }).finally(() => {
                    this.caseIdOfWatchedChangingProgress = null;
                });
            },

            unwatchCase(caseObj) {
                this.caseIdOfWatchedChangingProgress = caseObj.Id;

                const params = {
                    contactId: this.contactId,
                    caseId: caseObj.Id
                };

                this.$store.dispatch('UNWATCH_CASE', params).then(response => {
                    this.caseIdOfWatchedChangingError = null;
                    this.$emit('onIsWatchedChange', response);
                }, errorCaseId => {
                    this.caseIdOfWatchedChangingError = errorCaseId;
                }).finally(() => {
                    this.caseIdOfWatchedChangingProgress = null;
                });
            },

            createSubcase(caseObj) {
                this.$store.dispatch('CREATE_SUBCASE', caseObj.CaseNumber);
            },

            closeCase(caseObj) {
                const params = { caseId: caseObj.Id };

                this.$store.dispatch('CLOSE_CASE', params).then(response => {
                    this.$emit('onCloseCase', response);
                });
            },

            reopenCase(caseObj) {
                const params = { caseId: caseObj.Id };

                this.$store.dispatch('REOPEN_CASE', params).then(response => {
                    this.$emit('onReopenCase', response);
                });
            },

            actionCommandHandler({ handler, context }) {
                handler.call(this, context);
            },

            reset() {
                this.$refs.responsiveTable.reset();
            }
        }
    }
</script>

<style lang="scss">
    @import "../../../../styles/_colors.scss";
    @import "../../../../styles/_utilities.scss";

    .dcxa-case-card-list {
        .dcxa-pipe {
            margin: 7px 8px;
            color: tint($black,20);
            border-left: 1px solid tint($black,20);
            position: relative;
            z-index: 1;
        }

        .card-list-title-first-part {
            font-size: 14px;
        }

        .iswatched-changing-indicator {
            width: 16px;
            height: 16px;
            margin-top: auto;
            margin-bottom: auto;
        }
    }

</style>
