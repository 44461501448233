<template>
    <dcxa-responsive-table :data="tableSource"
                           :is-loading="!isLoaded"
                           :has-error="hasError"
                           :title="tableTitle"
                           titleSize="h4"
                           :special-states="specialStates"
                           default-sort-by-field="RequestDateFormatted"
                           default-sort-direction="desc">
        <dcxa-simple-table slot-scope="{context}" :context="context" :row-selection="false" :wrap-column-headers="true" :row-expander="true" row-expander-column-width="32px">
            <template slot="columns" slot-scope="{row}">
                <dcxa-responsive-table-column prop="RequestDateFormatted" label="Request Date" type="Date" width="20%" />
                <dcxa-responsive-table-column prop="MarketingType" label="MarketingType" width="calc(45% - 58px - 32px)" />
                <dcxa-responsive-table-column prop="RequestedAmount" label="Requested Amount" type="Number" width="18%">
                    {{row.RequestedAmount | dcxa-currency-filter('USD', true) }}
                </dcxa-responsive-table-column>
                <dcxa-responsive-table-column prop="ApprovedAmount" label="Approved Amount" type="Number" width="17%">
                    {{row.ApprovedAmount | dcxa-currency-filter('USD', true) }}
                </dcxa-responsive-table-column>
                <dcxa-responsive-table-column prop="Status" label="Status" width="58px">
                    <dcxa-table-cell :icon-classes="statusIcon(row.Status)"></dcxa-table-cell>
                </dcxa-responsive-table-column>
            </template>
            <template slot="expansion-panel" slot-scope="{row}">
                <dcxa-responsive-table-column prop="RequestedBy" label="Requested By" :skip-registration="true" />
                <dcxa-responsive-table-column prop="Description" label="Activity Description" :skip-registration="true" />
                <dcxa-responsive-table-column prop="StartDateFormatted" label="Activity Start Date" :skip-registration="true" />
                <dcxa-responsive-table-column prop="EndDateFormatted" label="Activity End Date" :skip-registration="true" />
                <dcxa-responsive-table-column prop="Country" label="Activity Country" :skip-registration="true" />
            </template>
        </dcxa-simple-table>
        <template slot="has-error">
            <dcxa-special-state-container v-if="!isAuthorized" icon="far fa-user-lock" >
                <span>Please sign in to see your accrual amounts.</span>
            </dcxa-special-state-container>
            <dcxa-special-state-container v-else-if="!isEligible" icon="far fa-frown">
                <span>
                    This account is not eligible for the MDF program.<br/>
                    For more information on eligibility, please see the <dcxa-text-link :is-target-blank="true" :secondary-target="programOverviewLinkTarget">Program Overview</dcxa-text-link>.
                </span>
            </dcxa-special-state-container>
            <dcxa-special-state-container v-else icon="far fa-frown">
                <span>
                    Our systems returned an error. Please try again.<br/>
                    <dcxa-text-link :secondary-target="contactUsLinkTarget">Contact us</dcxa-text-link> or <dcxa-text-link :secondaryTarget="callUsNumberLink">call us</dcxa-text-link> if the problem persists.
                </span>
            </dcxa-special-state-container>
        </template>
    </dcxa-responsive-table>
</template>

<script>
    import {mapGetters} from "vuex";

    const dcxaCore = require('@dcxa/dcxa-core');
    const { Utilities, Configs  } = dcxaCore;

    export default {
        name: "MdfOrgLevelActorWithdrawalTable",
        props: {
            specialStates: {
                type: Object
            },
            tableSource: {
                type: Array
            },
            tableTitle: {
                type: String
            },
            isLoaded: {
                type: Boolean
            },
            hasError: {
                type: Boolean
            },
            isAuthorized: {
                type: Boolean
            },
            isEligible: {
                type: Boolean
            },
        },
        computed: {
            ...mapGetters({
                programOverviewLinkTarget: 'getProgramOverviewLinkTarget',
                contactUsLinkTarget: 'getContactUsLinkTarget',
                callUsNumberLink: 'getCallUsNumberLink'
            }),
            statusIcon() {
                return (status) => {
                    return (Utilities.object.getDeepValue(Configs.Mappings.MdfWithdrawalStatus, status) || '') + " mdf-withdrawal-status";
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "../../../../../styles/_colors.scss";
    @import "../../../../../styles/_utilities.scss";

    .dcxa-table-cell-with-icon {
        display: block !important;

        .mdf-withdrawal-status {
            font-size: 20px !important;

            &.pending {
                color: $blue;
            }

            &.approved {
                color: $green;
            }

            &.rejected {
                color: $red;
            }
        }
    }

</style>