<template>
    <transition name="dcxa-modal-fade">
        <div class="dcxa-modal-mask" v-show="visible">
            <div class="dcxa-modal-wrapper" :style="styleVars" @click.self="onModalSelfClick">
                <div class="dcxa-modal-component" :class="type" v-if="visible">
                    <dcxa-text-link class="dcxa-modal-close-button-link" :disabled="isDisabled" @click="onModalClose('cancel')" v-if="showCloseButton" id="btn_close">
                        <i class="far fa-times dcxa-modal-close-button" :class="{'disabled': isDisabled}" aria-hidden="true"  />
                    </dcxa-text-link>
                    <div class="dcxa-modal-content" ref="content">
                        <slot>
                            <div v-html="content"></div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import $ from 'jquery';
    import Vue from 'vue'

    export default {
        name: "DcxaModal",

        data() {
            return {
                overflown: false,
                isLoading: false
            }
        },

        props: {
            closeButton: {
                type: Boolean,
                default: () => false
            },
            visible: {
                type: Boolean,
                default: () => false
            },
            type: {
                type: String,
                default: () => ''
            }
        },

        computed: {
            styleVars() {
                return {
                    '--bottom': this.$screenIs('xs') && this.type !== 'loading' && !this.overflown ? null : '0'
                }
            },

            showCloseButton() {
                return (this.closeButton && this.type === 'custom') || (this.$screenIs('xs') && this.closeButton && this.type !== 'loading');
            },

            isDisabled() {
                return this.isLoading;
            }
        },

        watch: {
            visible: function(val) {
                if (val === true) {
                    this.checkOverlow();
                }
            }
        },

        methods: {
            doClose(action) {
                if (!this.visible) return;
                this.visible = false;

                if (action) {
                    this.callback(action, this);
                }
            },

            onModalSelfClick() {

            },

            onModalClose(action) {
                this.$dcxaModal.close(null, action);
            },

            checkOverlow() {
                Vue.nextTick(() => {
                    let bounding = this.$refs.content.getBoundingClientRect();
                    this.overflown = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
                });
            },

            resizeCallback() {
                this.checkOverlow();
            }
        },

        mounted() {
            window.addEventListener('resize', this.resizeCallback);
        },

        beforeDestroy () {
            this.$el.parentNode.removeChild(this.$el);
            window.removeEventListener('resize', this.resizeCallback);
        },
    }
</script>


<style lang="scss">
    @import "../../../../../styles/utilities";
    @import "../../../../../styles/colors";

    $bottomOverflown: var(--bottom);

    .dcxa-modal-fade {
        opacity: 0;
    }

    .dcxa-modal-fade-leave-active {
        opacity: 0;
    }

    .dcxa-modal-fade-enter .dcxa-modal-component    ,
    .dcxa-modal-fade-leave-active .dcxa-modal-component {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .dcxa-modal-mask {
        position: fixed;
        z-index: 2000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .25);
        display: table;
        transition: opacity .3s ease;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            /* IE10+ CSS styles go here */
            height: 100vh;
        }

        .dcxa-modal-wrapper {
            position: fixed;
            top: 0;
            bottom: $bottomOverflown;
            left: 0;
            right: 0;
            text-align: center;
            overflow: auto;

            &::after {
                content: "";
                display: inline-block;
                height: 100%;
                width: 0;
                vertical-align: middle;
            }

            .dcxa-modal-component {
                position: relative;
                display: inline-block;
                width: 100%;
                padding-bottom: 10px;
                vertical-align: middle;
                background-color: white;
                border-radius: 3px;
                box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
                font-size: 16px;
                text-align: center;
                overflow: hidden;
                backface-visibility: hidden;

                &.loading {
                    width: auto;
                }

                &.simple {

                }

                &.error {

                }

                &.custom {

                }

                .dcxa-modal-close-button-link {
                    display: block;

                    position: fixed;
                    z-index: 2000;
                    top: 0;
                    right: 0;

                    .dcxa-modal-close-button {
                        width: 12px;
                        height: 12px;
                        line-height: 12px;
                        margin-top: 12px;
                        margin-right: 12px;

                        color: tint($black, 30);

                        &:hover {
                            color: tint($black, 50);
                        }

                        &.disabled {
                            color: tint($black, 30);
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(md) {
        .dcxa-modal-mask {
            .dcxa-modal-wrapper {
                bottom: $bottomOverflown;

                .dcxa-modal-component {
                    margin-top: 64px;
                    margin-bottom: 64px;
                    width: auto;

                    &.loading {

                    }

                    &.simple {

                    }

                    &.error {

                    }

                    &.custom {

                    }

                    .dcxa-modal-close-button-link {
                        position: absolute;
                    }
                }
            }
        }
    }

</style>
