<template>
    <div>
        <div ref="chartElement" class="dcxa-donut-chart"></div>
    </div>
</template>

<script>
    import Vue from 'vue'

    export default {
        name: 'DcxaDonutChart',
        props: {
            values: {
                type: Array,
                default: () => [100]
            },
            colors: {
                type: Array,
                default: () => ['#008cff', '#b3b3b3', '#e6e6e6']
            }
        },
        watch: {
            'values': function() {
                this.draw();
            }
        },
        methods: {
            draw() {
                let isEmpty = this.values[0] === 0;
                let values = isEmpty ? [100] : this.values;
                let colors = isEmpty ? ['#ffb566'] : this.colors;

                let targetDivContainer = this.$el;
                let targetDiv = d3.select(targetDivContainer).select('.dcxa-donut-chart');

                targetDiv.select('svg').remove();

                const chartWidth = 85;
                const chartHeight = 85;
                const chartRadius = chartWidth / 2;

                var arc = d3.arc()
                    .innerRadius(40)
                    .outerRadius(35);

                const svg = targetDiv
                    .append('svg')
                    .attr('width', chartWidth)
                    .attr('height', chartHeight);

                const g = svg.append("g")
                    .attr("transform", "translate(" + chartRadius + "," + chartRadius +")")

                var arcs = d3.pie().sort(null)(values);

                g.selectAll("path")
                    .data(arcs)
                    .enter()
                    .append("path")
                    .style("fill", function(d, i){ return colors[i]; })
                    .attr("d", arc);

                if (isEmpty) {
                    var subtext = svg.append("text")
                        .attr("text-anchor", "middle")
                        .attr("dominant-baseline", "text-top")
                        .attr("fill", "#5a656d")
                        .attr("transform", "translate(" + chartRadius + "," + (chartRadius - (chartRadius / 3)) +")");

                    subtext.append("tspan")
                        .attr("x", 0)
                        .attr("font-size", "18px")
                        .attr("line-height", "18px")
                        .attr("font-weight", "bold")
                        .attr("dy", 4)
                        .text('0%');

                    subtext.append("tspan")
                        .attr("x", 0)
                        .attr("font-size", "10px")
                        .attr("line-height", "11px")
                        .attr("dy", 14)
                        .text('Active');

                    subtext.append("tspan")
                        .attr("x", 0)
                        .attr("font-size", "10px")
                        .attr("line-height", "11px")
                        .attr("dy", 10)
                        .text('commitment');

                    subtext.append("tspan")
                        .attr("x", 0)
                        .attr("font-size", "10px")
                        .attr("line-height", "11px")
                        .attr("dy", 10)
                        .text('remaining');
                }
                else {
                    svg.append("text")
                        .attr("text-anchor", "middle")
                        .attr("dominant-baseline", "middle")
                        .attr("font-size", "18px")
                        .attr("font-weight", "bold")
                        .attr("fill", "#5a656d")
                        .attr("transform", "translate(" + chartRadius + "," + chartRadius +")")
                        .text(values[0] + '%');
                }
            }
        },

        mounted() {
            this.draw();
        }
    }
</script>

<style lang="scss">

    .dcxa-donut-chart {

        .value-percentage {
            font-size: 18px;
            line-height: 18px;
            color: #5a656d;
        }

        .value-message {
            font-size: 10px;
            line-height: 11px;
            color: #5a656d;
        }

    }

</style>
