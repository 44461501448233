<template>
    <el-switch class="dcxa-switch" :id="id" :name="name"
               :disabled="disabled"
               v-bind:value="value"
               v-on:change="updateValue">
    </el-switch>
</template>

<script>
    export default {
        name: "DcxaSwitch",
        data() {
            return {
                currVal: false
            }
        },
        props: [
            'id',
            'name',
            'value',
            'disabled'
        ],
        watch: {
            value: function(val) {
                this.updateValue(val || false);
            }
        },
        methods: {
            updateValue: function (value) {
                this.currVal = (value || false);
                this.$emit('change', value || false);
            }
        }
    }
</script>

<style lang="scss">
    @import "../../../../styles/_colors.scss";

    .dcxa-switch {
        &.el-switch {
            .el-switch__core {
                border-color: tint($black, 60);
                background-color: tint($black, 60);
                height: 24px;
                border-radius: 12px;

                &::after {
                    width: 18px;
                    height: 18px;
                    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
                    background-color: $white;
                    top: 2px;
                    left: 2px;
                }

                &:hover {
                    border-color: tint($black, 50);
                    background-color: tint($black, 50);
                }
            }

            &.is-checked {
                .el-switch__core {
                    border-color: tint($mint, 100);
                    background-color: tint($mint, 100);

                    &::after {
                        margin-left: -20px;
                        left: 100%;
                    }

                    &:hover {
                        border-color: tint($mint, 70);
                        background-color: tint($mint, 70);
                    }
                }
            }

            &.is-disabled {
                .el-switch__core {
                    border-color: tint($black, 30);
                    background-color: tint($black, 30);

                    &:hover {
                        border-color: tint($black, 30);
                        background-color: tint($black, 30);
                    }
                }
            }
        }
    }

</style>