var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-card-list" },
    [
      _vm.context.isLoading
        ? _c("dcxa-content-loader", {
            attrs: {
              type: "CardList",
              "number-of-columns": _vm.numberOfLoadingColumns,
              title: _vm.context.title
            }
          })
        : _vm._l(_vm.context.data, function(row, idx) {
            return _c("div", { key: idx, class: _vm.classes(row) }, [
              _c("div", { staticClass: "top-row" }, [
                _c(
                  "div",
                  { staticClass: "title-container" },
                  [_vm._t("title", null, { row: row })],
                  2
                ),
                _vm._v(" "),
                _vm.$screensFrom("md")
                  ? _c(
                      "div",
                      { staticClass: "card-list-tools-container" },
                      [_vm._t("tools", null, { row: row })],
                      2
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bottom-row" }, [
                _c(
                  "div",
                  { staticClass: "metadata-container" },
                  [
                    _c(
                      "card-list-row",
                      { attrs: { data: row } },
                      [_vm._t("metadata", null, { row: row })],
                      2
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.$screensUpTo("xs")
                  ? _c(
                      "div",
                      { staticClass: "card-list-tools-container" },
                      [_vm._t("tools", null, { row: row })],
                      2
                    )
                  : _vm._e()
              ])
            ])
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-796a598b", { render: render, staticRenderFns: staticRenderFns })
  }
}