<template>
    <dcxa-responsive-table :data="groupedAccruedData"
                           :is-loading="!isLoaded"
                           :has-error="hasError"
                           :show-filter-box="false"
                           :special-states="specialStates"
                           :show-pagination="false">
        <dcxa-simple-table slot-scope="{context}" :context="context" :row-selection="false" :wrap-column-headers="true" :row-expander="true" row-expander-column-width="32px">
            <template slot="columns" slot-scope="{row}">
                <dcxa-responsive-table-column prop="FundName" label="Partner Account" :width="accountNameColumnWidth" :sortable="false" />
                <dcxa-responsive-table-column prop="Amount" label="Amount" :width="amountColumnWidth" :sortable="false">
                    {{row.Amount | dcxa-currency-filter('USD', true) }}
                </dcxa-responsive-table-column>
                <dcxa-responsive-table-column prop="Balance" label="Balance" :width="balanceColumnWidth" :sortable="false">
                    {{row.Balance | dcxa-currency-filter('USD', true) }}
                </dcxa-responsive-table-column>
            </template>
            <template slot="expansion-panel" slot-scope="{row}">
                <div v-if="$screensFrom('xl')" v-for="q in row.Quarters" class="quarter-fields-row">
                    <div :style="{ width: 'calc(40% - 20px)' }"><span style="color:gray;padding-right:8px;">Quarter</span> {{q.Quarter}}</div>
                    <div :style="{ width: 'calc(60% + 20px - 160px)' }">{{q.Amount | dcxa-currency-filter('USD', true)}}</div>
                    <div :style="{ width: '160px', textAlign: 'right' }"><span style="color:gray;padding-right:8px;">Expiration</span> {{q.ExpirationDateFormatted}}</div>
                </div>
                <template v-else-if="$screensFrom('md')">
                    <dcxa-responsive-table-column label="Quarter" stretchTo="164px" :stick-to-next="true" :skip-registration="true">
                        {{q.Quarter}}
                    </dcxa-responsive-table-column>
                    <dcxa-responsive-table-column label="Amount" stretchTo="164px" :stick-to-next="true" :skip-registration="true">
                        {{q.Amount | dcxa-currency-filter('USD', true)}}
                    </dcxa-responsive-table-column>
                    <dcxa-responsive-table-column label="Expiration" stretchTo="164px" :skip-registration="true">
                        {{q.ExpirationDateFormatted}}
                    </dcxa-responsive-table-column>
                </template>
                <template v-else>
                    <dcxa-responsive-table-column label="Quarter" :stick-to-next="true" :skip-registration="true">
                        {{q.Quarter}}
                    </dcxa-responsive-table-column>
                    <dcxa-responsive-table-column label="Amount" :stick-to-next="true" :skip-registration="true">
                        {{q.Amount | dcxa-currency-filter('USD', true)}}
                    </dcxa-responsive-table-column>
                    <dcxa-responsive-table-column label="Expiration" :skip-registration="true">
                        {{q.ExpirationDateFormatted}}
                    </dcxa-responsive-table-column>
                </template>
            </template>
        </dcxa-simple-table>
        <template slot="has-error">
            <dcxa-special-state-container v-if="!isAuthorized" icon="far fa-user-lock" >
                <span>Please sign in to see your accrual amounts.</span>
            </dcxa-special-state-container>
            <dcxa-special-state-container v-else-if="!isEligible" icon="far fa-frown">
                <span>
                    This account is not eligible for the MDF program.<br/>
                    For more information on eligibility, please see the <dcxa-text-link :is-target-blank="true" :secondary-target="programOverviewLinkTarget">Program Overview</dcxa-text-link>.
                </span>
            </dcxa-special-state-container>
            <dcxa-special-state-container v-else icon="far fa-frown">
                <span>
                    Our systems returned an error. Please try again.<br/>
                    <dcxa-text-link :secondary-target="contactUsLinkTarget">Contact us</dcxa-text-link> or <dcxa-text-link :secondaryTarget="callUsNumberLink">call us</dcxa-text-link> if the problem persists.
                </span>
            </dcxa-special-state-container>
        </template>
    </dcxa-responsive-table>
</template>

<script>
    import {mapGetters} from "vuex";
    import _ from 'lodash';

    export default {
        name: "MdfUberAdminAccrualInfoTable",
        props: {
            specialStates: {
                type: Object
            },
            tableSource: {
                type: Array
            },
            isLoaded: {
                type: Boolean
            },
            hasError: {
                type: Boolean
            },
            isAuthorized: {
                type: Boolean
            },
            isEligible: {
                type: Boolean
            },
        },
        computed: {
            ...mapGetters({
                programOverviewLinkTarget: 'getProgramOverviewLinkTarget',
                contactUsLinkTarget: 'getContactUsLinkTarget',
                callUsNumberLink: 'getCallUsNumberLink'
            }),
            accountNameColumnWidth() {
                if (this.$screenIs('xl')) {
                    return 'calc(40% - 32px)';
                }
                else if (this.$screensFrom('md')) {
                    return 'calc(100% - 32px - 82px - 82px)';
                }
                else {
                    return 'auto';
                }
            },
            amountColumnWidth() {
                if (this.$screenIs('xl')) {
                    return '15%';
                }
                else if (this.$screensFrom('md')) {
                    return '82px';
                }
                else {
                    return 'auto';
                }
            },
            balanceColumnWidth() {
                if (this.$screenIs('xl')) {
                    return '45%';
                }
                else if (this.$screensFrom('md')) {
                    return '82px';
                }
                else {
                    return 'auto';
                }
            },
            groupedAccruedData() {
                let accounts = _.groupBy(this.tableSource, function(d) { return d.FundName; });

                return _.map(accounts, (v,k) => {
                    return {
                        FundName: k,
                        Amount: _.sumBy(v, function(o) { return Number(o.Amount); }),
                        Balance: _.sumBy(v, function(o) { return Number(o.Balance); }),
                        Quarters: v
                    };
                });
            }
        }
    }
</script>

<style lang="scss">
    @import "../../../../../styles/_colors.scss";
    @import "../../../../../styles/_utilities.scss";

    @include breakpoint(md) {
        .expansion-panel {
            padding-left: 48px !important;
            font-size: 14px;

            .quarter-fields-row {
                display: flex;
            }
        }
    }

</style>