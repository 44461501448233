<template>
    <div class="dcxa-contact-card-list">
        <div class="property-section-header">
            <h4>{{ title }}</h4>
        </div>
        <div class="property-section-content" v-if="!isLoading && !hasError">
            <dcxa-contact-card  v-for="(row, idx) in accountTeamMembers" :key="idx" :user="row.User" :role="row.TeamMemberRole" />
        </div>
        <slot name="has-error" v-else-if="hasError">
            <dcxa-special-state-container icon="far fa-frown" text="Unable to load contacts. Please try again."></dcxa-special-state-container>
        </slot>
        <div v-else-if="isLoading">
            <dcxa-content-loader :number-of-columns="4" type="AdvisorySection"></dcxa-content-loader>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContactCardList",
        props: [
            'accountTeamMembers',
            'title',
            'hasError',
            'isLoading'
        ]
    }
</script>

<style lang="scss">
    @import "../../../../styles/_colors.scss";
    @import "../../../../styles/_utilities.scss";

    .dcxa-contact-card-list {
        border-top: #cccccc solid 1px;
        padding: 24px 32px 32px 32px;
        text-align: left;

        .property-section-header{
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            h4 {
                display: inline-block;
                margin: 0;
                font-size: 22px;
                font-weight: bold;
                color: $slate;
            }
        }

        .property-section-content {
            margin-top: 32px;

            
        }
    }

</style>