<template>
  <div class="dcxa-custom-property-value-editor">
    <slot />
    <div class="floating-label-element" :class="{'visible': floatingLabel}">
        <label v-html="floatingTitleText" />
        <dcxa-icon-with-tooltip v-if="hasTooltip" placement="right">
            {{ tooltipContent }}
        </dcxa-icon-with-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DcxaCustomPropertyValueEditor',
  props: {
    placeholder: {
      type: String
    },
    floatingLabel: {
      type: Boolean
    },
    tooltipContent: {
      type: String
    },
    floatingTitle: String
  },
  computed: {
      hasTooltip() {
          return this.tooltipContent && this.tooltipContent.length > 0;
      },
      floatingTitleText() {
        return this.floatingTitle || this.placeholder;
      }
  }
}
</script>

<style lang="scss">
@import "../../../../styles/_utilities.scss";
@import "../../../../styles/_colors.scss";
@import "../../styles/_mixins.scss";

.dcxa-custom-property-value-editor {
  @include floating-label();
  width: 100%;
  .floating-label-element{
    pointer-events: auto;
    .dcxa-icon-with-tooltip {
      position: relative !important;
    }
    width: 100%;
  }
}


</style>
