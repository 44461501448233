var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-custom-property-value-editor" },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "floating-label-element",
          class: { visible: _vm.floatingLabel }
        },
        [
          _c("label", {
            domProps: { innerHTML: _vm._s(_vm.floatingTitleText) }
          }),
          _vm._v(" "),
          _vm.hasTooltip
            ? _c("dcxa-icon-with-tooltip", { attrs: { placement: "right" } }, [
                _vm._v("\n          " + _vm._s(_vm.tooltipContent) + "\n      ")
              ])
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-00e96e00", { render: render, staticRenderFns: staticRenderFns })
  }
}