<template>
    <component :is="titleSize" v-if="hasTitle" class="dcxa-content-title">{{title}}</component>
</template>

<script>
    export default {
        name: "ContentTitle",
        props: {
            title: {
                type: String,
                default: () => ''
            },
            showTitle: {
                type: Boolean,
                default: () => true
            },
            titleSize: {
                type: String,
                default: () => 'h3'
            }
        },
        computed: {
            hasTitle() {
                return this.showTitle && this.title.length > 0;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../../styles/utilities";
    @import "../../../../styles/colors";

    .dcxa-content-title {
        margin: 0 0 24px 0;
    }

    @include breakpoint(md) {
        .dcxa-content-title {
            margin: 0;
        }
    }
</style>