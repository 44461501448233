var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-modal-simple", attrs: { id: _vm.ids.modal_id } },
    [
      _c(
        "div",
        { staticClass: "dcxa-modal-header", attrs: { id: _vm.ids.title_id } },
        [
          _vm.hasIcon
            ? _c("i", {
                staticClass: "fal helper-icon",
                class: _vm.icon,
                attrs: { "aria-hidden": "true" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.$screenIs("xs")
            ? _c("h5", [_vm._v(_vm._s(_vm.title))])
            : _c("h4", [_vm._v(_vm._s(_vm.title))])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dcxa-modal-body", attrs: { id: _vm.ids.body_id } },
        [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.message) } }, [
            _vm._v(_vm._s(_vm.message))
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dcxa-modal-footer" },
        [
          _c("dcxa-modal-buttons", {
            attrs: {
              buttons: _vm.buttons,
              "modal-context": _vm.modalContext,
              ids: _vm.ids
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-52e35b21", { render: render, staticRenderFns: staticRenderFns })
  }
}