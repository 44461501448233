var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dcxa-table",
      class: { "enable-hover-effect": _vm.enableHoverEffect }
    },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.currentPageData,
            "highlight-current-row": false,
            "header-cell-class-name": "dcxa-header-cell",
            "header-row-class-name": "dcxa-header-row",
            "row-class-name": "dcxa-row",
            "empty-text": _vm.emptyText
          },
          on: {
            "sort-change": _vm.shortChanged,
            "row-click": _vm.rowClickHandler
          }
        },
        [_vm._t("table-columns")],
        2
      ),
      _vm._v(" "),
      _vm.data.length > 0 && _vm.pagination
        ? _c(
            "div",
            { staticClass: "dcxa-pagination" },
            [
              _c("div", { staticClass: "page-info" }, [
                _vm._v(
                  "\n            " +
                    _vm._s((_vm.currentPage - 1) * _vm.pageSize + 1) +
                    " - " +
                    _vm._s(
                      Math.min(_vm.currentPage * _vm.pageSize, _vm.data.length)
                    ) +
                    " of " +
                    _vm._s(_vm.data.length) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-size": _vm.pageSize,
                  "page-sizes": [10, 20, 30, 40, 50],
                  layout:
                    _vm.data.length > _vm.pageSize
                      ? "prev, pager, next, sizes"
                      : "sizes",
                  total: _vm.data.length
                },
                on: {
                  "size-change": _vm.handlePageSizeChange,
                  "current-change": _vm.handlePageChange,
                  "update:currentPage": function($event) {
                    _vm.currentPage = $event
                  },
                  "update:current-page": function($event) {
                    _vm.currentPage = $event
                  },
                  "update:pageSize": function($event) {
                    _vm.pageSize = $event
                  },
                  "update:page-size": function($event) {
                    _vm.pageSize = $event
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2b43a0d6", { render: render, staticRenderFns: staticRenderFns })
  }
}