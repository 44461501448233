<template>
  <div class="dcxa-combobox" :class="classes">
    <el-popover :disabled="!popover" trigger="focus" placement="bottom" effect="light" popper-class="dcxa-tooltip">
      <slot name="popoverContent" />

      <template slot="reference">
        <el-select
          :id="id"
          :name="name"
          v-bind:value="value"
          v-on:change="updateValue"
          @visible-change="dropdownVisibility"
          :disabled="disabled"
          :filterable="true"
          no-match-text="Item not found"
          no-data-text="No selectable item"
          :placeholder="placeholder"
          popper-class="dcxa-combobox-dropdown">
          <slot />
        </el-select>
      </template>
      
    </el-popover>

    <label class="floating-label-element" v-if="!hideFloatingLabel" :class="{'visible': showFloatingLabel}">{{placeholderText}}</label>
  </div>
</template>

<script>
export default {
  name: "DcxaCombobox",
  data() {
    return {
      currVal: "",
      placeholderText: this.placeholder,
      showFloatingLabel: this.value && (this.value || "").length > 0
    };
  },
  props: {
    id: {
        type: String
    },
    name: {
        type: String
    },
    value: {
        type: String
    },
    classes: {
        type: Object
    },
    disabled: {
        type: Boolean
    },
    noDataText: {
        type: String
    },
    placeholder: {
        type: String
    },
    popover: {
        type: Boolean
    },
    hideFloatingLabel: {
        type: Boolean,
        default: false
    },
  },
  watch: {
    value: function(val) {
      this.updateValue(val);
    }
  },
  methods: {
    updateValue: function(value) {
      this.currVal = value;
      console.log(value)
      this.$emit("input", value);
      this.showFloatingLabel = true;
    },
    dropdownVisibility: function(visibleStatus) {
      if (!this.currVal) {
        if (visibleStatus) {
          this.showFloatingLabel = true;
          this.placeholder = '';
        } else {
          this.showFloatingLabel = false;
          this.placeholder = this.placeholderText;
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../../../styles/_colors.scss";
@import "../../styles/_mixins.scss";

/////////////////////////
// Variables
/////////////////////////
$large-height: 48px;
$medium-height: 40px;
$small-height: 32px;
$compact-height: 24px;
/////////////////////////
.dcxa-combobox {
  @include floating-label();
  &:focus {
    outline: none;
  }
  /////////////////////////
  // Size variants
  /////////////////////////
  &.medium {
    .el-select {
      font-size: 16px;
      height: $medium-height;
      line-height: $medium-height;
      & .el-input {
        height: $medium-height;
        line-height: $medium-height;
        span.el-input__suffix {
          top: 8px;
        }
        & .el-input__inner {
          font-size: 16px;
          height: $medium-height;
          line-height: $medium-height;
          padding: 0 0 0 16px;
        }
      }
    }
  }
  &.small {
    .el-select {
      font-size: 16px;
      height: $small-height;
      line-height: $small-height;
      & .el-input {
        height: $small-height;
        line-height: $small-height;
         span.el-input__suffix {
          top: 6px;
           .el-input__suffix-inner {
           top: -10px; 
         }
        }
        & .el-input__inner {
          font-size: 16px;
          height: $small-height;
          line-height: $small-height;
          padding: 0 0 0 16px;
        }
      }
    }
  }
  &.compact {
    .el-select {
      font-size: 16px;
      height: $compact-height;
      line-height: $compact-height;
      & .el-input {
        height: $compact-height;
        line-height: $compact-height;
          span.el-input__suffix {
              top: 4px;
                .el-input__suffix-inner {
                top: -12px; 
              }
          }
        & .el-input__inner {
          font-size: 16px;
          height: $compact-height;
          line-height: $compact-height;
          padding: 0 0 0 8px;
        }
      }
    }
  }
  /////////////////////////
  // Validation variants
  /////////////////////////
  &.validation-error {
    .el-select {
      & .el-input {
        .el-input__inner {
          border: solid 1px $error-red;
          padding-right: 48px;
        }
        &::after {
          font-family: "Font Awesome 6 Pro";
          font-size: 12px;
          right: 32px;
          color: $error-red;
          content: "\f00d";
          position: absolute;
        }
      }
    }
  }
  &.validation-success {
    .el-select {
      & .el-input {
        .el-input__inner {
          padding-right: 48px;
        }
        &::after {
          font-family: "Font Awesome 6 Pro";
          font-size: 12px;
          right: 32px;
          top: 0;
          color: $success-green;
          content: "\f00c";
          position: absolute;
        }
      }
    }
  }
  .el-select {
    width: 100%;
    font-size: 16px;
    height: $large-height;
    line-height: $large-height;
    & .el-input {
      height: $large-height;
      line-height: $large-height;
      & .el-input__inner {
        height: $large-height;
        line-height: $large-height;
        padding: 0 0 0 16px;
        border: 1px solid;
        text-overflow: ellipsis;
        font-size: 16px;
      }
    }
    /////////////////////////
    // Utilities
    /////////////////////////
    & {
      border: none;
      &:focus {
        outline: none;
      }
    }
    /////////////////////////
    // Coloring variants
    /////////////////////////
    & .el-input {
      &:focus {
        outline: none;
      }
       .el-input__suffix {
         border-left: 1px solid #ddd;
         top: 10px; 
         right: 10px;
         height: 60%;
         padding-left: 10px;
         display: inline-block; 
         .el-input__suffix-inner {
           position: relative;
           top: -8px; 
         }
      }
      // &:focus .el-input__inner,
      &.is-focus .el-input__inner,
      & .el-input__inner:focus {
         border: 1px solid $mint;
         box-shadow: 0 0 8px 0 rgba($mint, 0.5);
      }
      & .el-input__inner {
        color: $data-gray;
        border-color: tint($data-gray, 20);
        &:hover {
          border-color: $brilliant-blue;
        }
        &:disabled {
          color: tint($data-gray, 60);
          background: tint($data-gray, 5);
          &:hover {
            border-color: tint($data-gray, 20);
          }
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: tint($data-gray, 60);
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            color: tint($data-gray, 60);
          }
          &:-ms-input-placeholder {
            /* IE 10+ */
            color: tint($data-gray, 60);
          }
          &:-moz-placeholder {
            /* Firefox 18- */
            color: tint($data-gray, 60);
          }
        }
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: tint($black, 50);
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: tint($black, 50);
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: tint($black, 50);
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: tint($black, 50);
        }
      }
      &.el-input--suffix {
        .el-select__caret.el-input__icon.el-icon-arrow-up {
          font-family: "Font Awesome 6 Pro" !important;
          transform: none;
          color: shade($mint, 110);
          font-size: 16px;
          &:before {
            content: "\f0d7";
          }
          &.is-reverse {
            color: $mint;
            &:before {
              content: "\f0d7";
              font-weight: 900;
            }
          }
        }
      }
    }
  }
}

.dcxa-combobox-dropdown.el-select-dropdown {
  box-shadow:  0 1px 8px 0 rgba(0, 0, 0, 0.3);
  border: none;
  .el-select-dropdown__list {
    padding: 0;
    .el-select-dropdown__item {
      font-size: 16px;
      color: $data-gray;
      padding: 0 16px;
      margin: 0;
      min-height: 47px;
      line-height: 47px;
      border: solid tint($black, 20);
      border-width: 0 0 1px 0;
      &.hover {
        background: transparent !important;
        &:hover {
          background: tint($black, 10) !important;
        }
      }
      &:hover {
        background: tint($black, 10) !important;
      }
      &.selected {
        font-weight: 500;
        // color: $data-gray;
        color: $mint;
        border-left: 3px solid $mint !important;
      }
      &.is-disabled {
        color: tint($data-gray, 50);
      }
      &:last-of-type {
        border-width: 0;
      }
    }
  }
  .el-select-dropdown__empty {
   text-align: left;
   padding: 16px;
    color: tint($black, 50);
  }
}

.el-popper {
	.popper__arrow {
		display: none;
	}
}
</style>
