import Vue from 'vue'
import vuex from 'vuex'
import router from 'vue-router'

const dcxaCore = require('@dcxa/dcxa-core');
const { Utilities } = dcxaCore;
// dcxaCore.Utils.test();



import ElementUI from 'element-ui';
import './styles/elementUI/elementSetup.scss';
import locale from 'element-ui/lib/locale/lang/en';

Vue.use(router);
Vue.use(ElementUI, { locale });

import { emptyStringOrNullFilter, nullFilter, currencyFilter, dateFilter, yesNoFilter, longDateFilter, capitalize } from './filters'

Vue.filter('dcxaEmptyStringOrNullFilter', emptyStringOrNullFilter);
Vue.filter('dcxaNullFilter', nullFilter);
Vue.filter('dcxaCurrencyFilter', currencyFilter);
Vue.filter('dcxaDateFilter', dateFilter);
Vue.filter('dcxaYesNoFilter', yesNoFilter);
Vue.filter('dcxaLongDateFilter', longDateFilter);
Vue.filter('dcxaCapitalize', capitalize);

import dcxaModalPlugin from './plugins/dcxaModal'
Vue.use(dcxaModalPlugin);

import VueResizeObserver from "vue-resize-observer";
Vue.use(VueResizeObserver);


import './responsive';

import './styles/main.scss'




import TextLabel from './components/basic/TextLabel.vue'
import TextLink from './components/basic/TextLink.vue'
import TextRouterLink from './components/basic/TextRouterLink.vue'
import Admonition from './components/basic/Admonition.vue'
import Button from './components/basic/Button.vue'
import Switch from './components/basic/Switch.vue'
import ToggleButton from './components/basic/ToggleButton.vue'
import Table from './components/basic/Table.vue'
import TableCell from './components/basic/Table/TableCell.vue'
import Input from './components/basic/Input.vue'
import Select from './components/basic/Select.vue'
import Combobox from './components/basic/Combobox.vue'
import Checkbox from './components/basic/Checkbox.vue'
import DatePicker from './components/basic/DatePicker.vue'
import TimePicker from './components/basic/TimePicker.vue'
import Tree from './components/basic/Tree/Tree.vue'
import Expander from './components/basic/Expander.vue'

import ModalButton from './components/basic/Modal/ModalButton.vue'
import ModalButtons from './components/basic/Modal/ModalButtons.vue'
import ModalDialog from './components/basic/ModalDialog.vue'
import ModalDialogButtons from './components/basic/ModalDialogButtons.vue'
import LoadMore from './components/basic/LoadMore.vue'
import Progress from './components/basic/Progress.vue'
import ArcProgress from './components/basic/Charts/ArcProgress.vue'
import SpotProgress from './components/basic/SpotProgress.vue'
import Pagination from './components/basic/Pagination.vue'
import ResponsiveTable from './components/basic/ResponsiveTable/ResponsiveTable.vue'
import ResponsiveTableColumn from './components/basic/ResponsiveTable/ResponsiveTableColumn.vue'
import CardMetadata from './components/basic/ResponsiveTable/CardMetadata.vue'
import SimpleTable from './components/basic/ResponsiveTable/SimpleTable.vue'
import SimpleTableRowExpander from './components/basic/ResponsiveTable/SimpleTableRowExpander.vue'
import CardList from './components/basic/ResponsiveTable/CardList.vue'
import CommentList from './components/basic/ResponsiveTable/CommentList.vue'
import Sorting from './components/basic/Sorting.vue'
import ContentLoader from './components/basic/ContentLoader.vue'
import SpecialStateContainer from './components/basic/SpecialStateContainer.vue'
import ContentTitle from './components/basic/ContentTitle.vue'

import FormEditRow from './components/business/Form/FormEditRow.vue'
import FormInfoRow from './components/business/Form/FormInfoRow.vue'
import PropertyEditor from './components/business/DataForm/PropertyEditor.vue'
import PropertyEditorSection from './components/business/DataForm/PropertyEditorSection.vue'
import PropertyValueEditor from './components/business/DataForm/PropertyValueEditor.vue'
import CustomPropertyValueEditor from './components/basic/CustomPropertyValueEditor.vue';
import PropertyValueViewer from './components/business/DataForm/PropertyValueViewer.vue'
import PropertyViewer from './components/business/DataForm/PropertyViewer.vue'
import InlinePropertyEditorContainer from "./components/business/DataForm/InlinePropertyEditorContainer.vue";
import InlinePropertyEditorSection from "./components/business/DataForm/InlinePropertyEditorSection.vue";

import CaseCardList from './components/business/CaseCardList.vue'
import CaseSummary from './components/business/CaseSummary.vue'
import CaseClosingDialog from './components/business/CaseClosingDialog.vue'
import CaseReopeningDialog from './components/business/CaseReopeningDialog.vue'
import CaseWatchedChangingErrorDialog from './components/business/CaseWatchedChangingErrorDialog.vue'

import SuccessModalBody from './components/basic/ModalBodies/SuccessModalBody.vue'
import TagList from './components/basic/TagList.vue';
import Tabs from './components/basic/Tabs.vue';

import Label from './components/basic/Label.vue';
import Accordion from './components/basic/Accordion.vue';

import LoadingPinner from './components/basic/LoadingSpinner.vue'
import IconWithTooltip from './components/basic/IconWithTooltip.vue'

import BarChart from './components/basic/Charts/Bar.vue';
import PieChart from './components/basic/Charts/Pie.vue';
import DonutChart from './components/basic/Charts/Donut.vue';

import PageHeader from './components/basic/PageHeader.vue'
import ContactCard from './components/business/ContactCard.vue'
import ContactCardList from './components/business/ContactCardList.vue'

import MdfOrgLevelActorAccrualInfoTable from "./components/business/MDF/MdfOrgLevelActorAccrualInfoTable.vue";
import MdfUberAdminAccrualInfoTable from "./components/business/MDF/MdfUberAdminAccrualInfoTable.vue";
import MdfOrgLevelActorWithdrawalTable from "./components/business/MDF/MdfOrgLevelActorWithdrawalTable.vue";
import MdfUberAdminWithdrawalTable from "./components/business/MDF/MdfUberAdminWithdrawalTable.vue";

import Badge from "./components/basic/Badge.vue";


Vue.component('dcxa-text-label', TextLabel);
Vue.component('dcxa-text-link', TextLink);
Vue.component('dcxa-text-router-link', TextRouterLink);
Vue.component('dcxa-admonition', Admonition);
Vue.component('dcxa-button', Button);
Vue.component('dcxa-switch', Switch);
Vue.component('dcxa-toggle-button', ToggleButton);
Vue.component('dcxa-table', Table);
Vue.component('dcxa-table-cell', TableCell);
Vue.component('dcxa-input', Input);
Vue.component('dcxa-select', Select);
Vue.component('dcxa-combobox', Combobox);
Vue.component('dcxa-checkbox', Checkbox);
Vue.component('dcxa-datepicker', DatePicker);
Vue.component('dcxa-timepicker', TimePicker);
Vue.component('dcxa-tree', Tree);
Vue.component('dcxa-expander', Expander);
Vue.component('dcxa-modal-button', ModalButton);
Vue.component('dcxa-modal-buttons', ModalButtons);
Vue.component('dcxa-modal-dialog', ModalDialog);
Vue.component('dcxa-modal-dialog-buttons', ModalDialogButtons);
Vue.component('dcxa-load-more', LoadMore);
Vue.component('dcxa-form-edit-row', FormEditRow);
Vue.component('dcxa-form-info-row', FormInfoRow);
Vue.component('dcxa-progress', Progress);
Vue.component('dcxa-arc-progress', ArcProgress);
Vue.component('dcxa-spot-progress', SpotProgress);
Vue.component('dcxa-pagination', Pagination);
Vue.component('dcxa-responsive-table', ResponsiveTable);
Vue.component('dcxa-responsive-table-column', ResponsiveTableColumn);
Vue.component('dcxa-card-metadata', CardMetadata);
Vue.component('dcxa-simple-table', SimpleTable);
Vue.component('dcxa-simple-table-row-expander', SimpleTableRowExpander);
Vue.component('dcxa-card-list', CardList);
Vue.component('dcxa-comment-list', CommentList);
Vue.component('dcxa-sorting-dropdown', Sorting);
Vue.component('dcxa-content-loader', ContentLoader);
Vue.component('dcxa-special-state-container', SpecialStateContainer);
Vue.component('dcxa-content-title', ContentTitle);

Vue.component('dcxa-success-modal-body', SuccessModalBody);

Vue.component('dcxa-case-card-list', CaseCardList);
Vue.component('dcxa-case-summary', CaseSummary);
Vue.component('dcxa-case-closing-dialog', CaseClosingDialog);
Vue.component('dcxa-case-reopening-dialog', CaseReopeningDialog);
Vue.component('dcxa-case-watched-changing-error-dialog', CaseWatchedChangingErrorDialog);

Vue.component('dcxa-tag-list', TagList);
Vue.component('dcxa-tabs', Tabs);
Vue.component('dcxa-label', Label);
Vue.component('dcxa-accordion', Accordion);

Vue.component('dcxa-property-editor', PropertyEditor);
Vue.component('dcxa-property-editor-section', PropertyEditorSection);
Vue.component('dcxa-property-value-editor', PropertyValueEditor);
Vue.component('dcxa-custom-property-value-editor', CustomPropertyValueEditor);
Vue.component('dcxa-property-value-viewer', PropertyValueViewer);
Vue.component('dcxa-property-viewer', PropertyViewer);
Vue.component('dcxa-inline-property-editor-container', InlinePropertyEditorContainer);
Vue.component('dcxa-inline-property-editor-section', InlinePropertyEditorSection);

Vue.component('dcxa-loading-spinner', LoadingPinner);
Vue.component('dcxa-icon-with-tooltip', IconWithTooltip);

Vue.component('dcxa-bar-chart', BarChart);
Vue.component('dcxa-pie-chart', PieChart);
Vue.component('dcxa-donut-chart', DonutChart);

Vue.component('dcxa-page-header', PageHeader);
Vue.component('dcxa-contact-card', ContactCard);
Vue.component('dcxa-contact-card-list', ContactCardList);

Vue.component('dcxa-mdf-org-level-actor-accrual-info-table', MdfOrgLevelActorAccrualInfoTable);
Vue.component('dcxa-mdf-uber-admin-accrual-info-table', MdfUberAdminAccrualInfoTable);
Vue.component('dcxa-mdf-org-level-actor-withdrawal-table', MdfOrgLevelActorWithdrawalTable);
Vue.component('dcxa-mdf-uber-admin-withdrawal-table', MdfUberAdminWithdrawalTable);

Vue.component('dcxa-badge', Badge);





import SearchResultItem from './components/business/SearchResultItem.vue'


Vue.component('dcxa-search-result-item', SearchResultItem);




module.exports = { ModalDialogStoreModule, CaseCardListStoreModule, ElementUI };
import ModalDialogStoreModule from './store/modules/modaldialog.js'
import CaseCardListStoreModule from './store/modules/casecardlist.js'

export { ModalDialogStoreModule, CaseCardListStoreModule, ElementUI };