var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-checkbox", class: _vm.classes },
    [
      _c(
        "el-popover",
        {
          attrs: {
            disabled: !_vm.popover || !_vm.enabledValidationPopover,
            trigger: "focus",
            placement: "bottom",
            effect: "light",
            "popper-class": "dcxa-tooltip"
          }
        },
        [
          _vm._t("popoverContent"),
          _vm._v(" "),
          _c(
            "template",
            { slot: "reference" },
            [
              _c(
                "el-checkbox",
                {
                  attrs: {
                    id: _vm.id,
                    name: _vm.name,
                    value: _vm.value,
                    disabled: _vm.disabled,
                    placeholder: _vm.placeHolderText,
                    floatingLabel: _vm.floatingTitleText
                  },
                  on: { change: _vm.updateValue }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.contentLabel) +
                      "\n                "
                  ),
                  _vm._t("content")
                ],
                2
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("label", {
        staticClass: "floating-label-element",
        class: { visible: _vm.showFloatingLabel },
        domProps: { innerHTML: _vm._s(_vm.floatingTitleText) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0b0f356b", { render: render, staticRenderFns: staticRenderFns })
  }
}