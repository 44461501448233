var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "dcxa-modal-fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible"
          }
        ],
        staticClass: "dcxa-modal-mask"
      },
      [
        _c(
          "div",
          {
            staticClass: "dcxa-modal-wrapper",
            style: _vm.styleVars,
            on: {
              click: function($event) {
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                return _vm.onModalSelfClick.apply(null, arguments)
              }
            }
          },
          [
            _vm.visible
              ? _c(
                  "div",
                  { staticClass: "dcxa-modal-component", class: _vm.type },
                  [
                    _vm.showCloseButton
                      ? _c(
                          "dcxa-text-link",
                          {
                            staticClass: "dcxa-modal-close-button-link",
                            attrs: {
                              disabled: _vm.isDisabled,
                              id: "btn_close"
                            },
                            on: {
                              click: function($event) {
                                return _vm.onModalClose("cancel")
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "far fa-times dcxa-modal-close-button",
                              class: { disabled: _vm.isDisabled },
                              attrs: { "aria-hidden": "true" }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { ref: "content", staticClass: "dcxa-modal-content" },
                      [
                        _vm._t("default", function() {
                          return [
                            _c("div", {
                              domProps: { innerHTML: _vm._s(_vm.content) }
                            })
                          ]
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-46fc2f33", { render: render, staticRenderFns: staticRenderFns })
  }
}