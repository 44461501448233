var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items.length > 0
    ? _c(
        "div",
        { staticClass: "dcxa-tag-list" },
        [
          _vm._l(_vm.items, function(item) {
            return _c(
              "el-tag",
              {
                key: item.id,
                attrs: { closable: true, type: _vm.type, size: _vm.size },
                on: {
                  close: function($event) {
                    return _vm.removeTag(item)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.titleFormatter(item, _vm.title)))]
            )
          }),
          _vm._v(" "),
          _c(
            "dcxa-text-link",
            {
              staticClass: "clear-filters",
              on: {
                click: function($event) {
                  return _vm.clearAllTags()
                }
              }
            },
            [_vm._v("Clear All Filters")]
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-c9ab0940", { render: render, staticRenderFns: staticRenderFns })
  }
}