var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "text-link",
      class: _vm.caretClassName,
      attrs: { disabled: _vm.disabled },
      on: {
        click: function($event) {
          if (
            $event.ctrlKey ||
            $event.shiftKey ||
            $event.altKey ||
            $event.metaKey
          ) {
            return null
          }
          return _vm.onClick.apply(null, arguments)
        }
      }
    },
    [
      _vm.isSecondaryTargetEnabled
        ? _c(
            "router-link",
            {
              class: _vm.className,
              attrs: { to: _vm.secondaryTarget, target: _vm.target }
            },
            [
              _vm.clamp && !_vm.$screenIs("xs")
                ? [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "bottom",
                          trigger: "hover",
                          disabled: !_vm.textIsClamped,
                          "popper-class": "dcxa-tooltip"
                        }
                      },
                      [
                        _vm._t("default", function() {
                          return [_vm._v("Tooltip")]
                        }),
                        _vm._v(" "),
                        _c(
                          "v-clamp",
                          {
                            attrs: {
                              slot: "reference",
                              "max-lines": 3,
                              autoresize: ""
                            },
                            on: { clampchange: _vm.onClampChange },
                            slot: "reference"
                          },
                          [
                            _vm._t("default", function() {
                              return [_vm._v("Link")]
                            })
                          ],
                          2
                        )
                      ],
                      2
                    )
                  ]
                : _vm._t("default", function() {
                    return [_vm._v("Link")]
                  })
            ],
            2
          )
        : _c(
            "span",
            [
              _vm.clamp && !_vm.$screenIs("xs")
                ? [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "bottom",
                          trigger: "hover",
                          disabled: !_vm.textIsClamped,
                          "popper-class": "dcxa-tooltip"
                        }
                      },
                      [
                        _vm._t("default", function() {
                          return [_vm._v("Tooltip")]
                        }),
                        _vm._v(" "),
                        _c(
                          "v-clamp",
                          {
                            attrs: {
                              slot: "reference",
                              "max-lines": 3,
                              autoresize: ""
                            },
                            on: { clampchange: _vm.onClampChange },
                            slot: "reference"
                          },
                          [
                            _vm._t("default", function() {
                              return [_vm._v("Link")]
                            })
                          ],
                          2
                        )
                      ],
                      2
                    )
                  ]
                : _vm._t("default", function() {
                    return [_vm._v("Link")]
                  })
            ],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3e2c69d0", { render: render, staticRenderFns: staticRenderFns })
  }
}