var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal-dialog" } }, [
    _vm.isVisible
      ? _c(
          "div",
          {
            staticClass: "modal-dialog-mask dcxa-modal-dialog forced-hidden",
            class: _vm.forcedHiddenRemoverClass
          },
          [
            _c(
              "div",
              {
                staticClass: "modal-dialog-wrapper",
                class: _vm.dialogTypeClass
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "modal-dialog-container",
                    class: _vm.dialogTypeClass
                  },
                  [
                    _vm.closeButton
                      ? _c(
                          "div",
                          {
                            staticClass: "modal-dialog-close-button-container"
                          },
                          [
                            _c(
                              "dcxa-text-link",
                              { on: { click: _vm.closeDialog } },
                              [
                                _c("i", {
                                  staticClass: "far fa-times",
                                  attrs: { "aria-hidden": "true" }
                                })
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "modal-dialog-header" },
                      [
                        _vm._t("header", function() {
                          return [
                            _vm.isLoading
                              ? _c("div", { staticClass: "loading-message" }, [
                                  _vm._v(_vm._s(_vm.text))
                                ])
                              : _vm.isSuccess
                              ? _c("div", { staticClass: "success-message" }, [
                                  _vm._v(_vm._s(_vm.text))
                                ])
                              : _vm.isError
                              ? _c("h4", { staticClass: "error-title" }, [
                                  _vm._v(_vm._s(_vm.title))
                                ])
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "modal-dialog-body" },
                      [
                        _vm._t("body", function() {
                          return [
                            _vm.isLoading
                              ? _c("div", { staticClass: "loading-spinner" })
                              : _vm.isSuccess
                              ? _c("div", { staticClass: "success-sign" })
                              : _vm.isError
                              ? [
                                  _c("div", { staticClass: "error-sign" }, [
                                    _c("em", {
                                      staticClass:
                                        "fal fa-exclamation-triangle",
                                      attrs: { "aria-hidden": "true" }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "error-message" }, [
                                    _vm._v(_vm._s(_vm.message))
                                  ])
                                ]
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "modal-dialog-footer" },
                      [
                        _vm._t("footer", function() {
                          return [
                            _vm.isError
                              ? _c("dcxa-modal-dialog-buttons", {
                                  attrs: {
                                    buttons: _vm.buttons,
                                    buttonActionArgs: _vm.buttonActionArgs
                                  }
                                })
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    )
                  ]
                )
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ce053846", { render: render, staticRenderFns: staticRenderFns })
  }
}