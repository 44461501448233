var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-modal-buttons" },
    _vm._l(_vm.buttonsWithType, function(btn, index) {
      return _c("dcxa-modal-button", {
        key: index,
        attrs: { "modal-context": _vm.modalContext, config: btn }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1012a07e", { render: render, staticRenderFns: staticRenderFns })
  }
}