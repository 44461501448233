var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.totalLength > 0
    ? _c(
        "div",
        {
          staticClass: "dcxa-pagination",
          attrs: { "data-dd-privacy": "mask-user-input" }
        },
        [
          _c(
            "div",
            { staticClass: "first-row" },
            [
              _c("div", { staticClass: "page-info" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.currentFrom) +
                    " - " +
                    _vm._s(_vm.currentTo) +
                    " of " +
                    _vm._s(_vm.totalLength) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              !_vm.lessThanSmallestPageSize && _vm.showPageSizeOptions
                ? _c(
                    "div",
                    { staticClass: "page-size-selector" },
                    [
                      _c(
                        "dcxa-select",
                        {
                          staticClass: "small",
                          attrs: { id: _vm.sizeSelectorId },
                          on: { input: _vm.paginationInfoChanged },
                          model: {
                            value: _vm.effectiveDataSource.pageSize,
                            callback: function($$v) {
                              _vm.$set(_vm.effectiveDataSource, "pageSize", $$v)
                            },
                            expression: "effectiveDataSource.pageSize"
                          }
                        },
                        _vm._l(_vm.pageSizeOptions, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.title, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.lessThanViewableItems &&
              _vm.$screenIs("xs") &&
              _vm.responsive
                ? _c("dcxa-button", {
                    staticClass: "medium inline-block scroll-up-button",
                    attrs: { type: "icon", icon: "fas fa-chevron-up" },
                    on: { click: _vm.scrollToTop }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.lessOrEqualThanSelectedPageSize &&
              (_vm.$screensFrom("md") || !_vm.responsive)
                ? _c(
                    "div",
                    { staticClass: "page-links" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          id: _vm.paginationId,
                          "current-page": _vm.effectiveDataSource.currentPage,
                          "page-size": _vm.effectiveDataSource.pageSize,
                          "pager-count": 5,
                          layout: "prev, pager, next",
                          total: _vm.totalLength
                        },
                        on: {
                          "current-change": _vm.paginationInfoChanged,
                          "update:currentPage": function($event) {
                            return _vm.$set(
                              _vm.effectiveDataSource,
                              "currentPage",
                              $event
                            )
                          },
                          "update:current-page": function($event) {
                            return _vm.$set(
                              _vm.effectiveDataSource,
                              "currentPage",
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "second-row" }, [
            !_vm.lessOrEqualThanSelectedPageSize &&
            _vm.$screenIs("xs") &&
            _vm.responsive
              ? _c(
                  "div",
                  { staticClass: "page-links" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.effectiveDataSource.currentPage,
                        "page-size": _vm.effectiveDataSource.pageSize,
                        "pager-count": 5,
                        layout: "prev, pager, next",
                        total: _vm.totalLength
                      },
                      on: {
                        "current-change": _vm.paginationInfoChanged,
                        "update:currentPage": function($event) {
                          return _vm.$set(
                            _vm.effectiveDataSource,
                            "currentPage",
                            $event
                          )
                        },
                        "update:current-page": function($event) {
                          return _vm.$set(
                            _vm.effectiveDataSource,
                            "currentPage",
                            $event
                          )
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-af40ac7c", { render: render, staticRenderFns: staticRenderFns })
  }
}