var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contact-card" }, [
    _c("div", { staticClass: "contact-name contact-property" }, [
      _vm._v(_vm._s(_vm.user.FirstName) + " " + _vm._s(_vm.user.LastName))
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "contact-role contact-property" }, [
      _vm._v(_vm._s(_vm.role))
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "contact-phone contact-property" },
      [
        _c("em", {
          staticClass: "fal fa-mobile contact-property-icon",
          attrs: { "aria-hidden": "true" }
        }),
        _vm._v(" "),
        _c(
          "dcxa-text-link",
          { attrs: { secondaryTarget: "tel:" + _vm.user.Phone } },
          [_vm._v(_vm._s(_vm.user.Phone))]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "contact-email contact-property" },
      [
        _c("em", {
          staticClass: "fal fa-envelope contact-property-icon",
          attrs: { "aria-hidden": "true" }
        }),
        _vm._v(" "),
        _c(
          "dcxa-text-link",
          { attrs: { secondaryTarget: "mailto:" + _vm.user.Email } },
          [_vm._v("Email " + _vm._s(_vm.user.FirstName))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3f47b668", { render: render, staticRenderFns: staticRenderFns })
  }
}