<template>
    <div class="dcxa-select" :class="classes" >
       <el-popover :disabled="!popover || !enabledValidationPopover" trigger="focus" placement="bottom" effect="light" popper-class="dcxa-tooltip">

            <slot name="popoverContent" />

            <template slot="reference" >
                <el-select 
                    :id="id"
                    :name="name"
                    v-bind:value="value"
                    v-on:change="updateValue"
                    :disabled="disabled"
                    :clearable="clearable"
                    :value-key="valueKey"
                    :multiple="multiselect"
                    :collapse-tags="true"
                    :filterable="filterableOptions"
                    :remote="remote"
                    :remote-method="remoteMethod"
                    no-data-text="No selectable item"
                    no-match-text="Item not found"
                    :loading="loading"
                    :loading-text="loadingText"
                    :placeholder="placeHolderText"
                    :floatingLabel="floatingLabel"
                    @blur="focusEventHandler(false)"
                    @focus="focusEventHandler(true)"
                    :popper-class="popperClass">
                    <slot />
                </el-select>
            </template>
       </el-popover>

        <label class="floating-label-element" :class="{'visible': showFloatingLabel}" v-html="floatingTitleText">
        </label>
    </div>
</template>

<script>
export default {
  name: 'DcxaSelect',
  data() {
    return {
        currVal: ''
    }
  },
  props: [
      'id',
      'name',
      'value',
      'classes',
      'disabled',
      'clearable',
      'multiselect',
      'filterable',
      'noDataText',
      'noMatchText',
      'loading',
      'loadingText',
      'placeholder',
      'floatingLabel',
      'floatingTitle',
      'remote',
      'remoteMethod',
      'popover',
      'valueKey',
      'numOfOptions'
  ],
  watch: {
      value: function(val, oldVal) {
          if (this.currVal != val) {
            this.updateValue(val);
          }
      }
  },
  computed: {
      showFloatingLabel() {
          return this.floatingLabel; //  && this.value && 
            //((this.value || '').length > 0 || _.isObject(this.value) || _.isNumber(this.value));
      },
      popperClass() {
          let className = 'dcxa-select-dropdown ';
          if(this.id && this.id != '') {
              className += this.id+'_values'
          }
          return className;
      },
      placeHolderText() {
          const parts = (this.placeholder || '').split('<span');
          return (parts.length > 1) ? parts[0] + '*' : parts[0];
      },
      floatingTitleText() {
          return this.floatingTitle || this.placeholder;
      },
      filterableOptions() {
        return this.filterable || (this.numOfOptions && this.numOfOptions > 3);
      }
  },
  methods: {
    updateValue: function (value) {
        this.currVal = value;
        this.$emit('changed', value);
        this.$emit('input', value)
    },
     focusEventHandler(value){
         this.$emit('focusEvent', value)
    }
  }
}
</script>

<style lang="scss">
@import "../../../../styles/_colors.scss";
@import "../../styles/_mixins.scss";

/////////////////////////
// Variables
/////////////////////////
$large-height: 48px;
$medium-height: 40px;
$small-height: 32px;
$compact-height: 24px;
/////////////////////////
.dcxa-select {
    @include floating-label();
    &:focus {
        outline: none;
    }

    /////////////////////////
    // Size variants
    /////////////////////////
    &.medium {
        .el-select {
            font-size: 16px;
            height: $medium-height;
            line-height: $medium-height;
            & .el-input {
                height: $medium-height;
                line-height: $medium-height;
                & .el-input__inner {
                    font-size: 16px;
                    height: $medium-height;
                    line-height: $medium-height;
                    padding: 0 38px 0 16px;
                }
            }

            & .el-select__tags {
                .el-tag {
                    height: 28px;
                    line-height: 28px;
                    padding: 0 12px;
                    font-size: 16px;
                }
            }
        }
    }
    &.small {
        .el-select {
            font-size: 16px;
            height: $small-height;
            line-height: $small-height;
            & .el-input {
                height: $small-height;
                line-height: $small-height;
                & .el-input__inner {
                    font-size: 16px;
                    height: $small-height;
                    line-height: $small-height;
                    padding: 0 34px 0 12px;
                }

                .el-input__icon {
                  line-height: $small-height;
                  margin-top: -4px;
                }
            }

            & .el-select__tags {
                .el-tag {
                    height: 24px;
                    line-height: 24px;
                    padding: 0 12px;
                    font-size: 16px;
                }
            }
        }
    }
    &.compact {
        .el-select {
            font-size: 14px;
            height: $compact-height;
            line-height: $compact-height;
            & .el-input {
                height: $compact-height;
                line-height: $compact-height;
                & .el-input__inner {
                    font-size: 14px;
                    height: $compact-height;
                    line-height: $compact-height;
                    padding: 0 30px 0 8px;
                }

                .el-input__icon {
                    line-height: $compact-height;
                    margin-top: -7px;
                }
            }

            & .el-select__tags {
                .el-tag {
                    height: 20px;
                    line-height: 20px;
                    padding: 0 12px;
                    font-size: 16px;
                }
            }
        }
    }

    /////////////////////////
    // Validation variants
    /////////////////////////
    /*&.validation-error {
        .el-select {
            & .el-input {
                .el-input__inner {
                    border: solid 1px $error-red;
                    padding-right: 48px;
                }
                &::after {
                    font-family: 'Font Awesome 6 Pro';
                    font-size: 12px;
                    right: 32px;
                    color: $error-red;
                    content: '\f00d';
                    position: absolute;
                }
            }
        }
    }
    &.validation-success {
        .el-select {
            & .el-input {
                .el-input__inner {
                    padding-right: 48px;
                }
                &::after {
                    font-family: 'Font Awesome 6 Pro';
                    font-size: 12px;
                    right: 32px;
                    top: 0;
                    color: $success-green;
                    content: '\f00c';
                    position: absolute;
                }
            }
        }
    }*/

    .el-select {
        width: 100%;
        font-size: 16px;
        height: $large-height;
        line-height: $large-height;
        & .el-input {
            height: $large-height;
            line-height: $large-height;
            & .el-input__inner {
                height: $large-height;
                line-height: $large-height;
                padding: 0 38px 0 16px;
                border: 1px solid;
                text-overflow: ellipsis;
                font-size: 16px;
            }
        }
        /////////////////////////
        // Utilities
        /////////////////////////
        & {
            border: none;
            &:focus {
                outline: none;
            }
        }
        /////////////////////////
        // Coloring variants
        /////////////////////////
        & .el-input {
            &:focus {
                outline: none;
            }
            // &:focus .el-input__inner,
            &.is-focus .el-input__inner,
            & .el-input__inner:focus {
                border: 1px solid $brilliant-blue;
            }
            & .el-input__inner {
                color: $data-gray;
                border-color: tint($data-gray, 20);
                &:hover {
                    border-color: $brilliant-blue;
                }
                &:disabled {
                    color: tint($data-gray, 60);
                    background: tint($data-gray, 5);
                    &:hover {
                        border-color: tint($data-gray, 20);
                    }
                    &::-webkit-input-placeholder {
                        /* Chrome/Opera/Safari */
                        color: tint($data-gray, 60);
                    }
                    &::-moz-placeholder {
                        /* Firefox 19+ */
                        color: tint($data-gray, 60);
                    }
                    &:-ms-input-placeholder {
                        /* IE 10+ */
                        color: tint($data-gray, 60);
                    }
                    &:-moz-placeholder {
                        /* Firefox 18- */
                        color: tint($data-gray, 60);
                    }
                }
                &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: $light-gray;
                }
                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: $light-gray;
                }
                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: $light-gray;
                }
                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: $light-gray;
                }
            }
            &.el-input--suffix {
                .el-select__caret.el-input__icon.el-icon-arrow-up {
                    font-family: "Font Awesome 6 Pro" !important;
                    transform: none;
                    color: shade($mint,110);
                    font-size: 16px;
                    &:before {
                        content: "\f0d7";
                    }
                    &.is-reverse {
                        color: $mint;
                        &:before {
                            content: "\f0d8";
                        }
                    }
                }
            }
        }
        & .el-select__tags {
            .el-tag {
                background-color: #f2f2f2;
                border: solid 1px #cccccc;

                .el-tag__close {
                    background: none;
                    color: $mint;
                }
            }
        }
        /////////////////////////
        // Multiselect styling 
        /////////////////////////
        & .el-select__tags {

            .el-tag {
                height: 32px;
                line-height: 32px;
                padding: 0 12px;
                font-size: 16px;

                .el-tag__close {
                    font-size: 16px;
                    &::before {
                        content: '\f00d';
                        font-family: 'Font Awesome 6 Pro';
                    }
                }
            }
        }

    }
}

.dcxa-select-dropdown.el-select-dropdown {
    max-width: 500.5px;
    @media (max-width: 768px) {
        max-width: 54%;   
    }   

    .el-select-dropdown__list {
        padding: 0;
        .el-select-dropdown__item {

            .custom-option-template {
                display: flex;
                flex-direction: column;

                span {
                   line-height: 1;
                }

                .secondary-title {
                    font-size: 14px;
                    color: tint($black, 50);
                    margin-top: 8px;
                }
            }



            font-size: 16px;
            color: $data-gray;
            padding: 12px 16px;
            margin: 0;
            height: unset;
            line-height: unset;
            border: solid tint($black, 30);
            border-width: 1px 1px 0 1px;
            &.hover {
                background: transparent !important;
                &:hover {
                    background: tint($data-gray, 5) !important;
                }
            }
            &:hover {
                background: tint($data-gray, 5) !important;
            }
            &.selected {
                font-weight: 500;
                // color: $data-gray;
                color: $mint;
                border-left: 3px solid $mint !important;

                .secondary-title {
                    color: $mint;
                }
            }
            &.is-disabled {
                color: tint($data-gray, 50);
            }
            &:last-of-type {
                border-width: 1px;
            }
        }
    }
    .el-select-dropdown__empty {
      text-align: left;
      padding: 16px;
      color: tint($black, 50);

    }
}


</style>
