<template>
    <div class="dcxa-load-more">

        <div class="load-more-controls" >
            <div class="left">
                <p v-if="showPagination"><span v-if="showPaginationPrefix">Showing</span> 1-{{currentRows}} of {{resultSetSize}} {{itemType}}</p>
                <div v-if="hasMoreItems">
                    <div class="load-more-left-line"></div>
                    <div class="load-more-link">
                        <dcxa-text-link @click="loadMoreResults" class="load-more-action-link">Load More</dcxa-text-link>
                    </div>
                    <div class="load-more-right-line"></div>
                </div>
            </div><div class="right">
                <dcxa-button
                        type="icon"
                        @click="scrollToTop"
                        :icon="'fal fa-chevron-up'"
                        class="medium inline-block">
                </dcxa-button>
            </div>
        </div>

    </div>
</template>

<script>
    const dcxaCore = require('@dcxa/dcxa-core');
    const {Utilities} = dcxaCore;

    export default {
        name: 'DcxaLoadMore',
        props: {
            currentPage: {
                type: Number
            },
            pageSize: {
                type: Number
            },
            resultSetSize: {
                type: Number
            },
            showPagination: {
                type: Boolean,
                default: true
            },
            showPaginationPrefix: {
                type: Boolean,
                default: true
            },
            itemType: {
                type: String,
                default: 'Results'
            }
        },
        computed: {
            currentRows() {
                return Math.min((this.currentPage) * this.pageSize, this.resultSetSize);
            },
            hasMoreItems() {
                return !this.showPagination || Math.min(this.currentPage * this.pageSize, this.resultSetSize) != this.resultSetSize;
            }
        },
        methods: {
            scrollToTop() {
                Utilities.dom.scrollToTop();
            },
            loadMoreResults() {
                this.$emit('loadMore')
            }
        }
    }
</script>

<style lang="scss">
@import "../../../../styles/_colors.scss";
    .dcxa-load-more {
        ::after {
            font-family: 'Font Awesome 6 Pro';
        }

        .load-more-controls {
            text-align: center;
            position: relative;

            .left {
                display: inline-block;
                padding-bottom: 10px;
                width: 100%;

                & > div {
                    padding-right: 50px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;

                    .load-more-left-line,
                    .load-more-right-line {
                        width: 100%;
                        height: 1px;
                        background-color: tint($black,20);
                    }

                    .load-more-left-line {
                        padding-left: 75px;
                    }

                    .load-more-link {
                        display: inline-block;
                    }

                    .load-more-action-link {
                        width: 120px;

                        span::after {
                            content: '\f078';
                            color: $mint;
                            padding-left: 6px;
                            font-size: 14px;
                        }
                    }

                }
            }

            .right {
                display: inline-block;
                position: absolute;
                right: 0;
                bottom: 0;
                width: 40px;

                .dcxa-icon-button {
                    width: 40px;
                    min-width: 40px;
                    border-radius: 40px;
                    box-shadow: 0 2px 3px 0 rgba($black, 0.15);
                }
            }
        }
    }


</style>
