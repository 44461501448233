<template>
  <div class="dcxa-page-header" :class="mainClasses" >

    <div class="main" :style="{'background-image': 'url('+config.background+')'}">
      <div class="dcxa-grid-container">
        <div class="dcxa-row content-row">
          <div class="dcxa-column dcxa-xs-full-width dcxa-md-12 dcxa-lg-8">

            <div class="eyebrow" v-if="config.eyebrow">{{config.eyebrow}}</div>
            <h1 class="title">{{config.title}}</h1>
            <h4 class="subheader" v-if="config.subheader">{{config.subheader}}</h4>
            <p class="paragraph" v-if="config.paragraph">{{config.paragraph}}</p>
            
            <template v-if="config.mode == 'cta' && config.cta && config.cta.primary && config.level < 3">
              <div class="ctas">
                <div v-if="config.cta.primary || config.cta.secondary" class="buttons">
                  <dcxa-button type="primary" v-if="config.cta.primary" >{{config.cta.primary.title}}</dcxa-button>
                  <dcxa-button type="secondary" class="alternate" v-if="config.cta.secondary" >{{config.cta.secondary.title}}</dcxa-button>
                </div>
                <dcxa-text-link class="tertiary-text-link" v-if="config.cta.tertiary">{{config.cta.tertiary.title}}</dcxa-text-link>
              </div>
            </template>
            <template v-if="config.mode == 'search'">
              <div class="search">
                <!-- <search-input /> -->
              </div>
            </template>

          </div>
        </div>
      </div>
    </div>

    <div class="cards" v-if="config.cards && config.cards.length === 3 && config.level < 3" >
      <div class="dcxa-grid-container">
        <div class="dcxa-row">
          
          <div class="dcxa-column dcxa-xs-full-width dcxa-md-full-width dcxa-lg-4" v-for="(card, i) in config.cards" :key="i">

            <div class="card">
              <h4 class="title">{{card.header}}</h4>
              <p class="paragraph">{{card.paragraph}}</p>
              <dcxa-text-link class="cta">{{card.cta.title}}</dcxa-text-link>
            </div>

          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script type="text/javascript">
import Vue from 'vue'
import { mapGetters } from 'vuex'


export default {
  name: 'DcxaPageHeader',
  components: {
  },
  props: [
      'config'
  ],
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
    }),

    hasErrors() {
        const ret = Object.keys(this.configurationErrors).length > 0;
        if (ret ) {
            console.log(this.configurationErrors);
        }
        return ret;
    },

    configurationErrors() {
        const errors = {};

        // minimum configuration
        if (!this.config.level || this.config.level < 1 || this.config.level > 3) {
            errors.level = "Level has to be set."
        }
        if (!this.config.background || this.config.background.length == 0) {
            errors.background = "Background has to be set."
        }
        if (!this.config.title || this.config.title.length == 0) {
            errors.title = "Header has to be set."
        }
        if (!this.config.theme || this.config.theme.length == 0) {
            errors.theme = "Theme has to be set."
        }

        // Level 1 restrictions
        if (this.config.level == 1) {
            // general text restrictions
            if ((this.config.eyebrow || '').length > 32) {
                errors.eyebrowLength = "Eyebrow length is over the allowed 32 chars."
            }
            if ((this.config.title || '').length > 32) {
                errors.titleLength = "Header length is over the allowed 32 chars."
            }
            if ((this.config.subheader || '').length > 64) {
                errors.subheaderLength = "Subheader length is over the allowed 64 chars."
            }
            if ((this.config.paragraph || '').length > 200) {
                errors.paragraphLength = "Paragraph length is over the allowed 200 chars."
            }

            // alignment check
            if (this.config.align && this.config.align != "left") {
                errors.alignIssue = "Level 1 headers can be only left aligned.";
            }


            // if cta mode is selected, restrict cta title length
            if (this.config.mode = 'cta') {
                if (!this.config.cta) {
                    errors.ctaNotDefined = "CTA mode is selected, but no CTA config found."
                } else {
                    if (!this.config.cta.primary) {
                        errors.primaryCtaNotDefined = "Primary CTA config not found.";
                    } else {
                        if (!this.config.cta.primary.title) {
                            errors.ctaPrimaryTitleNotDefined = "Primary CTA title not defined";
                        } else {
                            if (this.config.cta.primary.title.length > 15) {
                                errors.ctaPrimaryTitleLength = "Primary CTA title length is over 15 chars";
                            }
                        }
                    }
                    if (this.config.cta.secondary) {
                        if (this.config.cta.secondary.title.length > 15) {
                            errors.ctaSecondaryTitleLength = "Secondary CTA title length is over 15 chars";
                        }
                    }
                    if (!this.config.cta.tertiary) {
                        if (this.config.cta.tertiary.title.length > 15) {
                            errors.ctaTertiaryTitleLength = "Tertiary CTA title length is over 15 chars";
                        }
                    }
                }
            }



            if (this.config.cards) {
                // check cards related restrictions
                if (this.config.cards.length !== 3) {
                    errors.cardsNumberNotOk = "Number of cards has to be 3."
                }


                this.config.cards.forEach(card => {
                    // header check
                    if (!card.header || card.header.length == 0)  {
                        errors.cardHeaderRequired = "Card header is required."
                    } 
                    if (card.header && card.header.length > 25) {
                        errors.cardHeaderLength = "Card header length is over the allowed 25 chars."
                    }

                    // paragraph check
                    if (!card.paragraph || card.paragraph.length == 0)  {
                        errors.cardParagraphRequired = "Card paragraph is required."
                    }
                    if (card.paragraph && card.paragraph.length > 120) {
                        errors.cardParagraphLength = "Card paragraph length is over the allowed 120 chars."
                    }

                    // cta 
                    if (card.cta) {
                        if (!card.cta.title || card.cta.title.length == 0) {
                            errors.cardCtaTitleRequired = "If card CTA is specified, title has to be set."
                        }
                        if (card.cta.title && card.cta.title.length > 15) {
                            errors.cardCtaTitleRequired = "Card CTA length is over the allowed 15 chars."
                        }
                    }
                });
            }

        }

        // Level 2 restrictions
        if (this.config.level == 2) {
            // general text restrictions
            if (this.config.eyebrow && this.config.eyebrow.length > 0 && this.config.subheader && this.config.subheader.length > 0) { 
                errors.eyebrowOrSubheader = "In Level 2 headers either eyebrow or subheader can be configured, but not both.";
            }

            if ((this.config.eyebrow || '').length > 32) {
                errors.eyebrowLength = "Eyebrow length is over the allowed 32 chars."
            }
            if ((this.config.title || '').length > 32) {
                errors.titleLength = "Header length is over the allowed 32 chars."
            }
            if ((this.config.subheader || '').length > 64) {
                errors.subheaderLength = "Subheader length is over the allowed 64 chars."
            }
            if ((this.config.paragraph || '').length > 200) {
                errors.paragraphLength = "Paragraph length is over the allowed 200 chars."
            }

            // alignment check
            if (this.config.align && this.config.align != "left") {
                errors.alignIssue = "Level 2 headers can be only left aligned.";
            }


            // if cta mode is selected, restrict cta title length
            if (this.config.mode = 'cta') {
                if (!this.config.cta) {
                    errors.ctaNotDefined = "CTA mode is selected, but no CTA config found."
                } else {
                    if (!this.config.cta.primary) {
                        errors.primaryCtaNotDefined = "Primary CTA config not found.";
                    } else {
                        if (!this.config.cta.primary.title) {
                            errors.ctaPrimaryTitleNotDefined = "Primary CTA title not defined";
                        } else {
                            if (this.config.cta.primary.title.length > 15) {
                                errors.ctaPrimaryTitleLength = "Primary CTA title length is over 15 chars";
                            }
                        }
                    }
                    if (this.config.cta.secondary) {
                        if (this.config.cta.secondary.title.length > 15) {
                            errors.ctaSecondaryTitleLength = "Secondary CTA title length is over 15 chars";
                        }
                    }
                    if (this.config.cta.tertiary) {
                        errors.tertiaryCtaNotAllowed = "Tertiary CTA can not be used in Level 2 headers.";
                    }
                }
            }



            if (this.config.cards) {
                // check cards related restrictions
                if (this.config.cards.length !== 3) {
                    errors.cardsNumberNotOk = "Number of cards has to be 3."
                }

                this.config.cards.forEach(card => {
                    // header check
                    if (!card.header || card.header.length == 0)  {
                        errors.cardHeaderRequired = "Card header is required."
                    } 
                    if (card.header && card.header.length > 25) {
                        errors.cardHeaderLength = "Card header length is over the allowed 25 chars."
                    }

                    // paragraph check
                    if (!card.paragraph || card.paragraph.length == 0)  {
                        errors.cardParagraphRequired = "Card paragraph is required."
                    }
                    if (card.paragraph && card.paragraph.length > 120) {
                        errors.cardParagraphLength = "Card paragraph length is over the allowed 120 chars."
                    }

                    // cta 
                    if (card.cta) {
                        if (!card.cta.title || card.cta.title.length == 0) {
                            errors.cardCtaTitleRequired = "If card CTA is specified, title has to be set."
                        }
                        if (card.cta.title && card.cta.title.length > 15) {
                            errors.cardCtaTitleRequired = "Card CTA length is over the allowed 15 chars."
                        }
                    }
                });
            }

        }

        // Level 3 restrictions
        if (this.config.level == 3) {
            // general text restrictions
            if (this.config.eyebrow && this.config.eyebrow.length > 0 && this.config.subheader && this.config.subheader.length > 0) { 
                errors.eyebrowOrSubheader = "In Level 2 headers either eyebrow or subheader can be configured, but not both.";
            }

            if ((this.config.eyebrow || '').length > 32) {
                errors.eyebrowLength = "Eyebrow length is over the allowed 32 chars."
            }
            if ((this.config.title || '').length > 23) {
                errors.titleLength = "Header length is over the allowed 23 chars."
            }
            if ((this.config.subheader || '').length > 46) {
                errors.subheaderLength = "Subheader length is over the allowed 46 chars."
            }
            if ((this.config.paragraph || '').length > 136) {
                errors.paragraphLength = "Paragraph length is over the allowed 136 chars."
            }

            // alignment check
            if (this.config.align) {
                if (this.config.theme != "dark" && this.config.align == "center") {
                    errors.alignIssue = "Level 3 headers can be only center aligned only in dark theme.";
                }
            }


            // if cta mode is selected, restrict cta title length
            if (this.config.mode == 'cta') {
                errors.ctaModeNotAllowed = "CTA mode is not allowed in Level 3 headers.";
            }
            if (this.config.cta) {
                errors.ctaNotAllowed = "CTA config is not allowed in Level 3 headers.";
            }


            if (this.config.eyebrow && this.config.eyebrow.length > 0 && this.config.subheader && this.config.subheader.length > 0) {
                errors.eyebrowOrSubheader = "In Level 3 headers eyebrow and subheader can not be used together."
            }

            if (this.config.mode == 'search') {
                if ((this.config.eyebrow && this.config.eyebrow.length > 0) || (this.config.subheader && this.config.subheader.length > 0) || (this.config.paragraph && this.config.eyebrow.paragraph > 0)) {
                    errors.level3SearchModeNoEyebrowSubheaderParagraph = "In Level 3 header when search mode is selected no eyebrow, subheader or paragraph config should be set.";
                }
            }



            if (this.config.cards) {
                errors.noLevel3Cards = "In Level 3 headers no cards property is allowed.";                
            }

        }

        return errors;
    },



    mainClasses() {
      return {
        'light-theme': this.config.theme == 'light' ? true : false,
        'dark-theme': this.config.theme == 'dark' ? true : false,

        'l1': this.config.level == 1,
        'l2': this.config.level == 2,
        'l3': this.config.level == 3,
        'centered': this.config.align == 'center',

        'config-valid': !this.hasErrors

      }
    }

  },
  watch: {
  },
  methods: {
   
  },
  async mounted() {
    // TODO:
    //    - restrictions
    //    - search input
    //    - card slot
  }
}
</script>

<style scoped lang="scss">
@import "../../../../styles/_utilities.scss";
@import "../../../../styles/_colors.scss";

.dcxa-page-header {
    .main {
        background-size: cover;
        background-position: center center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 40px 0;
        .eyebrow {
            color: $bright-orange;
            font-weight: bold;
            text-transform: uppercase;
            margin: 0;
        }
        .title {
            margin: 8px 0 0;
        }
        .subheader {
            margin: 8px 0 0;
        }
        .paragraph {
            margin: 24px 0 0;
        }
        .ctas {
            margin: 32px 0 0 0;
            .buttons {
                display: flex;
                flex-direction: column;
                .dcxa-button {
                    width: 100%;
                    margin-bottom: 16px;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
            .buttons+.tertiary-text-link {
                margin: 16px 0 0 0;
            }
            .tertiary-text-link {
                text-align: center;
            }
        }
    }
    .cards {
        margin: 16px 0;
        .card {
            border-radius: 3px;
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
            background-color: #ffffff;
            padding: 16px;
            .title {
                margin: 0;
            }
            .paragraph {
                margin: 8px 0 0 0;
            }
            .cta {
                margin: 8px 0 0 0;
            }
        }
    }

    @include breakpoint_exact(xs) {
        .dcxa-button + .dcxa-button {
            margin-left: 0;
        }
    }

    @include breakpoint(md) {
        .main {
            min-height: unset;
            .ctas {
                .buttons {
                    flex-direction: row;
                    .dcxa-button {
                        width: auto;
                        margin-right: 8px;
                        margin-bottom: 0;
                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                }
                .buttons+.tertiary-text-link {
                    margin: 16px 0 0 0;
                }
                .tertiary-text-link {
                    text-align: unset;
                }
            }
        }
        .cards {}
    }
    @include breakpoint(lg) {
        .main {}
        .cards {
            .card {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .paragraph {
                    height: 100%;
                }
            }
        }
    }
    &.dark-theme {
        color: $white;
        .main {
            .title,
            .subheader,
            .paragraph {
                color: $white;
            }
        }
    }
    &.light-theme {}
    &.l1 {
        .main {
            min-height: 160px;
        }
        @include breakpoint(md) {
            .main {
                height: 480px;
            }
            .cards {
                margin-top: -24px;
                margin-bottom: 24px;
            }
        }
        @include breakpoint(lg) {
            .main {
                height: 480px;
            }
            .cards {
                margin-top: -32px;
                margin-bottom: 32px;
            }
        }
    }
    &.l2 {
        .main {
            min-height: 160px;
        }
        @include breakpoint(md) {
            .main {
                height: 380px;
            }
            .cards {
                margin-top: 24px;
                margin-bottom: 24px;
            }
        }
        @include breakpoint(lg) {
            .main {
                height: 380px;
            }
            .cards {
                margin-top: 32px;
                margin-bottom: 32px;
            }
        }
    }
    &.l3 {
        .main {
            min-height: 120px;
            .ctas {
                display: none;
            }
        }
        .cards {
            display: none;
        }
        @include breakpoint(md) {
            .main {
                height: 200px;
            }

            &.dark-theme {
              &.centered {
                  .main {
                      .content-row {
                          justify-content: center;
                      }
                      .eyebrow,
                      .title,
                      .subheader,
                      .paragraph {
                          text-align: center;
                      }
                  }
              }
          }
        }
        @include breakpoint(lg) {
            .main {
                height: 200px;
            }
        }
        
    }

    &:not(.config-valid) {
        border: 5px solid red;
    }
}

</style>