var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.config.type !== "text"
    ? _c(
        "dcxa-button",
        {
          staticClass: "small",
          class: { loading: _vm.config.loading },
          attrs: {
            id: _vm.config.id,
            type: _vm.config.type,
            disabled: _vm.isDisabled
          },
          on: {
            click: function($event) {
              return _vm.bindButtonAction(_vm.config.arg)
            }
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.config.title) + "\n")]
      )
    : _c(
        "dcxa-text-link",
        {
          attrs: { id: _vm.config.id, disabled: _vm.isDisabled },
          on: {
            click: function($event) {
              return _vm.bindButtonAction(_vm.config.arg)
            }
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.config.title) + "\n")]
      )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4143ad76", { render: render, staticRenderFns: staticRenderFns })
  }
}