<template>
  <div class="dcxa-select-vue" v-bind:class="{ 'dcxa-select-focus' : focusClass, 'dcxa-select-disabled' : disabled}">
    <div class="dcxa-sort-button" :class="classes"> 
    <dcxa-button :class="classes"
      type="secondary"
      class="sort-btn remove-min-width"
      :disabled="sortByField.length === 0 || disabled"
      @click="sort"
    >
      <i class="fas fa-sort-amount-up" aria-hidden="true" v-if="sortDirection === 'asc'"></i>
      <i class="fas fa-sort-amount-down" aria-hidden="true" v-else></i>
    </dcxa-button>
     <span class="dcxa-pipe" :class="classes">  </span>
    </div>
   
    <dcxa-select
      :class="classes"
      :disabled="disabled"
      :placeholder="placeholder"
      v-model="sortByField"
      @input="setSortByField()"
      @focusEvent="focusEvent"
      id="i_sort"
      :num-of-options="sortingOptions.length">
      <el-option :class="classes" v-for="item in sortingOptions" :key="item.id" :label="item.title" :value="item.id"></el-option>
    </dcxa-select>
  </div>
</template>
<script>
export default {
  name: "DcxaSortingDropDown",
  props: {
    defaultSortByField: {
      type: String,
      default: () => ""
    },
    defaultSortDirection: {
      type: String,
      default: () => "asc"
    },
    columnsToHide: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    classes: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: () => "Sort by..."
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      sortByField: this.defaultSortByField,
      sortDirection: this.defaultSortDirection,
      focusClass:false
    };
  },
  computed: {
    sortingOptions() {
      return _.map(this.sortableColumns, c => {
        return {
          id: c.prop,
          title: "Sort by " + c.label
        };
      });
    },
    sortableColumns() {
      return _.filter(this.options, c => {
        return c.sortable && !this.colHidden(c.prop);
      });
    },
    colHidden() {
      return col => {
        return this.hiddenCols.indexOf(col) > -1;
      };
    },
    hiddenCols() {
      return this.columnsToHide || [];
    }
  },
  methods: {
    sort() {
      this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      this.$emit("sortDirections", this.sortDirection);
    },
    setSortByField() {
      this.$emit("sortByField", this.sortByField);
    },
    focusEvent(value){
        this.focusClass = value;
    }
  }
};
</script>
<style lang="scss">
@import "../../../../styles/_colors.scss";
 .dcxa-select-vue {
     width: 100%;
     border-radius: 5px;
     border: 1px solid tint($black,20);
     &:hover {
        border-color: $mint;
        .dcxa-select {
         .el-icon-arrow-up {
           &:before {
            color: $mint !important;
           }
          }
        }
     }
    &.dcxa-select-focus{
       border-color: $mint;
       box-shadow: 0 0 8px 0 rgba($mint, 0.5);
    }
   &.dcxa-select-disabled {
     .is-disabled {
            background: tint($data-gray, 5) !important;
            border-radius: 5px;
            border: 0;
      }
       &:hover {
        border-color: tint($black,20);
     }
    }
  .dcxa-sort-button {
    display: flex;
    flex-direction: row;
    min-height: 34px;
    border-radius: 5px 0 0 5px; 
    border-right: 0;
    position: absolute; 
    width: auto; 
    
    z-index: 99;
    &.compact {
      min-height: 24px;
    }
    .dcxa-button {
      color: tint($black,60);
      &.el-button {
      &.el-button--secondary {
          border-radius: 5px 0 0 5px;
          background-color: $white;
          border: none;
          padding: 0 12px;
          &:active {
            box-shadow: none;
          }
        }
      }
    }
    .dcxa-pipe {
      margin: 7px 0;
      color: tint($black,20);
      border-left: 1px solid tint($black,20);
      position: relative;
      z-index: 1;
    }
  }
  .dcxa-select-focus
  {
    &:focus
    {
      border: 1px solid $mint;
      
    }
  }

.dcxa-select {
    .el-select {
      border-radius: 0px;
        .el-input {
          .el-input__inner {
            border: 0px !important;
            padding: 0 0 0 52px !important;
            height: auto; 
          }
           .el-input__icon {
            border-radius: 0px;
            margin-top: -4px;
            }
          } 
      }
     &.medium {
      .el-input {
       .el-input__inner {
           height: 40px !important;
       }
      }
     }
     &.small {
       .el-input {
         .el-input__inner {
            height: 32px !important;
         }
       }
     }
    &.compact {
      .el-input {
       .el-input__inner {
          height: 24px !important;
        }
        .el-input__icon {
          margin-top: -7px;
        }
      }
    }
 }

.el-input {
 &.el-input--suffix {
    .el-select__caret.el-input__icon.el-icon-arrow-up {
      &:before {
        color: tint($black,30);
      }
      &.is-reverse {
        &:before {
         content: "\f0d7" !important;
         font-weight: 900;
          color: $mint;
        }
      }
     }
   }
  }
}
.dcxa-select-dropdown {
 &.el-select-dropdown {
    box-shadow:  2px 1px 8px 0 rgba(0, 0, 0, 0.3);
     .el-select-dropdown__list {
       &.el-scrollbar__view {
        padding: 0;
        .el-select-dropdown__item {
            border: solid tint($black, 20) !important;
            border-width: 0 0 1px 0 !important;
            &.hover {
                background: transparent !important;
                &:hover {
                    background: tint($data-gray, 10) !important;
                }
            }
            &.selected {
              font-weight: 600 !important;
            }
            &:last-of-type {
                border-width: 0px !important;
            }
           }
         }
       }
     }
   }
 
.el-popper {
	.popper__arrow {
		display: none;
	 }
}


</style>


