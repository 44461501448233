var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasTitle
    ? _c(
        _vm.titleSize,
        { tag: "component", staticClass: "dcxa-content-title" },
        [_vm._v(_vm._s(_vm.title))]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-49020507", { render: render, staticRenderFns: staticRenderFns })
  }
}