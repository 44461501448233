<template>
    <div class="dcxa-modal-error" :id="ids.modal_id">
        <div class="dcxa-modal-header">
            <i class="fal fa-exclamation-triangle error-icon"  aria-hidden="true" />
            <div class="error-title" :id="ids.title_id">
                <h5 v-if="$screenIs('xs')">{{title}}</h5>
                <h4 v-else>{{title}}</h4>
            </div>
            <span class="error-code" v-if="errorCode != null">Error Code: {{errorCode}}</span>
        </div>

        <div class="dcxa-modal-body" :id="ids.body_id">
            <span v-html="message"></span>
        </div>

        <div class="dcxa-modal-footer">
            <dcxa-modal-buttons :buttons="buttons" :modal-context="modalContext" :ids="ids" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "ErrorModal",
        props: {
            title: {
                type: String,
                default: () => ''
            },
            message: {
                type: String,
                default: () => ''
            },
            errorCode: {
                type: String,
                default: () => ''
            },
            buttons: {
                type: Array,
                default: () => []
            },
            ids: {
                type: Object,
                default: () => {}
            }
        },
        computed: {
            modalContext() {
                return this.$parent;
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../../../../styles/utilities";
    @import "../../../../../styles/colors";

    .dcxa-modal-error {
        width: 100%;
        padding: 32px;
        text-align: left;

        .dcxa-modal-header {
            .error-icon {
                font-size: 32px;
                width: 32px;
                height: 32px;
                line-height: 32px;

                color: $red-light;
            }

            .error-title {
                margin-top: 16px;
                margin-bottom: 8px;

                h4, h5 {
                    margin-bottom: 0;
                }
            }

            .error-code {
                font-size: 14px;
                color: tint($black, 50);
            }
        }

        .dcxa-modal-body {
            margin-top: 16px;
        }
    }

    @include breakpoint(md) {
        .dcxa-modal-error {
            width: 480px;
        }
    }
</style>