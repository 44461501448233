<template>
    <div class="dcxa-tabs" ref="dcxaTabs">
        <div class="right-tab-overlay"></div>
        <div class="left-tab-overlay"></div>
        <div class="filter-tab" v-for="tab in tabs" :ref="'tab'+tab.id" :key="tab.id" :class="{active: activeTabId == tab.id}" @click="changeActiveTab(tab)" :id="tab.tabBtnId">
            <slot name="tab-title" :context="tab">
                {{ tab.title }}
            </slot>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import {mapGetters, mapActions} from 'vuex'
import Vue from 'vue'

const dcxaCore = require('@dcxa/dcxa-core');
const {Adobe, Search, Utilities} = dcxaCore;



export default {
  name: 'Dashboard',
  components: {
  },
  props: [
      'defaultItemId',
      'tabs'
  ],
  data: function() {
      return {
          activeTabId: '',
      }
  },
  computed: {
    activeTab() {
        return _.find(this.tabs, t => t.id == this.activeTabId);
    }
  },
  methods: {
    changeActiveTab(tab, silentActivation) {
        if(!this.$refs["tab"+tab.id]) return;
        let element = this.$refs["tab"+tab.id][0];
        let tabs = this.$refs.dcxaTabs;
        if(element) {
            tabs.scrollLeft = element.offsetLeft-(tabs.offsetWidth-element.offsetWidth)/2;
        }
        this.activeTabId = tab.id;

        if (silentActivation === true) return;

        this.$emit('activeTabChanged', tab);
    }
  },
  async mounted() {
    this.activeTabId = this.defaultItemId;
    
    let tabs = this.$refs.dcxaTabs;
    tabs.addEventListener('scroll', function(event) {
        let scrollSize = _.floor(tabs.scrollWidth-tabs.offsetWidth),
        left = _.floor(this.scrollLeft),
        currentClass;
        tabs.classList.remove("initial","middle","final");
        if(left === 0) {
            currentClass = 'initial';
        } else if(left === scrollSize) {
            currentClass = 'final';
        } else {
            currentClass = 'middle';
        }
        tabs.classList.add(currentClass);
    }, false);
  }
}
</script>

<style lang="scss">
    @import "../../../../styles/utilities";
    @import "../../../../styles/colors";

.dcxa-tabs {
    width: 100%;
    box-shadow: inset 0 -1px 0 0 #cccccc;
    padding: 0;
    height: 56px;
    text-align: left;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    // position: relative;
    // &::before {
    //     content: ' ';
    //     width: 50px;
    //     height: 56px;
    //     left: 0;
    //     position: absolute;
    //     background-image: linear-gradient(to left, rgba($white,0), rgba($white, 1) 80%);
    // }

    // &::after {
    //     content: ' ';
    //     width: 50px;
    //     height: 56px;
    //     right: 0;
    //     position: absolute;
    //     background-image: linear-gradient(to right, rgba($white,0), rgba($white, 1) 80%);
    // }
    /* Hide scrollbar */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar */
    .right-tab-overlay {
        position: absolute;
        display: none;
        right: 0;
        width: 55px;
        height: 54px;
        background-image: linear-gradient(to right, rgba($white,0) ,$white);
    }
    .left-tab-overlay {
        position: absolute;
        display: none;
        left: 0;
        width: 55px;
        height: 54px;
        background-image: linear-gradient(to left, rgba($white,0), $white);
    }
    .filter-tab {
        display: inline-block;
        text-align: left;
        margin-right: 32px;
        height: 56px;
        line-height: 56px;
        font-size: 16px;
        font-weight: normal;
        color: shade($mint,120);
        cursor: pointer;

        .tab-counter {
            background: tint($black,10);
            padding: 2px 8px;
            border-radius: 12px;
            color: tint($black,60);
            margin-left: 8px;
            font-weight: normal;
        }

        &.active {
            border-bottom: 1px solid $dark-orange;
            color: $dark-orange;
            .tab-counter
            {
                background: $dark-orange;
                color: $white;
            }
        }

        &:hover {
            color: $mint;
        }
    }
}
@include breakpoint(xs) {
    .dcxa-tabs {
        width: auto;
        &.initial {
            margin-right: -16px;
            .left-tab-overlay {
                display: none;
            }
            .right-tab-overlay {
                display: block;
            }
        }
        &.middle {
            margin-right: -16px;
            margin-left: -16px;
            .left-tab-overlay,.right-tab-overlay {
                display: block;
            }
        }
        &.final {
            margin-left: -16px;
            .right-tab-overlay {
                display: none;
            }.left-tab-overlay {
                display: block;
            }
        }
    }
}
@include breakpoint(xs) {
    .dcxa-tabs {
        width: auto;
        &.initial {
            margin-right: -16px;
            .left-tab-overlay {
                display: none;
            }
            .right-tab-overlay {
                display: block;
            }
        }
        &.middle {
            margin-right: -16px;
            margin-left: -16px;
            .left-tab-overlay,.right-tab-overlay {
                display: block;
            }
        }
        &.final {
            margin-left: -16px;
            .right-tab-overlay {
                display: none;
            }
        }
    }
}

</style>
