<template>
    <div class="dcxa-spot-progress" :class="{vertical: isVertical, horizontal: !isVertical}" :style="styleVars" ref="container">

        <div v-for="(spot, i) in spots" :key="i" class="spot" :class="{fulfilled: spot.fulfilled, active: spot.active}">
            <span class="tunnel" v-if="i > 0">
            </span>
            <span class="circle" >
            </span>
            <span class="title" v-if="showTitle">
                {{spot.title}} 
                <el-popover placement="bottom" width="400" trigger="hover" v-if="spot.popoverItems && spot.popoverItems.length > 0">
                    <div class="dcxa-spot-progress-title-popover">
                        <h5>{{ spot.popoverTitle }}</h5>
                        <ul class="tooltip-list no-bullets">
                            <li class="valid" v-for="(item, i) in spot.popoverItems" :key="i">{{ item.label }}</li>
                        </ul>
                    </div>

                    <i slot="reference" class="far popover-icon" :class="[infoIcon]"></i>
                </el-popover>
            </span>
        </div>

    </div>
</template>

<script>
export default {
    name: 'DcxaSpotProgress',
    props: {
        spots: Array,
        orientation: {
            type: String,
            default: () => 'horizontal'
        },
        showTitle: {
            type: Boolean,
            default: () => true
        },
        compact: {
            type: Boolean,
            default: () => false
        },
        infoIcon: {
            type: String,
            default: () => 'fa-check-circle'
        }
    },
    computed: {
        isVertical() {
            return this.orientation === 'vertical';
        },
        styleVars() {
            return {
                '--title-vertical-width': (this.showTitle ? '150px' : '0px'),
                '--spot-size': '24px',
                '--spot-fulfill-size': '24px'
            }
        }
    }
}
</script>

<style lang="scss">
@import "../../../../styles/_colors.scss";
@import "../../../../styles/_utilities.scss";

$spotSize: var(--spot-size);
$spotFulfillSize: var(--spot-size);
$spotDiff: calc(#{$spotSize} - #{$spotFulfillSize});

$tunnelSize: 12px;
$tunnelFulfillSize: 4px;
$tunnelDiff: $tunnelSize - $tunnelFulfillSize;

$titleWidth: 100px;
$titleVerticalWidth: var(--title-vertical-width);

.dcxa-spot-progress {
    display: flex;
    justify-content: space-between;
    padding-left: $spotSize;

    &.vertical {
        flex-direction: column;
        height: 100%;
    }
}

.spot {
    flex: 1;
    position: relative;

    .circle {
        display: inline-block;
        width: $spotSize;
        height: $spotSize;
        border-radius: calc(#{$spotSize} / 2);
        background: $white; // tint($black,10);
        border: 1px solid tint($black,20);
        position: absolute;

        z-index: 0;

        .horizontal & {
            right: 0;
        }

        .vertical & {
            left: $titleVerticalWidth;
            bottom: 0;
        }
    }

    .tunnel {
        display: inline-block;
        position: absolute;
        // background: tint($black,20);

        z-index: 1;

        .horizontal & {
            border-bottom: 1px solid tint($black,20);
            width: calc(100% - #{$spotFulfillSize} - (#{$spotDiff} / 2));
            top: calc((#{$spotSize} / 2));
            right: calc((#{$spotSize}));
            border-left: none;
            border-right: none;
        }

        .vertical & {
            border-left: 1px solid tint($black,20);
            content: ' ';
            height: calc(100% - #{$spotFulfillSize});
            left: calc(#{$titleVerticalWidth} + (#{$spotSize} / 2));
            border-top: none;
            border-bottom: none;
        }
    }

    .title {
        display: inline-block;
        position: absolute;

        .horizontal & {
            width: $titleWidth;
            top: calc(#{$spotSize} + 12px);
            right: calc((#{$spotSize} - #{$titleWidth}) / 2);
            text-align: center;
            font-size: 16px;
            color: tint($black, 80);

            .popover-icon {
                color: $orange;
                cursor: pointer;
                font-size: 14px;
            }
        }
        @include breakpoint(xs) {
            span {
                display: block;
            }
        }

        .vertical & {
            width: $titleVerticalWidth;
            left: 0;
            bottom: calc(#{$tunnelSize} / 2);
            text-align: right;
            font-size: 14px;
            color: tint($black,80);
            padding-right: 14px;
            vertical-align: middle;
        }
    }

    &.active {
        .title {
            font-weight: 500;
        }
    }

    &:first-child {
        flex: inherit;
        .circle {
            border: 1px solid $green-light;
        }
        .horizontal & {
            .title {
                text-align: left;
                right: unset;
                left: calc(#{$spotSize} * -1);
            }
        }
    }

    &:last-child {
        .horizontal & {
            .title {
                text-align: right;
                right: 0;
                left: unset;
            }
        }
    }

    &.fulfilled {
        .circle::after {
            content: ' ';
            width: $spotFulfillSize;
            height: $spotFulfillSize;
            border-radius: calc(#{$spotFulfillSize} / 2);
            background: $green-light;
            position: absolute;
            top: calc(((#{$spotSize} / 2) - (#{$spotFulfillSize} / 2)) - 1px);
            left: calc(((#{$spotSize} / 2) - (#{$spotFulfillSize} / 2)) - 1px);

            z-index: 2;
        }
        .circle::before {
            opacity: 1;
            z-index: 3;
            height: calc((var(--spot-size) / 2.5));
            width: calc((var(--spot-size) / 6));
            transform-origin: left top;
            border-right: 2px solid white;
            border-top: 2px solid white;
            content: " ";
            left: calc((var(--spot-size) / 5));
            top: calc((var(--spot-size) / 2));
            animation-duration: 800ms;
            animation-timing-function: ease;
            animation-name: checkmark;
            transform: scaleX(-1) rotate(135deg);
            position: absolute;
        }

        .tunnel::after {
            content: ' ';
            position: absolute;

            z-index: 3;

            // .horizontal & {
            //     width: calc(100% + #{$spotDiff});
            //     border-bottom: 1px solid $green-light;
            //     // height: $tunnelFulfillSize;
            //     top: calc((#{$tunnelSize} / 2) - (#{$tunnelFulfillSize} / 2) - 1px);
            //     right: calc(0px - (#{$spotDiff} / 2));
            // }

            .vertical & {
                // width: $tunnelFulfillSize;
                height: calc(100% + #{$spotDiff});
                border-left: 1px solid $green-light;
                bottom: calc(0px - (#{$spotDiff} / 2));
                left: calc((#{$tunnelSize} / 2) - (#{$tunnelFulfillSize} / 2) - 1px);
            }
        }

        .title {
            .vertical & {
                color: tint($black,20);
            }
        }
    }
    &.fulfilled + .spot {
        .tunnel {
            .vertical & {
                border-left: 1px solid $green-light;
            }
            .horizontal & {
                border-bottom: 1px solid $green-light;
            }
        }
        .circle {
            border: 1px solid $green-light;
        } 
    }
}

.dcxa-spot-progress-title-popover {
    padding: 8px 0 0;
    h5 {
        margin-bottom: 0;
    }
    hr {
        margin: 16px 0;
    }
    ul.tooltip-list li.valid::before {
        content: ''
    }
    ul.tooltip-list li {
        list-style-type: square;
        padding-left: 0;
        margin-left: 16px;
        color: tint($black, 80);
    }
}

</style>
