var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-accordion", class: _vm.styleClasses },
    [
      _vm.isLoading
        ? _c("div", { staticClass: "loading-columns-container" }, [
            _c("div", { staticClass: "animated-element loading-left-column" }),
            _vm._v(" "),
            _c("div", { staticClass: "animated-element loading-right-column" })
          ])
        : [
            !_vm.isHeaderHidden
              ? _c(
                  "div",
                  {
                    staticClass: "header",
                    class: { "wrap-content": _vm.wrapHeaderOnMobile }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "title-container" },
                      [
                        _vm.collapsible
                          ? _c(
                              "dcxa-text-link",
                              {
                                attrs: { disabled: !_vm.enabled },
                                on: { click: _vm.toggleCollapse }
                              },
                              [
                                !_vm.shrinkHeaderOnMobile ||
                                (_vm.shrinkHeaderOnMobile &&
                                  _vm.$screensFrom("md"))
                                  ? _c(
                                      "h4",
                                      {
                                        class: _vm.titleClass,
                                        attrs: { id: _vm.titleId }
                                      },
                                      [
                                        _c("i", { class: _vm.caretIcon }),
                                        _vm._v(" " + _vm._s(_vm.title))
                                      ]
                                    )
                                  : _vm.shrinkHeaderOnMobile &&
                                    _vm.$screenIs("xs")
                                  ? _c(
                                      "h5",
                                      {
                                        class: _vm.titleClass,
                                        attrs: { id: _vm.titleId }
                                      },
                                      [
                                        _c("i", { class: _vm.caretIcon }),
                                        _vm._v(" " + _vm._s(_vm.title))
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          : [
                              !_vm.shrinkHeaderOnMobile ||
                              (_vm.shrinkHeaderOnMobile &&
                                _vm.$screensFrom("md"))
                                ? _c(
                                    "h4",
                                    {
                                      class: _vm.titleClass,
                                      attrs: { id: _vm.titleId }
                                    },
                                    [_vm._v(_vm._s(_vm.title))]
                                  )
                                : _vm.shrinkHeaderOnMobile &&
                                  _vm.$screenIs("xs")
                                ? _c(
                                    "h5",
                                    {
                                      class: _vm.titleClass,
                                      attrs: { id: _vm.titleId }
                                    },
                                    [_vm._v(_vm._s(_vm.title))]
                                  )
                                : _vm._e()
                            ],
                        _vm._v(" "),
                        _vm._t("secondary-title")
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.enabled
                      ? _c(
                          "div",
                          { staticClass: "controls-container" },
                          [_vm._t("controls")],
                          2
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isCollapsed,
                    expression: "!isCollapsed"
                  }
                ],
                staticClass: "content",
                class: {
                  "header-hidden": _vm.isHeaderHidden,
                  collapsible: _vm.collapsible
                }
              },
              [_vm._t("default")],
              2
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3146a550", { render: render, staticRenderFns: staticRenderFns })
  }
}