var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dcxa-load-more" }, [
    _c("div", { staticClass: "load-more-controls" }, [
      _c("div", { staticClass: "left" }, [
        _vm.showPagination
          ? _c("p", [
              _vm.showPaginationPrefix
                ? _c("span", [_vm._v("Showing")])
                : _vm._e(),
              _vm._v(
                " 1-" +
                  _vm._s(_vm.currentRows) +
                  " of " +
                  _vm._s(_vm.resultSetSize) +
                  " " +
                  _vm._s(_vm.itemType)
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.hasMoreItems
          ? _c("div", [
              _c("div", { staticClass: "load-more-left-line" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "load-more-link" },
                [
                  _c(
                    "dcxa-text-link",
                    {
                      staticClass: "load-more-action-link",
                      on: { click: _vm.loadMoreResults }
                    },
                    [_vm._v("Load More")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "load-more-right-line" })
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("dcxa-button", {
            staticClass: "medium inline-block",
            attrs: { type: "icon", icon: "fal fa-chevron-up" },
            on: { click: _vm.scrollToTop }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-a5e3c1fa", { render: render, staticRenderFns: staticRenderFns })
  }
}