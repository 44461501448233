import CurrencyFormatter from 'currencyformatter.js'

import { Utilities } from '@dcxa/dcxa-core';
import _ from 'lodash';

export function dateFilter(d) {
    try {
        let date = d;
        return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
    } catch (e) {}

    try {
        let date = new Date(d);
        return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
    } catch (e) {}

    return null;
}

// displays a date in the format: Mar 13th, 2020 - 11:00AM PST
// expects a timestamp
export function longDateFilter(d) {
    try {
        return dcxa_moment(d).format('MMM Do, YYYY - HH:mmA z');
    } catch (e) {}
    return null;
}

export function nullFilter(value) {
    if (!value || value == 'null') {
        return '-';
    }
    return value;
}

export function emptyStringOrNullFilter(value) {
    if (_.isNull(value) || (typeof value === 'string' && (value.length === 0 || value == 'null'))) {
        return '-';
    }
    return value;
}

export function currencyFilter(value, currency, removeDecimals) {
    return Utilities.formatting.currency(value, currency, removeDecimals);
}

export function yesNoFilter(value) {
    if (value === true || value === 'true' || value === 'True') {
        return 'Yes';
    }

    return 'No';
}

export function capitalize(value) {
    if (!value) return '';
    value = value.toString().toLowerCase();
    return value.charAt(0).toUpperCase() + value.slice(1);
  }