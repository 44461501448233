var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-special-state-container" },
    [
      _c("dcxa-content-title", {
        attrs: {
          showTitle: _vm.showTitle,
          title: _vm.title,
          titleSize: _vm.titleSize
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "special-state-container" },
        [
          _c("i", { class: _vm.icon, attrs: { "aria-hidden": "true" } }),
          _vm._v(" "),
          [
            _vm.hasDefaultSlot
              ? _vm._t("default")
              : _c("span", { domProps: { innerHTML: _vm._s(_vm.text) } })
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2055ff91", { render: render, staticRenderFns: staticRenderFns })
  }
}