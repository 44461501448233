<template>
    <div ref="barChartRoot" class="dcxa-bar-chart">
        <div ref="barChartRoot" class="dcxa-bar-chart-root" />
    </div>
</template>

<script>
    import echarts from 'echarts';

    export default {
        name: "Bar",
        mounted() {
            var myChart = echarts.init(this.$refs.barChartRoot);

            let series = [{
                name: 'Credits remaining',
                data: [200000, 187500, 175000, 165000, 155000, 130000, 117500, 105000, 65000, 5000, 0, 0]
            }, {
                name: 'Credits Consumed',
                data: [14500, 12500, 12500, 10000, 10000, 25000, 12500, 12500, 40000, 60000, 0, 0]
            }, {
                name: 'Estimated Consumption',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 200000, 150000]
            }];

            /*function renderItem(params, api) {
                var xValue = api.value(0);
                var currentSeriesIndices = api.currentSeriesIndices();
                var barLayout = api.barLayout({
                    barGap: '30%', barCategoryGap: '20%', count: currentSeriesIndices.length - 1
                });

                var points = [];
                for (var i = 0; i < currentSeriesIndices.length; i++) {
                    var seriesIndex = currentSeriesIndices[i];
                    if (seriesIndex !== params.seriesIndex) {
                        var point = api.coord([xValue, api.value(seriesIndex)]);
                        point[0] += barLayout[i - 1].offsetCenter;
                        point[1] -= 20;
                        points.push(point);
                    }
                }
                var style = api.style({
                    stroke: api.visual('color'),
                    fill: null
                });

                return {
                    type: 'polyline',
                    shape: {
                        points: points
                    },
                    style: style
                };
            }*/

            let options = {
                color: ['#0000ff', '#ff0000', '#00ff00' ],
                legend: {
                    data: series.map(item => item.name).concat(['Estimated Consumption']),
                    bottom: 10,
                    left: 'left'

                },
                xAxis: {
                    type: 'category',
                    data: ['Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb']
                },

                yAxis: {
                    type: 'value'
                },
                series: series.map((item, index) => Object.assign(item, {
                    type: 'bar',
                    stack: true
                }))
            };

            myChart.setOption(options);
        }
    }
</script>

<style scoped lang="scss">
    .dcxa-bar-chart {
        display: flex;
        align-items: stretch;
        height: 300px;

        .dcxa-bar-chart-root {

        }
    }
</style>