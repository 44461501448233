var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-responsive-table", attrs: { id: _vm.id } },
    [
      _vm.noData
        ? _vm._t("no-data", function() {
            return [
              _c("dcxa-special-state-container", {
                attrs: {
                  icon: _vm.noDataInfo.icon,
                  text: _vm.noDataInfo.message,
                  title: _vm.title,
                  titleSize: _vm.titleSize
                }
              })
            ]
          })
        : _vm.hasError
        ? _vm._t("has-error", function() {
            return [
              _c("dcxa-special-state-container", {
                attrs: {
                  icon: _vm.errorInfo.icon,
                  text: _vm.errorInfo.message,
                  title: _vm.title,
                  titleSize: _vm.titleSize
                }
              })
            ]
          })
        : _c(
            "div",
            [
              _vm.showTools && _vm.isLoaded
                ? _c("div", { staticClass: "tools-container" }, [
                    _vm.$screensFrom("md")
                      ? _c(
                          "div",
                          {
                            staticClass: "main-row",
                            class: { "empty-tools": _vm.isEmptyToolContainer },
                            attrs: { "data-dd-privacy": "mask-user-input" }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "left-column" },
                              [
                                _c("dcxa-content-title", {
                                  attrs: {
                                    id: _vm.componentIds.titleId,
                                    title: _vm.title,
                                    titleSize: _vm.titleSize
                                  }
                                }),
                                _vm._v(" "),
                                _vm.showSortDropdownForDesktop &&
                                _vm.hasSortableColumns
                                  ? _c("dcxa-sorting-dropdown", {
                                      attrs: {
                                        classes: ["small"],
                                        options: this.columns,
                                        "default-sort-by-field":
                                          _vm.defaultSortByField,
                                        "default-sort-direction":
                                          _vm.defaultSortDirection
                                      },
                                      on: {
                                        sortByField:
                                          _vm.setSortByFieldEventValue,
                                        sortDirections:
                                          _vm.setSortDirectionsEventValue
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "right-column" }, [
                              _vm.showFilterBox && !_vm.remote
                                ? _c(
                                    "div",
                                    { staticClass: "search-tool tool" },
                                    [
                                      _c("i", {
                                        staticClass: "fal fa-search",
                                        attrs: { "aria-hidden": "true" }
                                      }),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.innerFilterText,
                                            expression: "innerFilterText"
                                          }
                                        ],
                                        attrs: {
                                          placeholder: _vm.filterBoxText,
                                          id: _vm.componentIds.filterBoxId
                                        },
                                        domProps: {
                                          value: _vm.innerFilterText
                                        },
                                        on: {
                                          input: [
                                            function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.innerFilterText =
                                                $event.target.value
                                            },
                                            _vm.updateFilterTextValue
                                          ]
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$screensUpTo("xs") && _vm.responsive
                      ? _c(
                          "div",
                          { staticClass: "stack-tools" },
                          [
                            _c("dcxa-content-title", {
                              attrs: {
                                id: _vm.componentIds.titleId,
                                title: _vm.title,
                                titleSize: _vm.titleSize
                              }
                            }),
                            _vm._v(" "),
                            _vm.showFilterBox && !_vm.remote
                              ? _c("div", { staticClass: "search-tool tool" }, [
                                  _c("i", {
                                    staticClass: "fal fa-search",
                                    attrs: { "aria-hidden": "true" }
                                  }),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.innerFilterText,
                                        expression: "innerFilterText"
                                      }
                                    ],
                                    attrs: {
                                      placeholder: _vm.filterBoxText,
                                      id: _vm.componentIds.filterBoxId
                                    },
                                    domProps: { value: _vm.innerFilterText },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.innerFilterText =
                                            $event.target.value
                                        },
                                        _vm.updateFilterTextValue
                                      ]
                                    }
                                  })
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.hasSortableColumns && !_vm.noFilteredData
                              ? _c(
                                  "div",
                                  { staticClass: "sort-tool tool" },
                                  [
                                    _c("dcxa-sorting-dropdown", {
                                      attrs: {
                                        options: this.columns,
                                        classes: ["large"],
                                        "default-sort-by-field":
                                          _vm.defaultSortByField,
                                        "default-sort-direction":
                                          _vm.defaultSortDirection
                                      },
                                      on: {
                                        sortByField:
                                          _vm.setSortByFieldEventValue,
                                        sortDirections:
                                          _vm.setSortDirectionsEventValue
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.noFilteredData && _vm.isLoaded
                ? _vm._t("no-filtered-data", function() {
                    return [
                      _c("dcxa-special-state-container", {
                        attrs: {
                          icon: _vm.noFilteredDataInfo.icon,
                          text: _vm.noFilteredDataInfo.message,
                          title: _vm.title,
                          titleSize: _vm.titleSize,
                          "show-title": false
                        }
                      })
                    ]
                  })
                : _c(
                    "div",
                    [
                      _vm._t("default", null, { context: _vm.tableContext }),
                      _vm._v(" "),
                      _vm.isLoaded && _vm.showPagination
                        ? _c("dcxa-pagination", {
                            attrs: {
                              responsive: _vm.responsive,
                              data: _vm.sortedData,
                              "show-page-size-options": _vm.showPageSizeOptions,
                              sizeSelectorId: _vm.componentIds.sizeSelectorId,
                              paginationId: _vm.componentIds.paginationId,
                              "pagination-info": _vm.paginationInfo,
                              remote: _vm.remote,
                              "remote-pagination-info":
                                _vm.remoteData.pagination
                            },
                            on: { paginationChange: _vm.paginationChanged }
                          })
                        : _vm._e()
                    ],
                    2
                  )
            ],
            2
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1650db66", { render: render, staticRenderFns: staticRenderFns })
  }
}