var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-timepicker", class: _vm.classes },
    [
      _c(
        "el-popover",
        {
          attrs: {
            disabled: !_vm.popover || !_vm.enabledValidationPopover,
            trigger: "focus",
            placement: "bottom",
            effect: "light",
            "popper-class": "dcxa-tooltip"
          }
        },
        [
          _vm._t("popoverContent"),
          _vm._v(" "),
          _c("template", { slot: "reference" }, [
            _c(
              "div",
              { staticClass: "extended-control" },
              [
                _c(
                  "dcxa-button",
                  {
                    staticClass: "medium remove-min-width",
                    attrs: { type: "secondary" },
                    on: { click: _vm.backward }
                  },
                  [_c("i", { staticClass: "fal fa-chevron-left" })]
                ),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      "allow-create": "",
                      "default-first-option": "",
                      "popper-class": "dcxa-timepicker-dropdown",
                      placeholder: "HH:MM"
                    },
                    model: {
                      value: _vm.currVal,
                      callback: function($$v) {
                        _vm.currVal = $$v
                      },
                      expression: "currVal"
                    }
                  },
                  _vm._l(_vm.options, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value }
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "dcxa-button",
                  {
                    staticClass: "medium remove-min-width",
                    attrs: { type: "secondary" },
                    on: { click: _vm.forward }
                  },
                  [_c("i", { staticClass: "fal fa-chevron-right" })]
                )
              ],
              1
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("label", {
        staticClass: "floating-label-element",
        class: { visible: _vm.showFloatingLabel },
        domProps: { innerHTML: _vm._s(_vm.floatingTitleText) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-19e2993a", { render: render, staticRenderFns: staticRenderFns })
  }
}