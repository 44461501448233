<template>
    <div class="text-link" v-on:click.exact="onClick" :class="caretClassName" :disabled="disabled">
        <router-link :to="secondaryTarget" :class="className" :target="target" v-if="isSecondaryTargetEnabled">
            <template v-if="clamp && !$screenIs('xs')">
                <el-popover placement="bottom" trigger="hover" :disabled="!textIsClamped" popper-class="dcxa-tooltip">
                    <slot>Tooltip</slot>
                    <v-clamp slot="reference" :max-lines="3" autoresize v-on:clampchange="onClampChange">
                        <slot>Link</slot>
                    </v-clamp>
                </el-popover>
            </template>
            <slot v-else>Link</slot>
        </router-link>
        <span v-else>
            <template v-if="clamp && !$screenIs('xs')">
                <el-popover placement="bottom" trigger="hover" :disabled="!textIsClamped" popper-class="dcxa-tooltip">
                    <slot>Tooltip</slot>
                    <v-clamp slot="reference" :max-lines="3" autoresize v-on:clampchange="onClampChange">
                        <slot>Link</slot>
                    </v-clamp>
                </el-popover>
            </template>
            <slot v-else>Link</slot>
        </span>
    </div>
</template>

<script>
    import VClamp from 'vue-clamp';

const dcxaCore = require('@dcxa/dcxa-core');
const { EDL  } = dcxaCore;


export default {
  name: 'TextRouterLink',
  components: {
      VClamp
  },
  data(){
    return {
      textIsClamped: false
    }
  },
  props: {
      analyticsName: {
          type: String,
          default: ''
      },
      caret: Boolean,
      caretDirection: {
          type: String,
          default: 'right'
      },
      caretPosition: {
          type: String,
          default: 'after'
      },
      disabled: Boolean,
      secondaryTarget: String,
      isTargetBlank: {
          type: Boolean,
          default: false
      },
      useCustomHandler: {
          type: Boolean,
          default: false
      },
      className: String,
      clamp: {
          type: Boolean,
          default: () => false
      }
  },
  computed: {
      isSecondaryTargetEnabled() {
          return this.secondaryTarget && !this.disabled;
      },
      target() {
          return this.isTargetBlank ? '_blank' : '_self';
      },
      caretClassName() {
          var classNames = {
              'show-caret': this.caret
          };

          if (this.caret) {
              classNames[this.caretDirection] = true;
              classNames[this.caretPosition] = true;
          }

          return classNames;
      }
  },
  methods: {
      onClampChange(clamped){
        this.textIsClamped = clamped;
      },
      onClick(event) {
          if (this.analyticsName) {
                EDL.pushEvent(new EDL.models.ButtonClicked(this.analyticsName));
          }
          if (this.useCustomHandler || !this.secondaryTarget) {
              event.preventDefault();
          }

          if (!this.disabled) {
              this.$emit('click');
          }
      }
  }
}
</script>

<style lang="scss">
@import "../../../../styles/_colors.scss";

    .text-link a:focus {
        text-decoration: none;
    }

.text-link {
    display: inline-block;
    line-height: normal;

    font-size: 16px;
    &:hover {
        cursor: pointer;

        &:disabled {
            cursor: none;
        }
    }
    /////////////////////////
    // Utilities
    /////////////////////////
    &.show-caret {
        &.after {
            &::after {
                margin-left: 2px;
                font-family: 'Font Awesome 6 Pro';
            }
            &.left::after {
                content: ' \f104';
            }
            &.right::after {
                content: ' \f105';
            }
            &.up::after {
                content: ' \f106';
            }
            &.down::after {
                content: ' \f107';
            }
        }

        &.before {
            &::before {
                margin-right: 8px;
                font-family: 'Font Awesome 6 Pro';
            }
            &.left::before {
                content: ' \f104';
            }
            &.right::before {
                content: ' \f105';
            }
            &.up::before {
                content: ' \f106';
            }
            &.down::before {
                content: ' \f107';
            }
        }
    }
    /////////////////////////
    // Size variants
    /////////////////////////
    &.xlarge {
        font-size: 22px;
    }
    &.large {
        font-size: 18px;
    }
    &.small {
        font-size: 14px;
    }
    &.xsmall {
        font-size: 12px;
    }
    &.header {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
    }
    &.table-cell-link {
        font-size: 14px;
        font-weight: normal;
    }
    /////////////////////////
    // Coloring variants
    /////////////////////////
    &,
    a {
        color: shade($mint,120);
        &:hover {
            color: $mint;
        }
    }
    a {
        &:hover {
            text-decoration: none;
        }
    }

    &.black-orange {
        &,
        a {
            color: tint($black, 80);
        }
        &:hover {
            &,
            a {
                color: $bright-orange;
            }
        }
    }

    &.alternate {
        &,
        a {
            color: tint($mint, 70);
        }
        &:hover {
            &,
            a {
                color: tint($mint, 50);
            }
        }
    }
    &.alternate.reversed {
        &,
        a {
            color: $white;
        }
        &:hover {
            &,
            a {
                color: tint($mint, 40);
            }
        }
    }
    &.table-cell-link {
        color: $mint;
    }

    .selectable-row.selected & {
        color: $white;
    }

    &[disabled] {
        color: tint($black,30);
        &:hover {
            color: tint($black,30);
            cursor: not-allowed;
        }
    }
}


</style>
