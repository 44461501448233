var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "myc-search-result-item" }, [
    _c(
      "h4",
      {
        staticClass: "title",
        on: {
          click: function($event) {
            if (
              $event.ctrlKey ||
              $event.shiftKey ||
              $event.altKey ||
              $event.metaKey
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.open.apply(null, arguments)
          }
        }
      },
      [
        _c("a", { staticClass: "titleLink", attrs: { href: _vm.target } }, [
          _vm._v(_vm._s(_vm.data.title))
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "teaser" },
      [
        _c("v-clamp", { attrs: { "max-lines": 3, autoresize: "" } }, [
          _vm._v("\n          " + _vm._s(_vm.truncatedTeaser) + "\n      ")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "metadata" },
      [
        _vm.hasContentTypeMeta
          ? [
              _c("div", {
                staticClass: "doc-type-icon fal",
                class: _vm.contentTypeClasses
              }),
              _vm._v("\n          " + _vm._s(_vm.contentTypeName) + "\n      ")
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f71902a0", { render: render, staticRenderFns: staticRenderFns })
  }
}