<template>
    <div class="dcxa-expander" :style="dynamicStyles" :class="dynamicClasses">

        <div class="controls">
            <dcxa-button type="icon" :icon="controlIcon" class="small" @click="toggleExpanded"/>
            <h5 v-if="expanded"><slot name="title"></slot></h5>
        </div>

        <div class="content" v-if="expanded">
            <slot name="content"></slot>
        </div>

    </div>
</template>

<script>
export default {
  name: 'DcxaExpander',
  data() {
    return {
        expanded: true
    }
  },
  props: {
      expandedWidth: {
          type: Number,
          default: 261
      },
      closedWidth: {
          type: Number,
          default: 32
      }
  },
  watch: {
  },
  computed: {
      controlIcon() {
          return (this.expanded ? 'fas fa-outdent' : 'fas fa-indent');
      },
      dynamicClasses() {
          return {
              expanded: this.expanded,
              closed: !this.expanded
          }
      },
      dynamicStyles() {
          return {
              width: (this.expanded ? this.expandedWidth : this.closedWidth) + 'px'
          }
      }
  },
  methods: {
    toggleExpanded() {
        this.expanded = !this.expanded;
    }
     
  }
}
</script>

<style lang="scss">

.dcxa-expander {
    display: inline-block;

    .controls {
        margin-bottom: 30px;

        .dcxa-icon-button {
            font-size: 16px;
            line-height: 34px;
        }

        h5 {
            font-size: 16px;
            display: inline-block;
            margin-left: 16px;
            margin-bottom: 0;
        }
    }

    .content {

    }

}


</style>
