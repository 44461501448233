var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type != "icon"
    ? _c(
        "el-button",
        {
          staticClass: "dcxa-button",
          class: _vm.classes,
          attrs: {
            type: _vm.type,
            disabled: _vm.disabled,
            id: _vm.id,
            name: _vm.name
          },
          on: { click: _vm.onClick }
        },
        [
          _vm.type == "iconSplit"
            ? _c("span", { staticClass: "split-icon" }, [
                _c("i", { staticClass: "dcxa-fa", class: _vm.splitIconClasses })
              ])
            : _vm._e(),
          _vm._t("default")
        ],
        2
      )
    : _c(
        "el-button",
        {
          staticClass: "dcxa-button dcxa-icon-button",
          class: _vm.classes,
          attrs: {
            type: _vm.type,
            disabled: _vm.disabled,
            id: _vm.id,
            name: _vm.name
          },
          on: { click: _vm.onClick }
        },
        [
          _c("div", { staticClass: "icon" }, [
            _c("i", { staticClass: "dcxa-fa", class: _vm.iconClasses })
          ])
        ]
      )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-a248554c", { render: render, staticRenderFns: staticRenderFns })
  }
}