var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dcxa-responsive-table",
    {
      attrs: {
        data: _vm.tableSource,
        "is-loading": !_vm.isLoaded,
        "has-error": _vm.hasError,
        title: _vm.tableTitle,
        titleSize: "h6",
        "show-filter-box": false,
        "special-states": _vm.specialStates,
        "show-pagination": false
      },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function(ref) {
            var context = ref.context
            return _c("dcxa-simple-table", {
              attrs: {
                context: context,
                "row-selection": false,
                "wrap-column-headers": true
              },
              scopedSlots: _vm._u([
                {
                  key: "columns",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c("dcxa-responsive-table-column", {
                        attrs: {
                          prop: "Quarter",
                          label: "Quarter",
                          width: "calc((100% - 86px) / 3)",
                          sortable: false
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "dcxa-responsive-table-column",
                        {
                          attrs: {
                            prop: "Amount",
                            label: "Amount",
                            width: "calc((100% - 86px) / 3)",
                            type: "Number",
                            sortable: false
                          }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm._f("dcxa-currency-filter")(
                                  row.Amount,
                                  "USD",
                                  true
                                )
                              ) +
                              "\n            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "dcxa-responsive-table-column",
                        {
                          attrs: {
                            prop: "Balance",
                            label: "Balance",
                            width: "calc((100% - 86px) / 3)",
                            type: "Number",
                            sortable: false
                          }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm._f("dcxa-currency-filter")(
                                  row.Balance,
                                  "USD",
                                  true
                                )
                              ) +
                              "\n            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("dcxa-responsive-table-column", {
                        attrs: {
                          prop: "ExpirationDateFormatted",
                          label: "Expiration",
                          width: "86px",
                          type: "Date",
                          sortable: false
                        }
                      })
                    ]
                  }
                }
              ])
            })
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "template",
        { slot: "has-error" },
        [
          !_vm.isAuthorized
            ? _c(
                "dcxa-special-state-container",
                { attrs: { icon: "far fa-user-lock" } },
                [
                  _c("span", [
                    _vm._v("Please sign in to see your accrual amounts.")
                  ])
                ]
              )
            : !_vm.isEligible
            ? _c(
                "dcxa-special-state-container",
                { attrs: { icon: "far fa-frown" } },
                [
                  _c(
                    "span",
                    [
                      _vm._v(
                        "\n                This account is not eligible for the MDF program."
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                For more information on eligibility, please see the "
                      ),
                      _c(
                        "dcxa-text-link",
                        {
                          attrs: {
                            "is-target-blank": true,
                            "secondary-target": _vm.programOverviewLinkTarget
                          }
                        },
                        [_vm._v("Program Overview")]
                      ),
                      _vm._v(".\n            ")
                    ],
                    1
                  )
                ]
              )
            : _c(
                "dcxa-special-state-container",
                { attrs: { icon: "far fa-frown" } },
                [
                  _c(
                    "span",
                    [
                      _vm._v(
                        "\n                Our systems returned an error. Please try again."
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "dcxa-text-link",
                        {
                          attrs: { "secondary-target": _vm.contactUsLinkTarget }
                        },
                        [_vm._v("Contact us")]
                      ),
                      _vm._v(" or "),
                      _c(
                        "dcxa-text-link",
                        { attrs: { secondaryTarget: _vm.callUsNumberLink } },
                        [_vm._v("call us")]
                      ),
                      _vm._v(" if the problem persists.\n            ")
                    ],
                    1
                  )
                ]
              )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3b8be76f", { render: render, staticRenderFns: staticRenderFns })
  }
}