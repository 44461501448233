var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dcxa-property-value-editor",
      class: {
        inline: _vm.inline,
        "has-error": _vm.hasValidationMessage,
        "floating-label": _vm.floatingLabel
      }
    },
    [
      _vm.$screensFrom("md") && !_vm.forceFloatingLabel && !_vm.hideLabel
        ? _c(
            "span",
            {
              staticClass: "property",
              attrs: { "data-dd-privacy": "mask-user-input" }
            },
            [
              _vm._v(_vm._s(_vm.title) + "\n        "),
              _vm.isRequired
                ? _c("span", { staticClass: "asterisk" }, [_vm._v("*")])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasTooltip
                ? _c(
                    "dcxa-icon-with-tooltip",
                    {
                      attrs: { classes: _vm.infoIconSize, placement: "bottom" }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.tooltipContent) +
                          "\n        "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "value" },
        [
          _c(
            "div",
            { staticClass: "secondary-label-container" },
            [
              _vm._t("secondaryLabel", function() {
                return [
                  _vm.floatingLabel && _vm.hasTooltip && _vm.type != "custom"
                    ? _c(
                        "dcxa-icon-with-tooltip",
                        {
                          attrs: {
                            classes: _vm.infoIconSize,
                            placement: "bottom"
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.tooltipContent) +
                              "\n            "
                          )
                        ]
                      )
                    : _vm._e()
                ]
              }),
              _vm._v(" "),
              _vm.charCounterVisible
                ? _c(
                    "div",
                    {
                      ref: "charCounter",
                      staticClass: "char-counter",
                      class: {
                        error: _vm.calculatedLength > _vm.textAreaMaxChars
                      },
                      style: _vm.charCounterStyles
                    },
                    [
                      _vm._v(
                        "\n              Length: " +
                          _vm._s(_vm.calculatedLength) +
                          " / " +
                          _vm._s(_vm.textAreaMaxChars) +
                          "\n          "
                      )
                    ]
                  )
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _vm.type == "label"
            ? _c("dcxa-label", {
                attrs: {
                  id: _vm.id,
                  value: _vm.input,
                  title: _vm.title,
                  floatingLabel: _vm.floatingLabel
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.type == "text" || _vm.type == "textarea"
            ? _c(
                "dcxa-input",
                {
                  class: _vm.appliedClasses,
                  attrs: {
                    id: _vm.id,
                    name: _vm.name,
                    type: _vm.type,
                    popover: _vm.hasValidationHint,
                    disabled: _vm.config.disabled || _vm.disabled,
                    floatingLabel: _vm.floatingLabel,
                    rows: _vm.inputRows,
                    prefixIcon: _vm.prefixIcon,
                    resize: "none",
                    placeholder: _vm.placeholder,
                    floatingTitle: _vm.floatingTitle
                  },
                  model: {
                    value: _vm.input,
                    callback: function($$v) {
                      _vm.input = $$v
                    },
                    expression: "input"
                  }
                },
                [
                  _c("template", { slot: "popoverContent" }, [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.validationHint) }
                    })
                  ])
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.type == "password"
            ? _c(
                "dcxa-input",
                {
                  class: _vm.appliedClasses,
                  attrs: {
                    id: _vm.id,
                    name: _vm.name,
                    popover: _vm.hasValidationHint,
                    type: "password",
                    disabled: _vm.config.disabled || _vm.disabled,
                    enabledValidationPopover:
                      _vm.config.enabledValidationPopover,
                    floatingLabel: _vm.floatingLabel,
                    prefixIcon: _vm.prefixIcon,
                    placeholder: _vm.placeholder,
                    floatingTitle: _vm.floatingTitle
                  },
                  model: {
                    value: _vm.input,
                    callback: function($$v) {
                      _vm.input = $$v
                    },
                    expression: "input"
                  }
                },
                [
                  _c("template", { slot: "popoverContent" }, [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.validationHint) }
                    })
                  ])
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.type == "checkbox"
            ? _c(
                "dcxa-checkbox",
                {
                  class: _vm.appliedClasses,
                  attrs: {
                    id: _vm.id,
                    name: _vm.name,
                    popover: _vm.hasValidationHint,
                    disabled:
                      _vm.config.disabled ||
                      _vm.disabled ||
                      _vm.config.progressing,
                    floatingLabel: _vm.floatingLabel,
                    floatingTitle: _vm.floatingTitle,
                    contentLabel: _vm.contentLabel,
                    classes: { progressing: _vm.config.progressing }
                  },
                  model: {
                    value: _vm.input,
                    callback: function($$v) {
                      _vm.input = $$v
                    },
                    expression: "input"
                  }
                },
                [
                  _c("template", { slot: "popoverContent" }, [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.validationHint) }
                    })
                  ])
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.type == "datepicker"
            ? _c(
                "dcxa-datepicker",
                {
                  class: _vm.appliedClasses,
                  attrs: {
                    id: _vm.id,
                    name: _vm.name,
                    popover: _vm.hasValidationHint,
                    disabled: _vm.config.disabled || _vm.disabled,
                    clearable: _vm.config.clearable,
                    pickerOptions: _vm.config.pickerOptions,
                    floatingLabel: _vm.floatingLabel,
                    placeholder: _vm.placeholder,
                    floatingTitle: _vm.floatingTitle
                  },
                  model: {
                    value: _vm.input,
                    callback: function($$v) {
                      _vm.input = $$v
                    },
                    expression: "input"
                  }
                },
                [
                  _c("template", { slot: "popoverContent" }, [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.validationHint) }
                    })
                  ])
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.type == "timepicker"
            ? _c(
                "dcxa-timepicker",
                {
                  class: _vm.appliedClasses,
                  attrs: {
                    id: _vm.id,
                    name: _vm.name,
                    popover: _vm.hasValidationHint,
                    disabled: _vm.config.disabled || _vm.disabled,
                    clearable: _vm.config.clearable,
                    pickerOptions: _vm.config.pickerOptions,
                    floatingLabel: _vm.floatingLabel,
                    placeholder: _vm.placeholder,
                    floatingTitle: _vm.floatingTitle
                  },
                  model: {
                    value: _vm.input,
                    callback: function($$v) {
                      _vm.input = $$v
                    },
                    expression: "input"
                  }
                },
                [
                  _c("template", { slot: "popoverContent" }, [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.validationHint) }
                    })
                  ])
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.type == "select"
            ? _c(
                "dcxa-select",
                {
                  class: _vm.appliedClasses,
                  attrs: {
                    id: _vm.id,
                    name: _vm.name,
                    disabled: _vm.config.disabled || _vm.disabled,
                    popover: _vm.hasValidationHint,
                    clearable: _vm.config.clearable,
                    filterable: _vm.config.filterable,
                    remote: _vm.config.remote,
                    remoteMethod: _vm.inlineEditorRemoteMethod,
                    multiselect: _vm.config.enableMultiselect,
                    floatingLabel: _vm.floatingLabel,
                    floatingTitle: _vm.floatingTitle,
                    placeholder: _vm.placeholder,
                    "num-of-options": _vm.options.length
                  },
                  model: {
                    value: _vm.input,
                    callback: function($$v) {
                      _vm.input = $$v
                    },
                    expression: "input"
                  }
                },
                [
                  _vm._l(_vm.options, function(item) {
                    return _c(
                      "el-option",
                      {
                        key: item[_vm.optionItem.value || "id"],
                        attrs: {
                          label: item[_vm.optionItem.title || "title"],
                          value: item[_vm.optionItem.value || "id"],
                          disabled: item["disabled"]
                        }
                      },
                      [_vm._t("customOptionTemplate", null, { item: item })],
                      2
                    )
                  }),
                  _vm._v(" "),
                  _c("template", { slot: "popoverContent" }, [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.validationHint) }
                    })
                  ])
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.type == "toggle"
            ? [
                _vm.options.length <= 4
                  ? _c(
                      "dcxa-toggle-button",
                      {
                        class: _vm.appliedClasses,
                        attrs: {
                          id: _vm.id,
                          name: _vm.name,
                          disabled: _vm.config.disabled || _vm.disabled,
                          popover: _vm.hasValidationHint,
                          mode: _vm.mode,
                          options: _vm.options,
                          floatingLabel:
                            !_vm.config.hideFloatingLabel && _vm.floatingLabel,
                          floatingTitle: _vm.floatingTitle,
                          placeholder: _vm.placeholder
                        },
                        model: {
                          value: _vm.input,
                          callback: function($$v) {
                            _vm.input = $$v
                          },
                          expression: "input"
                        }
                      },
                      [
                        _c("template", { slot: "popoverContent" }, [
                          _c("div", {
                            domProps: { innerHTML: _vm._s(_vm.validationHint) }
                          })
                        ])
                      ],
                      2
                    )
                  : _c(
                      "dcxa-select",
                      {
                        class: _vm.appliedClasses,
                        attrs: {
                          id: _vm.id,
                          name: _vm.name,
                          disabled: _vm.config.disabled || _vm.disabled,
                          popover: _vm.hasValidationHint,
                          clearable: _vm.config.clearable,
                          filerable: _vm.config.filterable,
                          remote: _vm.config.remote,
                          remoteMethod: _vm.inlineEditorRemoteMethod,
                          multiselect: _vm.config.enableMultiselect,
                          "no-data-text": "No selectable item.",
                          floatingLabel: _vm.floatingLabel,
                          floatingTitle: _vm.floatingTitle,
                          placeholder: _vm.placeholder,
                          "num-of-options": _vm.options.length
                        },
                        model: {
                          value: _vm.input,
                          callback: function($$v) {
                            _vm.input = $$v
                          },
                          expression: "input"
                        }
                      },
                      [
                        _vm._l(_vm.options, function(item) {
                          return _c(
                            "el-option",
                            {
                              key: item[_vm.optionItem.value || "id"],
                              attrs: {
                                label: item[_vm.optionItem.title || "title"],
                                value: item[_vm.optionItem.value || "id"],
                                disabled: item["disabled"]
                              }
                            },
                            [
                              _vm._t("customOptionTemplate", null, {
                                item: item
                              })
                            ],
                            2
                          )
                        }),
                        _vm._v(" "),
                        _c("template", { slot: "popoverContent" }, [
                          _c("div", {
                            domProps: { innerHTML: _vm._s(_vm.validationHint) }
                          })
                        ])
                      ],
                      2
                    )
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.type == "custom"
            ? _c(
                "dcxa-custom-property-value-editor",
                {
                  class: _vm.appliedClasses,
                  attrs: {
                    id: _vm.id,
                    name: _vm.name,
                    floatingLabel: _vm.floatingLabel,
                    tooltipContent: _vm.tooltipContent,
                    placeholder: _vm.placeholder,
                    floatingTitle: _vm.floatingTitle
                  }
                },
                [_vm._t("default")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.inline
            ? _c(
                "div",
                { staticClass: "inline-editor-buttons" },
                [
                  _c(
                    "dcxa-button",
                    {
                      class: _vm.inlineButtonClasses,
                      attrs: { type: "primary", disabled: !_vm.state.result },
                      on: { click: _vm.onInlineSubmit }
                    },
                    [
                      _c("i", {
                        staticClass: "far fa-check submit",
                        attrs: { "aria-hidden": "true" }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "dcxa-button",
                    {
                      class: _vm.appliedWithOuterClasses({}),
                      attrs: { disabled: _vm.inlineProgressing },
                      on: { click: _vm.onInlineCancel }
                    },
                    [
                      _c("i", {
                        staticClass: "far fa-times cancel",
                        attrs: { "aria-hidden": "true" }
                      })
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.inline && _vm.hasInlineError
            ? _c(
                "span",
                {
                  staticClass: "inline-error-message",
                  class: _vm.validationClasses
                },
                [
                  _vm._v(
                    "\n            There was an error with saving your changes. Please try again.\n        "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasValidationMessage
            ? _c(
                "span",
                {
                  staticClass: "validation-message",
                  class: _vm.validationClasses
                },
                [
                  _c("i", { staticClass: "fal fa-exclamation-circle" }),
                  _vm._v(" " + _vm._s(_vm.validationMessage) + "\n        ")
                ]
              )
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4fbe7b20", { render: render, staticRenderFns: staticRenderFns })
  }
}