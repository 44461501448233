<template>
    <div class="success-modal-body"> 
        <h4>{{message}}</h4>
        <i class="fal fa-check" aria-hidden="true"></i>
    </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'

const dcxaCore = require('@dcxa/dcxa-core');
const { Configs, Utilities, Utils, Api, ValidationFramework  } = dcxaCore;


export default {
  name: 'SuccessModalBody',
  components: {
  },
  props: ['message'],
  data() {
    return {
       
    }
  },
  computed: {
    ...mapGetters({
    })
  },
  watch: {
  },
  methods: {
   
  },
  async mounted() {
  }
}
</script>

<style scoped lang="scss">
@import "../../../../../styles/_colors.scss";
.success-modal-body {
    text-align: center;

    .fal {
        font-size: 27px;
        color: $green;
        margin-bottom: 16px;
    }

    h4 {
        margin: 16px 0 16px;
    }

}

</style>
