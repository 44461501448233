var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dcxa-property-value-viewer",
      class: { "enable-multiline": _vm.enableMultiline }
    },
    [
      _vm.title
        ? _c(
            "span",
            { staticClass: "property dd-privacy-mask-user-input" },
            [
              _vm._v("\n        " + _vm._s(_vm.title) + "\n        "),
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "right",
                    trigger: "hover",
                    content: _vm.infoText,
                    "popper-class": "dcxa-tooltip"
                  }
                },
                [
                  _vm.isInfoMessageVisible
                    ? _c("i", {
                        staticClass:
                          "far fa-info-circle property-value-info-tooltip",
                        attrs: { slot: "reference", "aria-hidden": "true" },
                        slot: "reference"
                      })
                    : _vm._e()
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "value", style: _vm.propertyValueViewerStyle },
        [
          _vm.$scopedSlots.hasOwnProperty("customValue") && _vm.isProvided
            ? _vm._t("customValue", null, { context: _vm.valueContext })
            : [
                _c("div", { staticClass: "value" }, [
                  _c(
                    "span",
                    {
                      class: { missing: !_vm.isProvided },
                      attrs: { id: _vm.id }
                    },
                    [_vm._v(_vm._s(_vm.valueToShow))]
                  )
                ])
              ]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5c12b145", { render: render, staticRenderFns: staticRenderFns })
  }
}