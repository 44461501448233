<template>
    <div class="dcxa-pagination" v-if="totalLength > 0" data-dd-privacy="mask-user-input">

        <div class="first-row">
            <div class="page-info">
                {{currentFrom}} - {{currentTo}} of {{ totalLength }}
            </div>

            <div class="page-size-selector" v-if="!lessThanSmallestPageSize && showPageSizeOptions">
                <dcxa-select :id="sizeSelectorId" class="small" v-model="effectiveDataSource.pageSize" @input="paginationInfoChanged">
                    <el-option v-for="item in pageSizeOptions" :key="item.id"
                               :label="item.title"
                               :value="item.id">
                    </el-option>
                </dcxa-select>
            </div>

            <dcxa-button v-if="!lessThanViewableItems && $screenIs('xs') && responsive"
                    type="icon"
                    @click="scrollToTop"
                    :icon="'fas fa-chevron-up'"
                    class="medium inline-block scroll-up-button">
            </dcxa-button>

            <div class="page-links" v-if="!lessOrEqualThanSelectedPageSize && ($screensFrom('md') || !responsive)">
                <el-pagination
                        :id= "paginationId"
                        @current-change="paginationInfoChanged"
                        :current-page.sync="effectiveDataSource.currentPage"
                        :page-size="effectiveDataSource.pageSize"
                        :pager-count="5"
                        layout="prev, pager, next"
                        :total="totalLength">
                </el-pagination>
            </div>
        </div>
        <div class="second-row">
            <div class="page-links" v-if="!lessOrEqualThanSelectedPageSize && $screenIs('xs') && responsive">
                <el-pagination
                        @current-change="paginationInfoChanged"
                        :current-page.sync="effectiveDataSource.currentPage"
                        :page-size="effectiveDataSource.pageSize"
                        :pager-count="5"
                        layout="prev, pager, next"
                        :total="totalLength">
                </el-pagination>
            </div>
        </div>

    </div>
</template>

<script>
    const dcxaCore = require('@dcxa/dcxa-core');
    const {Utilities} = dcxaCore;

    export default {
        name: "Pagination",
        props: {
            responsive: {
                type: Boolean,
                default: true
            },
            data: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            paginationInfo: {
                type: Object,
                default: function () {
                    return {
                        pageSize: 10,
                        pageSizes: [10, 25, 50],
                        currentPage: 1
                    }
                }
            },
            sizeSelectorId: {
                type: String,
                default: () => ''
            },
            paginationId: {
                type: String,
                default: () => ''
            },
            showPageSizeOptions: {
                type: Boolean,
                default: () => true
            },
            remote: {
                type: Boolean,
                default: () => false
            },
            remotePaginationInfo: {
              type: Object,
              default: () => {
                return {numberOfPages: 0, dataSetSize: 0, pageSize: 0, currentPage: 0}
              }
            }
        },
        computed: {
            effectiveDataSource() {
                return (this.remote) ? this.remotePaginationInfo : this.paginationInfo;
            },
            totalLength() {
                return (this.remote) ? this.remotePaginationInfo.dataSetSize : this.data.length;
            },
            currentFrom() {
                return (this.effectiveDataSource.currentPage - 1) * this.effectiveDataSource.pageSize + 1;
            },
            currentTo() {
                return Math.min(this.effectiveDataSource.currentPage * this.effectiveDataSource.pageSize, this.totalLength);
            },
            lessThanSmallestPageSize() {
                return this.totalLength < this.paginationInfo.pageSizes[0];
            },
            lessOrEqualThanSelectedPageSize() {
                return this.totalLength <= this.effectiveDataSource.pageSize;
            },
            lessThanViewableItems() {
                return this.totalLength < 4;
            },
            pageSizeOptions() {
                return _.map(this.paginationInfo.pageSizes, c => { return {
                    id: c,
                    title: c + '/page'
                }});
            }
        },
        methods: {
            paginationInfoChanged() {
                this.effectiveDataSource.currentPage = this.effectiveDataSource.currentPage <= Math.ceil(this.totalLength/this.effectiveDataSource.pageSize) ? this.effectiveDataSource.currentPage : Math.ceil(this.totalLength/this.effectiveDataSource.pageSize)
                this.$emit('paginationChange', this.effectiveDataSource);
            },
            scrollToTop() {
                Utilities.dom.scrollToTop();
            }
        }
    }
</script>

<style lang="scss">
    @import "../../../../styles/_utilities.scss";
    @import "../../../../styles/_colors.scss";
    .dcxa-pagination {
        .el-pagination {
            padding: 0;
            color: tint($black, 80);
            font-weight: normal;

            & > button {
                background: transparent;
                color: $mint;
                width: 32px;
                min-width: 32px;
                height: 32px;
            }

            & > .el-pager > li {
                background: transparent;
                min-width: 32px;
                height: 32px;
                line-height: 32px;

                &.active {
                    background:  $mint;
                    border-radius: 3px;
                    color: $white;
                }
            }
        }

        .first-row {
            display: flex;
            justify-content: flex-start;
            margin: 16px 0 0 0;

            .page-loader {
                width: 100%;
            }

            .page-info {
                font-size: 14px;
                color: tint($black, 80);
                padding: 0 0 0 6px;
                line-height: 1.29;
                display: flex;
                align-items: center;
            }

            .page-size-selector {
                display: flex;
                align-items: center;

                span {
                    display: inline-block;
                }

                .dcxa-select {
                    margin-left: 8px;
                    display: inline-block;
                    width: 112px;
                }
            }

            .page-links {
                margin-left: auto;
                margin-right: 0;

                button.btn-next {
                    padding-right: 0;
                }
            }

            .scroll-up-button {
                margin-left: auto;
                margin-right: 0;

                width: 40px;
                min-width: 40px;
                border-radius: 40px;
                box-shadow: 0 2px 3px 0 rgba($black, 0.15);
            }
        }

        .second-row {
            display: flex;
            justify-content: center;

            .page-links {
                margin-top: 20px;

                button.btn-next {
                    padding-right: 0;
                }
            }
        }
    }
</style>