<template>
    <div class="dcxa-loading-spinner" />
</template>

<script>
    export default {
        name: 'DcxaLoadingSpinner'
    }
</script>

<style lang="scss">
    @import "../../../../styles/_colors.scss";
    @import "../../styles/_mixins.scss";

    .dcxa-loading-spinner {
        @include loading-spinner(normal);
    }

</style>
