var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dcxa-expander",
      class: _vm.dynamicClasses,
      style: _vm.dynamicStyles
    },
    [
      _c(
        "div",
        { staticClass: "controls" },
        [
          _c("dcxa-button", {
            staticClass: "small",
            attrs: { type: "icon", icon: _vm.controlIcon },
            on: { click: _vm.toggleExpanded }
          }),
          _vm._v(" "),
          _vm.expanded ? _c("h5", [_vm._t("title")], 2) : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.expanded
        ? _c("div", { staticClass: "content" }, [_vm._t("content")], 2)
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4f67a46f", { render: render, staticRenderFns: staticRenderFns })
  }
}