<template>
    <div class="dcxa-inline-property-editor-section">
        <div>
            <slot :sectionContext="this" />
        </div>
        <div class="local-property-section-editor-controls" v-if="!disableLocalEditing">
            <dcxa-button type="secondary" :disabled="saving" class="medium local-editor-cancel-button" @click="onCancel">Cancel</dcxa-button>
            <dcxa-button type="primary" :disabled="!isValid || !changed" class="medium local-editor-save-button" :class="{'loading': saving}" @click="onSave">Save</dcxa-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InlinePropertyEditorSection",
        props: {
            saving: {
                type: Boolean,
                default: () => false
            },
            isValid: {
                type: Boolean,
                default: () => false
            },
            disableLocalEditing: {
                type: Boolean,
                default: () => false
            },
            editorStates: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                changed: false
            }
        },
        methods: {
            onCancel() {
                if (!this.disableLocalEditing) {
                    this.$emit('cancel');
                }
            },
            onSave() {
                if (!this.disableLocalEditing) {
                    this.$emit('save');
                }
            },
            editRowStateChanged(id, state) {
                let oldValue = this.editorStates[id].value;

                this.editorStates[id] = state;

                if (this.editorStates[id].firstRound !== true && oldValue !== this.editorStates[id].value) {
                    this.changed = true;
                    this.$emit('changed');
                }

                this.$emit('validated');
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../../../../styles/utilities";
    @import "../../../../../styles/colors";

    .dcxa-inline-property-editor-section {
        .local-property-section-editor-controls {
            margin-top: 40px;
            display: flex;
            align-items: stretch;
            flex-direction: column-reverse;

            .local-editor-cancel-button {

            }

            .local-editor-save-button {
                margin: 0 0 16px 0;
            }
        }

        @include breakpoint(md) {
            .local-property-section-editor-controls {
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;

                .local-editor-cancel-button {
                    margin-right: 24px;
                    width: 102px;
                    min-width: 102px;
                }

                .local-editor-save-button {
                    margin-bottom: 0;
                    width: 102px;
                    min-width: 102px;
                }
            }
        }

        @include breakpoint(xl) {
        }
    }
</style>