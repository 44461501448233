var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-select", class: _vm.classes },
    [
      _c(
        "el-popover",
        {
          attrs: {
            disabled: !_vm.popover || !_vm.enabledValidationPopover,
            trigger: "focus",
            placement: "bottom",
            effect: "light",
            "popper-class": "dcxa-tooltip"
          }
        },
        [
          _vm._t("popoverContent"),
          _vm._v(" "),
          _c(
            "template",
            { slot: "reference" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    id: _vm.id,
                    name: _vm.name,
                    value: _vm.value,
                    disabled: _vm.disabled,
                    clearable: _vm.clearable,
                    "value-key": _vm.valueKey,
                    multiple: _vm.multiselect,
                    "collapse-tags": true,
                    filterable: _vm.filterableOptions,
                    remote: _vm.remote,
                    "remote-method": _vm.remoteMethod,
                    "no-data-text": "No selectable item",
                    "no-match-text": "Item not found",
                    loading: _vm.loading,
                    "loading-text": _vm.loadingText,
                    placeholder: _vm.placeHolderText,
                    floatingLabel: _vm.floatingLabel,
                    "popper-class": _vm.popperClass
                  },
                  on: {
                    change: _vm.updateValue,
                    blur: function($event) {
                      return _vm.focusEventHandler(false)
                    },
                    focus: function($event) {
                      return _vm.focusEventHandler(true)
                    }
                  }
                },
                [_vm._t("default")],
                2
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("label", {
        staticClass: "floating-label-element",
        class: { visible: _vm.showFloatingLabel },
        domProps: { innerHTML: _vm._s(_vm.floatingTitleText) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-36c8ee24", { render: render, staticRenderFns: staticRenderFns })
  }
}