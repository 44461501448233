<template>
    <div class="dcxa-content-loader">
        <dcxa-content-title :title="title" :titleSize="titleSize" v-if="isSimpleTable || isCardList"></dcxa-content-title>
        <div v-if="$screensFrom('md')">
            <template v-if="isSimpleTable">
                <div v-for="currentRow in numberOfRows" class="loading-element loading-desktop animated-element" :class="[`element-${currentRow}`]">
                    <div v-for="currentColumn in numberOfColumns" :key="currentColumn" :style="simpleTableDesktopColumnStyle">
                        <div class="loading-item"></div>
                    </div>
                </div>
            </template>
            <template v-if="isAdvisorySection">
                <div class="loading-element loading-mobile">
                    <div class="animated-element element-1" :style="advisorySectionLongerStyle"><div class="loading-item"></div></div>
                    <div class="animated-element element-2" :style="advisorySectionShorterStyle"><div class="loading-item"></div></div>
                    <div class="animated-element element-3" :style="advisorySectionLongerStyle"><div class="loading-item"></div></div>
                    <div class="animated-element element-4" :style="advisorySectionShorterWithoutMarginStyle"><div class="loading-item"></div></div>
                </div>
            </template>
            <template v-else-if="isCardList">
                <div v-for="(n, i) in 3" class="loading-element loading-desktop animated-element" :class="[`element-${i+1}`]">
                    <div class="loading-card-list-title" :style="fullWidthStyle"><div class="loading-item"></div></div>
                    <div :style="partialWidthStyle">
                        <div v-for="currentColumn in numberOfColumns" :key="currentColumn" :style="cardListDesktopColumnStyle">
                            <div class="loading-item loading-metadata"></div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else-if="isContent">
                <div class="content-loader-container">
                    <dcxa-loading-spinner />
                    <span>{{title}}</span>
                </div>
            </template>
            <template v-else-if="isCommentList">
                <div v-for="(n, i) in 3" class="loading-element loading-desktop loading-desktop-comment animated-element" :class="[`element-${i+1}`]">
                    <div class="loading-comment-title1" :style="commentDesktopTitle1Style"><div class="loading-item"></div></div>
                    <div class="loading-comment-title2" :style="commentDesktopTitle2Style"><div class="loading-item"></div></div>
                    <div :style="fullWidthStyle">
                        <div v-for="currentColumn in (numberOfColumns - 1)" :key="currentColumn" :style="fullWidthStyle">
                            <div class="loading-item loading-comment"></div>
                        </div>
                        <div :style="partialWidthStyle"><div class="loading-item loading-comment"></div></div>
                    </div>
                </div>
            </template>
        </div>
        <div v-else>
            <div class="loading-element loading-mobile">
                <template v-if="isSimpleTable">
                    <div v-for="currentColumn in numberOfColumns" :key="currentColumn" :class="[`element-${currentColumn}`]" class="loading-item-container animated-element">
                        <div class="loading-item-prop"><div class="loading-item"></div></div>
                        <div class="loading-item-value"><div class="loading-item"></div></div>
                    </div>
                </template>
                <template v-if="isAdvisorySection">
                    <div class="animated-element element-1" :style="advisorySectionLongerStyle"><div class="loading-item"></div></div>
                    <div class="animated-element element-2" :style="advisorySectionShorterStyle"><div class="loading-item"></div></div>
                    <div class="animated-element element-3" :style="advisorySectionLongerStyle"><div class="loading-item"></div></div>
                    <div class="animated-element element-4" :style="advisorySectionShorterWithoutMarginStyle"><div class="loading-item"></div></div>
                </template>
                <template v-else-if="isCardList">
                    <div class="animated-element loading-card-list-title1" :style="fullWidthStyle" style="{margin-bottom: 8px;}"><div class="loading-item"></div></div>
                    <div class="animated-element loading-card-list-title"  :style="partialWidthStyle"><div class="loading-item"></div></div>

                    <div v-for="currentColumn in (numberOfColumns - 1)" :key="currentColumn" :class="[`element-${currentColumn}`]" class="animated-element" >
                        <div class="loading-item loading-metadata"></div>
                    </div>
                    <div class="animated-element" :class="[`element-${numberOfColumns}`]" :style="partialWidthStyle">
                        <div class="loading-item loading-metadata"></div>
                    </div>
                </template>
                <template v-else-if="isContent">
                    <div class="content-loader-container">
                        <dcxa-loading-spinner />
                        <span>{{title}}</span>
                    </div>
                </template>
                <template v-else-if="isCommentList">
                    <div class="animated-element loading-comment-title1" :style="commentMobileTitle1Style"><div class="loading-item"></div></div>
                    <div class="animated-element loading-comment-title2" :style="commentMobileTitle2Style"><div class="loading-item"></div></div>

                    <div v-for="currentColumn in (numberOfColumns - 1)" :key="currentColumn" :class="[`element-${currentColumn}`]" class="animated-element loading-comment" >
                        <div class="loading-item"></div>
                    </div>
                    <div class="animated-element loading-comment" :class="[`element-${numberOfColumns}`]" :style="partialWidthStyle">
                        <div class="loading-item"></div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContentLoader",
        props: {
            title: {
                type: String,
                default: () => ''
            },
            titleSize: {
                type: String,
                default: () => undefined
            },
            numberOfColumns: {
                type: Number,
                default: () => 5
            },
            numberOfRows: {
                type: Number,
                default: () => 10
            },
            type: {
                type: String,
                default: () => 'Content',
                validator: function (value) {
                    return ['Content', 'SimpleTable', 'CardList', 'CommentList', 'AdvisorySection'].indexOf(value) !== -1
                }
            }
        },
        computed: {
            isSimpleTable() {
                return this.type === 'SimpleTable';
            },
            isCardList() {
                return this.type === 'CardList';
            },
            isContent() {
                return this.type === 'Content';
            },
            isCommentList() {
                return this.type === 'CommentList';
            },
            isAdvisorySection() {
                return this.type === 'AdvisorySection';
            },
            simpleTableDesktopColumnStyle() {
                return {width: (100 / this.numberOfColumns) + '%', minWidth: (100 / this.numberOfColumns) + '%'};
            },
            fullWidthStyle() {
                return {width: '100%', minWidth: '100%'};
            },
            partialWidthStyle() {
                return {width: '80%', minWidth: '80%'};
            },
            cardListDesktopColumnStyle() {
                return {width: '25%', minWidth: '25%', float: 'left'};
            },
            commentDesktopTitle1Style() {
                return {width: '20%', minWidth: '20%'};
            },
            commentDesktopTitle2Style() {
                return {width: '25%', minWidth: '25%'};
            },
            commentMobileTitle1Style() {
                return {width: '40%', minWidth: '40%'};
            },
            commentMobileTitle2Style() {
                return {width: '60%', minWidth: '60%', marginBottom: '18px'};
            },
            advisorySectionLongerStyle() {
                return {width: '80%', minWidth: '80%', marginBottom: '16px'};
            },
            advisorySectionShorterStyle() {
                return {width: '60%', minWidth: '60%', marginBottom: '16px'};
            },
            advisorySectionShorterWithoutMarginStyle() {
                return {width: '60%', minWidth: '60%'};
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../../styles/utilities";
    @import "../../../../styles/colors";

    .dcxa-content-loader {
        margin-top: 18px;

        .dcxa-content-title {
            margin-bottom: 32px;
        }

        .loading-metadata {
            height: 12px;
            margin-top: 8px;
        }

        .loading-comment {
            margin-top: 8px;
        }

        .loading-comment-title2 {
            margin-top: 4px;
            margin-bottom: 10px;
        }

        .loading-card-list-title1 {
            margin-bottom: 8px;
        }

        .loading-card-list-title {
            margin-bottom: 16px;
        }

        .loading-element {
            background-color: tint($black, 7);
            padding: 20px 16px;
            margin-bottom: 4px;
            border-radius: 3px;

            .loading-item-container {
                display: flex;
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }

                .loading-item-prop {
                    width: 38%;
                }

                .loading-item-value {
                    width: 62%;
                }
            }
        }

        .content-loader-container {
            margin-top: 32px;
            height: 200px;
            width: 100%;
            border-radius: 3px;
            background-color: tint($black, 7);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            span {
                font-size: 16px;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: center;
                color: $black;
            }
        }
    }

    @include breakpoint(md) {
        .dcxa-content-loader {
            .loading-desktop {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                &.loading-desktop-comment {
                    flex-direction: column;
                }

                .loading-card-list-title {
                    margin-bottom: 16px;
                }
            }
        }
    }
</style>