<template>
    <div class="dcxa-modal-buttons">
        <dcxa-modal-button v-for="(btn, index) in buttonsWithType" :key="index" :modal-context="modalContext" :config="btn" />
    </div>
</template>

<script>
    export default {
        name: "DcxaModalButtons",

        props: {
            buttons: {
                type: Array,
                default: () => []
            },

            modalContext: {
                type: Object,
                default: () => {}
            },

            ids: {
                type: Object,
                default: () => {}
            }
        },

        computed: {
            buttonsWithType() {
                //return this.buttons;
                return _.forEach(this.buttons, (b, i) => {
                    if (!b.type) {
                        if (this.buttons.length === 1) {
                            b.type = 'primary';
                            if (!b.id) {
                                b.id = this.ids['primary_btn_id'] ? this.ids['primary_btn_id'] : null;
                            }
                        }
                        else if (this.buttons.length === 2) {
                            if (i === 0) {
                                b.type = 'secondary';
                                if (!b.id) {
                                    b.id = this.ids['secondary_btn_id'] ? this.ids['secondary_btn_id'] : null;
                                }
                            }
                            else if (i === 1) {
                                b.type = 'primary';
                                if (!b.id) {
                                    b.id = this.ids['primary_btn_id'] ? this.ids['primary_btn_id'] : null;
                                }
                            }
                        }
                        else if (this.buttons.length === 3) {
                            if (i === 0) {
                                b.type = 'text';
                                if (!b.id) {
                                    b.id = this.ids['text_btn_id'] ? this.ids['text_btn_id'] : null;
                                }
                            }
                            else if (i === 1) {
                                b.type = 'secondary';
                                if (!b.id) {
                                    b.id = this.ids['secondary_btn_id'] ? this.ids['secondary_btn_id'] : null;
                                }
                            }
                            else if (i === 2) {
                                b.type = 'primary';
                                if (!b.id) {
                                    b.id = this.ids['primary_btn_id'] ? this.ids['primary_btn_id'] : null;
                                }
                            }
                        }
                    }
                    else {
                      if (!b.id) {
                          b.id = this.ids[b.type + '_btn_id'] ? this.ids[b.type + '_btn_id'] : null;
                      }
                    }
                });
            }
        }
    }
</script>


<style scoped lang="scss">
    @import "../../../../../styles/utilities";
    @import "../../../../../styles/colors";

    .dcxa-modal-buttons {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 24px;

        .dcxa-button {
            width: 100%;
            margin: 0 0 16px 0;
        }
    }

    @include breakpoint(md) {
        .dcxa-modal-buttons {
            flex-direction: row;
            justify-content: flex-end;

            .dcxa-button {
                width: auto;
                margin: 0 0 0 8px;

                &:first-child {
                    margin-left: 0;
                }
            }

            .text-link {
                margin-right: 8px;
            }
        }
    }
</style>
