<template>

    <dcxa-modal-dialog type="content" class="dcxa-case-watched-changing-error-dialog" :visible="visible" :close-button="true" @onCloseDialog="watchedChangingCaseErrorDialogCancelAction">
        <template slot="header">
            <div class="dialog-head">
                <i class="far fa-exclamation-triangle dialog-header-icon" />
                <h4>Failed to {{dialogTypeTitle}} Case</h4>
            </div>
        </template>

        <template slot="body">
            <div class="subtitle">Please try again. <dcxa-text-link :secondary-target="contactUsLink">Contact us</dcxa-text-link> or <dcxa-text-link secondary-target="https://my.cloudera.com/faq.html#support">call us</dcxa-text-link> if the problem persists.</div>
        </template>

        <template slot="footer">
            <dcxa-button type="secondary" class="medium" :disabled="inProgress" @click="watchedChangingCaseErrorDialogCancelAction">Cancel</dcxa-button>
            <dcxa-button type="primary" class="medium" :class="{'loading': inProgress}" @click="watchedChangingCaseErrorDialogSubmitAction">Retry</dcxa-button>
        </template>
    </dcxa-modal-dialog>
</template>

<script>
    const dcxaCore = require('@dcxa/dcxa-core');
    const { Configs, Api, Utilities } = dcxaCore;

    export default {
        name: "DcxaCaseWatchedChangingErrorDialog",
        props: [
            'visible',
            'inProgress',
            'caseId',
            'isWatchDialog'
        ],
        computed: {
            dialogTypeTitle() {
                return this.isWatchDialog ? 'Watch' : 'Unwatch';
            },
            contactUsLink() {
                return '/content/support/my/en-us/faq.html#support';
            },
        },
        methods: {
            watchedChangingCaseErrorDialogCancelAction() {
                this.$emit('onCancelDialog');
            },
            watchedChangingCaseErrorDialogSubmitAction() {
                if (this.isWatchDialog) {
                    this.$emit('onRetryWatching', this.caseId);
                }
                else {
                    this.$emit('onRetryUnwatching', this.caseId);
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "../../../../styles/_colors.scss";
    @import "../../../../styles/_utilities.scss";

    .dcxa-case-watched-changing-error-dialog {
        .dialog-header-icon {
            color: $red;
            font-size: 24px;
            margin-bottom: 10px;
        }

        .modal-dialog-body {
            .subtitle {
                font-size: 16px;
            }
        }

        .modal-dialog-footer {
            .dcxa-button {
                margin: 0 0 16px 0;
                width: 100%;

                &:first-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    @include breakpoint(md) {
        .dcxa-case-watched-changing-error-dialog {
            .modal-dialog-footer {
                .dcxa-button {
                    width: auto;
                    margin: 0 0 0 8px;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }

</style>