<template>
    <div class="dcxa-comment-list">
        <dcxa-content-loader v-if="context.isLoading" type="CommentList" :number-of-columns="numberOfLoadingColumns"></dcxa-content-loader>
        <div v-else v-for="(row, idx) in context.data" :key="idx" class="card">
            <div class="case-comment-author">
                <span class="is-clouderan-author-icon" v-if="row.IsClouderan" /> {{row.Author}}
            </div>
            <div class="case-comment-last-modified">{{row.LastModifiedDateFormatted}}</div>
            <template v-if="row.IsClosing">
                <div class="special-comment-prefix">Closing Comment</div>
                <div class="case-comment-text" v-text="row.ClosingCommentBodyWithoutPrefix" />
            </template>
            <template v-else-if="row.IsReopening">
                <div class="special-comment-prefix">Reopening Comment</div>
                <div class="case-comment-text" v-text="row.ReopeningCommentBodyWithoutPrefix" />
            </template>
            <div class="case-comment-text" v-else v-text="row.CommentBody" />
        </div>
    </div>
</template>

<script>
    import CommentListRow from  './CommentListRow.vue';

    export default {
        name: "DcxaCommentList",
        components: {
            'comment-list-row': CommentListRow
        },
        props: {
            context: {
                type: Object,
                default: () => {}
            },
            numberOfLoadingColumns: {
                type: Number,
                default: () => 3
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../../../styles/utilities";
    @import "../../../../../styles/colors";

    .dcxa-comment-list {
        .card {
            width: 100%;
            padding: 24px;
            border-radius: 3px;
            box-shadow: 0 1px 5px 0 rgba($black, 0.25);
            background-color: $white;
            margin-bottom: 16px;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;

            &:last-child {
                border-bottom: 0;
            }

            .case-comment-author {
                font-size: 12px;
                color: tint($black, 80);
                display: flex;
                align-items: center;

                .is-clouderan-author-icon {
                    background: url('../../../../assets/cloudera-logo.png');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    width: 12px;
                    height: 12px;
                    display: inline-block;
                    margin-right: 4px;
                }
            }

            .case-comment-last-modified {
                font-size: 12px;
                color: tint($black, 50);
                margin-bottom: 14px;
            }

            .case-comment-text {
                white-space: pre-line;
            }

            .special-comment-prefix {
                font-weight: bold;
            }
        }
    }

    @include breakpoint(md) {
        .dcxa-comment-list {
            .card {

            }
        }
    }
</style>