var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "editor-row" }, [
    _vm.mode == "tableStyle" && !_vm.floatingLabel
      ? _c("span", { staticClass: "property" }, [_vm._v(_vm._s(_vm.title))])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "span",
      { staticClass: "value" },
      [
        _vm._t("secondaryLabel", function() {
          return [
            _vm.charCounterVisible
              ? _c(
                  "div",
                  {
                    staticClass: "char-counter",
                    class: {
                      error:
                        (_vm.input || "").trim().length > _vm.textAreaMaxChars
                    }
                  },
                  [
                    _vm._v(
                      "\n                Length: " +
                        _vm._s((_vm.input || "").trim().length) +
                        " / " +
                        _vm._s(_vm.textAreaMaxChars) +
                        "\n            "
                    )
                  ]
                )
              : _vm._e()
          ]
        }),
        _vm._v(" "),
        _vm.type == "text" || _vm.type == "textarea"
          ? _c(
              "dcxa-input",
              {
                class: _vm.appliedClasses,
                attrs: {
                  id: _vm.id,
                  name: _vm.name,
                  type: _vm.type,
                  popover: _vm.hasValidationHint,
                  disabled: _vm.config.disabled || _vm.disabled,
                  floatingLabel: _vm.floatingLabel,
                  rows: _vm.inputRows,
                  prefixIcon: _vm.prefixIcon,
                  resize: "none",
                  placeholder: _vm.title
                },
                model: {
                  value: _vm.input,
                  callback: function($$v) {
                    _vm.input = $$v
                  },
                  expression: "input"
                }
              },
              [
                _c("template", { slot: "popoverContent" }, [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.validationHint) }
                  })
                ])
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.type == "password"
          ? _c(
              "dcxa-input",
              {
                class: _vm.appliedClasses,
                attrs: {
                  id: _vm.id,
                  name: _vm.name,
                  popover: _vm.hasValidationHint,
                  type: "password",
                  disabled: _vm.config.disabled || _vm.disabled,
                  floatingLabel: _vm.floatingLabel,
                  prefixIcon: _vm.prefixIcon,
                  placeholder: _vm.title
                },
                model: {
                  value: _vm.input,
                  callback: function($$v) {
                    _vm.input = $$v
                  },
                  expression: "input"
                }
              },
              [
                _c("template", { slot: "popoverContent" }, [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.validationHint) }
                  })
                ])
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.type == "checkbox"
          ? _c(
              "dcxa-checkbox",
              {
                class: _vm.appliedClasses,
                attrs: {
                  id: _vm.id,
                  name: _vm.name,
                  popover: _vm.hasValidationHint,
                  disabled: _vm.config.disabled || _vm.disabled,
                  floatingLabel: _vm.floatingLabel,
                  placeholder: _vm.title,
                  contentLabel: "Yes"
                },
                model: {
                  value: _vm.input,
                  callback: function($$v) {
                    _vm.input = $$v
                  },
                  expression: "input"
                }
              },
              [
                _c("template", { slot: "popoverContent" }, [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.validationHint) }
                  })
                ])
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.type == "datepicker"
          ? _c(
              "dcxa-datepicker",
              {
                class: _vm.appliedClasses,
                attrs: {
                  id: _vm.id,
                  name: _vm.name,
                  popover: _vm.hasValidationHint,
                  disabled: _vm.config.disabled || _vm.disabled,
                  clearable: _vm.config.clearable,
                  floatingLabel: _vm.floatingLabel,
                  placeholder: _vm.title
                },
                model: {
                  value: _vm.input,
                  callback: function($$v) {
                    _vm.input = $$v
                  },
                  expression: "input"
                }
              },
              [
                _c("template", { slot: "popoverContent" }, [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.validationHint) }
                  })
                ])
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.type == "select"
          ? _c(
              "dcxa-select",
              {
                class: _vm.appliedClasses,
                attrs: {
                  id: _vm.id,
                  name: _vm.name,
                  disabled: _vm.config.disabled || _vm.disabled,
                  popover: _vm.hasValidationHint,
                  clearable: _vm.config.clearable,
                  filterable: _vm.config.filterable,
                  multiselect: _vm.config.enableMultiselect,
                  floatingLabel: _vm.floatingLabel,
                  placeholder: _vm.title,
                  "num-of-options": _vm.options.length
                },
                model: {
                  value: _vm.input,
                  callback: function($$v) {
                    _vm.input = $$v
                  },
                  expression: "input"
                }
              },
              [
                _vm._l(_vm.options, function(item) {
                  return _c("el-option", {
                    key: item[_vm.optionItem.value || "id"],
                    attrs: {
                      label: item[_vm.optionItem.title || "title"],
                      value: item[_vm.optionItem.value || "id"],
                      disabled: item["disabled"]
                    }
                  })
                }),
                _vm._v(" "),
                _c("template", { slot: "popoverContent" }, [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.validationHint) }
                  })
                ])
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.hasValidationMessage
          ? _c(
              "span",
              {
                staticClass: "validation-message",
                class: _vm.validationClasses
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.validationMessage) +
                    "\n        "
                )
              ]
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-cc4f1a8e", { render: render, staticRenderFns: staticRenderFns })
  }
}