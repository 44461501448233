var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("dcxa-admonition", {
        ref: "successAdmonition",
        attrs: {
          type: "dcxa-success",
          visible: _vm.successAdmonitionVisible,
          message: _vm.successAdmonitionMessage
        }
      }),
      _vm._v(" "),
      _vm._t("default", null, { containerContext: this })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-719324dd", { render: render, staticRenderFns: staticRenderFns })
  }
}