var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dcxa-modal-dialog",
    {
      staticClass: "dcxa-case-reopening-dialog",
      attrs: {
        type: "content",
        visible: _vm.reopeningCaseActionDialog.visible,
        "close-button": true,
        "data-dd-privacy": "mask-user-input"
      },
      on: { onCloseDialog: _vm.reopeningCaseDialogCancelAction }
    },
    [
      _c("template", { slot: "header" }, [
        _c(
          "div",
          { staticClass: "dialog-head" },
          [
            !_vm.reopeningCaseActionDialog.hasError
              ? [_c("h4", [_vm._v("Reopen Case")])]
              : [
                  _c("i", {
                    staticClass:
                      "far fa-exclamation-triangle dialog-header-icon"
                  }),
                  _vm._v(" "),
                  _c("h4", [_vm._v("Failed to Reopen Case")])
                ]
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "body" },
        [
          !_vm.reopeningCaseActionDialog.hasError
            ? [
                _c("div", { staticClass: "subtitle" }, [
                  _vm._v("Please enter a reason before reopening the case.")
                ]),
                _vm._v(" "),
                _c("dcxa-property-value-editor", {
                  attrs: {
                    forceFloatingLabel: true,
                    classes: [],
                    title: "Reopening Reason",
                    inputRows: 4,
                    source: _vm.reopeningComment,
                    config: _vm.editorConfig.reopeningComment
                  },
                  on: {
                    stateChanged: function(state) {
                      return _vm.editRowStateChanged("reopeningComment", state)
                    }
                  }
                })
              ]
            : [
                _c(
                  "div",
                  { staticClass: "subtitle" },
                  [
                    _vm._v("Please try again. "),
                    _c(
                      "dcxa-text-link",
                      { attrs: { secondaryTarget: _vm.contactUsLink } },
                      [_vm._v("Contact us")]
                    ),
                    _vm._v(" or "),
                    _c(
                      "dcxa-text-link",
                      { attrs: { secondaryTarget: _vm.telLink } },
                      [_vm._v("call us")]
                    ),
                    _vm._v(" if the problem persists.")
                  ],
                  1
                )
              ]
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "dcxa-button",
            {
              staticClass: "medium",
              attrs: {
                type: "secondary",
                disabled: _vm.reopeningCaseActionDialog.inProgress
              },
              on: { click: _vm.reopeningCaseDialogCancelAction }
            },
            [_vm._v("Cancel")]
          ),
          _vm._v(" "),
          _c(
            "dcxa-button",
            {
              staticClass: "medium",
              class: { loading: _vm.reopeningCaseActionDialog.inProgress },
              attrs: { type: "primary", disabled: !_vm.isValid },
              on: { click: _vm.reopeningCaseDialogSubmitAction }
            },
            [_vm._v(_vm._s(_vm.submitButtonText))]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f2559696", { render: render, staticRenderFns: staticRenderFns })
  }
}