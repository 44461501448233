<template>
  <div class="dcxa-accordion" :class="styleClasses">

    <div class="loading-columns-container" v-if="isLoading">
      <div class="animated-element loading-left-column"></div>
      <div class="animated-element loading-right-column"></div>
    </div>
    <template v-else>
      <div class="header" :class="{'wrap-content': wrapHeaderOnMobile}" v-if="!isHeaderHidden">
        <div class="title-container">
          <dcxa-text-link v-if="collapsible" @click="toggleCollapse" :disabled="!enabled">
            <h4 v-if="!shrinkHeaderOnMobile || (shrinkHeaderOnMobile && $screensFrom('md'))" :id="titleId" :class="titleClass"><i :class="caretIcon"></i> {{title}}</h4>
            <h5 v-else-if="shrinkHeaderOnMobile && $screenIs('xs')" :id="titleId" :class="titleClass"><i :class="caretIcon"></i> {{title}}</h5>
          </dcxa-text-link>
          <template v-else>
            <h4 v-if="!shrinkHeaderOnMobile || (shrinkHeaderOnMobile && $screensFrom('md'))" :id="titleId" :class="titleClass">{{title}}</h4>
            <h5 v-else-if="shrinkHeaderOnMobile && $screenIs('xs')" :id="titleId" :class="titleClass">{{title}}</h5>
          </template>
          <slot name="secondary-title"/>
        </div>
        <div v-if="enabled" class="controls-container">
          <slot name="controls"/>
        </div>
      </div>

      <div class="content" :class="{'header-hidden': isHeaderHidden, 'collapsible': collapsible}" v-show="!isCollapsed">
        <slot />
      </div>
    </template>

  </div>

</template>

<script type="text/javascript">
import Vue from 'vue'
import _ from 'lodash'
import { mapGetters } from 'vuex'

const dcxaCore = require('@dcxa/dcxa-core');
const { Configs, Api, Utilities } = dcxaCore;

export default {
  name: 'CaseCreationStep',
  components: {
  },
  props: {
    title: {
      type: String,
      default: () => null
    },
    instanceId: {
      type: String,
      default: () => '1'
    },
    titleId: {
      type: String,
      default: () => ''
    },
    titleClass: {
      type: String,
      default: () => ''
    },
    enabled: {
      type: Boolean,
      default: () => true
    },
    collapsible: {
      type: Boolean,
      default: () => true
    },
    hideHeader: {
      type: Boolean,
      default: () => false
    },
    closeByDefault: {
      type: Boolean,
      default: () => false
    },
    colorType: {
      type: String,
      default: () => '',
      validator: function (value) {
        return ['',
          'CRITICAL_VALIDATION',
          'ERROR_VALIDATION',
          'WARN_VALIDATION',
          'CURIOSITY_VALIDATION',
          'INFO_VALIDATION',
          'REVIEWED_VALIDATION',
          'NATIVE_ACCOUNT_TYPE',
          'INDIVIDUAL_ACCOUNT_TYPE',
          'ACCOUNT_ACCOUNT_TYPE'].indexOf(value) !== -1
      }
    },
    compact: {
      type: Boolean,
      default: () => false
    },
    shrinkHeaderOnMobile: {
      type: Boolean,
      default: () => false
    },
    wrapHeaderOnMobile: {
      type: Boolean,
      default: () => false
    },
    isLoading: {
      type: Boolean,
      default: () => false
    },
    isRounded: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      collapsed: this.closeByDefault
    }
  },
  computed: {
      isCollapsed() {
        return !this.enabled || (this.enabled && this.collapsible && this.collapsed);
      },
      caretIcon() {
        return this.collapsible ?
            (this.isCollapsed ?
                `fal fa-caret-down collapse-icon accordion_${this.instanceId}_collapseIcon`
                : `fal fa-caret-up collapse-icon accordion_${this.instanceId}_collapseIcon`)
            : '';
      },
      isHeaderHidden() {
        return this.hideHeader && !this.collapsible;
      },
      styleClasses() {
        let classes = {
          disabled: !this.enabled,
          compact: this.compact,
          loading: this.isLoading,
          rounded: this.isRounded
        };

        if (this.colorType !== '') {
          classes[this.colorType] = this.colorType;
        }

        return classes;
      }
  },
  methods: {
    toggleCollapse() {
      this.collapsed = !this.collapsed;
      this.$emit('click', this.collapsed);
    },

    expand() {
      this.collapsed = false;
    },

    collapse() {
      this.collapsed = true;
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../styles/_utilities.scss";
@import "../../../../styles/_colors.scss";

.dcxa-accordion {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  padding: 26px 16px 26px 16px;
  display: flex;
  flex-direction: column;

  &.rounded {
    border-radius: 3px;
  }

  &.loading {
    background-color: tint($black, 7);
    box-shadow: unset;
    padding: 32px 24px 32px 24px;

    &.compact {
      padding: 24px 24px 24px 24px;
    }

    .loading-columns-container {
      display: flex;
      justify-content: space-between;

      .loading-left-column {
        width: 30%;
        min-width: 30%;

        background-color: tint($black, 15);
        border-radius: 3px;
        height: 16px;
      }

      .loading-right-column {
        width: 15%;
        min-width: 15%;

        background-color: tint($black, 15);
        border-radius: 3px;
        height: 16px;
      }

      .animated-element {
        @keyframes cascading {
          0% {
            opacity: 1;
          }
          25% {
            opacity: 0.4;
          }
          50% {
            opacity: 1;
          }
        }

        -webkit-animation: cascading 2.5s infinite;
        animation: cascading 2.5s infinite;
      }
    }
  }

  &.compact {
    padding: 18px 24px 18px 24px;
  }

  &.CRITICAL_VALIDATION {
    &:not(.loading) {
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25), inset 3px 0 0 0 $red-light;
    }
  }

  &.ERROR_VALIDATION {
    &:not(.loading) {
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25), inset 3px 0 0 0 $bright-orange;
    }
  }

  &.WARN_VALIDATION {
    &:not(.loading) {
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25), inset 3px 0 0 0 #f7c200;
    }
  }

  &.CURIOSITY_VALIDATION {
    &:not(.loading) {
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25), inset 3px 0 0 0 $mint;
    }
  }

  &.INFO_VALIDATION {
    &:not(.loading) {
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25), inset 3px 0 0 0 $blue;
    }
  }

  &.REVIEWED_VALIDATION {
    &:not(.loading) {
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25), inset 3px 0 0 0 tint($black, 30);
    }
  }

  &.NATIVE_ACCOUNT_TYPE {
    &:not(.loading) {
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25), inset 3px 0 0 0 $green;
    }
  }

  &.INDIVIDUAL_ACCOUNT_TYPE {
    &:not(.loading) {
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25), inset 3px 0 0 0 $blue;
    }
  }

  &.ACCOUNT_ACCOUNT_TYPE {
    &:not(.loading) {
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25), inset 3px 0 0 0 #f7c200;
    }
  }

  &.disabled {
    .header {
      .text-link {
        h4, h5 {
          color: #b3b3b3;
        }
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.wrap-content {
      flex-direction: column;
      align-items: normal;
    }

    .title-container {
      display: flex;
      flex-wrap: wrap;

      .collapse-icon {
        color: $mint;
      }
    }

    .controls-container {
      white-space: nowrap;

      .accordion-control-container {
        display: flex;
        flex-direction: column;
        font-size: 14px;

        .accordion-control-item {
          margin-top: 4px;

          .accordion-control-item-key {
            color: tint($black, 50);
          }
          .accordion-control-item-value {
            margin-left: 4px;
            color: tint($black, 80);
          }
        }
      }
    }

    h4 {
      margin: 0 16px 0 0;
      font-size: 22px;
      line-height: 28px;

      i {
        width: 16px;
      }
    }

    h5 {
      margin: 0 16px 0 0;
      font-size: 16px;
      line-height: 20px;

      i {
        width: 12px;
      }
    }

    user-select: none;
  }

  .content {
    padding: 0;

    &.collapsible {
      padding: 24px 0 0;
    }

    &.header-hidden {
      padding: 0;
    }
  }

  @include breakpoint_exact(xs){
    .header {
      .controls-container {
        .accordion-control-container {
          .accordion-control-item {
            &:first-child {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }

  @include breakpoint(md){
    padding-left: 24px;
    padding-right: 24px;

    .header {
      align-items: center;

      &.wrap-content {
        flex-direction: row;
        align-items: center;
      }

      .title-container {
        display: flex;
        align-items: center;

        .dcxa-badge {

        }
      }

      .controls-container {
        text-align: right;
      }
    }
  }

  @include breakpoint(lg) {
    .header {
      .controls-container {

        .accordion-control-container {
          flex-direction: row;
          .accordion-control-item {
            padding-right: 16px;
            margin-top: 0px;

            &:last-child {
              padding-right: 0px;
              padding-left: 16px;
              border-left: 1px solid tint($black, 50);
            }

            .accordion-control-item-key {
            }
            .accordion-control-item-value {
            }
          }
        }
      }
    }
  }
}


</style>
