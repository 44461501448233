<template>
    <div class="dcxa-checkbox" :class="classes" >
       <el-popover :disabled="!popover || !enabledValidationPopover" trigger="focus" placement="bottom" effect="light" popper-class="dcxa-tooltip">

            <slot name="popoverContent" />
           
            <template slot="reference" >
                <el-checkbox 
                    :id="id"
                    :name="name"
                    v-bind:value="value"
                    v-on:change="updateValue"
                    :disabled="disabled"
                    :placeholder="placeHolderText"
                    :floatingLabel="floatingTitleText">
                    {{contentLabel}}
                    <slot name="content" />
                </el-checkbox>

            </template>
       </el-popover>

        <label class="floating-label-element" :class="{'visible': showFloatingLabel}" v-html="floatingTitleText">
        </label>
    </div>
</template>

<script>
export default {
  name: 'DcxaCheckbox',
  data() {
    return {
        currVal: false
    }
  },
  props: [
      'id',
      'name',
      'value',
      'classes',
      'disabled',
      'clearable',
      'filterable',
      'noDataText',
      'placeholder',
      'floatingLabel',
      'floatingTitle',
      'contentLabel',
      'popover',
      'enabledValidationPopover'
  ],
  watch: {
      value: function(val) {
          this.updateValue(val || false);
      }
  },
  computed: {
      showFloatingLabel() {
          return this.floatingLabel;
      },
      placeHolderText() {
          const parts = (this.placeholder || '').split('<span');
          return (parts.length > 1) ? parts[0] + '*' : parts[0];
      },
      floatingTitleText() {
        return this.floatingTitle || this.placeholder;
      }
  },
  methods: {
    updateValue: function (value) {
        this.currVal = (value || false);
        this.$emit('input', value || false)
    }
  }
}
</script>

<style lang="scss">
@import "../../../../styles/_colors.scss";
@import "../../styles/_mixins.scss";

.dcxa-checkbox {

    /////////////////////////
    // TODO: remove
    /////////////////////////
    position: relative;
    width: 100%;

    .floating-label-element {
        position: absolute;
        pointer-events: none;
        top: 5px;
        left: 0;
        line-height: 14px;
        font-size: 14px;
        opacity: 0;
        -webkit-transition: .2s ease-in-out;
        transition: .2s ease-in-out;

        &.visible {
            opacity: 1;
            top: -22px;
            color: tint($black,80);
        }
    }
    /////////////////////////

    &:focus {
        outline: none;
    }

    .el-select {
        font-size: 14px;
        height: 24px;
        line-height: 24px;
        & .el-checkbox {
            height: 24px;
            line-height: 24px;
            & .el-input__inner {
                font-size: 14px;
                height: 24px;
                line-height: 24px;
                padding: 0 0 0 8px;
            }
        }
    }

    /////////////////////////
    // Coloring
    /////////////////////////
    .el-checkbox {
        .el-checkbox__inner {
            border-color: tint($black, 60);
            border-radius: 2px;
            width: 16px;
            height: 16px;

            &:hover{
              border-color: $mint;
            }
        }

        &.is-disabled {
          .el-checkbox__input {
            .el-checkbox__inner {
              background-color: $white;
              border-color: tint($black, 30);
            }
          }
        }

        &.is-checked {
            .el-checkbox__input {
                .el-checkbox__inner {
                    background-color: $mint;
                    border-color: $mint;
                }
            }
        }
    }

    .el-checkbox {
      &:focus {
        outline: none;
      }

      .el-checkbox__input {
        & + .el-checkbox__label {
          color: #333333;
          font-size: 16px;
          font-weight: normal;
        }

        &.is-checked {
          .el-checkbox__inner {
            /*border: none !important;*/
            background-color: unset !important;
            color: $mint;

            &::after {
              font-family: "Font Awesome 6 Pro";
              content: "\f14a";
              font-weight: 900;
              font-size: 18px;
              transform: unset;
              left: -1px;
              top: -2px;
              transition: unset !important;
            }
          }

          &.is-disabled {
            .el-checkbox__inner {
              color: tint($black, 30);
              background-color: $white;
              border-color: tint($black, 30);
            }
          }


          & + .el-checkbox__label {
            color: #333333;
            font-weight: normal;
          }
        }
      }
    }

      .el-checkbox {
        .el-checkbox__input {
          vertical-align: bottom;

          &.is-checked {
            .el-checkbox__inner {
              &::after {
                font-family: "Font Awesome 6 Pro";
                content: "\f14a";
                font-weight: 900;
                font-size: 18px;
                transform: unset;
                left: -1px;
                top: -2px;
                transition: unset !important;
              }
            }
          }
        }
      }

    &.progressing {
        .el-checkbox {
            .el-checkbox__input {
                height: 16px;
                width: 16px;

                .el-checkbox__inner {
                    cursor: default;
                    background-color: unset !important;

                    @include loading-spinner(small);

                    &::after {
                      content: unset !important;
                    }
                }
            }

            .el-checkbox__label {
                cursor: default;
            }
        }
    }

    .el-checkbox {
        &.is-disabled {
            .el-checkbox__input {
                cursor: default;

                .el-checkbox__inner {
                    cursor: default;

                    &::after {
                        cursor: default;
                    }
                }
            }

            .el-checkbox__label {
                cursor: default;
            }
        }
    }



    /////////////////////////
    // Behavior
    /////////////////////////
    &.long-label {
        margin-bottom: 16px;

        label.el-checkbox {
            white-space: normal !important;
            display: flex;
        }

        span.el-checkbox__label {
            margin-top: -2px;
        }
    }
    
}


</style>
