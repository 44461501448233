<template>
    <div class="dcxa-property-editor-section" >
        <div class="section-header" v-if="hasTitle">
            <h4 :id="titleId" :class="titleClass">{{editorTitle || title}}</h4>
        </div>
        <div class="section-content">
            <slot />
        </div>
        <div class="property-editor-controls">
            <slot name="controls"/>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'PropertyEditorSection',
  data() {
    return {
    }
  },
  props: {
      'title': String,
      'editorTitle': String,
      'titleId': String,
      'titleClass': String,
  },
  computed: {
    hasTitle() {
      let titleLength = (this.editorTitle || this.title || '').trim().length;
      return titleLength > 0;
    }
  },
  watch: {
  },
  methods: {

  }
}
</script>

<style>
</style>
