<template>
    <div class="dcxa-timepicker" :class="classes" >
       <el-popover :disabled="!popover || !enabledValidationPopover" trigger="focus" placement="bottom" effect="light" popper-class="dcxa-tooltip">

            <slot name="popoverContent" />

            <template slot="reference" >
                <div class="extended-control">
                    <dcxa-button @click="backward" type="secondary" class="medium remove-min-width"><i class="fal fa-chevron-left"></i></dcxa-button>
                   

                   <el-select
                        v-model="currVal"
                        filterable
                        allow-create
                        default-first-option
                        popper-class="dcxa-timepicker-dropdown"
                        placeholder="HH:MM">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>

                  

                    <dcxa-button @click="forward" type="secondary" class="medium remove-min-width"><i class="fal fa-chevron-right"></i></dcxa-button>
                </div>
            </template>
       </el-popover>

        <label class="floating-label-element" :class="{'visible': showFloatingLabel}" v-html="floatingTitleText">
        </label>
    </div>
</template>

<script>

function parseTime(timeString) {
    var parts = timeString.split(':');

    return {
        hours: parts[0] | 0,
        minutes: parts[1] | 0
    }
} 

function stringifyTime(time) {
    return ('' + time.hours).padStart(2, '0') + ':' + ('' + time.minutes).padStart(2, '0');
}

export default {
  name: 'DcxaTimePicker',
  data() {
    return {
        currVal: '08:00',
        options: [
            { value: "00:00", label: "00:00" },
            { value: "01:00", label: "01:00" },
            { value: "02:00", label: "02:00" },
            { value: "03:00", label: "03:00" },
            { value: "04:00", label: "04:00" },
            { value: "05:00", label: "05:00" },
            { value: "06:00", label: "06:00" },
            { value: "07:00", label: "07:00" },
            { value: "08:00", label: "08:00" },
            { value: "09:00", label: "09:00" },
            { value: "10:00", label: "10:00" },
            { value: "11:00", label: "11:00" },
            { value: "12:00", label: "12:00" },
            { value: "13:00", label: "13:00" },
            { value: "14:00", label: "14:00" },
            { value: "15:00", label: "15:00" },
            { value: "16:00", label: "16:00" },
            { value: "17:00", label: "17:00" },
            { value: "18:00", label: "18:00" },
            { value: "19:00", label: "19:00" },
            { value: "20:00", label: "20:00" },
            { value: "21:00", label: "21:00" },
            { value: "22:00", label: "22:00" },
            { value: "23:00", label: "23:00" }
        ]
    }
  },
  props: {
      'id': { default: () => undefined },
      'name': { default: () => undefined },
      'value': { default: () => undefined },
      'classes': { default: () => undefined },
      'disabled': { default: () => undefined },
      'clearable': { default: () => undefined },
      'filterable': { default: () => undefined },
      'noDataText': { default: () => undefined },
      'placeholder': { default: () => undefined },
      'floatingLabel': { default: () => undefined },
      'floatingTitle': { default: () => undefined },
      'enabledValidationPopover': { default: () => undefined },
      'popover': { default: () => undefined },
      'pickerOptions': {
          default: () => {
            return {
                
            }
          }
      }
  },
  watch: {
      value: function(val, oldVal) {
          if (val != oldVal){ 
            this.updateValue(val);
          }
      },
      currVal: function(val, oldVal) {
          var regexp = new RegExp(/^\d{1,2}:\d{1,2}$/g);

          const validFormat = regexp.test(val);
          if (!validFormat && val) {
              this.currVal = oldVal;
              return;
          }

          let [hour, minute] = val.split(':');
          hour = hour | 0;
          minute = minute | 0;
          if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
              this.currVal = oldVal;
              return;
          } 


          if (val && val.length != 5 ) {
            hour = '' + hour;
            minute = '' + minute;
              val = [hour.padStart(2, '0'), minute.padStart(2,'0')].join(':');
              this.currVal = val;
          }

          if (val != oldVal) {
              this.updateValue(val);
          }
      }
  },
  computed: {
      showFloatingLabel() {
          return this.floatingLabel;// && this.currVal;
      },
      placeHolderText() {
          const parts = (this.placeholder || '').split('<span');
          return (parts.length > 1) ? parts[0] + '*' : parts[0];
      },
      floatingTitleText() {
          return this.floatingTitle || this.placeholder;
      }
  },
  methods: {
    forward: function() {
        const parsed = parseTime(this.currVal);
        const next = {
            hours: parsed.hours == 23 ? 0 : parsed.hours + 1,
            minutes: 0
        }
        this.updateValue(stringifyTime(next));
    },
    backward: function() {
        const parsed = parseTime(this.currVal);
        const next = {
            hours: parsed.hours == 0 ? 23 : parsed.hours - 1,
            minutes: 0
        }
        this.updateValue(stringifyTime(next));
    },
    updateValue: function (value) {
        console.log('updating value ------------------' + value);
        this.currVal = value;
        this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
@import "../../../../styles/_colors.scss";
@import "../../styles/_mixins.scss";

.dcxa-timepicker {
    position: relative;

    .extended-control {
        display: flex;
        align-items: center;
    }

    @include floating-label();


    .el-input {
        .el-input__inner:focus {
            border-color: $mint;
        } 
        &.is-focus {
            .el-input__inner {
                border-color: $mint;
            }
        }
    }

}

.dcxa-timepicker-dropdown {

    .el-select-dropdown__list {
        padding: 0;
        .el-select-dropdown__item {
            height: 48px;
            line-height: 24px;
            font-size: 16px;
            color: #333333;
            margin: 0;

            border-bottom: 1px solid #cccccc;

            display: flex;
            align-items: center;

            &.selected {
                color: $mint;
                border-left: 3px solid $mint;
                padding-left: 17px;

                &.hover {

                }
            }
            &.hover {
                background: #e6e6e6;
            }

            &:last-of-type {
                border-bottom: none;
            }
        }
    }
}


</style>
