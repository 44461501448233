<template>
    <div class="dcxa-card-list">
        <dcxa-content-loader v-if="context.isLoading" type="CardList" :number-of-columns="numberOfLoadingColumns" :title="context.title"></dcxa-content-loader>
        <div v-else v-for="(row, idx) in context.data" :key="idx" :class="classes(row)">
            <div class="top-row">
                <div class="title-container">
                    <slot name="title" :row="row" />
                </div>

                <div class="card-list-tools-container" v-if="$screensFrom('md')">
                    <slot name="tools" :row="row" />
                </div>
            </div>
            <div class="bottom-row">
                <div class="metadata-container">
                    <card-list-row :data="row">
                        <slot name="metadata" :row="row" />
                    </card-list-row>
                </div>
                <div class="card-list-tools-container" v-if="$screensUpTo('xs')">
                    <slot name="tools" :row="row" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CardListRow from  './CardListRow.vue';

    export default {
        name: "DcxaCardList",
        components: {
            'card-list-row': CardListRow
        },
        props: {
            context: {
                type: Object,
                default: () => {}
            },
            numberOfLoadingColumns: {
                type: Number,
                default: () => 7
            },
            highlightedStates: {
                type: Array,
                default: () => ['Awaiting Customer Response']
            }
        },
        computed: {
            classes() {
                return row => {
                    return {
                        'card': true,
                        'highlighted': this.isHighlighted(row)
                    };
                }
            }
        },
        methods: {
            isHighlighted(row) {
                return this.highlightedStates.indexOf(row['Status']) > -1;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../../../styles/utilities";
    @import "../../../../../styles/colors";

    .dcxa-card-list {
        .card {
            width: 100%;
            padding: 24px;
            border-radius: 3px;
            box-shadow: 0 1px 5px 0 rgba($black, 0.25);
            background-color: $white;
            margin-bottom: 16px;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;

            &.highlighted {
                box-shadow: 0 1px 5px 0 rgba($black, 0.25), inset 3px 0 0 0 $mint;
            }

            &:last-child {
                border-bottom: 0;
            }

            .top-row {
                display: flex;
                justify-content: space-between;
                margin-bottom: 24px;
            }

            .bottom-row {
                display: flex;
                justify-content: space-between;

                .metadata-container {
                    .dcxa-table-cell-content {
                        color: tint($black, 50);
                    }
                }

                .card-list-tools-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                    margin-left: 16px;

                    > * {
                        &:last-child {
                            margin-top: 12px;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(md) {
        .dcxa-card-list {
            .card {
                .top-row {
                    .title-container {
                        margin-right: 16px;
                    }

                    .card-list-tools-container {
                        display: flex;
                        justify-content: flex-end;
                        align-self: flex-start;

                        > * {
                            align-self: center;

                            &:last-child {
                                margin-left: 12px;
                            }
                        }

                    }
                }
            }
        }
    }
</style>