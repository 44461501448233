var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-tree-node", class: _vm.dcxaTreeNodeClasses },
    [
      _vm.nodeData
        ? [
            !_vm.nodeData.isRoot
              ? _c(
                  "div",
                  {
                    staticClass: "dcxa-tree-node-title",
                    class: _vm.dcxaTreeNodeClasses
                  },
                  [
                    !_vm.isLeaf
                      ? [
                          _c(
                            "span",
                            {
                              staticClass: "expander-icon",
                              on: { click: _vm.expanderClicked }
                            },
                            [
                              _vm.expanded
                                ? _c("i", {
                                    staticClass:
                                      "dcxa-carat-down fas fa-caret-down",
                                    attrs: { "aria-hidden": "true" }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.expanded
                                ? _c("i", {
                                    staticClass:
                                      "dcxa-carat-right fal fa-caret-right",
                                    attrs: { "aria-hidden": "true" }
                                  })
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _vm.expanded && !_vm.labelsOnly
                            ? _c("i", {
                                staticClass: "dcxa-minus fas fa-minus-square"
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.labelsOnly && !_vm.expanded
                            ? _c("el-checkbox", {
                                on: {
                                  change: function($event) {
                                    return _vm.changeSelection(_vm.nodeData)
                                  }
                                },
                                model: {
                                  value: _vm.nodeData.selected,
                                  callback: function($$v) {
                                    _vm.$set(_vm.nodeData, "selected", $$v)
                                  },
                                  expression: "nodeData.selected"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.labelsOnly
                            ? _c(
                                "dcxa-text-link",
                                {
                                  staticClass: "linkTitle",
                                  on: {
                                    click: function($event) {
                                      return _vm.selectionChanged(_vm.nodeData)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.nodeData.title))]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "title",
                                  on: {
                                    click: function($event) {
                                      return _vm.titleClicked(_vm.nodeData)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.nodeData.title))]
                              ),
                          _vm._v(" "),
                          _vm.nodeData.count
                            ? _c("div", { staticClass: "counter" }, [
                                _vm._v(_vm._s(_vm.nodeData.count))
                              ])
                            : _vm._e()
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isLeaf
                      ? [
                          !_vm.labelsOnly
                            ? _c("el-checkbox", {
                                on: {
                                  change: function($event) {
                                    return _vm.changeSelection(_vm.nodeData)
                                  }
                                },
                                model: {
                                  value: _vm.nodeData.selected,
                                  callback: function($$v) {
                                    _vm.$set(_vm.nodeData, "selected", $$v)
                                  },
                                  expression: "nodeData.selected"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.labelsOnly
                            ? _c(
                                "dcxa-text-link",
                                {
                                  staticClass: "linkTitle",
                                  on: {
                                    click: function($event) {
                                      return _vm.selectionChanged(_vm.nodeData)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.nodeData.title))]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "title",
                                  on: {
                                    click: function($event) {
                                      return _vm.titleClicked(_vm.nodeData)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.nodeData.title))]
                              ),
                          _vm._v(" "),
                          _vm.nodeData.count
                            ? _c("div", { staticClass: "counter" }, [
                                _vm._v(_vm._s(_vm.nodeData.count))
                              ])
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c("transition", { attrs: { name: "slide" } }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.nodeData.isRoot || _vm.expanded,
                      expression: "nodeData.isRoot || expanded"
                    }
                  ],
                  staticClass: "dcxa-tree-node-content",
                  class: {
                    "is-root": _vm.nodeData.isRoot
                  }
                },
                [
                  _vm._l(_vm.childrenToShow, function(node, k) {
                    return _c("tree-node", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.visibleChildrenCount > k,
                          expression: "visibleChildrenCount > k"
                        }
                      ],
                      key: node.id,
                      ref: "childnodes",
                      refInFor: true,
                      attrs: {
                        nodeData: node,
                        labelsOnly: _vm.labelsOnly,
                        level: _vm.level + 1
                      },
                      on: {
                        change: _vm.selectionChanged,
                        expanderModified: _vm.emitExpanderModifiedEvent
                      }
                    })
                  }),
                  _vm._v(" "),
                  !_vm.allChildrenShown
                    ? _c(
                        "div",
                        { staticClass: "see-more-button" },
                        [
                          _c(
                            "dcxa-text-link",
                            {
                              attrs: { caret: false },
                              on: { click: _vm.seeMore }
                            },
                            [
                              _vm._v("See More "),
                              _c("i", {
                                staticClass:
                                  "dcxa-carat-down fal fa-caret-down",
                                attrs: { "aria-hidden": "true" }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    : _vm.allChildrenShown &&
                      _vm.visibleChildrenCount ==
                        (_vm.nodeData.children || []).length &&
                      _vm.visibleChildrenCount > _vm.defaultChidrenCount
                    ? _c(
                        "div",
                        { staticClass: "see-less-button" },
                        [
                          _c(
                            "dcxa-text-link",
                            {
                              attrs: { caret: false },
                              on: { click: _vm.seeLess }
                            },
                            [
                              _vm._v("See Less "),
                              _c("i", {
                                staticClass: "dcxa-carat-down fal fa-caret-up",
                                attrs: { "aria-hidden": "true" }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4d5525b9", { render: render, staticRenderFns: staticRenderFns })
  }
}