var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-label", attrs: { id: _vm.id, name: _vm.name } },
    [
      _c("span", [_vm._v("\n        " + _vm._s(_vm.value) + "\n    ")]),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "floating-label-element",
          class: { visible: _vm.floatingLabel }
        },
        [_vm._v("\n        " + _vm._s(_vm.title) + "\n    ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7d90e0fc", { render: render, staticRenderFns: staticRenderFns })
  }
}