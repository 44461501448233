<template>
    <div class="dcxa-progress" :style="fadeBackground" ref="container">
        <div class="progress-fill" :style="progressStyle">
            <template v-if="currPercentage > 0">
                {{valueFormatter(currVal)}}
            </template>
        </div>
        
        <div class="stop-container">
            <div class="stop" :class="{'show-bar': s.line}" v-for="(s, i) in markers" :key="i" :style="{left: markerLeftPosition(s.percentage) + 'px' }">
                <span>{{s.text}}</span>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'DcxaProgress',
    data() {
        return {
            barWidth: 0,
        }
    },
    props: {
        markers: {
            default: function() { return [] }
        },
        mainColor: {
            type: String,
            default: '#00a3af'
        },
        secondColor: {
            type: String,
            default: '#e6e6e6'
        },
        barColor: {
            type: String,
            default: '#b3e3e7'
        },
        minVal: {
            type: Number,
            default: 0
        },
        maxVal: {
            type: Number,
            default: 100
        },
        currVal: {
            type: Number,
            default: 0
        },
        valueFormatter: {
            type: Function,
            default: val => val
        }
    },
    watch: {
        
    },
    computed: {
        currPercentage() {
            return Math.min(this.currVal / (this.maxVal - this.minVal) * 100, 100);
        },
        markerLeftPosition() {
            return percentage => {
                return this.barWidth * percentage / 100;
            }
        },
        progressStyle() {
            return {
                'background-color':  this.mainColor,
                width: (this.currPercentage + '%')
            };
        },
        fadeBackground() {
             return {
                'background-color':  this.secondColor,
                '--barColor': this.barColor
             }
        }
    },

    methods: {
        handleResize () {
            this.barWidth = Math.max((this.$refs.container.clientWidth - 10), 0);
        }
    },

    mounted() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
    },

    beforeDestroy: function () {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

<style lang="scss">
 @import "../../../../styles/_colors.scss";
.dcxa-progress {
    border-radius: 25px;
    height: 35px;
    padding: 5px;
    position: relative;
    box-shadow: inset 0 0 1px 0 rgba($black, 0.1);
    
    .progress-fill {
        height: 100%;
        border-radius: 25px;
        text-align: right;
        font-size: 16px;
        font-weight: 600;
        color: $white;
        padding: 0 10px;
        line-height: 25px;

        -webkit-transition: width 0.5s; /* For Safari 3.1 to 6.0 */
        transition: width 0.5s;
    }

    .stop-container {
        position: absolute;
        top: 0;
        height: 35px;
        line-height: 35px;
        // width: calc(100% - 10px);

        .stop {
            display: inline-block;
            height: 100%;
            position: relative  ;

            &::before {
                display: block;
                content: ' ';
                height: 100%;
            }

            span {
                left: -20px;
                position: absolute;
                right: auto;
                text-align: center;
                width: 40px;
                font-weight: 300;
            }

            &.show-bar {
                &::before {
                    border-left: 1px solid var(--barColor);
                }
                &:last-child::before, &:first-child::before{
                    border-left-width: 0px;;
                }
            }
        }
    }
}

</style>
