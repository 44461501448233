<template>
    <div>
        <dcxa-admonition type="dcxa-success" ref="successAdmonition" :visible="successAdmonitionVisible" :message="successAdmonitionMessage"/>
        <slot :containerContext="this" />
    </div>
</template>

<script>
    import _ from "lodash";

    export default {
        name: "InlinePropertyEditorContainer",
        props: {
            localEditors: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                successAdmonitionMessage: '',
                successAdmonitionVisible: false,
                openLocalEditor: '',
                localEditorSaving: false,
            }
        },
        watch: {
            localEditors: {
                handler(val){
                    let openEditor = _.findKey(val, function(o) { return o.visible === true; });
                    this.openLocalEditor = _.isUndefined(openEditor) ? '' : openEditor;
                },
                deep: true
            }
        },
        methods: {
            reset() {
                _.forEach(this.localEditors, (editor) => {
                    editor.visible = false;
                    editor.changed = false;
                });
            },

            async onEdit(localEditorType) {
                this.successAdmonitionVisible = false;
                let otherOpenSection = _.findKey(this.localEditors, function(o) { return o.visible === true; });

                if (_.isUndefined(otherOpenSection) || (!_.isUndefined(otherOpenSection) && this.localEditors[otherOpenSection].changed === false)) {
                    if (!_.isUndefined(otherOpenSection)) {
                        this.localEditors[otherOpenSection].visible = false;
                    }
                    this.localEditors[localEditorType].visible = true;
                }
                else {
                    let submitButtonConfig =  Cloudera.Modal.ButtonBuilder.build('red', 'submit', 'Discard', () => {
                        this.localEditors[otherOpenSection].visible = false;
                        this.localEditors[otherOpenSection].changed = false;

                        this.localEditors[localEditorType].visible = true;

                        return true;
                    });

                    let simpleModalConfig = Cloudera.Modal.SimpleConfigBuilder.build(
                        "",
                        "Are you sure want to discard the changes?",
                        [ Cloudera.Modal.Buttons.Cancel(), submitButtonConfig ]);

                    let { promise } = Cloudera.Modal.simple(simpleModalConfig);
                    await promise;
                }
            },

            async onSave(localEditingResult) {
                if (!this.localEditorSaving) {
                    this.localEditorSaving = true;

                    let result = new Promise((resolve, reject) => {
                        this.$emit('update', localEditingResult, resolve, reject)
                    });

                    result.then(() => {
                        this.localEditorSaving = false;

                        this.localEditors[localEditingResult.sectionType].visible = false;
                        this.localEditors[localEditingResult.sectionType].changed = false;

                        this.$refs.successAdmonition.reset();
                        this.successAdmonitionMessage = "Changes have been saved.";
                        this.successAdmonitionVisible = true;
                    }).catch(async () => {
                        this.localEditorSaving = false;

                        let errorModalConfig = Cloudera.Modal.ErrorConfigBuilder.build(
                            {
                                clientErrorCode: "LocalSavingFailed",
                                isUIErrorCode: true,
                                messagePlaceholderValues: [
                                    {placeholder: "##CONTACT_US##", value: this.contactUsLink}
                                ]
                            }, [Cloudera.Modal.Buttons.Ok()]
                        );

                        await Cloudera.Modal.error(errorModalConfig).promise;
                    });

                    return result;
                }
            },

            onChanged(localEditorType) {
                this.localEditors[localEditorType].changed = true;
            },

            onCancel(localEditorType) {
                this.localEditors[localEditorType].visible = false;
                this.localEditors[localEditorType].changed = false;
            }
        }
    }
</script>