var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dcxa-simple-table",
      class: { "compact-desktop-row": _vm.compactDesktopRow }
    },
    [
      _vm.context.isLoading
        ? _c("dcxa-content-loader", {
            attrs: {
              type: "SimpleTable",
              "number-of-columns": _vm.numberOfLoadingColumns,
              "number-of-rows": _vm.numberOfLoadingRows,
              title: _vm.context.title,
              titleSize: _vm.titleSize
            }
          })
        : _vm.$screensFrom("md") || !_vm.responsive
        ? _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass: "simple-table-view-header",
                  attrs: { "data-dd-privacy": "mask-user-input" }
                },
                [
                  _vm.hasExpansionPanel
                    ? _c("div", {
                        staticClass: "simple-table-view-column-header",
                        style: { width: _vm.rowExpanderColumnWidth }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.context.columns, function(col, idx) {
                    return col.visible
                      ? _c(
                          "div",
                          {
                            key: idx,
                            staticClass: "simple-table-view-column-header",
                            class: { wrap: _vm.wrapColumnHeaders },
                            style: _vm.getTableViewHeaderStyle(col)
                          },
                          [
                            _vm._v("\n                " + _vm._s(col.label)),
                            _c("span", [
                              col.sortable
                                ? _c("i", {
                                    staticClass: "sort-caret",
                                    class: _vm.getSortIndicator(col),
                                    on: {
                                      click: function($event) {
                                        return _vm.sortBy(col.prop)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ])
                          ]
                        )
                      : _vm._e()
                  })
                ],
                2
              ),
              _vm._v(" "),
              _vm._l(_vm.context.data, function(row, idx) {
                return _c(
                  "div",
                  { key: idx, staticClass: "simple-table-view-row-container" },
                  [
                    _c("simple-table-row", {
                      ref: "simple_table_row_" + idx,
                      refInFor: true,
                      staticClass: "simple-table-view-row",
                      class: _vm.rowSelectionClasses(row),
                      attrs: {
                        data: row,
                        "row-idx": idx,
                        "alternate-coloring": _vm.alternateColoring
                      },
                      on: {
                        click: function($event) {
                          if (
                            $event.ctrlKey ||
                            $event.shiftKey ||
                            $event.altKey ||
                            $event.metaKey
                          ) {
                            return null
                          }
                          return _vm.onRowClick.apply(null, arguments)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "columns",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _vm.hasExpansionPanel
                                  ? _c("dcxa-simple-table-row-expander", {
                                      attrs: {
                                        "context-prefix-id":
                                          _vm.contextPrefixId,
                                        "row-idx": idx,
                                        width: _vm.rowExpanderColumnWidth
                                      },
                                      on: { rowExpanded: _vm.rowExpanded }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._t("columns", null, { row: row })
                              ]
                            }
                          },
                          {
                            key: "expansion-panel",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _vm._t("expansion-panel", null, { row: row })
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _vm._v(" "),
                    _vm.hasRowMessage(row)
                      ? _c(
                          "div",
                          {
                            staticClass: "row-message",
                            class: _vm.rowMessageType(row)
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.rowMessage(row)) +
                                "\n            "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          )
        : _c(
            "div",
            _vm._l(_vm.context.data, function(row, idx) {
              return _c(
                "div",
                { key: idx, staticClass: "simple-card-view-row-container" },
                [
                  _c(
                    "div",
                    {
                      class: _vm.rowSelectionClasses(row),
                      on: {
                        click: function($event) {
                          if (
                            $event.ctrlKey ||
                            $event.shiftKey ||
                            $event.altKey ||
                            $event.metaKey
                          ) {
                            return null
                          }
                          return _vm.onSelectRow(row, $event)
                        }
                      }
                    },
                    [
                      _c("simple-table-row", {
                        ref: "simple_table_row_" + idx,
                        refInFor: true,
                        staticClass: "simple-card-view-row details",
                        attrs: {
                          data: row,
                          "row-idx": idx,
                          "alternate-coloring": _vm.alternateColoring
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "columns",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._t("columns", null, { row: row }),
                                  _vm._v(" "),
                                  _vm.hasExpansionPanel
                                    ? _c("dcxa-simple-table-row-expander", {
                                        attrs: {
                                          "context-prefix-id":
                                            _vm.contextPrefixId,
                                          "row-idx": idx
                                        },
                                        on: { rowExpanded: _vm.rowExpanded }
                                      })
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "expansion-panel",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._t("expansion-panel", null, { row: row })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.hasRowMessage(row)
                    ? _c(
                        "div",
                        {
                          staticClass: "row-message",
                          class: _vm.rowMessageType(row)
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.rowMessage(row)) +
                              "\n            "
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            }),
            0
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6d9f798f", { render: render, staticRenderFns: staticRenderFns })
  }
}