<template>
    <div ref="dcxaTree" class="dcxa-tree">
        <!-- {{selectionChanged}} -->
        <tree-node :node-data="data" :labelsOnly="labelsOnly" :level="0" @change="selectionChanged" @expanderModified="isFilterExpanded" ref="rootNode" />
        <div class="controls" v-if="hasData">
        <dcxa-text-link @click="expandAll">Expand All <i class="fal fa-caret-down" aria-hidden="true"></i></dcxa-text-link>
        <dcxa-text-link @click="collapseAll" class="collapse_all"><i class="fal fa-caret-up" aria-hidden="true"></i> Collapse All </dcxa-text-link>
        </div>
    </div>
</template>

<script>
const dcxaCore = require('@dcxa/dcxa-core');
const { Utilities } = dcxaCore;

import _ from 'lodash'
import Vue from 'vue';
import TreeNode from './TreeNode.vue'

export default {
  name: 'DcxaTree',
  components: {
      'tree-node': TreeNode
  },
  props: {
      data: {
            type: Object,
            default: {}
      },
      labelsOnly: {
            type: Boolean,
            default: false
      },
      syncSelectionOfNodes: {
          type: Boolean,
          default: false
      },
      isTreeExpanded: {
          type: Boolean,
          default: false
      }
  },
  computed: {
      hasData() {
          return Utilities.object.hasDeepValue(this.data, 'children') && this.data.children.length > 0
      },
      isTreeCollapsed() {
        alert();
        this.isFilterExpanded();
      },
           
  },
  methods: {
      selectionChanged(nodeData) {
          if (this.syncSelectionOfNodes === true) {
              let isSelected = nodeData.selected;

              let selectedLeafNodes = [];

              Utilities.object.traverseHierarchy(nodeData, 'children', (nodeChild) => {
                  nodeChild.selected = isSelected;
                  if (nodeChild.children.length === 0) {
                      selectedLeafNodes.push(nodeChild);
                  }
              });
            
              this.$emit('change', selectedLeafNodes);
          }
          else {
              this.$emit('change', nodeData);
          }
      },
      setupHeights() {
          Vue.nextTick(()=>{
              Utilities.dom.setupHeights();
          })
      },
      collapseAll() {
          Utilities.object.traverseHierarchy(this.$refs.rootNode, '$refs.childnodes', (n) => {
              n.collapseNode();
          });
          this.setupHeights();
          this.isTreeExpanded = false;
          // Utilities.dom.scrollToTop();
          let el = this.$refs.dcxaTree
          let rect = el.getBoundingClientRect()
          // Scroll to the element (using x and y axis)
          window.scrollTo(rect.left, rect.top)
      },
      expandAll() {
          Utilities.object.traverseHierarchy(this.$refs.rootNode, '$refs.childnodes', (n) => {
              if (!n.isLeaf) {
                  n.expandNode();
              }
             // add expanded property to every children to use it in handling collapse all button behaviour
            _.each(n.nodeData.children, function (data) {
                data.isExpanded = true;
            });
          });
          this.isTreeExpanded = true;
          this.setupHeights();
      },
      expandSelectedNodePaths() {
          Utilities.object.traverseHierarchy(this.$refs.rootNode, '$refs.childnodes', (nodeDown) => {
              if (nodeDown.nodeData && nodeDown.nodeData.children && _.some(nodeDown.nodeData.children, { selected: true })) {
                  Utilities.object.traverseUpHierarchy(nodeDown, '$parent', 'isRoot', (nodeUp) => {
                      if (!nodeUp.isLeaf) {
                          nodeUp.expandNode();
                      }
                  });
              }
          });
          this.setupHeights();
      },
      isFilterExpanded(nodeData){
          var self = this;
           _.each(this.$refs.rootNode.nodeData.children, function (data) {
                    if(data.isExpanded != undefined && data.isExpanded == true){
                        self.isTreeExpanded = true;
                        return false;
                    }
                    else {
                        self.isTreeExpanded = false;
                    }
            });
      }
  }
}
</script>

<style lang="scss">
@import "../../../../../styles/_colors.scss";
.dcxa-tree {
   > .controls {
        margin: 16px;
        font-size: 14px;
        line-height: 1;
        color: shade($mint,120);
       > .left {
            float: left;
       }

       > .right {
            float: right;
       }

       i {
            vertical-align: middle;
            margin: 0px 6px;
       }

       span {
           font-size: 14px;
       }
       > .collapse_all {
           float: right;
       }
   }
}


</style>
