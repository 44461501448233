import Modal from '../components/basic/Modal/Modal.vue'
import LoadingModal from '../components/basic/Modal/LoadingModal.vue'
import SimpleModal from '../components/basic/Modal/SimpleModal.vue'
import ErrorModal from '../components/basic/Modal/ErrorModal.vue'
import TextEncoding from "text-encoding";
import { isVNode } from "element-ui/src/utils/vdom";

import { Configs, Errors } from '@dcxa/dcxa-core';
import { upperFirst } from 'lodash';

function merge(target) {
    for (let i = 1, j = arguments.length; i < j; i++) {
        let source = arguments[i] || {};
        for (let prop in source) {
            if (source.hasOwnProperty(prop)) {
                let value = source[prop];
                if (value !== undefined) {
                    target[prop] = value;
                }
            }
        }
    }

    return target;
}

function install(Vue) {
    let currentMsg = null;
    let instance = null;
    let msgQueue = [];

    const defaultCallback = action => {
        if (currentMsg) {
            if (currentMsg.resolve) {
                currentMsg.resolve({
                    closeAction: action
                });
            }
        }
    };

    const initInstance = (options) => {
        const DcxaModalConstructor = Vue.extend(merge({
            el: document.createElement('div')
        }, Modal));

        instance = new DcxaModalConstructor({
            store: options.store,
            propsData: {
                // common properties
            }
        });

        const h = instance.$createElement;

        if (options.type === DcxaModalBuilder.Type.LOADING) {
            instance.content = h(LoadingModal, {
                props: {
                    title: options.title || 'Loading...'
                }
            });
        } else if (options.type === DcxaModalBuilder.Type.SIMPLE) {
            instance.content = h(SimpleModal, {
                props: options
            });
        } else if (options.type === DcxaModalBuilder.Type.ERROR) {
            instance.content = h(ErrorModal, {
                props: options
            });
        }

        instance.callback = defaultCallback;
    };

    const showNextMsg = () => {
        if (msgQueue.length > 0) {
            if (instance) {
                instance.doClose();
                instance.$destroy();
                instance = null;
            }

            currentMsg = msgQueue.shift();
            let options = currentMsg.options;

            initInstance(options);

            for (let prop in options) {
                if (options.hasOwnProperty(prop)) {
                    instance[prop] = options[prop];
                }
            }

            if (isVNode(instance.content)) {
                instance.$slots.default = [instance.content];
                instance.content = null;
            } else {
                delete instance.$slots.default;
            }

            instance.$mount();
            document.body.appendChild(instance.$el);

            Vue.nextTick(() => {
                instance.visible = true;
            });
        }
    };

    const DcxaModal = function(options) {
        let result = {}

        if (options.type !== 'loading') {
            result.promise = new Promise((resolve, reject) => {
                result.resolvePromise = resolve;

                msgQueue.push({
                    options: options,
                    resolve: resolve
                });

                showNextMsg();
            });
        } else {
            msgQueue.push({ options });
            showNextMsg();
        }

        result.handler = instance;

        return result;
    }

    const DcxaModalBuilder = function() {};

    DcxaModalBuilder.Type = {
        LOADING: 'loading',
        SIMPLE: 'simple',
        ERROR: 'error',
        CUSTOM: 'custom'
    };

    DcxaModalBuilder.Button = {
        Close: { type: 'primary', title: 'Close', action: 'close', loading: false, callback: () => { return true; } },
        Ok: { type: 'primary', title: 'Ok', action: 'confirm', loading: false, callback: async() => { return true; } },
        Cancel: { type: 'primary', title: 'Cancel', action: 'cancel', loading: false, callback: () => { return true; } },
        Continue: { type: 'primary', title: 'Continue', action: 'confirm', loading: false, callback: () => { return true; } },
    };

    DcxaModalBuilder.createOkButton = (id, type) => {
        return {
            id: id,
            type: type || 'secondary',
            title: 'Ok',
            action: 'confirm',
            loading: false,
            callback: () => { return true; }
        }
    };

    DcxaModalBuilder.createCloseButton = (id) => {
        return {
            id: id,
            type: 'secondary',
            title: 'Close',
            action: 'close',
            loading: false,
            callback: () => { return true; }
        }
    };

    DcxaModalBuilder.createCancelButton = (id) => {
        return {
            id: id,
            type: 'secondary',
            title: 'Cancel',
            action: 'cancel',
            loading: false,
            callback: () => { return true; }
        }
    };

    DcxaModalBuilder.createCancelLinkButton = (id) => {
        return {
            id: id,
            type: 'text',
            title: 'Cancel',
            action: 'cancel',
            loading: false,
            callback: () => { return true; }
        }
    };

    DcxaModalBuilder.createContinueButton = (id) => {
        return {
            id: id,
            type: 'primary',
            title: 'Continue',
            action: 'confirm',
            loading: false,
            callback: () => { return true; }
        }
    };

    DcxaModalBuilder.createButton = (type, action, title, callback, id) => {
        return {
            type: type,
            title: title,
            action: action,
            loading: false,
            callback: callback,
            id: id
        }
    };

    DcxaModalBuilder.createSimpleConfig = (title, message, buttons, ids, icon) => {
        return {
            title: title,
            message: message,
            buttons: buttons,
            ids: ids || {},
            icon: icon || ''
        }
    };

    DcxaModalBuilder.createErrorConfig = (errorDescriptor, buttons, buttonActionArgs, ids) => {
        let errorInfo = Configs.Errors[errorDescriptor.clientErrorCode] || {};

        if (errorDescriptor.isUIErrorCode) {
            errorInfo = Configs.UIErrors[errorDescriptor.clientErrorCode];
            errorDescriptor.clientErrorCode = null;
        }

        let configObj = {
            title: errorInfo.title,
            message: errorInfo.message,
            errorCode: errorDescriptor.clientErrorCode,
            ids: ids || {}
        }

        if (errorDescriptor.messagePlaceholderValues) {
            _.each(errorDescriptor.messagePlaceholderValues, (v) => {
                configObj.message = configObj.message.replace(v.placeholder, v.value);
            });
        }

        if (!buttonActionArgs && errorDescriptor.buttonActionArgs) {
            buttonActionArgs = errorDescriptor.buttonActionArgs;
        }

        if (buttons) {
            configObj.buttons = buttons;
        } else if (errorInfo.buttons) {
            let buttonDefs = _.map(errorInfo.buttons, (btnConfigName) => {
                return {
                    arg: buttonActionArgs ? buttonActionArgs[btnConfigName] : null,
                    ...Errors.buttons[btnConfigName]
                }
            });

            configObj.buttons = buttonDefs;
        }

        return configObj;
    };

    DcxaModalBuilder.createCustomConfig = (component, closeButton, store) => {
        return {
            content: component,
            closeButton: closeButton,
            store: store
        }
    };

    DcxaModalBuilder.create = (type, config) => {
        if (!config) {
            config = {};
        }

        return DcxaModal(merge({
            type: type
        }, config));
    };

    DcxaModalBuilder.createLoadingModal = (loadingTitle) => {
        return DcxaModalBuilder.create(DcxaModalBuilder.Type.LOADING, { title: loadingTitle });
    };

    DcxaModalBuilder.createSimpleModal = (config) => {
        return DcxaModalBuilder.create(DcxaModalBuilder.Type.SIMPLE, config);
    };

    DcxaModalBuilder.createErrorModal = (config) => {
        return DcxaModalBuilder.create(DcxaModalBuilder.Type.ERROR, config);
    };

    DcxaModalBuilder.createCustomModal = (config) => {
        return DcxaModalBuilder.create(DcxaModalBuilder.Type.CUSTOM, config);
    };

    DcxaModalBuilder.close = (handler, action) => {
        let inst = handler ? handler : instance;

        if (inst) {
            inst.doClose(action);
            inst.$destroy();
        }

        inst = null;
        msgQueue = [];
        currentMsg = null;
    };

    let dcxaModalInstanceObject = {
        create: DcxaModalBuilder.create,
        close: DcxaModalBuilder.close,
        loading: DcxaModalBuilder.createLoadingModal,
        simple: DcxaModalBuilder.createSimpleModal,
        error: DcxaModalBuilder.createErrorModal,
        custom: DcxaModalBuilder.createCustomModal,
        SimpleConfigBuilder: {
            build: DcxaModalBuilder.createSimpleConfig
        },
        ErrorConfigBuilder: {
            build: DcxaModalBuilder.createErrorConfig
        },
        CustomConfigBuilder: {
            build: DcxaModalBuilder.createCustomConfig
        },
        ButtonBuilder: {
            build: DcxaModalBuilder.createButton
        },
        Buttons: {
            Ok: DcxaModalBuilder.createOkButton,
            Close: DcxaModalBuilder.createCloseButton,
            Cancel: DcxaModalBuilder.createCancelButton,
            CancelLink: DcxaModalBuilder.createCancelLinkButton,
            Continue: DcxaModalBuilder.createContinueButton
        },
        ...DcxaModalBuilder.Type
    };

    if (window.Cloudera && !window.Cloudera.Modal) {
        window.Cloudera.Modal = dcxaModalInstanceObject;
    }

    Vue.prototype.$dcxaModal = dcxaModalInstanceObject;
}

export default install;