<template>
    <div class="dcxa-case-summary">
        <dcxa-loading-spinner v-if="isLoading" />
        <div class="error-text" v-else-if="hasError" data-dd-privacy="mask-user-input">Failed to fetch data. Please try to reload the page.</div>
        <template v-else>
            <v-clamp :max-lines="maxLinesForClamping" autoresize="">
                <template slot="before">
                    <span class="case-summary-subject-prefix" data-dd-privacy="mask-user-input">Case Subject: </span>
                </template><!--
            -->{{caseObj.Subject}}
            </v-clamp>
            <div class="case-summary-metadata-container">
                <div v-if="isMetadataListVisible">
                    <card-list-row :data="caseObj">
                        <slot />
                    </card-list-row>
                </div>
                <dcxa-text-link v-if="$screensUpTo('xs')" @click="toggleMetadataList">
                    <template v-if="expanded">
                        <i class="far fa-chevron-up metadata-expander"></i>Less Info
                    </template>
                    <template v-else>
                        <i class="far fa-chevron-down metadata-expander"></i>More Info
                    </template>
                </dcxa-text-link>
            </div>
        </template>
    </div>
</template>

<script>
    import VClamp from 'vue-clamp'
    import CardListRow from  '../basic/ResponsiveTable/CardListRow.vue';

    export default {
        name: "DcxaCaseSummary",
        components: {
            VClamp,
            'card-list-row': CardListRow
        },
        props: [
            'data',
            'hasError',
            'isLoading'
        ],
        data() {
            return {
                expanded: false
            }
        },
        computed: {
            caseObj() {
                return this.data;
            },
            maxLinesForClamping() {
                if (this.$screensUpTo('xs')) {
                    return this.expanded ? Number.MAX_VALUE : 2;
                }

                return 1;
            },
            isMetadataListVisible() {
                if (this.$screensFrom('md') || (this.$screensUpTo('xs') && this.expanded)) {
                    return true;
                }

                return false;
            }
        },
        methods: {
            toggleMetadataList() {
                this.expanded = !this.expanded;
            }
        }
    }
</script>

<style lang="scss">
    @import "../../../../styles/_colors.scss";
    @import "../../../../styles/_utilities.scss";

    .dcxa-case-summary {
        overflow: auto;
        min-height: 56px;

        .dcxa-loading-spinner {
            margin-top: 18px;
        }

        .error-text {
            display: inline-block;
            margin-top: 18px;
            font-size: 16px;
            letter-spacing: normal;
            color: tint($black, 80);
        }

        .case-summary-subject-prefix {
            display: none;

            + span {
                color: tint($black, 80);
                font-size: 16px;
            }
        }

        .case-summary-metadata-container {
            margin-top: 12px;

            i.metadata-expander {
               margin-right: 8px;
            }
        }

        @include breakpoint(md) {
            .case-summary-subject-prefix {
                display: unset;
                color: tint($black, 50);
                font-size: 16px;
            }
        }
    }

</style>