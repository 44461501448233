<template>
    <div class="dcxa-modal-loading">
        <div class="dcxa-modal-header">
            <h5 v-if="$screenIs('xs')">{{title}}</h5>
            <h4 v-else>{{title}}</h4>
            <dcxa-loading-spinner />
        </div>
    </div>
</template>

<script>
    export default {
        name: "LoadingModal",
        props: {
            title: {
                type: String,
                default: () => ''
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../../../../styles/utilities";
    @import "../../../../../styles/colors";

    .dcxa-modal-loading {
        display: inline-block;

        .dcxa-modal-header {
            display: flex;
            flex-direction: column;
            align-items: center;

            padding: 32px 64px;
            text-align: center;

            h4, h5 {
                margin-bottom: 16px;
            }

            .dcxa-loading-spinner {
               margin-bottom: 0;
            }
        }
    }

    @include breakpoint(md) {
        .dcxa-modal-loading {

        }
    }
</style>