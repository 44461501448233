<template>
    <dcxa-button v-if="config.type !== 'text'" :id="config.id" class="small" :class="{'loading': config.loading}"
                 :type="config.type" :disabled="isDisabled" @click="bindButtonAction(config.arg)">
        {{ config.title }}
    </dcxa-button>
    <dcxa-text-link v-else :id="config.id" :disabled="isDisabled" @click="bindButtonAction(config.arg)">
        {{ config.title }}
    </dcxa-text-link>
</template>

<script>
    import Vue from 'vue';

    export default {
        name: "DcxaModalButton",

        props: {
            buttons: {
                type: Array,
                default: () => []
            },

            modalContext: {
                type: Object,
                default: () => {}
            },

            config: {
                type: Object,
                default: () => {}
            }
        },

        computed: {
            isDisabled() {
                return !this.config.isLoading && this.modalContext.isLoading;
            }
        },

        methods: {
            setLoading(loadingValue) {
                Vue.set(this.modalContext, 'isLoading', loadingValue);
                Vue.set(this.config, 'loading', loadingValue);
            },

            async bindButtonAction() {
                this.setLoading(true);

                let shouldCloseModal = await this.config.callback.call(this.modalContext, {}, this.modalContext, this.config);

                if (shouldCloseModal) {
                    this.$dcxaModal.close(null, this.config.action);
                }
                else {
                    this.setLoading(false);
                }
            }
        }
    }
</script>


<style scoped lang="scss">

</style>
