<template>
    <div class="dcxa-property-value-viewer" :class="{'enable-multiline': enableMultiline}" >
        <span class="property dd-privacy-mask-user-input" v-if="title">
            {{title}}
            <el-popover placement="right" trigger="hover" :content="infoText" popper-class="dcxa-tooltip">
                <i slot="reference" class="far fa-info-circle property-value-info-tooltip" v-if="isInfoMessageVisible" aria-hidden="true"></i>
            </el-popover>
        </span>
        <div class="value" :style="propertyValueViewerStyle">
            <slot v-bind:context="valueContext" name="customValue" class="value" v-if="$scopedSlots.hasOwnProperty('customValue') && isProvided" />
            <template v-else>
                <div class="value"><span :id="id" :class="{'missing': !isProvided}">{{valueToShow}}</span></div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
  name: 'PropertyValueViewer',
  data() {
    return {
    }
  },
  props: [
      'title',
      'value',
      'infoText',
      'missingText',
      'valueFormatter',
      'notProvidedText',
      'enableMultiline',
      'id'
  ],
  computed: {
      valueContext() {
          return { id: this.id };
      },
      isProvided() {
          if (typeof this.value == "boolean") return true;
          
          return (this.value && (typeof this.value == 'string' ? this.value.length > 0: true));
      },
      isInfoMessageVisible() {
          return (this.infoText && (typeof this.infoText == 'string' ? this.infoText.length > 0 : true));
      },
      valueToShow() {
          if (this.isProvided) {
              if (this.valueFormatter) {
                  return this.valueFormatter(this.value);
              } else {
                  return this.value;
              }
          } else {
              return this.missingText || this.notProvidedText || "-";
          }
      },
      propertyValueViewerStyle() {
          return {
              whiteSpace: 'pre-line',
              wordBreak: 'break-all'
          };
      }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../../styles/utilities";
@import "../../../../../styles/colors";

.dcxa-property-value-viewer {
    margin-bottom: 32px;

    display: flex;

    .property {
        width: 100%;
        max-width: 38%;
        min-width: 38%;
        padding-right: 16px;

        /*font-weight: 600;*/
        font-size: 16px;
        color: tint($black, 50);
        word-break: break-word;

        .property-value-info-tooltip {
            color: $dark-orange;
            cursor: pointer;
            font-weight: 200;
        }
    }

    .value {
        font-size: 16px;
        color: tint($black, 80);
        word-break: break-word;

        span.missing {
            color: tint($black, 50);
        }
    }

}

</style>
