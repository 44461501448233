var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      staticClass: "dcxa-icon-with-tooltip",
      class: _vm.classes,
      attrs: {
        placement: _vm.placement,
        trigger: "hover",
        "popper-class": "dcxa-tooltip"
      }
    },
    [
      _vm._t("default", function() {
        return [_vm._v("\n        Tooltip\n    ")]
      }),
      _vm._v(" "),
      _c("i", {
        staticClass: "tooltip-opener-element",
        class: _vm.icon,
        attrs: { slot: "reference", "aria-hidden": "true" },
        slot: "reference"
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-39b67fa8", { render: render, staticRenderFns: staticRenderFns })
  }
}