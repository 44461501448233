var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-toggle-button" },
    [
      _c(
        "el-radio-group",
        {
          class: { multirow: _vm.isMultirow },
          on: { change: _vm.updateValue },
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        _vm._l(_vm.options, function(opt, i) {
          return _c(
            "el-radio-button",
            { key: i, attrs: { label: opt[_vm.valueKey] } },
            [_vm._v(_vm._s(opt[_vm.titleKey]))]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("label", {
        staticClass: "floating-label-element",
        class: { visible: _vm.floatingLabel },
        domProps: { innerHTML: _vm._s(_vm.floatingTitleText) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-52e0b064", { render: render, staticRenderFns: staticRenderFns })
  }
}