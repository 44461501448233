var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "container",
      staticClass: "dcxa-spot-progress",
      class: { vertical: _vm.isVertical, horizontal: !_vm.isVertical },
      style: _vm.styleVars
    },
    _vm._l(_vm.spots, function(spot, i) {
      return _c(
        "div",
        {
          key: i,
          staticClass: "spot",
          class: { fulfilled: spot.fulfilled, active: spot.active }
        },
        [
          i > 0 ? _c("span", { staticClass: "tunnel" }) : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "circle" }),
          _vm._v(" "),
          _vm.showTitle
            ? _c(
                "span",
                { staticClass: "title" },
                [
                  _vm._v(
                    "\n            " + _vm._s(spot.title) + " \n            "
                  ),
                  spot.popoverItems && spot.popoverItems.length > 0
                    ? _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "bottom",
                            width: "400",
                            trigger: "hover"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "dcxa-spot-progress-title-popover" },
                            [
                              _c("h5", [_vm._v(_vm._s(spot.popoverTitle))]),
                              _vm._v(" "),
                              _c(
                                "ul",
                                { staticClass: "tooltip-list no-bullets" },
                                _vm._l(spot.popoverItems, function(item, i) {
                                  return _c(
                                    "li",
                                    { key: i, staticClass: "valid" },
                                    [_vm._v(_vm._s(item.label))]
                                  )
                                }),
                                0
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "far popover-icon",
                            class: [_vm.infoIcon],
                            attrs: { slot: "reference" },
                            slot: "reference"
                          })
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-25a2a8f7", { render: render, staticRenderFns: staticRenderFns })
  }
}