<template>
    <div class="dcxa-card-metadata" v-popover:metadataPopover v-if="isMetadataVisible">
        <el-popover :placement="tooltipPosition" trigger="hover" popper-class="metadata-popover dcxa-tooltip" :disabled="disabledTooltip">
            <span>{{label}}</span>

            <div slot="reference" class="dcxa-card-metadata-with-icon">
                <div class="dcxa-card-metadata-icon" v-if="hasIcon">
                    <i class="dcxa-fa" :class="classesToApply" aria-hidden="true"></i>
                </div><div class="dcxa-card-metadata-content"><slot v-if="hasDefaultSlot" />
                <template v-else>
                    {{ownerRow.data[prop]}}
                </template>
                </div>
            </div>
        </el-popover>
    </div>
</template>

<script>
    const dcxaCore = require('@dcxa/dcxa-core');
    const { Utilities, Configs  } = dcxaCore;

    export default {
        name: 'DcxaCardMetadata',
        props: {
            iconClasses: {
                type: String
            },
            prop: {
                type: String,
                default: () =>  ''
            },
            label: {
                type: String,
                default: () =>  ''
            },
            visible: {
                type: Boolean,
                default: () => true
            },
            sortable: {
                type: Boolean,
                default: () => true
            },
            filterable: {
                type: Boolean,
                default: () => true
            },
            disabledTooltip: {
                type: Boolean,
                default: () => false
            }
        },
        computed: {
            isMetadataVisible() {
                return this.visible && this.ownerRow.data[this.prop];
            },
            hasDefaultSlot() {
                return this.$slots.default;
            },
            tooltipPosition() {
                return this.$screensUpTo('xs') ? "top-start" : "top";
            },
            metadataIcon() {
                if (this.prop === 'Status') {
                    return (Utilities.object.getDeepValue(Configs.Mappings.CaseStatus, this.ownerRow.data[this.prop]) || '') + " myc-case-metadata myc-case-status";
                }
                else if (this.prop === 'Priority') {
                    return (Utilities.object.getDeepValue(Configs.Mappings.CaseSeverity, this.ownerRow.data[this.prop]) || '') + " myc-case-metadata myc-case-severity";
                }
                else {
                    return (Utilities.object.getDeepValue(Configs.Mappings.CaseMetadata, this.prop) || '') + " myc-case-metadata";
                }
            },
            classesToApply() {
                return (this.iconClasses || this.metadataIcon).split(' ');
            },
            hasIcon() {
                return this.iconClasses || this.metadataIcon;
            },
            ownerTable() {
                let parent = this.$parent;

                while (parent && parent.constructor.options.name !== 'DcxaResponsiveTable') {
                    parent = parent.$parent;
                }

                return parent;
            },
            ownerRow() {
                let parent = this.$parent;

                while (parent && parent.constructor.options.name !== 'CardListRow') {
                    parent = parent.$parent;
                }

                return parent;
            },
        },
        mounted() {
            if (this.ownerTable) {
                let columnDefinition = {
                    prop: this.prop,
                    label: this.label,
                    sortable: this.sortable,
                    filterable: this.filterable
                };

                this.ownerTable.insertColumn(columnDefinition);
            }
        }
    }
</script>

<style lang="scss">
    @import "../../../../../styles/utilities";
    @import "../../../../../styles/colors";

    .el-popover {
        &.metadata-popover {
            min-width: unset;
            text-align: center;
        }
    }

    .dcxa-card-metadata {
        cursor: default;
        width: auto;

        .myc-case-status {
            &.green {
                color: $green;
            }
            &.orange {
                color: $bright-orange;
            }
            &.mint {
                color: $mint;
            }
            &.red {
                color: $red;
            }
        }

        .myc-case-severity {
            &.severity1 {
                color: $red;
            }
            &.severity2 {
                color: $bright-orange;
            }
            &.severity3 {
                color: $mint;
            }
            &.severity4 {
                color: tint($black, 40);
            }
            &.tbd {
                color: $mint;
            }
        }

        .myc-case-metadata {
            color: $slate;
        }

        .dcxa-card-metadata-with-icon {
            $icon-size: 14px;
            $icon-right-margin: 8px;
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            width: auto;

            .dcxa-card-metadata-icon {
                width: $icon-size;
                height: $icon-size;
                margin-right: $icon-right-margin;
                display: inline-block;

                .dcxa-fa {
                    font-size: $icon-size;
                    vertical-align: top;

                    &.metadata {
                        color: tint($black, 50);
                    }
                }
            }

            .dcxa-card-metadata-content {
                display: inline-block;
                font-size: 12px;
                color: tint($black, 50);
            }
        }
    }

    @include breakpoint(md) {
        .dcxa-card-metadata {
            float: left;
            margin-right: 16px;
        }
    }
</style>
