var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "content-wrapper", staticClass: "editor-section-wrapper" },
    [
      _c(
        "div",
        { staticClass: "property-section-top-controls" },
        [_vm._t("navigation")],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "property-section" }, [
        !_vm.hideHeader
          ? _c(
              "div",
              { staticClass: "property-section-header" },
              [
                _c(
                  "h4",
                  { class: _vm.titleClass, attrs: { id: _vm.titleId } },
                  [_vm._v(_vm._s(_vm.title)), _vm._t("extension-content")],
                  2
                ),
                _vm._v(" "),
                !_vm.hideEditIcon
                  ? _c("dcxa-text-link", { on: { click: _vm.openEditor } }, [
                      _c("i", {
                        staticClass: "far fa-pencil-alt",
                        attrs: { id: _vm.editBtnId, "aria-hidden": "true" }
                      })
                    ])
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "property-section-content" },
          [_vm._t("default")],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-629957c0", { render: render, staticRenderFns: staticRenderFns })
  }
}