<template>
    <el-button :type="type" :disabled="disabled" class="dcxa-button" :class="classes" @click="onClick" v-if="type != 'icon'" :id="id" :name="name">
        <span v-if="type=='iconSplit'" class="split-icon">
            <i class="dcxa-fa" :class="splitIconClasses"></i>
        </span><slot />
    </el-button>

    <el-button :type="type" :disabled="disabled" class="dcxa-button dcxa-icon-button" :class="classes" @click="onClick" v-else :id="id" :name="name">
        <div class="icon">
            <i class="dcxa-fa" :class="iconClasses"></i>
        </div>
    </el-button>
</template>

<script>
export default {
  name: 'DcxaButton',
  data() {
    return {
    }
  },
  props: [
      'id',
      'name',
      'type',
      'classes',
      'disabled',
      'splitIcon',
      'icon'
  ],
  watch: {
  },
  computed: {
      splitIconClasses() {
          var ret = {};
          ret[this.splitIcon] = true;
          return ret;
      },
      iconClasses() {
          var ret = {};
          ret[this.icon] = true;
          return ret;
      }
  },
  methods: {
      onClick() {
          this.$emit('click');
      }
  }
}
</script>

<style lang="scss">
@import "../../../../styles/_colors.scss";

.dcxa-button {
    &.inline-block {
        display: inline-block;
        vertical-align: middle;
    }

    &.dcxa-icon-button {
        min-width: 56px;
        width: 56px;
        color: $mint;
        border-radius: 3px;
        border: 1px solid tint($black, 30);
        padding: 0;

        .icon {
            .dcxa-fa {
                vertical-align: text-top;
            }
        }

        &.small {
            min-width: 32px;
            width: 32px;
        }

        &:active {
            background: initial;
        }
        &:hover,
        &:focus:hover {
            background: initial;
            color: shade($mint,120);
        }
        &:focus {
            background: initial;
            color: $mint;
        }

    }

}


</style>
