var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "container",
      staticClass: "dcxa-progress",
      style: _vm.fadeBackground
    },
    [
      _c(
        "div",
        { staticClass: "progress-fill", style: _vm.progressStyle },
        [
          _vm.currPercentage > 0
            ? [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.valueFormatter(_vm.currVal)) +
                    "\n        "
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "stop-container" },
        _vm._l(_vm.markers, function(s, i) {
          return _c(
            "div",
            {
              key: i,
              staticClass: "stop",
              class: { "show-bar": s.line },
              style: { left: _vm.markerLeftPosition(s.percentage) + "px" }
            },
            [_c("span", [_vm._v(_vm._s(s.text))])]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-cf48d256", { render: render, staticRenderFns: staticRenderFns })
  }
}