<template>
    <div class="dcxa-responsive-table" :id="id">
        <slot name="no-data" v-if="noData">
            <dcxa-special-state-container :icon="noDataInfo.icon" :text="noDataInfo.message" :title="title" :titleSize="titleSize"></dcxa-special-state-container>
        </slot>
        <slot name="has-error" v-else-if="hasError">
            <dcxa-special-state-container :icon="errorInfo.icon" :text="errorInfo.message" :title="title" :titleSize="titleSize"></dcxa-special-state-container>
        </slot>
        <div v-else>
            <div class="tools-container" v-if="showTools && isLoaded">

                <div class="main-row" :class="{'empty-tools': isEmptyToolContainer}" v-if="$screensFrom('md')" data-dd-privacy="mask-user-input">
                    <div class="left-column">
                        <dcxa-content-title :id="componentIds.titleId" :title="title" :titleSize="titleSize"></dcxa-content-title>
                        <dcxa-sorting-dropdown v-if="showSortDropdownForDesktop && hasSortableColumns" :classes="['small']" :options="this.columns"
                                               :default-sort-by-field="defaultSortByField" :default-sort-direction="defaultSortDirection"
                                               @sortByField="setSortByFieldEventValue" @sortDirections="setSortDirectionsEventValue"/>
                    </div>
                    <div class="right-column">
                        <div class="search-tool tool" v-if="showFilterBox && !remote">
                            <i class="fal fa-search" aria-hidden="true"></i>
                            <input :placeholder="filterBoxText" :id="componentIds.filterBoxId" v-on:input="updateFilterTextValue" v-model="innerFilterText" />
                        </div>
                    </div>
                </div>

                <div class="stack-tools" v-if="$screensUpTo('xs') && responsive">
                    <dcxa-content-title :id="componentIds.titleId" :title="title" :titleSize="titleSize"></dcxa-content-title>
                    <div class="search-tool tool" v-if="showFilterBox && !remote">
                        <i class="fal fa-search" aria-hidden="true"></i>
                        <input :placeholder="filterBoxText" :id="componentIds.filterBoxId" v-on:input="updateFilterTextValue" v-model="innerFilterText" />
                    </div>
                    <div class="sort-tool tool" v-if="hasSortableColumns && !noFilteredData">
                        <dcxa-sorting-dropdown :options="this.columns" :classes="['large']"
                                               :default-sort-by-field="defaultSortByField" :default-sort-direction="defaultSortDirection"
                                               @sortByField="setSortByFieldEventValue" @sortDirections="setSortDirectionsEventValue"/>
                    </div>
                </div>

            </div>

            <slot name="no-filtered-data" v-if="noFilteredData && isLoaded">
                <dcxa-special-state-container :icon="noFilteredDataInfo.icon" :text="noFilteredDataInfo.message" :title="title" :titleSize="titleSize" :show-title="false"></dcxa-special-state-container>
            </slot>
            <div v-else>
                <slot :context="tableContext" />
                <dcxa-pagination v-if="isLoaded && showPagination" :responsive="responsive" @paginationChange="paginationChanged" :data="sortedData" :show-page-size-options="showPageSizeOptions" :sizeSelectorId="componentIds.sizeSelectorId" :paginationId="componentIds.paginationId" :pagination-info="paginationInfo" :remote="remote" :remote-pagination-info="remoteData.pagination"></dcxa-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    import Vue from "vue";

    const dcxaCore = require('@dcxa/dcxa-core');
    const { Utilities, Api } = dcxaCore;

    export default {
        name: "DcxaResponsiveTable",
        props: {
            id: {
                type: String,
                default: () => ''
            },
            title: {
                type: String,
                default: () => ''
            },
            titleSize: {
                type: String,
                default: () => undefined
            },
            filterBoxText: {
                type: String,
                default: () => 'Filter by word'
            },
            componentIds: {
                type: Object,
                default: () => { return {} }
            },
            responsive: {
                type: Boolean,
                default: () => true
            },
            defaultSortByField: {
                type: String,
                default: () => ''
            },
            defaultSortDirection: {
                type: String,
                default: () => 'asc'
            },
            data: { 
                type: Array,
                default: () => []
            },
            hasError: { 
                type: Boolean,
                default: () => false
            },
            isLoading: { 
                type: Boolean,
                default: () => false
            },
            specialStates: {
                type: Object,
                default: () => {
                    return {
                        noData: {
                            icon: '',
                            message: ''
                        },
                        noFilteredData: {
                            icon: '',
                            message: ''
                        },
                        error: {
                            icon: '',
                            message: ''
                        }
                    }
                }
            },
            pageSizes: { 
                type: Array,
                default: () => [10, 25, 50]
            },
            pageSize: {
                type: Number,
                default: () => 10
            },
            showPageSizeOptions: {
                type: Boolean,
                default: () => true
            },
            showFilterBox: {
                type: Boolean,
                default: () => true
            },
            showTools: {
                type: Boolean,
                default: () => true
            },
            showSortDropdownForDesktop: {
                type: Boolean,
                default: () => false
            },
            fieldValueResolver: {
                type: Function,
                default: () => null
            },
            showPagination: {
                type: Boolean,
                default: () => true
            },

            remote: {
                type: Boolean,
                default: () => false
            },
            remoteOptions: {
                type: Object,
                default: () => { return { path: '', dummyData: [], paramsValidPredicate: () => false } }
            },
            remoteParams: {
                type: Object,
                default: () => { return { urlParams: {}, queryParams: {} } }
            }
        },
        data() {
            return {
                columns: [],
                innerFilterText: '',
                sortByField: this.defaultSortByField,
                sortDirection: this.defaultSortDirection,
                paginationInfo: {
                    pageSizes: this.pageSizes,
                    pageSize: this.pageSize,
                    currentPage: 1
                },
                remoteData: {
                    pagination: {
                        numberOfPages: 0,
                        dataSetSize: 0,
                        pageSize: 0,
                        currentPage: 0
                    }
                }
            }
        },
        computed: {
            noData() {
                this.innerFilterText = '';
                return this.data.length === 0 && !this.hasError && !this.isLoading && !this.hasFilterTermSet;
            },
            noFilteredData() {
                return this.hasFilterTermSet && this.sortedData.length === 0;
            },
            isLoaded() {
                return !this.noData && !this.hasError && !this.isLoading;
            },

            noDataInfo() {
                return {
                    icon: 'far ' + (this.specialStates.noData && this.specialStates.noData.icon ? this.specialStates.noData.icon : 'fa-smile'),
                    message: this.specialStates.noData && this.specialStates.noData.message ? this.specialStates.noData.message : 'You have no data.',
                }
            },
            noFilteredDataInfo() {
                return {
                    icon: 'far ' + (this.specialStates.noFilteredData && this.specialStates.noFilteredData.icon ? this.specialStates.noFilteredData.icon : 'fa-frown'),
                    message: this.specialStates.noFilteredData && this.specialStates.noFilteredData.message ? this.specialStates.noFilteredData.message : 'No results. Please try another search term.',
                }
            },
            errorInfo() {
                return {
                    icon: 'far ' + (this.specialStates.error && this.specialStates.error.icon ?  this.specialStates.error.icon : 'fa-frown'),
                    message: this.specialStates.error && this.specialStates.error.message ? this.specialStates.error.message : 'Failed to fetch data. Please try to reload the page.',
                }
            },

            tableContext() {
                return {
                    data: this.currentPageData,
                    columns: this.columns,
                    sortByField: this.sortByField,
                    sortDirection: this.sortDirection,
                    isLoading: this.isLoading,
                    title: this.title
                };
            },

            filteredData() {
                if (this.hasFilterTermSet) {
                    let filterText = this.innerFilterText.toUpperCase();

                    return _.filter(this.data, obj => {
                        let filterPredicates = _.map(this.filterableColumns, (column) => {
                            return (objParam) => {
                                const fieldValue = column.customField === true && this.fieldValueResolver && typeof this.fieldValueResolver === 'function' ?
                                    this.fieldValueResolver(objParam, column.prop) :
                                    column.prop;

                                return objParam.hasOwnProperty(fieldValue) && objParam[fieldValue] && objParam[fieldValue].toString().toUpperCase().indexOf(filterText) > -1;
                            };
                        });

                        let orFilterConditions = _.overSome(filterPredicates);

                        return orFilterConditions(obj);
                    });
                }

                return this.data;
            },
            sortedData() {
                if (this.remote) {
                    // if data is set to remote, do not sort on the UI (the endpoint will provide the required data in the proper order/format)
                    return this.data;
                } else {
                    let filteredData = this.filteredData;

                    let sortByFieldValue = this.sortByField;
                    let sortDirectionValue = this.sortDirection;

                    if (sortByFieldValue) {
                        let columnDefinitions = _.filter(this.filterableColumns, c => c.prop === sortByFieldValue);
                        let columnDefinition = columnDefinitions[0];

                        if (columnDefinition) {

                            const fieldValueResolver = columnDefinition.customField === true ? this.fieldValueResolver : null;

                            let sortedDataSet = [];

                            if (columnDefinition.type === 'Number') {
                                sortedDataSet = Utilities.sorting.number(filteredData, sortByFieldValue, sortDirectionValue, fieldValueResolver);
                            } else if (columnDefinition.type === 'Date') {
                                sortedDataSet = Utilities.sorting.date(filteredData, sortByFieldValue, sortDirectionValue, fieldValueResolver);
                            } else if (columnDefinition.type === 'Time') {
                                sortedDataSet = Utilities.sorting.time(filteredData, sortByFieldValue, sortDirectionValue, fieldValueResolver);
                            } else if (columnDefinition.type === 'Version') {
                                sortedDataSet = Utilities.sorting.version(filteredData, sortByFieldValue, sortDirectionValue, fieldValueResolver);
                            } else if (columnDefinition.type === 'Environment') {
                                sortedDataSet = Utilities.sorting.environment(filteredData, sortByFieldValue, sortDirectionValue, fieldValueResolver);
                            } else if (columnDefinition.type === 'ValidationSeverity') {
                                sortedDataSet = Utilities.sorting.validationSeverity(filteredData, sortByFieldValue, sortDirectionValue, fieldValueResolver);
                            } else if (sortByFieldValue === 'CaseNumber') {
                                sortedDataSet = Utilities.sorting.numericString(filteredData, sortByFieldValue, sortDirectionValue, fieldValueResolver);
                            } else {
                                sortedDataSet = Utilities.sorting.string(filteredData, sortByFieldValue, sortDirectionValue, fieldValueResolver);
                            }

                            return sortedDataSet;
                        }
                    }

                    return filteredData;
                }
            },
            currentPageData() {
                if (this.remote) {
                    return this.data;
                } else {
                    let pages = _.chunk(this.sortedData, this.paginationInfo.pageSize);
                    return pages[this.paginationInfo.currentPage - 1];
                }
            },
            hasFilterTermSet() {
                return this.innerFilterText.length > 0;
            },
            sortableColumns() {
                return _.filter(this.columns, c => { return c.sortable });
            },
            hasSortableColumns() {
                return this.sortableColumns.length > 0;
            },
            filterableColumns() {
                return _.filter(this.columns, c => { return c.filterable });
            },
            sortingOptions() {
                return _.map(this.sortableColumns, c => { return {
                    id: c.prop,
                    title: 'Sort by ' + c.label
                }});
            },
            isEmptyToolContainer() {
                return this.showTools && !this.title && !this.showSortDropdownForDesktop && !this.showFilterBox;
            }
        },
        methods: {
            async loadRemoteData(paginationInfoProvided) {
                if (_.isFunction(this.remoteOptions.paramsValidPredicate) && !this.remoteOptions.paramsValidPredicate(this.remoteParams)) {
                  return;
                }

                console.log(this.remoteParams);

                let remoteLoading = true;
                let remoteError = false;

                this.$emit('remoteDataLoading');

                let queryParams = {};
                let urlParams = {};
                if (this.remoteParams) {
                    if (this.remoteParams.urlParams) {
                        urlParams = this.remoteParams.urlParams;
                    }
                    if (this.remoteParams.queryParams) {
                        queryParams = this.remoteParams.queryParams;
                    }
                }

                let extraQueryParams = {
                    page: paginationInfoProvided ? paginationInfoProvided.currentPage : this.paginationInfo.currentPage,
                    size: paginationInfoProvided ? paginationInfoProvided.pageSize : this.paginationInfo.pageSize,
                    field: this.sortByField,
                    ascending: (this.sortDirection === 'asc')
                };

                if (_.isFunction(this.remoteOptions.paramsTransformer)) {
                    extraQueryParams = this.remoteOptions.paramsTransformer(extraQueryParams);
                }

                let raw_remote_resp;
                let loaded = false;

                try {
                    await new Promise(resolve => setTimeout(resolve, 1000));

                    raw_remote_resp = await Api.callEndpoint(this.remoteOptions.path, { urlParams, queryParams: { ...queryParams, ...extraQueryParams } });

                    loaded = true;
                    remoteLoading = false;
                } catch (e) {
                    loaded = false;
                    remoteLoading = false;
                    remoteError = true;

                    /* FOR LOCAL DEVELOPMENT
                    remoteError = false;
                    raw_remote_resp = {
                        content: _.chunk(this.remoteOptions.dummyData, 10)[2],
                        totalPages: 5,
                        totalElements: 45,
                        size: 10,
                        number: 3
                    };

                    loaded = true;
                    */
                }

                this.$emit('remoteDataLoaded', { loading: remoteLoading, error: remoteError });

                if (loaded) {
                    this.remoteData.pagination = {
                        numberOfPages: raw_remote_resp.totalPages,
                        dataSetSize: raw_remote_resp.totalElements,
                        pageSize: raw_remote_resp.size,
                        currentPage: raw_remote_resp.number + 1
                    };

                    this.$emit('remoteDataReceived', raw_remote_resp.content);
                }
            },

            bringIntoView(idProp, idValue) {
                const i = _.findIndex(this.sortedData, r => r[idProp] == idValue) | 0;
                const page = Math.floor( i / this.paginationInfo.pageSize ) + 1;
                this.paginationInfo.currentPage = page;
                this.paginationChanged(this.paginationInfo);
            },

            sort() {
                this.sortDirection = (this.sortDirection === 'asc' ? 'desc' : 'asc');
                if (this.remote) {
                    this.$emit('dataSortChanged', {
                      sortByField: this.sortByField,
                      sortDirection: this.sortDirection
                    });
                    this.loadRemoteData();
                }
            },

            sortBy(columnName) {
                if (this.sortByField !== columnName) {
                    this.sortByField = columnName;
                }
                this.sort();
            },

            insertColumn(columnDefinition) {
                let isColumnAlreadyAdded = _.find(this.columns, c => { return c.prop === columnDefinition.prop; });

                if (!isColumnAlreadyAdded) {
                    this.columns.push(columnDefinition);
                }
                else {
                    let idx = _.findIndex(this.columns, c => { return c.prop === columnDefinition.prop; });
                    Vue.set(this.columns, idx, columnDefinition);
                }
            },

            updateColumn(columnDefinition) {
                let idx = _.findIndex(this.columns, c => { return c.prop === columnDefinition.prop; });
                Vue.set(this.columns, idx, columnDefinition);
            },

            updateFilterTextValue: function () {
                this.paginationInfo.currentPage = 1;
                this.$emit('filterTextChange', this.innerFilterText)
            },

            paginationChanged: function(paginationInfo) {
                if (this.remote) {
                    this.loadRemoteData(paginationInfo);
                }

                let scrollTop = this.$el.parentNode.offsetTop;
                window.scrollTo({ top: scrollTop, behavior: 'smooth'});

                // TODO: is this still in use? if not remove
                this.$emit('requestNewPageData', paginationInfo);
            },

            setSortByFieldEventValue(value){
                this.sortByField = value;
                if (this.remote) {
                    this.loadRemoteData();
                }
            },

            setSortDirectionsEventValue(value){
                this.sortDirection = value;
                if (this.remote) {
                    this.loadRemoteData();
                }
            },

            reset(skipSettingSortingFields) {
                this.paginationInfo.currentPage = 1;

                if (!skipSettingSortingFields) {
                  this.sortByField = this.defaultSortByField;
                  this.sortDirection = this.defaultSortDirection;
                }
            }
        },
        watch: {
            remoteParams: {
                handler(){
                    if (this.remote) {
                      this.loadRemoteData();
                    }
                },
                deep: true
            },
            defaultSortByField: function (val) {
                this.sortByField = val;
            },
            defaultSortDirection: function (val) {
                this.sortDirection = val;
            }
        },
        mounted() {
            if (this.remote) {
                this.loadRemoteData();
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../../../styles/utilities";
    @import "../../../../../styles/colors";

    .dcxa-responsive-table {
        line-height: normal;

        .tools-container {
            display: flex;
            flex-direction: column;

            .stack-tools {
                width: 100%;
                margin-top: 18px;

                .responsive-table-main-title {
                    margin-bottom: 32px;
                }
            }

            .tool {
                width: 100%;
            }

            .sort-tool {
                display: flex;
                margin-bottom: 16px;

                i {
                    width: 16px;
                    height: 16px;
                    font-size: 14px;
                }

                .sort-btn {
                    display: inline-block;
                    padding: 0;
                    width: 32px;
                }
            }

            .search-tool {
                height: 32px;
                display: flex;
                position: relative;
                align-self: baseline;
                margin-bottom: 16px;

                i {
                    color: tint($black,50);
                    height: 16px;
                    font-size: 16px;
                    font-style: normal;
                    position: absolute;
                    left: 16px;
                    top: 8px;
                }

                input {
                    width: 100%;
                    font-size: 16px;
                    line-height: 24px;
                    border-radius: 3px;
                    border: 1px solid tint($black,20);
                    outline: none;
                    box-shadow: none;
                    padding-left: 40px;
                    padding-right: 13px;

                    &:focus {
                        border-color: $mint;
                        box-shadow: 0 0 8px rgba(0, 167, 225, 0.5);

                        & + i {
                            color: $red !important;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(md) {
        .dcxa-responsive-table {
            .tools-container {
                .main-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-top: 18px;
                    margin-bottom: 32px;

                    &.empty-tools {
                        margin-top: 0;
                        margin-bottom: 0;
                    }

                    .left-column {
                        .dcxa-select-vue {
                            margin-top: 24px;
                            width: 240px;
                        }
                    }

                    .right-column {
                        align-self: flex-end;

                        .search-tool {
                            margin-bottom: 0;
                        }
                    }

                    .tool {
                        width: 280px;
                        min-width: 240px;
                    }
                }
            }
        }
    }


</style>