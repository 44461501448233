<template>
    <div class="dcxa-modal-simple" :id="ids.modal_id">
        <div class="dcxa-modal-header" :id="ids.title_id">
            <i v-if="hasIcon" class="fal helper-icon" :class="icon" aria-hidden="true" />
            <h5 v-if="$screenIs('xs')">{{title}}</h5>
            <h4 v-else>{{title}}</h4>
        </div>

        <div class="dcxa-modal-body" :id="ids.body_id">
            <span v-html="message">{{message}}</span>
        </div>

        <div class="dcxa-modal-footer">
            <dcxa-modal-buttons :buttons="buttons" :modal-context="modalContext" :ids="ids" />
        </div>
    </div>
</template>

<script>
export default {
    name: "SimpleModal",
    props: {
        title: {
            type: String,
            default: () => ''
        },
        message: {
            type: String,
            default: () => ''
        },
        buttons: {
            type: Array,
            default: () => []
        },
        ids: {
            type: Object,
            default: () => {}
        },
        icon: {
            type: String,
            default: () => ''
        }
    },
    computed: {
        modalContext() {
            return this.$parent;
        },
        hasIcon() {
          return this.icon && this.icon.length > 0;
        }
    }
}
</script>

<style scoped lang="scss">
    @import "../../../../../styles/utilities";
    @import "../../../../../styles/colors";

    .dcxa-modal-simple {
        width: 100%;
        padding: 32px;
        text-align: left;

        .dcxa-modal-header {
            .helper-icon {
              font-size: 32px;
              width: 32px;
              height: 32px;
              line-height: 32px;
              margin-bottom: 16px;

              color: $mint;
            }
            h4, h5 {
                margin-bottom: 16px;
            }
        }
    }

    @include breakpoint(md) {
        .dcxa-modal-simple {
            width: 480px;
        }
    }
</style>