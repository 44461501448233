var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return (_vm.$screensFrom("md") || !_vm.responsive) &&
    !_vm.skipRegistration &&
    _vm.columnVisibility
    ? _c(
        "div",
        {
          staticClass: "simple-table-view-cell",
          class: _vm.tableViewClass,
          style: _vm.tableViewCellStyle
        },
        [
          _vm.hasDefaultSlot
            ? _vm._t("default")
            : [
                !_vm.$screenIs("xs")
                  ? [
                      _c(
                        "v-clamp",
                        { attrs: { "max-lines": 3, autoresize: "" } },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.columnValue) +
                              "\n            "
                          )
                        ]
                      )
                    ]
                  : [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.columnValue) +
                          "\n        "
                      )
                    ]
              ]
        ],
        2
      )
    : _vm.columnVisibility
    ? _c(
        "div",
        {
          staticClass: "details-item",
          class: {
            separator: _vm.isSeparator,
            stickToNext: _vm.stickToNext,
            stretchTo: _vm.isStretchToProvided
          },
          style: _vm.tableViewCellStyle
        },
        [
          _c("div", { staticClass: "details-item-title" }, [
            _vm._v(_vm._s(_vm.mobileLabelValue))
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "details-item-value",
              class: _vm.tableViewDetailsClass
            },
            [
              _vm.hasDefaultSlot
                ? _vm._t("default")
                : [
                    _vm._v(
                      "\n            " + _vm._s(_vm.columnValue) + "\n        "
                    )
                  ]
            ],
            2
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-52674b03", { render: render, staticRenderFns: staticRenderFns })
  }
}