<template>
    <div class="dcxa-input" :class="classes" >
        <el-popover ref="popover" :disabled="!popover || !enabledValidationPopover" trigger="focus" placement="bottom" effect="light" popper-class="dcxa-tooltip">
            <slot name="popoverContent" />
        </el-popover>

        <el-input 
            @focus="null"
            v-popover:popover
            :id="id"
            :name="name"
            :type="type"
            ref="input"
            :rows="rows"
            :resize="resize"
            v-bind:value="value"
            v-on:input="updateValue"
            class="floating-label-source"
            :class="{'icon-prefixed': prefixIcon && prefixIcon.length > 0}"
            :prefixIcon="prefixIcon"
            :disabled="disabled" 
            :clearable="clearable"
            :placeholder="placeHolderText"
            :autofocus="autofocus"/> 

        <label class="floating-label-element" :class="{'visible': showFloatingLabel}" v-html="floatingTitleText"></label>
    </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'DcxaInput',
  data() {
    return {
        firstFocused: true
    }
  },
  props: [
      'id',
      'name',
      'type',
      'value',
      'classes',
      'disabled',
      'placeholder',
      'enabledValidationPopover',
      'floatingLabel',
      'floatingTitle',
      'popover',
      'rows',
      'resize',
      'clearable',
      'autofocus',
      'prefixIcon'
  ],
  watch: {
      popover: function(newVal, oldVal) {
          if (newVal && this.firstFocused) {
            this.firstFocused = false;
            const i = this.$refs.input;
            i.blur();
            Vue.nextTick(function() {
                i.focus();
            });
        }
      }
  },
  computed: {
      showFloatingLabel() {
          return this.floatingLabel;// && ((this.value || '') + '').length > 0;
      },
      placeHolderText() {
          const parts = (this.placeholder || '').split('<span');
          return (parts.length > 1) ? parts[0] + '*' : parts[0];
      },
      floatingTitleText() {
          return this.floatingTitle || this.placeholder;
      }
  },
  methods: {
    updateValue: function (value) {
        this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
@import "../../../../styles/_colors.scss";
@import "../../styles/_mixins.scss";

.dcxa-input {
    &.inline-block {
        display: inline-block;
        vertical-align: middle;
    }
}

.dcxa-input {
    @include floating-label();
    &:focus {
        outline: none;
    }
    /////////////////////////
    // Size variants
    /////////////////////////
    &.medium {
        .el-input,
        .el-textarea {
            font-size: 16px;
        }
        .el-input{
            line-height: 40px;
            height: 40px;
            & .el-input__inner{
                height: 40px;
                line-height: 40px;
                padding: 0 0 0 16px;
            }
        }
    }
    &.small {
        .el-input,
        .el-textarea {
            font-size: 16px;
            color: tint($black,80);
        }
        .el-input {
            line-height: 32px;
            height: 32px;
            & .el-input__inner{
                line-height: 32px;
                height: 32px;
                padding: 0 0 0 16px;
            }
        }
    }
    &.compact {
        .el-input,
        .el-textarea {
            font-size: 14px;
        }
        .el-input {
            line-height: 24px;
            height: 24px;
            & .el-input__inner {
                height: 24px;
                line-height: 24px;
                padding: 0 0 0 8px;
            }
        }
    }
    /////////////////////////
    // Validation variants
    /////////////////////////
    &.validation-error {
        .el-input,
        .el-textarea {
            .el-input__inner,
            .el-textarea__inner {
                border-color: $error-red;
                padding-right: 32px;

                &:hover {
                  border-color: $error-red;
                }
                &:focus {
                  border-color: $error-red;
                }
            }
        }
        &.has-validation-icons {
          .el-input,
          .el-textarea {
            &::after {
              font-family: 'Font Awesome 6 Pro';
              font-size: 12px;
              right: 16px;
              color: $error-red;
              content: '\f00d';
              position: absolute;
            }
          }
        }
    }

    &.validation-success {
        .el-input,
        .el-textarea {
            .el-input__inner,
            .el-textarea__inner {
                padding-right: 32px;
            }
        }
        & .el-textarea {
            &::after {
                top: 12px;
            }
        }
        &.has-validation-icons {
          .el-input,
          .el-textarea {
            &::after {
              font-family: 'Font Awesome 6 Pro';
              font-size: 12px;
              right: 16px;
              color: $success-green;
              content: '\f00c';
              position: absolute;
            }
          }
        }
    }

    .el-input {
        line-height: 48px;
        height: 48px;
        & .el-input__inner{
            line-height: 48px;
        }
    }
    .el-textarea {
        & .el-textarea__inner{
            padding: 8px 16px;
        }
    }

    .el-input,
    .el-textarea {
        // General input settings
        border-radius: 3px;
        font-size: 16px;
        & .el-input__inner {
            height: 48px;
            padding: 0 0 0 16px;
        }
        /////////////////////////
        // Utilities
        /////////////////////////
        & {
            border: none;
            &:focus {
                outline: none;
            }
        }
        .el-input__prefix {
            width: 47px;
            height: calc(100% - 2px);
            top: 1px;
            left: 1px;
            border-radius: 3px;
            // border: solid 1px tint($black,10);
            // background-color: tint($black,5);
            border-left: none;
            border-top: none;
            border-bottom: none;
            user-select: none;
            &:hover {
                // border-color: $brilliant-blue;
            }
            &.focus {
                // border-color: $brilliant-blue;
                // box-shadow: 0 0 8px 0 rgba(41, 167, 222, 0.5);
            }
            &.disabled {
                // border-color: tint($data-gray, 20);
                color: tint($black, 30);
                background-color: tint($black, 5);
            }
        }
        &.icon-prefixed {
            .el-input__inner,
            .el-textarea__inner {
                padding-left: 64px;
            }
        }
        /////////////////////////
        // Coloring variants
        /////////////////////////
        & {
            & .el-input__inner,
            & .el-textarea__inner {
                border-color: tint($black, 20);
                &:hover {
                    border-color: $mint;
                }
                &:focus {
                    border-color: $mint;
                    // box-shadow: 0 0 8px 0 rgba(41, 167, 222, 0.5);
                }
                &:disabled {
                    border-color: tint($data-gray, 20);
                    color: tint($data-gray, 60);
                    background-color: tint($data-gray, 5);
                    &::-webkit-input-placeholder {
                        /* Chrome/Opera/Safari */
                        color: tint($black, 30);
                    }
                    &::-moz-placeholder {
                        /* Firefox 19+ */
                        color: tint($black, 30);
                    }
                    &:-ms-input-placeholder {
                        /* IE 10+ */
                        color: tint($black, 30);
                    }
                    &:-moz-placeholder {
                        /* Firefox 18- */
                        color: tint($black, 30);
                    }
                }
                &::-webkit-input-placeholder {
                        /* Chrome/Opera/Safari */
                    color: tint($black, 50);
                }
                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: tint($black, 50);
                }
                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: tint($black, 50);
                }
                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: tint($black, 50);
                }
            }
        }
    }
}


.el-radio__input.is-checked  {
.el-radio__inner {
        border-color: $mint;
        background:  $mint;
    }
   & + .el-radio__label {
        color: tint($black, 80);
    }
}
.el-checkbox__input.is-checked  {
    .el-checkbox__inner {
            border-color: $mint;
            background:  $mint;
        }
       & + .el-checkbox__label {
            color: tint($black, 80);
        }
 }
    

</style>
