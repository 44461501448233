//
//
//
//
//
//
//
//
//
//
//

    export default {
        name: "SimpleTableRow",
        data() {
            return {
                expanded: false
            }
        },
        props: {
            data: {
                type: Object,
                default: () => {}
            },
            rowIdx: {
                type: Number,
                default: () => 0
            },
            alternateColoring: {
                type: Boolean,
                default: () => false
            }
        },
        methods: {
            toggle(expanded) {
                if (typeof expanded == 'undefined') {
                    this.expanded = !this.expanded;
                    this.$emit('expansion-changed', this.expanded);
                    return;
                }

                this.expanded = expanded;
                this.$emit('expansion-changed', this.expanded);
            },
        }
    }
