<template>
    <div class="simple-table-view-cell simple-table-row-expander-cell" :style="cellStyle" v-if="$screensFrom('md') || !responsive">
        <dcxa-text-link @click="toggleRow" :id="`${contextPrefixId}row_${rowIdx}_expander`" :caret="true" :caret-direction="expanderIconTypeClass" caret-position="after"><span /></dcxa-text-link>
    </div>
    <div v-else class="expansion-panel-toggler">
        <dcxa-text-link @click="toggleRow" :id="`${contextPrefixId}row_${rowIdx}_expander`" :caret="true" :caret-direction="expanderIconTypeClass" caret-position="before">Further details</dcxa-text-link>
    </div>
</template>

<script>
    const dcxaCore = require('@dcxa/dcxa-core');
    const { Utilities } = dcxaCore;

    export default {
        name: "SimpleTableRowExpander",
        props: {
            width: {
                type: String,
                default: () =>  'auto'
            },
            minWidth: {
                type: String
            },
            rowIdx: {
                type: Number,
                default: () => 0
            },
            contextPrefixId: {
                type: String,
                default: () => ""
            }
        },
        data() {
            return {
                responsive: true,
                expanded: false
            }
        },
        computed: {
            ownerRow() {
                let parent = this.$parent;

                while (parent && parent.constructor.options.name !== 'SimpleTableRow') {
                    parent = parent.$parent;
                }

                return parent;
            },
            cellStyle() {
                if (this.$screensFrom('md') || !this.responsive) {
                    return {
                        width: this.width,
                        minWidth: this.minWidth ? this.minWidth : this.width
                    };
                }

                return null;
            },
            expanderIconTypeClass() {
                if (this.expanded) {
                    return 'up';
                }

                return 'down';
            }
        },
        methods: {
            toggleRow() {
                if (this.ownerRow) {
                    this.ownerRow.toggle();
                }
            }
        },
        mounted() {
            this.ownerRow.$on('expansion-changed', (expandedValue) => {
                this.expanded = expandedValue;
                this.$emit('rowExpanded', this.ownerRow);
            });
        },
        beforeDestroy() {
            this.ownerRow.$off('expansion-changed');
        }
    }
</script>

<style lang="scss">
    @import "../../../../../styles/utilities";
    @import "../../../../../styles/colors";

    .simple-table-view-cell {
        padding-right: 24px;
        text-align: left;

        &:last-child {
            padding-right: 0;
        }

        &.simple-table-row-expander-cell {
            .text-link {
                font-size: 24px;
            }
        }
    }
</style>