var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-case-card-list" },
    [
      _c("dcxa-case-watched-changing-error-dialog", {
        attrs: {
          "is-watch-dialog": !_vm.isWatchedById(
            _vm.caseIdOfWatchedChangingError
          ),
          visible: _vm.caseIdOfWatchedChangingError !== null,
          "in-progress": _vm.caseIdOfWatchedChangingProgress !== null,
          "case-id": _vm.caseIdOfWatchedChangingError
        },
        on: {
          onCancelDialog: _vm.cancelWatchedChangingErrorDialog,
          onRetryWatching: _vm.retryWatchingCase,
          onRetryUnwatching: _vm.retryUnwatchingCase
        }
      }),
      _vm._v(" "),
      _c("dcxa-responsive-table", {
        ref: "responsiveTable",
        attrs: {
          data: _vm.cases,
          titleId: _vm.titleId,
          title: _vm.title,
          "filter-box-text": "Filter cases",
          "filter-box-id": "i_filter",
          "page-size": _vm.pageSize,
          "show-page-size-options": _vm.showPageSizeOptions,
          "show-sort-dropdown-for-desktop": true,
          "show-pagination": _vm.showPagination,
          "has-error": _vm.hasError,
          "is-loading": _vm.isLoading,
          "special-states": _vm.specialStates,
          "show-tools": _vm.showTools,
          "default-sort-by-field": _vm.defaultSortByField,
          "default-sort-direction": _vm.defaultSortDirection
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var context = ref.context
              return _c("dcxa-card-list", {
                attrs: { context: context, id: _vm.cardListId },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "tools",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          [
                            _vm.isWatchedChangingForCase(row)
                              ? _c("dcxa-loading-spinner", {
                                  staticClass: "iswatched-changing-indicator"
                                })
                              : _vm.isWatchedIconVisible(row)
                              ? _c("i", { staticClass: "fal fa-eye" })
                              : _vm._e()
                          ],
                          _vm._v(" "),
                          _c(
                            "el-dropdown",
                            {
                              attrs: { trigger: "click" },
                              on: { command: _vm.actionCommandHandler },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "dropdown",
                                    fn: function() {
                                      return [
                                        _c(
                                          "el-dropdown-menu",
                                          { staticClass: "actions-dropdown" },
                                          [
                                            _vm._l(_vm.caseActions, function(
                                              action,
                                              i
                                            ) {
                                              return [
                                                _c(
                                                  "el-popover",
                                                  {
                                                    attrs: {
                                                      placement: "left",
                                                      trigger: "hover",
                                                      content:
                                                        action.disabledTooltip,
                                                      disabled: !action.isDisabled(
                                                        row
                                                      )
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          slot: "reference"
                                                        },
                                                        slot: "reference"
                                                      },
                                                      [
                                                        action.isVisible(row)
                                                          ? _c(
                                                              "el-dropdown-item",
                                                              {
                                                                key: i,
                                                                class:
                                                                  action.style,
                                                                attrs: {
                                                                  disabled: action.isDisabled(
                                                                    row
                                                                  ),
                                                                  command: {
                                                                    handler:
                                                                      action.clickHandler,
                                                                    context: row
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("i", {
                                                                  class:
                                                                    action.icon,
                                                                  attrs: {
                                                                    "aria-hidden":
                                                                      "true"
                                                                  }
                                                                }),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    attrs: {
                                                                      "data-dd-privacy":
                                                                        "mask-user-input"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        action.title
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ]
                                            })
                                          ],
                                          2
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c("div", { staticClass: "el-dropdown-link" }, [
                                _c("i", {
                                  staticClass: "dcxa-fa fal fa-ellipsis-h",
                                  attrs: { "aria-hidden": "true" }
                                })
                              ])
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "title",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c(
                            "dcxa-text-link",
                            {
                              staticClass: "large",
                              attrs: {
                                useCustomHandler: true,
                                secondaryTarget: _vm.getCaseTargetUrl(row)
                              },
                              on: {
                                click: function($event) {
                                  return _vm.goToCase(row)
                                }
                              }
                            },
                            [
                              _c(
                                "v-clamp",
                                { attrs: { "max-lines": 3, autoresize: "" } },
                                [
                                  _c("template", { slot: "before" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "card-list-title-first-part",
                                        attrs: {
                                          "data-dd-privacy": "mask-user-input"
                                        }
                                      },
                                      [_vm._v("#" + _vm._s(row.CaseNumber))]
                                    ),
                                    _c("span", { staticClass: "dcxa-pipe" })
                                  ]),
                                  _vm._v(
                                    _vm._s(row.Subject) +
                                      "\n                    "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "metadata",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("dcxa-card-metadata", {
                            attrs: {
                              prop: "CaseNumber",
                              label: "Case #",
                              visible: false
                            }
                          }),
                          _vm._v(" "),
                          _c("dcxa-card-metadata", {
                            attrs: {
                              prop: "Subject",
                              label: "Subject",
                              visible: false
                            }
                          }),
                          _vm._v(" "),
                          _vm._t("default", null, { row: row })
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0ebd2236", { render: render, staticRenderFns: staticRenderFns })
  }
}