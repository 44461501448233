var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dcxa-inline-property-editor-section" }, [
    _c("div", [_vm._t("default", null, { sectionContext: this })], 2),
    _vm._v(" "),
    !_vm.disableLocalEditing
      ? _c(
          "div",
          { staticClass: "local-property-section-editor-controls" },
          [
            _c(
              "dcxa-button",
              {
                staticClass: "medium local-editor-cancel-button",
                attrs: { type: "secondary", disabled: _vm.saving },
                on: { click: _vm.onCancel }
              },
              [_vm._v("Cancel")]
            ),
            _vm._v(" "),
            _c(
              "dcxa-button",
              {
                staticClass: "medium local-editor-save-button",
                class: { loading: _vm.saving },
                attrs: {
                  type: "primary",
                  disabled: !_vm.isValid || !_vm.changed
                },
                on: { click: _vm.onSave }
              },
              [_vm._v("Save")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-51e04641", { render: render, staticRenderFns: staticRenderFns })
  }
}