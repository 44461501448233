<template>
    <div class="dcxa-table" :class="{'enable-hover-effect': enableHoverEffect}">
        <el-table 
            @sort-change="shortChanged"
            :data="currentPageData" 
            @row-click="rowClickHandler"
            :highlight-current-row="false"
            header-cell-class-name="dcxa-header-cell"
            header-row-class-name="dcxa-header-row"
            row-class-name="dcxa-row"
            :empty-text="emptyText">
            <slot name="table-columns" />
        </el-table>
        <div class="dcxa-pagination" v-if="data.length > 0 && pagination">
            <div class="page-info">
                {{(currentPage - 1) * pageSize + 1}} - {{Math.min(currentPage * pageSize, data.length)}} of {{ data.length }}
            </div>

            <el-pagination
                @size-change="handlePageSizeChange"
                @current-change="handlePageChange"
                :current-page.sync="currentPage"
                :page-size.sync="pageSize"
                :page-sizes="[10, 20, 30, 40, 50]"
                :layout="data.length > pageSize ? 'prev, pager, next, sizes' :  'sizes'"
                :total="data.length">
            </el-pagination>

        </div>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'Table',
    props: {
        data: {
            type: Object
        },
        pageSize: {
            type: Number,
            default: 10
        },
        pagination: {
            type: Boolean,
            default: true
        },
        rowClickHandler: {
            default: (row, event, col) => { }
        },
        enableHoverEffect: {
            type: Boolean,
            default: false
        },
        emptyText: {
            type: String,
            default: ''
        }
    },
    computed: {
        sortedData() {
            if (!this.sortOn || !this.order){
                return this.data;
            }
            const asc = _.sortBy(this.data, r => ('' + r[this.sortOn]).toUpperCase());
            return (this.order == 'ascending') ? asc : _.reverse(asc);
        },
        pages() {
            return _.chunk(this.sortedData, this.pageSize);
        },
        currentPageData() {
            return this.pages[this.currentPage - 1];
        }
    },
    data() {
        return {
            sortOn: null,
            order: null,
            currentPage: 1,
        }
    },
    methods: {
        shortChanged({column, prop, order}) {
            this.sortOn = prop;
            this.order = order;
            console.log(arguments);
            this.$emit('shortChanged', {prop, order})
        },
        handlePageSizeChange() {

        },
        handlePageChange() {
            
        }
    },
    watch: {
        // whenever data changes, this function will run
        data: function () {
            this.currentPage = 1;
        }
    },
    mounted() {
        
    }
}
</script>

<style lang="scss">

@import "../../../../styles/_colors.scss";

.dcxa-table {
    
    .el-table {
        background: transparent;    

        &::before {
            display: none;
        }

        .el-table__body-wrapper {
            table {
                border-collapse: separate;
                border-spacing: 0 4px;
            }
        }

        .dcxa-header-row {
            background: transparent;
        }
        .dcxa-header-cell {
            background: transparent;
            border: none;
            text-align: left;
            font-size: 16px;
            color: $slate;
            padding-left: 12px;
            padding-right: 12px;
            .cell {
                padding: 0;
                word-break: break-word;
            }
        }
        .dcxa-row {
            background: $white;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
            border: 1px solid transparent;


            font-size: 14px;
            color: $slate;
            height: 56px;
            line-height: 1.12;

            td {
                padding: 0 12px 0 12px;
                border: 1px solid transparent;
                .cell {
                    padding: 0;
                    word-break: break-word;
                    // text-overflow: ellipsis;
                    // white-space: nowrap;
                    // overflow: hidden;

                }

                &:first-child {
                    border-radius: 3px 0 0 3px;
                }
                &:last-child {
                    border-radius: 0 3px 3px 0;
                }
            }

            &:hover {
                background: $white;
                td {
                    background: $white;
                }
            }
        }

    }

    .dcxa-pagination {
        display: flex;
        justify-content: space-between;
        margin: 16px 0 0 0;

        .page-info {
            font-size: 12px;
            color: $slate;
            padding: 0 16px;
            line-height: 28px;
        }

        .el-pagination {
            padding: 0 16px;
            & > button {
                background: transparent;
                color: $mint;
            }
            & > .el-pager > li {
                background: transparent;

                &.active {
                    background: $mint;
                    border-radius: 3px;
                    color: $white;
                }
            }
        }
    }

    &.enable-hover-effect {
        .el-table {
            .dcxa-row {
                cursor: pointer;
                &:hover {
                    background: red;
                    td {
                        background: tint($black,5);
                    }
                }
            }
        }
    }
}




</style>
