var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "dcxaTabs", staticClass: "dcxa-tabs" },
    [
      _c("div", { staticClass: "right-tab-overlay" }),
      _vm._v(" "),
      _c("div", { staticClass: "left-tab-overlay" }),
      _vm._v(" "),
      _vm._l(_vm.tabs, function(tab) {
        return _c(
          "div",
          {
            key: tab.id,
            ref: "tab" + tab.id,
            refInFor: true,
            staticClass: "filter-tab",
            class: { active: _vm.activeTabId == tab.id },
            attrs: { id: tab.tabBtnId },
            on: {
              click: function($event) {
                return _vm.changeActiveTab(tab)
              }
            }
          },
          [
            _vm._t(
              "tab-title",
              function() {
                return [
                  _vm._v("\n            " + _vm._s(tab.title) + "\n        ")
                ]
              },
              { context: tab }
            )
          ],
          2
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-64c8bfa6", { render: render, staticRenderFns: staticRenderFns })
  }
}