var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dcxa-modal-dialog",
    {
      staticClass: "dcxa-case-watched-changing-error-dialog",
      attrs: { type: "content", visible: _vm.visible, "close-button": true },
      on: { onCloseDialog: _vm.watchedChangingCaseErrorDialogCancelAction }
    },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "dialog-head" }, [
          _c("i", {
            staticClass: "far fa-exclamation-triangle dialog-header-icon"
          }),
          _vm._v(" "),
          _c("h4", [
            _vm._v("Failed to " + _vm._s(_vm.dialogTypeTitle) + " Case")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          { staticClass: "subtitle" },
          [
            _vm._v("Please try again. "),
            _c(
              "dcxa-text-link",
              { attrs: { "secondary-target": _vm.contactUsLink } },
              [_vm._v("Contact us")]
            ),
            _vm._v(" or "),
            _c(
              "dcxa-text-link",
              {
                attrs: {
                  "secondary-target": "https://my.cloudera.com/faq.html#support"
                }
              },
              [_vm._v("call us")]
            ),
            _vm._v(" if the problem persists.")
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "dcxa-button",
            {
              staticClass: "medium",
              attrs: { type: "secondary", disabled: _vm.inProgress },
              on: { click: _vm.watchedChangingCaseErrorDialogCancelAction }
            },
            [_vm._v("Cancel")]
          ),
          _vm._v(" "),
          _c(
            "dcxa-button",
            {
              staticClass: "medium",
              class: { loading: _vm.inProgress },
              attrs: { type: "primary" },
              on: { click: _vm.watchedChangingCaseErrorDialogSubmitAction }
            },
            [_vm._v("Retry")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2b22911d", { render: render, staticRenderFns: staticRenderFns })
  }
}