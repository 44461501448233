<template>
    <div class="simple-table-view-cell" :class="tableViewClass" :style="tableViewCellStyle" v-if="($screensFrom('md') || !responsive) && !skipRegistration && columnVisibility">
        <slot v-if="hasDefaultSlot" />
        <template v-else>
            <template v-if="!$screenIs('xs')">
                <v-clamp :max-lines="3" autoresize="">
                    {{columnValue}}
                </v-clamp>
            </template>
            <template v-else>
                {{columnValue}}
            </template>
        </template>
    </div>
    <div class="details-item" :class="{'separator': isSeparator, 'stickToNext': stickToNext, 'stretchTo': isStretchToProvided}" :style="tableViewCellStyle" v-else-if="columnVisibility">
        <div class="details-item-title">{{mobileLabelValue}}</div>
        <div class="details-item-value" :class="tableViewDetailsClass">
            <slot v-if="hasDefaultSlot" />
            <template v-else>
                {{columnValue}}
            </template>
        </div>
    </div>
</template>

<script>
    import VClamp from 'vue-clamp';

    const dcxaCore = require('@dcxa/dcxa-core');
    const { Utilities } = dcxaCore;


    export default {
        name: "ResponsiveTableColumn",
        components: {
            VClamp
        },
        props: {
            prop: {
                type: String,
                default: () =>  ''
            },
            label: {
                type: String,
                default: () =>  ''
            },
            mobileLabel: {
                type: String,
                default: () => ''
            },
            fillAvailableSpace: {
                type: Boolean,
                default: () => false
            },
            width: {
                type: String,
                default: () =>  'auto'
            },
            minWidth: {
                type: String
            },
            sortable: {
                type: Boolean,
                default: () => true
            },
            filterable: {
                type: Boolean,
                default: () => true
            },
            toolContainer: {
                type: Boolean,
                default: () => false
            },
            type: {
                type: String,
                default: () => 'String'
            },
            customField: {
                type: Boolean,
                default: () => false
            },
            skipRegistration: {
                type: Boolean,
                default: () => false
            },
            isSeparator: {
                type: Boolean,
                default: () => false
            },
            stickToNext: {
                type: Boolean,
                default: () => false
            },
            stretchTo: {
                type: String,
                default: () => ''
            },
            hideOnXs: {
                type: Boolean,
                default: () => false
            },
            hideOnMd: {
                type: Boolean,
                default: () => false
            },
            hideOnLg: {
                type: Boolean,
                default: () => false
            },
            hideOnXl: {
                type: Boolean,
                default: () => false
            }
        },
        data() {
            return {
                responsive: true
            }
        },
        computed: {
            hasDefaultSlot() {
                return this.$slots.default;
            },
            ownerTable() {
                let parent = this.$parent;

                while (parent && parent.constructor.options.name !== 'DcxaResponsiveTable') {
                    parent = parent.$parent;
                }

                return parent;
            },
            ownerSimpleTable() {
              let parent = this.$parent;

              while (parent && parent.constructor.options.name !== 'DcxaSimpleTable') {
                parent = parent.$parent;
              }

              return parent;
            },
            ownerRow() {
                let parent = this.$parent;

                while (parent && parent.constructor.options.name !== 'SimpleTableRow') {
                    parent = parent.$parent;
                }

                return parent;
            },
            tableViewClass() {
                let cellClass = {};
                cellClass[this.ownerSimpleTable.contextPrefixId + 'row_' + this.ownerRow.rowIdx + '_' + this.prop] = true;

                return cellClass;
            },
            tableViewDetailsClass() {
              let cellClass = {
                'tool-container': this.toolContainer
              };
              cellClass[this.ownerSimpleTable.contextPrefixId + 'row_' + this.ownerRow.rowIdx + '_' + this.prop] = true;

              return cellClass;
            },
            tableViewCellStyle() {
                let cellStyleObject = this.cellStyle;
                if (!cellStyleObject) {
                    return {
                        '--stretch-to-value': (this.isStretchToProvided ? this.stretchTo : '0px'),
                        wordBreak: 'break-all'
                    };
                }

                cellStyleObject.wordBreak = 'break-all';
                return cellStyleObject;
            },
            cellStyle() {
                if (this.$screensFrom('md') || !this.responsive) {
                    let styleObj = {
                        '--stretch-to-value': (this.isStretchToProvided ? this.stretchTo : '0px')
                    };

                    if (this.fillAvailableSpace === true) {
                        styleObj['width'] = ['-webkit-fill-available', '-moz-available', 'fill-available', '100%'];
                    }
                    else {
                        styleObj['width'] = this.width;
                        styleObj['minWidth'] = this.minWidth ? this.minWidth : this.width;
                    }

                    return styleObj;
                }

                return null;
            },
            columnValue() {
                return Utilities.object.getDeepValue(this.ownerRow.data, this.prop) || "";
            },
            isStretchToProvided() {
                return this.stretchTo !== '';
            },
            mobileLabelValue() {
                if (this.$screenIs('xs') && this.mobileLabel !== '') {
                    return this.mobileLabel;
                }
                return this.label;
            },
            columnVisibility() {
                let currentScreenSize = this.$mq;

                if (currentScreenSize === 'xs') {
                    return !this.hideOnXs;
                }
                else if (currentScreenSize === 'md' || currentScreenSize === 'lg') {
                    return !this.hideOnMd;
                }
                else if (currentScreenSize === 'xl') {
                    return !this.hideOnXl;
                }
                else {
                    return true;
                }
            },
            columnDefinition() {
                return {
                    label: this.label,
                    prop: this.prop,
                    cellStyle: this.cellStyle,
                    sortable: this.sortable,
                    filterable: this.filterable,
                    toolContainer: this.toolContainer,
                    type: this.type,
                    customField: this.customField,
                    visible: this.columnVisibility
                };
            }
        },
        watch: {
            width: function() {
                if (!this.skipRegistration) {
                    this.ownerTable.updateColumn(this.columnDefinition);
                }
            },
            '$mq': function() {
                if (!this.skipRegistration) {
                    this.ownerTable.updateColumn(this.columnDefinition);
                }
            }
        },
        mounted() {
            if (this.ownerTable && !this.skipRegistration) {
                this.responsive = this.ownerTable.responsive;
                this.ownerTable.insertColumn(this.columnDefinition);
            }
        }
    }
</script>

<style lang="scss">
    @import "../../../../../styles/utilities";
    @import "../../../../../styles/colors";

    $stretchTo: var(--stretch-to-value);

    .details-item {
        display: flex;
        margin-bottom: 8px;
        margin-left: 16px;
        margin-right: 16px;
        font-size: 14px;

        &.separator {
            margin-bottom: 36px;
        }

        &.stickToNext {
            margin-bottom: 0;
        }

        .details-item-title {
            width: 38%;
            padding-right: 16px;
            color: tint($black,50);

            .selectable-row.selected & {
                color: $white;
            }
        }

        .details-item-value {
            width: 62%;

            &.tool-container {
                text-align: right;
            }
        }
    }

    .simple-table-view-cell {
        padding-right: 24px;
        text-align: left;

        &:last-child {
            padding-right: 0;
        }
    }

    .item-value:nth-child(1) {
        font-size: 22px;
        margin-bottom: 15px;
    }

    .item-value:nth-child(2) {
        font-size: 16px;
        margin-bottom: 13px;
    }

    @include breakpoint(md) {
        .details-item {
            margin-left: 0;
            margin-right: 0;

            .details-item-title {
                width: 144px;
                min-width: 144px;
            }

            .details-item-value {
                width: auto;
            }
        }

        .simple-table-view-cell {
            .compact-desktop-row & {
                padding-right: 14px;
            }
        }
    }

    .stretchTo {
        .details-item-title {
            width: calc(100% - #{$stretchTo});
        }

        .details-item-value {
            width: $stretchTo;
        }
    }
</style>