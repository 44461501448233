var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isMetadataVisible
    ? _c(
        "div",
        {
          directives: [
            {
              name: "popover",
              rawName: "v-popover:metadataPopover",
              arg: "metadataPopover"
            }
          ],
          staticClass: "dcxa-card-metadata"
        },
        [
          _c(
            "el-popover",
            {
              attrs: {
                placement: _vm.tooltipPosition,
                trigger: "hover",
                "popper-class": "metadata-popover dcxa-tooltip",
                disabled: _vm.disabledTooltip
              }
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.label))]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dcxa-card-metadata-with-icon",
                  attrs: { slot: "reference" },
                  slot: "reference"
                },
                [
                  _vm.hasIcon
                    ? _c("div", { staticClass: "dcxa-card-metadata-icon" }, [
                        _c("i", {
                          staticClass: "dcxa-fa",
                          class: _vm.classesToApply,
                          attrs: { "aria-hidden": "true" }
                        })
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "dcxa-card-metadata-content" },
                    [
                      _vm.hasDefaultSlot
                        ? _vm._t("default")
                        : [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.ownerRow.data[_vm.prop]) +
                                "\n            "
                            )
                          ]
                    ],
                    2
                  )
                ]
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4b527dc8", { render: render, staticRenderFns: staticRenderFns })
  }
}