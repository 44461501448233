var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "barChartRoot", staticClass: "dcxa-bar-chart" }, [
    _c("div", { ref: "barChartRoot", staticClass: "dcxa-bar-chart-root" })
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-78aa443e", { render: render, staticRenderFns: staticRenderFns })
  }
}