<template>
    <div class="dcxa-simple-table" :class="{'compact-desktop-row': compactDesktopRow}">
        <dcxa-content-loader v-if="context.isLoading" type="SimpleTable" :number-of-columns="numberOfLoadingColumns" :number-of-rows="numberOfLoadingRows" :title="context.title" :titleSize="titleSize"></dcxa-content-loader>
        <div v-else-if="$screensFrom('md') || !responsive">
            <div class="simple-table-view-header" data-dd-privacy="mask-user-input">
                <div class="simple-table-view-column-header" :style="{ width: rowExpanderColumnWidth }" v-if="hasExpansionPanel" />
                <div v-for="(col, idx) in context.columns" :key="idx" v-if="col.visible" class="simple-table-view-column-header" :class="{'wrap': wrapColumnHeaders}" :style="getTableViewHeaderStyle(col)">
                    {{col.label}}<span><i class="sort-caret" :class="getSortIndicator(col)" @click="sortBy(col.prop)" v-if="col.sortable"></i></span>
                </div>
            </div>
            <div v-for="(row, idx) in context.data" :key="idx" class="simple-table-view-row-container">
                <simple-table-row class="simple-table-view-row" :ref="`simple_table_row_${idx}`" :data="row" :row-idx="idx" :alternate-coloring="alternateColoring" :class="rowSelectionClasses(row)" v-on:click.exact="onRowClick">
                    <template slot="columns" slot-scope="{row}">
                        <dcxa-simple-table-row-expander :context-prefix-id="contextPrefixId" :row-idx="idx" :width="rowExpanderColumnWidth" v-if="hasExpansionPanel" @rowExpanded="rowExpanded"/>
                        <slot name="columns" :row="row" />
                    </template>
                    <template slot="expansion-panel" slot-scope="{row}">
                        <slot name="expansion-panel" :row="row" />
                    </template>
                </simple-table-row>
                <div class="row-message" :class="rowMessageType(row)" v-if="hasRowMessage(row)">
                    {{rowMessage(row)}}
                </div>
            </div>
        </div>
        <div v-else>
            <div v-for="(row, idx) in context.data" :key="idx" class="simple-card-view-row-container">
                <div :class="rowSelectionClasses(row)" v-on:click.exact="onSelectRow(row, $event)">
                    <simple-table-row :data="row" :ref="`simple_table_row_${idx}`" :row-idx="idx" :alternate-coloring="alternateColoring" class="simple-card-view-row details">
                        <template slot="columns" slot-scope="{row}">
                            <slot name="columns" :row="row" />
                            <dcxa-simple-table-row-expander :context-prefix-id="contextPrefixId" :row-idx="idx" v-if="hasExpansionPanel" @rowExpanded="rowExpanded"/>
                        </template>
                        <template slot="expansion-panel" slot-scope="{row}">
                            <slot name="expansion-panel" :row="row" />
                        </template>
                    </simple-table-row>
                </div>
                <div class="row-message" :class="rowMessageType(row)" v-if="hasRowMessage(row)">
                    {{rowMessage(row)}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import SimpleTableRow from  './SimpleTableRow.vue';

    export default {
        name: "DcxaSimpleTable",
        components: {
            'simple-table-row': SimpleTableRow
        },
        props: {
            contextPrefixId: {
                type: String,
                default: () => ""
            },
            rowSelection: {
                type: Boolean,
                default: () => true
            },
            context: {
                type: Object,
                default: () => {}
            },
            numberOfLoadingColumns: {
                type: Number,
                default: () => 5
            },
            numberOfLoadingRows: {
                type: Number,
                default: () => 10
            },
            titleSize: {
                type: String,
                default: () => undefined
            },
            customRowStyle: {
                type: Function,
                default: () => { return {}; }
            },
            rowMessageHandlers: {
                type: Object,
                default: () => null
            },
            wrapColumnHeaders: {
                type: Boolean,
                default: () => true
            },
            compactDesktopRow: {
                type: Boolean,
                default: () => false
            },
            rowExpander: {
                type: Boolean,
                default: () => false
            },
            rowExpanderColumnWidth: {
                type: Number,
                default: () => '24px'
            },
            alternateColoring: {
                type: Boolean,
                default: () => false
            },
            responsive: {
                type: Boolean,
                default: () => true
            }
        },
        data() {
            return {
                selectedRow: null
            }
        },
        computed: {
            rowSelectionClasses() {
                return row => {
                    const customStyle = this.customRowStyle(row);
                    const defaultStyles = {
                        'selectable-row': this.rowSelection,
                        'selected': row === this.selectedRow
                    };
                    return {
                        ...customStyle,
                        ...defaultStyles
                    };
                };
            },
            ownerResponsiveTable() {
                let parent = this.$parent;

                while (parent && parent.constructor.options.name !== 'DcxaResponsiveTable') {
                    parent = parent.$parent;
                }

                return parent;
            },
            getSortIndicator() {
                return col => {
                    if (col.prop === this.context.sortByField) {
                        return this.context.sortDirection === 'asc' ? 'fas fa-caret-up' : 'fas fa-caret-down';
                    }
                    return 'far fa-sort';
                };
            },
            getTableViewHeaderStyle() {
                return col => {
                    let columnHeaderStyleObject = col.cellStyle;
                    if (columnHeaderStyleObject) {
                        columnHeaderStyleObject.wordBreak = 'normal';
                        return columnHeaderStyleObject;
                    }

                    return null;
                }
            },
            hasRowMessage(row) {
                return  row => {
                    if (this.rowMessageHandlers && this.rowMessageHandlers.hasMessage) {
                        return this.rowMessageHandlers.hasMessage(row)
                    }
                };
            },
            rowMessage(row) {
                return  row => {
                    if (this.rowMessageHandlers && this.rowMessageHandlers.message) {
                        return this.rowMessageHandlers.message(row)
                    }
                };
            },
            rowMessageType(row) {
                return  row => {
                    if (this.rowMessageHandlers && this.rowMessageHandlers.messageType) {
                        return this.rowMessageHandlers.messageType(row)
                    }
                };
            },
            hasExpansionPanel() {
                return this.rowExpander === true && this.$scopedSlots.hasOwnProperty('expansion-panel');
            }
        },
        methods: {
            clear() {
                this.selectedRow = null;
            },
            sortBy(columnName) {
                if (this.ownerResponsiveTable) {
                    this.ownerResponsiveTable.sortBy(columnName);
                }
            },
            onRowClick({row, event}) {
                this.onSelectRow(row, event);
            },
            rowExpanded(row) {
                this.$emit('rowExpanded', row);
            },
            updateSelection(idProp, idValue, suppressChangedEvent) {
                const rowToSelect = _.find(this.context.data, r => r[idProp] == idValue);
                this.selectedRow = rowToSelect;

                if (!suppressChangedEvent) {
                    this.$emit('selectionChanged', this.selectedRow);
                }

            },
            onSelectRow(row) {
                if (this.rowSelection) {
                    this.selectedRow = this.selectedRow == row ? null : row;
                    this.$emit('selectionChanged', this.selectedRow);
                }
            },
            reset() {
                for (let i = 0; i < this.context.data.length; i++) {
                    let currentSimpleTableRowInstance = this.$refs['simple_table_row_' + i];
                    if (currentSimpleTableRowInstance && currentSimpleTableRowInstance.length === 1) {
                        currentSimpleTableRowInstance[0].toggle(false);
                    }
                }
            }
        },
        mounted() {
            if (this.ownerResponsiveTable) {
                this.responsive = this.ownerResponsiveTable.responsive;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../../../styles/utilities";
    @import "../../../../../styles/colors";

    .simple-table-view-header {
        display: flex;
        justify-content: space-between;
        padding: 0 16px 16px 16px;

        .compact-desktop-row & {
            padding: 0 8px 16px 8px;
        }

        .simple-table-view-column-header {
            padding-right: 24px;
            font-size: 14px;
            font-weight: normal;
            color: $black;
            white-space: nowrap;
            word-break: normal !important;

            .compact-desktop-row & {
                padding-right: 14px;
            }

            &:last-child {
                padding-right: 0;
            }

            .sort-caret {
                cursor: pointer;
                color: $mint;
            }

            &.wrap {
                text-align: left;
                word-wrap: normal;
                white-space: normal !important;
                display: flex;
                align-items: center;
                justify-content: left;

                .sort-caret {
                    margin-left: 6px;
                }
            }
        }
    }

    .simple-table-view-row-container {
        margin-bottom: 4px;
        text-align: left;
    }

    .simple-card-view-row-container {
        margin-bottom: 9px;
    }

    .simple-table-view-row-container,
    .simple-card-view-row-container {
        .row-message {
            font-size: 12px;

            &.error {
                color: #de1502;

            }
            &.warning {
                color: #f96702;
            }
        }
    }


    .selectable-row {
        cursor: pointer;

        &:hover {
            background: tint($mint, 10) !important;
        }

        .card {
            &:hover {
                background: tint($mint, 10) !important;
            }
        }

        &.selected {
            background: tint($mint, 100) !important;
            color: $white !important;
            font-weight: bold !important;

            &:hover {
                background: tint($mint, 70) !important;
            }

            .card {
                background: tint($mint, 100) !important;

                &:hover {
                    background: tint($mint, 70) !important;
                }
            }

            .dcxa-table-cell-icon i {
                color: $white !important;
            }

            .dcxa-fa {
                .selectable-row.selected & {
                    color: $white !important;
                }
            }
        }
    }
</style>