<template>
  <div class="dcxa-text-label">
    <template v-if="clamp">
      <el-popover placement="bottom" trigger="hover" :disabled="!textIsClamped" popper-class="dcxa-tooltip">
        <slot>Tooltip</slot>
        <v-clamp slot="reference" :max-lines="lines" autoresize v-on:clampchange="onClampChange">
          <slot>Text</slot>
        </v-clamp>
      </el-popover>
    </template>
    <slot v-else>Text</slot>
  </div>
</template>

<script>
import VClamp from 'vue-clamp';

const dcxaCore = require('@dcxa/dcxa-core');
const { EDL  } = dcxaCore;


export default {
  name: 'TextLabel',
  components: {
    VClamp
  },
  data(){
    return {
      textIsClamped: false
    }
  },
  props: {
    clamp: {
      type: Boolean,
      default: () => false
    },
    lines: {
      type: Number,
      default: () => 3
    }
  },
  methods: {
    onClampChange(clamped){
      this.textIsClamped = clamped;
    }
  }
}
</script>

<style lang="scss">

</style>
