<template>
    <div class="dcxa-special-state-container">
        <dcxa-content-title :showTitle="showTitle" :title="title" :titleSize="titleSize"></dcxa-content-title>
        <div class="special-state-container">
            <i :class="icon" aria-hidden="true"></i>
            <template>
                <slot v-if="hasDefaultSlot" />
                <span v-else v-html="text" />
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SpecialStateContainer",
        props: {
            icon: {
                type: String
            },
            text: {
                type: String
            },
            title: {
                type: String,
                default: () => ''
            },
            titleSize: {
                type: String,
                default: () => undefined
            },
            showTitle: {
                type: Boolean,
                default: () => true
            }
        },
        computed: {
            hasDefaultSlot() {
                return this.$slots.default;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../../styles/utilities";
    @import "../../../../styles/colors";

    .dcxa-special-state-container {

        .dcxa-content-title {
            margin-top: 18px;
        }

        .special-state-container {
            margin-top: 18px;
            height: 200px;
            width: 100%;
            border-radius: 3px;
            background-color: tint($black, 10);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 0 16px 16px 16px;

            i {
                color: tint($black, 30);
                margin-bottom: 9px;
                height: 40px;
                font-size: 36px;
                font-style: normal;
            }

            span {
                font-size: 16px;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: center;
                color: tint($black, 80);
            }
        }
    }
</style>