var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "success-modal-body" }, [
    _c("h4", [_vm._v(_vm._s(_vm.message))]),
    _vm._v(" "),
    _c("i", { staticClass: "fal fa-check", attrs: { "aria-hidden": "true" } })
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-35de7076", { render: render, staticRenderFns: staticRenderFns })
  }
}