var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-switch", {
    staticClass: "dcxa-switch",
    attrs: {
      id: _vm.id,
      name: _vm.name,
      disabled: _vm.disabled,
      value: _vm.value
    },
    on: { change: _vm.updateValue }
  })
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f66cd748", { render: render, staticRenderFns: staticRenderFns })
  }
}