<template>
    <div class="dcxa-badge" :id="id">
        <el-popover placement="top" trigger="hover" :content="tooltip" :disabled="!hasTooltip" popper-class="dcxa-tooltip">
            <div slot="reference">
                <div class="container" :class="[colorType, {'compact' : isCompact}]">
                    <template v-if="!hideCounter">{{counter}}</template><template v-if="!hideCounter && isLabelVisible">&nbsp;</template><template v-if="isLabelVisible">{{label}}</template>
                </div>
            </div>
        </el-popover>
    </div>
</template>

<script>
    export default {
        name: "DcxaBadge",
        props: {
            id: {
                type: String,
                default: ''
            },
            counter: {
                type: Number,
                default: () => 0
            },
            hideCounter: {
                type: Boolean,
                default: () => false
            },
            label: {
                type: String,
                default: () => ''
            },
            colorType: {
                type: String,
                default: () => '',
                validator: function (value) {
                    return ['',
                        'ACTIVE_ASSET',
                        'DECOMMISSIONED_ASSET',
                        'NEW_VALIDATION',
                        'UNREVIEWED_VALIDATION',
                        'REVIEWED_VALIDATION',
                        'CRITICAL',
                        'ERROR',
                        'WARN',
                        'CURIOSITY',
                        'INFO',
                        'NATIVE_ACCOUNT_TYPE',
                        'INDIVIDUAL_ACCOUNT_TYPE',
                        'ACCOUNT_ACCOUNT_TYPE'
                    ].indexOf(value) !== -1
                }
            },
            isCompact: {
                type: Boolean,
                default: () => false
            },
            tooltip: {
                type: String,
                default: () => ''
            },
            shrinkOnMobile: {
                type: Boolean,
                default: () => true
            }
        },
        computed: {
            isLabelVisible() {
                return !(this.$screenIs('xs') && this.shrinkOnMobile === true && this.hideCounter === false);
            },
            hasTooltip() {
                return this.tooltip !== '';
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../../../styles/_colors.scss";
    @import "../../../../styles/_utilities.scss";

    .dcxa-badge {
        margin: 0 8px 0 0;
        display: inline-block;
        font-weight: bold;
        line-height: 0.75;
        text-transform: uppercase;
        white-space: nowrap;

        &:last-child {
          margin: 0;
        }

        .container {
            padding: 7px 12px;
            border-radius: 12.5px;
            font-size: 12px;

            &.compact {
                border-radius: 8.5px;
                padding: 5px 7px;
                font-size: 10px;
            }

            &.ACTIVE_ASSET {
                background-color: $white;
                color: $green;
            }

            &.DECOMMISSIONED_ASSET {
                background-color: $white;
                color: tint($black, 50);
            }

            &.NEW_VALIDATION {
                background-color: $green;
                color: $white;
            }

            &.UNREVIEWED_VALIDATION {
                background-color: tint($black, 50);
                color: $white;
            }

            &.REVIEWED_VALIDATION {
                background-color: tint($black, 10);
                color: tint($black, 60);
            }

            &.CRITICAL {
                background-color: $red-extralight;
                color: $red;
            }

            &.ERROR {
                background-color: tint($bright-orange, 20);
                color: $dark-orange;
            }

            &.WARN {
                background-color: #ffecb3;
                color: #cc8800;
            }

            &.CURIOSITY {
                background-color: tint($mint, 20);
                color: $mint;
            }

            &.INFO {
                background-color: $blue-extralight;
                color: $blue;
            }

            &.NATIVE_ACCOUNT_TYPE {
              background-color: $green-extralight;
              color: $green;
            }

            &.INDIVIDUAL_ACCOUNT_TYPE {
              background-color: $blue-extralight;
              color: $blue;
            }

            &.ACCOUNT_ACCOUNT_TYPE {
              background-color: #ffecb3;
              color: #cc8800;
            }
        }
    }

</style>