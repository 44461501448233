(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@dcxa/dcxa-core"), require("lodash"), require("dcxa_moment"), require("dcxa_vue"), require("dcxa_vueRouter"), require("dcxa_vuex"));
	else if(typeof define === 'function' && define.amd)
		define(["@dcxa/dcxa-core", "lodash", "dcxa_moment", "dcxa_vue", "dcxa_vueRouter", "dcxa_vuex"], factory);
	else if(typeof exports === 'object')
		exports["dcxaVueLibrary"] = factory(require("@dcxa/dcxa-core"), require("lodash"), require("dcxa_moment"), require("dcxa_vue"), require("dcxa_vueRouter"), require("dcxa_vuex"));
	else
		root["dcxaVueLibrary"] = factory(root["dcxaCore"], root["_"], root["dcxa_moment"], root["dcxa_vue"], root["dcxa_vueRouter"], root["dcxa_vuex"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__dcxa_dcxa_core__, __WEBPACK_EXTERNAL_MODULE_lodash__, __WEBPACK_EXTERNAL_MODULE_moment__, __WEBPACK_EXTERNAL_MODULE_vue__, __WEBPACK_EXTERNAL_MODULE_vue_router__, __WEBPACK_EXTERNAL_MODULE_vuex__) {
return 