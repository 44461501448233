<template>
    <div>
        <dcxa-button type="primary" class="small"
                v-for="(btn, index) in buttonDefs"
                :key="index" @click="bindButtonAction(btn)">
            {{ btn.title }}
        </dcxa-button>
    </div>
</template>

<script>
    import { Errors } from '@dcxa/dcxa-core'

    export default {
        name: "DcxaModalDialogButtons",

        props: {
            buttons: {
                type: Array,
                default: []
            },

            buttonActionArgs: {
                type: Object,
                default: {}
            }
        },

        computed: {
            buttonDefs() {
                return _.map(this.buttons, (btnConfigName) => {
                    return {
                        name: btnConfigName,
                        arg: this.buttonActionArgs[btnConfigName],
                        ...Errors.buttons[btnConfigName]
                    }
                });
            }
        },

        methods: {
            bindButtonAction(btn) {
                btn.action.call(this.$store, btn.arg);
            }
        }
    }
</script>


<style scoped lang="scss">

</style>
