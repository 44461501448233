var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dcxa-table-cell-with-icon" }, [
    _vm.iconClasses
      ? _c("div", { staticClass: "dcxa-table-cell-icon" }, [
          _c("i", {
            staticClass: "dcxa-fa",
            class: _vm.classesToApply,
            attrs: { "aria-hidden": "true" }
          })
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "dcxa-table-cell-content" },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5d36cc92", { render: render, staticRenderFns: staticRenderFns })
  }
}