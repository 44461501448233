<template>
    <div class="dcxa-table-cell-with-icon"><div class="dcxa-table-cell-icon" v-if="iconClasses">
            <i class="dcxa-fa" :class="classesToApply" aria-hidden="true"></i>
        </div><div class="dcxa-table-cell-content"><slot />
        </div>
    </div>
</template>

<script>
export default {
  name: 'TableCell',
  data() {
    return {
    }
  },
  props: [
    'iconClasses',
  ],
  computed: {
      classesToApply() {
          return (this.iconClasses || '').split(' ');
      }
  },
}
</script>

<style scoped lang="scss">
.dcxa-table-cell-with-icon {
  $icon-size: 14px;
  $icon-right-margin: 8px;
  display: flex;
  align-items: center;

  .dcxa-table-cell-icon {
    width: $icon-size;
    height: $icon-size;
    margin-right: $icon-right-margin;
    display: inline-block;

    .dcxa-fa {
      font-size: $icon-size;
      vertical-align: top;
    }
  }
  .dcxa-table-cell-content {
    display: inline-block;
    // line-height: 1;
    
  }
}
</style>
