<template>
    <div class="dcxa-property-editor">
        <div class="property-editor-card" >
            <div class="property-editor-content">
              <form>
                <slot />
              </form>
            </div>
        </div>
        <div class="property-section-editor-controls">
            <slot name="controls"/>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'PropertyEditor',
  data() {
    return {
    }
  },
  props: {
  },
  watch: {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
    @import "../../../../../styles/utilities";
    @import "../../../../../styles/colors";

    .dcxa-property-editor {
        .asterisk {
            color: $red;
        }

        .property-editor-card {
            width: 100%;
            top: 0;
            z-index: 2;
            padding: 24px 16px 32px 16px;
            border-radius: 3px;
            background-color: $white;
            box-shadow: 0 1px 5px 0 rgba($black, 0.25);
            margin: 14px 0 16px 0;
        }

        .property-section-editor-controls {
            display: flex;
            flex-direction: column-reverse;
            align-items: stretch;

            .dcxa-button {
                margin: 0 0 16px 0;
                width: 100%;

                &:first-child {
                    margin-bottom: 0;
                }
            }
        }

        @include breakpoint(md) {
            .property-editor-card {
                padding: 32px 40px 40px 40px;
            }

            .property-section-editor-controls {
                display: block;
                text-align: right;

                .dcxa-button {
                    width: auto;
                    margin: 0 0 0 8px;
                }
            }
        }
    }

</style>
