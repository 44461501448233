var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-comment-list" },
    [
      _vm.context.isLoading
        ? _c("dcxa-content-loader", {
            attrs: {
              type: "CommentList",
              "number-of-columns": _vm.numberOfLoadingColumns
            }
          })
        : _vm._l(_vm.context.data, function(row, idx) {
            return _c(
              "div",
              { key: idx, staticClass: "card" },
              [
                _c("div", { staticClass: "case-comment-author" }, [
                  row.IsClouderan
                    ? _c("span", { staticClass: "is-clouderan-author-icon" })
                    : _vm._e(),
                  _vm._v(" " + _vm._s(row.Author) + "\n        ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "case-comment-last-modified" }, [
                  _vm._v(_vm._s(row.LastModifiedDateFormatted))
                ]),
                _vm._v(" "),
                row.IsClosing
                  ? [
                      _c("div", { staticClass: "special-comment-prefix" }, [
                        _vm._v("Closing Comment")
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "case-comment-text",
                        domProps: {
                          textContent: _vm._s(
                            row.ClosingCommentBodyWithoutPrefix
                          )
                        }
                      })
                    ]
                  : row.IsReopening
                  ? [
                      _c("div", { staticClass: "special-comment-prefix" }, [
                        _vm._v("Reopening Comment")
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "case-comment-text",
                        domProps: {
                          textContent: _vm._s(
                            row.ReopeningCommentBodyWithoutPrefix
                          )
                        }
                      })
                    ]
                  : _c("div", {
                      staticClass: "case-comment-text",
                      domProps: { textContent: _vm._s(row.CommentBody) }
                    })
              ],
              2
            )
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0a80d370", { render: render, staticRenderFns: staticRenderFns })
  }
}