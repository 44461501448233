<template>
    <transition name="modal-dialog">
        <div class="modal-dialog-mask dcxa-modal-dialog forced-hidden" :class="forcedHiddenRemoverClass" v-if="isVisible">
            <div class="modal-dialog-wrapper" :class="dialogTypeClass">
                <div class="modal-dialog-container" :class="dialogTypeClass">

                    <div class="modal-dialog-close-button-container" v-if="closeButton">
                        <dcxa-text-link @click="closeDialog"><i class="far fa-times" aria-hidden="true"/></dcxa-text-link>
                    </div>

                    <div class="modal-dialog-header">
                        <slot name="header">
                            <div v-if="isLoading" class="loading-message">{{ text }}</div>
                            <div v-else-if="isSuccess" class="success-message">{{ text }}</div>
                            <h4 v-else-if="isError" class="error-title">{{ title }}</h4>
                        </slot>

                    </div>

                    <div class="modal-dialog-body">
                        <slot name="body">
                            <div v-if="isLoading" class="loading-spinner"></div>
                            <div v-else-if="isSuccess" class="success-sign"></div>
                            <template v-else-if="isError">
                                <div class="error-sign"><em class="fal fa-exclamation-triangle" aria-hidden="true"></em></div>
                                <span class="error-message">{{ message }}</span>
                            </template>
                        </slot>
                    </div>

                    <div class="modal-dialog-footer">
                        <slot name="footer">
                            <dcxa-modal-dialog-buttons v-if="isError" :buttons="buttons" :buttonActionArgs="buttonActionArgs" />
                        </slot>
                    </div>

                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "DcxaModalDialog",

        props: {
            type: {
                type: String,
                validator: function (value) {
                    return ['loading', 'success', 'error', 'content'].indexOf(value) !== -1
                }
            },

            closeButton: {
                type: Boolean,
                default: false
            },

            visible: {
                type: Boolean,
                default: false
            },

            text: {
                type: String,
                default: ''
            },

            title: {
                type: String,
                default: ''
            },

            message: {
                type: String,
                default: ''
            },

            buttons: {
                type: Array,
                default: function() {
                    return [];
                }
            },

            buttonActionArgs: {
                type: Object,
                default: function() {
                    return {};
                }
            }
        },

        computed: {
            isLoading() { return this.type === 'loading' },

            isSuccess() { return this.type === 'success' },

            isError() { return this.type === 'error' },

            isContent() { return this.type === 'content' },

            isVisible() { return this.visible },

            forcedHiddenRemoverClass() {
                return {
                    'forced-hidden-remover': this.isVisible,
                }
            },

            dialogTypeClass() {
                return {
                    'loading-dialog': this.isLoading,
                    'success-dialog': this.isSuccess,
                    'error-dialog': this.isError,
                    'content-dialog': this.isContent
                }
            }
        },

        methods: {
            closeDialog() {
                this.$emit('onCloseDialog');
            }
        }
    }
</script>


<style scoped lang="scss">
    @import "../../../../styles/utilities";
    @import "../../../../styles/colors";

    .forced-hidden {
        display: none;
    }

    .forced-hidden-remover {
        display: flex;
    }

    .modal-dialog-enter {
        opacity: 0;
    }

    .modal-dialog-wrapper {
        .modal-dialog-container {

            &.content-dialog {
                .modal-dialog-close-button-container {
                    text-align: right;
                    margin-bottom: 12px;

                    i {
                        font-size: 16px;
                    }
                }
            }

            .modal-dialog-header {
                text-align: center;

                .dialog-header-icon {
                    font-size: 24px;
                }

                h4 {
                    margin-top: 16px;
                    margin-bottom: 24px;
                }
            }

            .modal-dialog-body {
                overflow: auto;
                text-align: center;
                padding-bottom: 40px;
            }

            .modal-dialog-footer {
                display: flex;
                flex-direction: column-reverse;
                align-items: stretch;

                .dcxa-button {
                    margin: 0 0 16px 0;
                    width: 100%;

                    &:first-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        @include breakpoint(md) {

            .modal-dialog-container {
                .modal-dialog-head {
                    h4 {
                        margin-top: 16px;
                        margin-bottom: 40px;
                    }
                }

                .modal-dialog-footer {
                    flex-direction: row;
                    justify-content: center;

                    .dcxa-button {
                        width: auto;
                        margin: 0 0 0 8px;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }

    .modal-dialog-leave-active {
        opacity: 0;
    }

    .modal-dialog-enter .modal-dialog-container,
    .modal-dialog-leave-active .modal-dialog-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

</style>
