var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-input", class: _vm.classes },
    [
      _c(
        "el-popover",
        {
          ref: "popover",
          attrs: {
            disabled: !_vm.popover || !_vm.enabledValidationPopover,
            trigger: "focus",
            placement: "bottom",
            effect: "light",
            "popper-class": "dcxa-tooltip"
          }
        },
        [_vm._t("popoverContent")],
        2
      ),
      _vm._v(" "),
      _c("el-input", {
        directives: [
          { name: "popover", rawName: "v-popover:popover", arg: "popover" }
        ],
        ref: "input",
        staticClass: "floating-label-source",
        class: { "icon-prefixed": _vm.prefixIcon && _vm.prefixIcon.length > 0 },
        attrs: {
          id: _vm.id,
          name: _vm.name,
          type: _vm.type,
          rows: _vm.rows,
          resize: _vm.resize,
          value: _vm.value,
          prefixIcon: _vm.prefixIcon,
          disabled: _vm.disabled,
          clearable: _vm.clearable,
          placeholder: _vm.placeHolderText,
          autofocus: _vm.autofocus
        },
        on: { focus: null, input: _vm.updateValue }
      }),
      _vm._v(" "),
      _c("label", {
        staticClass: "floating-label-element",
        class: { visible: _vm.showFloatingLabel },
        domProps: { innerHTML: _vm._s(_vm.floatingTitleText) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-07885ed2", { render: render, staticRenderFns: staticRenderFns })
  }
}