var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dcxa-modal-loading" }, [
    _c(
      "div",
      { staticClass: "dcxa-modal-header" },
      [
        _vm.$screenIs("xs")
          ? _c("h5", [_vm._v(_vm._s(_vm.title))])
          : _c("h4", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("dcxa-loading-spinner")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7e5d18bb", { render: render, staticRenderFns: staticRenderFns })
  }
}