<template>
    <div class="contact-card">
        <div class="contact-name contact-property">{{user.FirstName}} {{user.LastName}}</div>
        <div class="contact-role contact-property">{{role}}</div>
        <div class="contact-phone contact-property">
            <em class="fal fa-mobile contact-property-icon" aria-hidden="true"></em>
            <dcxa-text-link :secondaryTarget="'tel:' + user.Phone">{{user.Phone}}</dcxa-text-link>
        </div>
        <div class="contact-email contact-property">
            <em class="fal fa-envelope contact-property-icon" aria-hidden="true"></em>
            <dcxa-text-link :secondaryTarget="'mailto:' + user.Email">Email {{user.FirstName}}</dcxa-text-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContactCard",
        props: [
            'user',
            'role'
        ]
    }
</script>

<style lang="scss">
    @import "../../../../styles/_colors.scss";
    @import "../../../../styles/_utilities.scss";

    .contact-card {
        background-color: #f7f7f7;
        padding: 24px;
        margin-bottom: 24px;
        position: relative;
        text-align: left;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 0;
            height: 0;
            border-top: 24px solid #ff8400;
            border-right: 24px solid transparent;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .contact-property {
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }

            .contact-property-icon {
                color: #999;
                margin-right: 8px;
                width: 12px;

                &.fa-mobile {
                    padding-left: 3px;
                }
            }
        }

        .contact-name {
            font-weight: bold;
            color: $slate;
        }

        .contact-role {
            color: tint($black, 50);
        }

        .contact-phone {

        }

        .contact-email {

        }        
    }
    

</style>