<template>
    <div class="dcxa-datepicker" :class="classes" >
       <el-popover :disabled="!popover || !enabledValidationPopover" trigger="focus" placement="bottom" effect="light" popper-class="dcxa-tooltip">

            <slot name="popoverContent" />

            <template slot="reference" >
                <el-date-picker
                    :id="id"
                    :name="name" 
                    type="date"
                    :picker-options="pickerOptions"
                    :clearable="clearable"
                    v-model="currVal"
                    :placeholder="placeHolderText">
                    <slot />
                </el-date-picker>
            </template>
       </el-popover>

        <label class="floating-label-element" :class="{'visible': showFloatingLabel}" v-html="floatingTitleText">
        </label>
    </div>
</template>

<script>

export default {
  name: 'DcxaDatePicker',
  data() {
    return {
        currVal: new Date(Date.now())
    }
  },
  props: {
      'id': { default: () => undefined },
      'name': { default: () => undefined },
      'value': { default: () => undefined },
      'classes': { default: () => undefined },
      'disabled': { default: () => undefined },
      'clearable': { default: () => undefined },
      'filterable': { default: () => undefined },
      'noDataText': { default: () => undefined },
      'placeholder': { default: () => undefined },
      'floatingLabel': { default: () => undefined },
      'floatingTitle': { default: () => undefined },
      'enabledValidationPopover': { default: () => undefined },
      'popover': { default: () => undefined },
      'pickerOptions': {
          default: () => {
            return {
                disabledDate(time) {
                   return time.getTime() < Date.now();
                }
            }
          }
      }
  },
  watch: {
      value: function(val, oldVal) {
          if (val != oldVal){ 
            this.updateValue(val);
          }
      },
      currVal: function(val, oldVal) {
          if (val != oldVal) {
              this.updateValue(val);
          }
      }
  },
  computed: {
      showFloatingLabel() {
          return this.floatingLabel;
      },
      placeHolderText() {
          const parts = (this.placeholder || '').split('<span');
          return (parts.length > 1) ? parts[0] + '*' : parts[0];
      },
      floatingTitleText() {
        return this.floatingTitle || this.placeholder;
      }
  },
  methods: {
    updateValue: function (value) {
        this.currVal = value;
        this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
@import "../../../../styles/_colors.scss";
@import "../../styles/_mixins.scss";

.dcxa-datepicker {
    @include floating-label();
    width: 100%;

  /////////////////////////
  // Validation variants
  /////////////////////////
  &.validation-error {
    .el-date-editor {
      &.el-input {
        .el-input__inner {
          border-color: $error-red;
          padding-right: 32px;

          &:hover {
            border-color: $error-red;
          }

          &:focus {
            border-color: $error-red;
          }
        }
      }
    }
  }

  .el-date-editor {
    &.el-input {
      width: 100%;
      line-height: 48px;
      height: 48px;

      .el-input__inner {
        padding-left: 16px;
        height: 48px;
        line-height: 48px;
        font-size: 16px;
        border-color: tint($black, 20);

        &:hover {
          border-color: $mint;

          ~ .el-input__suffix {
            color: $mint;
          }
        }

        &:focus {
          border-color: $mint;

          ~ .el-input__suffix {
            color: $mint;
          }
        }

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: tint($black, 50);
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: tint($black, 50);
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: tint($black, 50);
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: tint($black, 50);
        }
      }

      .el-input__prefix {
        display: none;
      }

      .el-input__suffix {
        color: tint($black, 60);

        &::before {
          content: "\f073";
          font-family: "Font Awesome 6 Pro";
          font-weight: 300;
          margin-right: 16px;
          font-size: 16px;
        }
      }
    }
  }
}

.datepicker.datepicker-dropdown {
    padding: 0px;
    table {
        border-collapse: separate;
        border-spacing: 0px;
    }
    table.table-condensed {
        border: solid 1px #eaebec;
        thead th {
            background: #f4f5f6;
            font-weight: normal;
            border-radius: 0px;
        }
        tbody tr {
            border-radius: 0px;
            background: #ffffff;
            height: 30px;
            font-size: 16px;
            line-height: 1em;
            &:first-child {
                background: #ffffff;
            }
            td {
                color: #33414b;
                border: solid 1px #eaebec;
                border-radius: 0px;
                &.old.day {
                    color: #b0b4b8;
                }
                &.active.active,
                .active:active {
                    background-color: #e9f6fb;
                    border: solid 1px #b6e1f4;
                    color: #36434d;
                    border-radius: 0px;
                    text-shadow: none;
                    padding: 5px;
                }
                &.day:hover {
                    background-color: white;
                    border: solid 1px #29a7de;
                    color: #29a7de;
                    border-radius: 0px;
                    text-shadow: none;
                }
            }
        }
        tfoot tr th.today {
            border: solid 1px #d8dadd;
            background-color: #f3f5f6;
            color: #336f89;
        }
    }
    .dropdown-menu {
        border-radius: 0px;
    }
}


</style>
