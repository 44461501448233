<template>
    <div class="editor-section-wrapper" ref="content-wrapper">
        <div class="property-section-top-controls">
            <slot name="navigation"/>
        </div>
        <div class="property-section">
            <div class="property-section-header" v-if="!hideHeader">
                <h4 :id="titleId" :class="titleClass">{{title}}<slot name="extension-content"/></h4>
                <dcxa-text-link v-if="!hideEditIcon" @click="openEditor"><i :id="editBtnId" class="far fa-pencil-alt" aria-hidden="true"></i></dcxa-text-link>
            </div>
            <div class="property-section-content">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'PropertyViewer',
  data() {
    return {
    }
  },
  props: {
      'title': String,
      'titleClass': String,
      'editorTitle': String,
      'titleId': String,
      'editBtnId': String,
      'hideEditIcon': {
          type: Boolean,
          default: function() {
              return false;
          }
       },
      'hideHeader': {
          type: Boolean,
          default: function() {
              return false;
          }
      }
  },
  watch: {
  },
  methods: {
        openEditor() {
            this.$emit('openingEditor');
        },

  }
}
</script>

<style scoped lang="scss">
@import "../../../../../styles/colors";

.editor-section-wrapper {
    position: relative;
}

.property-section-top-controls {
    display: flex;
    justify-content: space-between;
}

.property-section {
  width: 100%;
  border-radius: 3px;
	background-color: $white;
  box-shadow: 0 1px 5px 0 rgba($black, 0.25);
  margin: 0 0 16px 0;
  padding: 32px 40px;
}

/*.profile-section:hover {
    box-shadow: 0 8px 8px 0 rgba($black, 0.15);
}*/

.property-section-header{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.property-section-header h4 {
  display: inline-block;
  margin-bottom: 32px;
}

/*.profile-section-header em[data-open-editor],
.profile-section-header em[data-close-editor] {
  font-size: 16px;
  color: $mint;
  cursor: pointer;
}*/


.full-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba($white, 0.6);
    top: 0;
    left: 0;
    z-index: 1;
}

.property-section-editor-controls {
    margin: 40px 0 0 0;
    display: flex;
    justify-content: flex-end;
}
</style>
