var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-case-summary" },
    [
      _vm.isLoading
        ? _c("dcxa-loading-spinner")
        : _vm.hasError
        ? _c(
            "div",
            {
              staticClass: "error-text",
              attrs: { "data-dd-privacy": "mask-user-input" }
            },
            [_vm._v("Failed to fetch data. Please try to reload the page.")]
          )
        : [
            _c(
              "v-clamp",
              {
                attrs: { "max-lines": _vm.maxLinesForClamping, autoresize: "" }
              },
              [
                _c("template", { slot: "before" }, [
                  _c(
                    "span",
                    {
                      staticClass: "case-summary-subject-prefix",
                      attrs: { "data-dd-privacy": "mask-user-input" }
                    },
                    [_vm._v("Case Subject: ")]
                  )
                ]),
                _vm._v(_vm._s(_vm.caseObj.Subject) + "\n        ")
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "case-summary-metadata-container" },
              [
                _vm.isMetadataListVisible
                  ? _c(
                      "div",
                      [
                        _c(
                          "card-list-row",
                          { attrs: { data: _vm.caseObj } },
                          [_vm._t("default")],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.$screensUpTo("xs")
                  ? _c(
                      "dcxa-text-link",
                      { on: { click: _vm.toggleMetadataList } },
                      [
                        _vm.expanded
                          ? [
                              _c("i", {
                                staticClass:
                                  "far fa-chevron-up metadata-expander"
                              }),
                              _vm._v("Less Info\n                ")
                            ]
                          : [
                              _c("i", {
                                staticClass:
                                  "far fa-chevron-down metadata-expander"
                              }),
                              _vm._v("More Info\n                ")
                            ]
                      ],
                      2
                    )
                  : _vm._e()
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-212f8e6e", { render: render, staticRenderFns: staticRenderFns })
  }
}