var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dcxa-property-editor-section" }, [
    _vm.hasTitle
      ? _c("div", { staticClass: "section-header" }, [
          _c("h4", { class: _vm.titleClass, attrs: { id: _vm.titleId } }, [
            _vm._v(_vm._s(_vm.editorTitle || _vm.title))
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "section-content" }, [_vm._t("default")], 2),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "property-editor-controls" },
      [_vm._t("controls")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6c296e1a", { render: render, staticRenderFns: staticRenderFns })
  }
}