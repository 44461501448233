<template>

    <dcxa-modal-dialog  type="content" class="dcxa-case-reopening-dialog" :visible="reopeningCaseActionDialog.visible" :close-button="true" @onCloseDialog="reopeningCaseDialogCancelAction" data-dd-privacy="mask-user-input">
        <template slot="header">
            <div class="dialog-head">
                <template v-if="!reopeningCaseActionDialog.hasError">
                    <h4>Reopen Case</h4>
                </template>
                <template v-else>
                    <i class="far fa-exclamation-triangle dialog-header-icon" />
                    <h4>Failed to Reopen Case</h4>
                </template>
            </div>
        </template>

        <template slot="body">
            <template v-if="!reopeningCaseActionDialog.hasError">
                <div class="subtitle">Please enter a reason before reopening the case.</div>
                <dcxa-property-value-editor :forceFloatingLabel="true" :classes="[]" title="Reopening Reason" :inputRows="4" :source="reopeningComment" :config="editorConfig.reopeningComment" v-on:stateChanged="state => editRowStateChanged('reopeningComment', state)"/>
            </template>
            <template v-else>
                <div class="subtitle">Please try again. <dcxa-text-link :secondaryTarget="contactUsLink">Contact us</dcxa-text-link> or <dcxa-text-link :secondaryTarget="telLink">call us</dcxa-text-link> if the problem persists.</div>
            </template>
        </template>

        <template slot="footer">
            <dcxa-button type="secondary" class="medium" :disabled="reopeningCaseActionDialog.inProgress" @click="reopeningCaseDialogCancelAction">Cancel</dcxa-button>
            <dcxa-button type="primary" class="medium" :disabled="!isValid" :class="{'loading': reopeningCaseActionDialog.inProgress}" @click="reopeningCaseDialogSubmitAction">{{submitButtonText}}</dcxa-button>
        </template>
    </dcxa-modal-dialog>
</template>

<script>
    import { mapGetters } from 'vuex'

    const dcxaCore = require('@dcxa/dcxa-core');
    const { Configs, Api, Utilities } = dcxaCore;

    export default {
        name: "DcxaCaseReopeningDialog",
        data() {
            return {
                reopeningComment: '',
                editorStates: {
                    reopeningComment: {}
                }
            }
        },
        computed: {
            ...mapGetters({
                reopeningCaseActionDialog: 'getReopeningCaseActionDialog'
            }),
            editorConfig() {
                return {
                    reopeningComment: {
                        type: 'textarea',
                        validation: {
                            supressErrorOnFirstRound: true,
                            rules: [
                                {rule: 'required'},
                                {rule: 'maxlength', args: [3900]}
                            ]
                        }
                    }
                }
            },
            isValid() {
                return this.editorStates.reopeningComment.result;
            },
            submitButtonText() {
                return this.reopeningCaseActionDialog.hasError ? 'Retry' : 'Reopen Case';
            },
            contactUsLink() {
                return '/content/support/my/en-us/faq.html#support';
            },

            telLink() {
                return 'tel:888-938-5711'
            }
        },
        methods: {
            editRowStateChanged(id, state) {
                this.editorStates[id] = state;
            },
            reopeningCaseDialogCancelAction() {
                this.$store.dispatch('CLOSE_REOPENING_CASE_DIALOG');
            },
            reopeningCaseDialogSubmitAction() {
                this.$store.dispatch('RUN_REOPEN_CASE_ACTION', this.editorStates.reopeningComment.value);
            }
        }
    }
</script>

<style lang="scss">
    @import "../../../../styles/_colors.scss";
    @import "../../../../styles/_utilities.scss";

    .dcxa-case-reopening-dialog {
        .dialog-header-icon {
            color: $red;
        }

        .modal-dialog-body {
            .subtitle {
                font-size: 16px;
            }

            .dcxa-property-value-editor {
                margin-top: 49px;
            }
        }

        .modal-dialog-footer {
            .dcxa-button {
                margin: 0 0 16px 0;
                width: 100%;

                &:first-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    @include breakpoint(md) {
        .dcxa-case-reopening-dialog {
            .modal-dialog-footer {
                .dcxa-button {
                    width: auto;
                    margin: 0 0 0 8px;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }

</style>