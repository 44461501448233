import { Api, DataProvider } from '@dcxa/dcxa-core'

// initial state
const state = {
    closingCaseActionDialog: {
        visible: false,
        inProgress: false,
        hasError: false,
        caseId: null
    },
    reopeningCaseActionDialog: {
        visible: false,
        inProgress: false,
        hasError: false,
        caseId: null
    }
}

// getters
const getters = {
    getClosingCaseActionDialog: state => state.closingCaseActionDialog,
    getReopeningCaseActionDialog: state => state.reopeningCaseActionDialog
}

// actions
const actions = {
    WATCH_CASE({ commit }, params) {
        return new Promise(async(resolve, reject) => {
            const payload = { data: params.contactId };
            const urlParams = { caseId: params.caseId };

            return Api.callEndpoint("SKYNET_ADD_NEW_CASE_TEAM_MEMBER", { payload, urlParams }).then(response => {
                resolve({ caseId: params.caseId, isWatched: true });
            }, error => {
                reject(params.caseId);
            });

        });
    },

    UNWATCH_CASE({ commit }, params) {
        return new Promise(async(resolve, reject) => {

            /* develblock:start */
            //await new Promise(resolve => setTimeout(resolve, 2000));
            //resolve({ caseId: params.caseId, isWatched: false });
            /* develblock:end */

            const urlParams = {
                caseId: params.caseId,
                contactId: params.contactId
            };

            return Api.callEndpoint("SKYNET_REMOVE_CASE_TEAM_MEMBER", { urlParams }).then(response => {
                resolve({ caseId: params.caseId, isWatched: false });
            }, error => {
                reject(params.caseId);
            });

        });
    },

    async CLOSE_CASE({ commit }, params) {
        return new Promise((res, rej) => {
            this.closingCaseResolver = res;
            commit('IS_CLOSING_CASE_DIALOG_VISIBLE', { visible: true, caseId: params.caseId });
        });
    },

    async RUN_CLOSE_CASE_ACTION({ commit, state }, closingComment) {
        try {
            commit('IS_CLOSING_CASE_DIALOG_IN_PROGRESS', true);

            const payload = { CommentBody: DataProvider.CaseCommentPrefixes.Closing + '\n' + closingComment };
            const urlParams = { caseId: state.closingCaseActionDialog.caseId };

            /* develblock:start */
            //await new Promise(resolve => setTimeout(resolve, 2000));
            /* develblock:end */

            const response = await Api.callEndpoint("SKYNET_CLOSE_CASE", { payload, urlParams });

            if (this.closingCaseResolver) {
                this.closingCaseResolver(response.Case);
                this.closingCaseResolver = null;
            }

            commit('IS_CLOSING_CASE_DIALOG_VISIBLE', { visible: false });
            commit('HAS_CLOSING_CASE_DIALOG_ERROR', false);
        } catch (e) {
            commit('HAS_CLOSING_CASE_DIALOG_ERROR', true);
        } finally {
            commit('IS_CLOSING_CASE_DIALOG_IN_PROGRESS', false);
        }
    },

    CLOSE_CLOSING_CASE_DIALOG({ commit }) {
        this.closingCaseResolver = null;
        commit('IS_CLOSING_CASE_DIALOG_VISIBLE', { visible: false });
    },



    REOPEN_CASE({ commit }, params) {
        return new Promise((res, rej) => {
            this.reopeningCaseResolver = res;
            commit('IS_REOPENING_CASE_DIALOG_VISIBLE', { visible: true, caseId: params.caseId });
        });
    },

    async RUN_REOPEN_CASE_ACTION({ commit, state }, reopeningComment) {
        try {
            commit('IS_REOPENING_CASE_DIALOG_IN_PROGRESS', true);

            const payload = { CommentBody: DataProvider.CaseCommentPrefixes.Reopening + '\n' + reopeningComment };
            const urlParams = { caseId: state.reopeningCaseActionDialog.caseId };

            /* develblock:start */
            //await new Promise(resolve => setTimeout(resolve, 2000));
            /* develblock:end */

            const response = await Api.callEndpoint("SKYNET_REOPEN_CASE", { payload, urlParams });

            if (this.reopeningCaseResolver) {
                this.reopeningCaseResolver(response.Case);
                this.reopeningCaseResolver = null;
            }

            commit('IS_REOPENING_CASE_DIALOG_VISIBLE', { visible: false });
            commit('HAS_REOPENING_CASE_DIALOG_ERROR', false);
        } catch (e) {
            commit('HAS_REOPENING_CASE_DIALOG_ERROR', true);
        } finally {
            commit('IS_REOPENING_CASE_DIALOG_IN_PROGRESS', false);
        }
    },

    CLOSE_REOPENING_CASE_DIALOG({ commit }) {
        this.reopeningCaseResolver = null;
        commit('IS_REOPENING_CASE_DIALOG_VISIBLE', { visible: false });
    },

    CREATE_SUBCASE({ commit }, caseNumber) {
        window.location = '/cases/new?parent=' + caseNumber;
    }
}

// mutations
const mutations = {
    IS_CLOSING_CASE_DIALOG_VISIBLE(state, params) {
        state.closingCaseActionDialog.visible = params.visible;
        state.closingCaseActionDialog.caseId = params.visible === true && params.caseId ? params.caseId : null;

        state.closingCaseActionDialog.inProgress = false;
        state.closingCaseActionDialog.hasError = false;
    },

    IS_CLOSING_CASE_DIALOG_IN_PROGRESS(state, value) {
        state.closingCaseActionDialog.inProgress = value;
    },

    HAS_CLOSING_CASE_DIALOG_ERROR(state, value) {
        state.closingCaseActionDialog.hasError = value;
    },



    IS_REOPENING_CASE_DIALOG_VISIBLE(state, params) {
        state.reopeningCaseActionDialog.visible = params.visible;
        state.reopeningCaseActionDialog.caseId = params.visible == true && params.caseId ? params.caseId : null;

        state.reopeningCaseActionDialog.inProgress = false;
        state.reopeningCaseActionDialog.hasError = false;
    },

    IS_REOPENING_CASE_DIALOG_IN_PROGRESS(state, value) {
        state.reopeningCaseActionDialog.inProgress = value;
    },

    HAS_REOPENING_CASE_DIALOG_ERROR(state, value) {
        state.reopeningCaseActionDialog.hasError = value;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}