<template>

    <dcxa-modal-dialog  type="content" class="dcxa-case-closing-dialog" :visible="closingCaseActionDialog.visible" :close-button="true" @onCloseDialog="closingCaseDialogCancelAction" data-dd-privacy="mask-user-input">
        <template slot="header">
            <div class="dialog-head">
                <template v-if="!closingCaseActionDialog.hasError">
                    <h4>Close Case</h4>
                </template>
                <template v-else>
                    <i class="far fa-exclamation-triangle dialog-header-icon" />
                    <h4>Failed to Close Case</h4>
                </template>
            </div>
        </template>

        <template slot="body">
            <template v-if="!closingCaseActionDialog.hasError">
                <div class="subtitle">Please enter a comment to close this case.</div>
                <dcxa-property-value-editor :forceFloatingLabel="true" :classes="[]" title="Closing Comment" :inputRows="4" :source="closingComment" :config="editorConfig.closingComment" v-on:stateChanged="state => editRowStateChanged('closingComment', state)"/>
            </template>
            <template v-else>
                <div class="subtitle">Please try again. <dcxa-text-link :secondaryTarget="contactUsLink">Contact us</dcxa-text-link> or <dcxa-text-link :secondaryTarget="telLink">call us</dcxa-text-link> if the problem persists.</div>
            </template>
        </template>

        <template slot="footer">
            <dcxa-button type="secondary" class="medium" :disabled="closingCaseActionDialog.inProgress" @click="closingCaseDialogCancelAction">Cancel</dcxa-button>
            <dcxa-button type="primary" class="medium" :disabled="!isValid" :class="{'loading': closingCaseActionDialog.inProgress}" @click="closingCaseDialogSubmitAction">{{submitButtonText}}</dcxa-button>
        </template>
    </dcxa-modal-dialog>
</template>

<script>
    import { mapGetters } from 'vuex'

    const dcxaCore = require('@dcxa/dcxa-core');
    const { Configs, Api, Utilities } = dcxaCore;

    export default {
        name: "DcxaCaseClosingDialog",
        data() {
            return {
                closingComment: '',
                editorStates: {
                    closingComment: {}
                }
            }
        },
        computed: {
            ...mapGetters({
                closingCaseActionDialog: 'getClosingCaseActionDialog'
            }),
            editorConfig() {
                return {
                    closingComment: {
                        type: 'textarea',
                        validation: {
                            supressErrorOnFirstRound: true,
                            rules: [
                                {rule: 'required'},
                                {rule: 'maxlength', args: [3900]}
                            ]
                        }
                    }
                }
            },
            isValid() {
                return this.editorStates.closingComment.result;
            },
            submitButtonText() {
                return this.closingCaseActionDialog.hasError ? 'Retry' : 'Close Case';
            },
            contactUsLink() {
                return '/content/support/my/en-us/faq.html#support';
            },
            telLink() {
                return 'tel:888-938-5711'
            }
        },
        methods: {
            editRowStateChanged(id, state) {
                this.editorStates[id] = state;
            },
            closingCaseDialogCancelAction() {
                this.$store.dispatch('CLOSE_CLOSING_CASE_DIALOG');
            },
            closingCaseDialogSubmitAction() {
                this.$store.dispatch('RUN_CLOSE_CASE_ACTION', this.editorStates.closingComment.value);
            }
        }
    }
</script>

<style lang="scss">
    @import "../../../../styles/_colors.scss";
    @import "../../../../styles/_utilities.scss";

    .dcxa-case-closing-dialog {
        .dialog-header-icon {
            color: $red;
        }

        .modal-dialog-body {
            .subtitle {
                font-size: 16px;
            }

            .dcxa-property-value-editor {
                margin-top: 49px;
            }
        }

        .modal-dialog-footer {
            .dcxa-button {
                margin: 0 0 16px 0;
                width: 100%;

                &:first-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    @include breakpoint(md) {
        .dcxa-case-closing-dialog {
            .modal-dialog-footer {
                .dcxa-button {
                    width: auto;
                    margin: 0 0 0 8px;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }

</style>