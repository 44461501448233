<template>
  <div v-if="isVisible" :class="classes">
    <span class="info-icon">
      <i :class="icon == '' ? defaultInfoIcon : icon"></i>
    </span>
    <div class="controls" v-if="isDismissable">
      <dcxa-text-link v-if="!isNewAdmonition" @click="dismiss">Dismiss</dcxa-text-link>
      <dcxa-text-link v-else @click="dismiss">
        <i class="far fa-times" aria-hidden="true"></i>
      </dcxa-text-link>
    </div>
    <div class="content">
      <template v-if="message">
        <p v-if="title">
          <strong>{{title}}</strong>
        </p>
        <p v-html="message"></p>
      </template>
      <template v-if="!message">
        <slot>{{ defaultMessage }}</slot>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

const DEFAULT_TIMEOUT = 3000;

function DEFAULT_STATE() {
  return {
    dismissed: false,
    dismissTimeoutHandler: null
  }
}

export default {
  name: "Admonition",
  data() {
    return DEFAULT_STATE();
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: "success"
    },
    message: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    notDismissable: {
      type: Boolean,
      default: false
    },
    disableAutoDismiss: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    },
    timeout: {
      type: Number,
      default: DEFAULT_TIMEOUT
    }
  },
  computed: {
    isDismissable() {
      return !this.notDismissable;
    },
    isAutoDismissable() {
      return !this.disableAutoDismiss && this.isSuccess;
    },

    dismissTimeout() {
      return this.timeout || DEFAULT_TIMEOUT;
    },

    isVisible() {
      return this.visible && !this.dismissed;
    },
    defaultMessage() {
      if (this.isError || this.isDcxaError) {
        return "Error happened.";
      }
      if (this.isWarning || this.isDcxaWarning) {
        return "There is a warning.";
      }
      if (this.isDcxaNeutral) {
        return "Something happened.";
      }

      return "Operation successfully finished.";
    },

    isError() {
      return this.type === "error";
    },
    isSuccess() {
      return this.type === "success";
    },
    isWarning() {
      return this.type === "warning";
    },
    isNeutral() {
      return this.type === "neutral";
    },

    isDcxaNeutral() {
      return this.type === "dcxa-neutral";
    },
    isDcxaSuccess() {
      return this.type === "dcxa-success";
    },
    isDcxaWarning() {
      return this.type === "dcxa-warning";
    },
    isDcxaError() {
      return this.type === "dcxa-error";
    },
    isNewAdmonition() {
      return (
        this.type === "dcxa-neutral" ||
        this.type === "dcxa-success" ||
        this.type === "dcxa-warning" ||
        this.type === "dcxa-error"
      );
    },
    defaultInfoIcon() {
      if (this.type == "error") {
        return "fal fa-times-circle";
      } else if (this.type == "warning") {
        return "fal fa-exclamation-triangle";
      } else if (this.type == "success") {
        return "fal fa-check-circle";
      } else if (this.type == "dcxa-error") {
        return "fal fa-times-circle";
      } else if (this.type == "dcxa-warning") {
        return "fal fa-exclamation-triangle";
      } else if (this.type == "dcxa-success") {
        return "fal fa-check-circle";
      } else if (this.type == "dcxa-neutral") {
        return "fal fa-paper-plane";
      }
    },
    classes() {
      return {
        "dcxa-admonition": true,
        error: this.isError,
        success: this.isSuccess,
        warning: this.isWarning,
        neutral: this.isNeutral,
        "dcxa-admonition-main": this.isNewAdmonition,
        "dcxa-neutral": this.isDcxaNeutral,
        "dcxa-success": this.isDcxaSuccess,
        "dcxa-warning": this.isDcxaWarning,
        "dcxa-error": this.isDcxaError
      };
    }
  },
  methods: {
    reset: function (){
      Object.assign(this.$data, DEFAULT_STATE());
    },
    dismiss() {
      if (this.dismissTimeoutHandler) {
        clearTimeout(this.dismissTimeoutHandler);
      }

      this.dismissTimeoutHandler = null;
      this.dismissed = true;
      
      this.$emit('admonitionDismissed');
    }
  },
  watch: {
    message: function(val, oldVal) {
      if (!val) {
        return;
      }

      // un-dismiss the admonition
      this.dismissed = false;

      if (this.isAutoDismissable) {
        // clear current timeout
        if (this.dismissTimeoutHandler) {
          clearTimeout(this.dismissTimeoutHandler);
          this.dismissTimeoutHandler = null;
        }

        // set timeout to dismiss
        this.dismissTimeoutHandler = setTimeout(() => {
          Vue.nextTick(() => {
            this.dismiss();
          });
        }, this.dismissTimeout);
      }
    }
  }
};
</script>

<style lang="scss">
@import "~@dcxa/dcxa-core/src/sass/variables/variables";
@import "../../../../styles/_utilities.scss";
@import "../../../../styles/_colors.scss";

.dcxa-admonition {
  display: block;
  position: relative;
  border-radius: 3px;
  min-height: 48px;
  font-size: 16px;
  line-height: 1.33;
  padding: 11px 16px 13px 48px;
  margin-bottom: 24px;
  color: $data-gray;
  .info-icon {
    i {
      position: absolute;
      left: 14px;
      font-size: 20px;
      &::before {
        vertical-align: middle;
      }
    }
  }
  .content {
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .controls {
    float: right;
    margin-left: 8px;
    span {
      i {
        position: absolute;
        top: 16px;
        right: 16px;
      }
    }
  }
  &::before {
    font-family: "Font Awesome 6 Pro";
    position: absolute;
    font-size: 20px;
    left: 14px;
  }
  &.error {
    background: tint($error-red, 10);
    border: 1px solid $error-red;
    .info-icon {
      color: $error-red;
    }
  }
  &.warning {
    background: tint($sunrise-yellow, 10);
    border: 1px solid $sunrise-yellow;
    .info-icon {
      color: $sunrise-yellow;
    }
  }
  &.success {
    background: tint($success-green, 10);
    border: 1px solid $success-green;
    .info-icon {
      color: $success-green;
    }
  }
  &.neutral {
    background-color: tint($dcxa-neutral-blue, 5);
    border: 1px solid $dcxa-neutral-blue;
    .info-icon {
      color: $dcxa-neutral-blue;
    }
  }
  &.dcxa-admonition-main {
    background: $dcxa-white;
    display: inline-block;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);

    .info-icon {
      color: $dcxa-white;
      height: 100%;
      width: 48px;
      margin: -11px -14px;
      padding: 14px 0;
      justify-content: center;
      border-radius: 3px 0 0 3px;
      text-align: center;
      position: absolute;
      left: 14px;
      font-size: 20px;
    }
    .content {
      padding-left: 16px;
      padding-right: 16px;
      display: table-cell;
      padding-top: 0;
      padding-bottom: 0;
      text-align: left;
    }
  }
  &.dcxa-neutral {
    .info-icon {
      background: $dcxa-neutral-blue;
    }
  }
  &.dcxa-success {
    .info-icon {
      background: $dcxa-success-green;
    }
  }
  &.dcxa-warning {
    .info-icon {
      background: $dcxa-sunrise-yellow;
    }
  }
  &.dcxa-error {
    .info-icon {
      background: $dcxa-error-red;
    }
  }
  @include breakpoint(md) {
    &.dcxa-admonition-main {
      width: auto;
    }
  }
}
</style>