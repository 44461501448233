var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-badge", attrs: { id: _vm.id } },
    [
      _c(
        "el-popover",
        {
          attrs: {
            placement: "top",
            trigger: "hover",
            content: _vm.tooltip,
            disabled: !_vm.hasTooltip,
            "popper-class": "dcxa-tooltip"
          }
        },
        [
          _c("div", { attrs: { slot: "reference" }, slot: "reference" }, [
            _c(
              "div",
              {
                staticClass: "container",
                class: [_vm.colorType, { compact: _vm.isCompact }]
              },
              [
                !_vm.hideCounter ? [_vm._v(_vm._s(_vm.counter))] : _vm._e(),
                !_vm.hideCounter && _vm.isLabelVisible
                  ? [_vm._v(" ")]
                  : _vm._e(),
                _vm.isLabelVisible ? [_vm._v(_vm._s(_vm.label))] : _vm._e()
              ],
              2
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1fb80e2b", { render: render, staticRenderFns: staticRenderFns })
  }
}