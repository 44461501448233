var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.buttonDefs, function(btn, index) {
      return _c(
        "dcxa-button",
        {
          key: index,
          staticClass: "small",
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              return _vm.bindButtonAction(btn)
            }
          }
        },
        [_vm._v("\n        " + _vm._s(btn.title) + "\n    ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6396b894", { render: render, staticRenderFns: staticRenderFns })
  }
}