//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import Vue from 'vue';

const dcxaCore = require('@dcxa/dcxa-core');
const { Utilities } = dcxaCore;

const DEFAULT_VISIBLE_CHILDREN_COUNT = 5;

export default {
  name: 'tree-node',
  data() {
    return {
        expanded: false,
        visibleChildrenCount: DEFAULT_VISIBLE_CHILDREN_COUNT,
        defaultChidrenCount: DEFAULT_VISIBLE_CHILDREN_COUNT
    }
  },
  props: {
      nodeData: {
          type: Object,
          default: null
      },
      level: {
          type: Number,
          default: 0
      },
      labelsOnly: {
          type: Boolean,
          default: false
      }
    },
  computed: {
      isLeaf() {
          if (!this.nodeData) return false;

          return !this.nodeData.children || this.nodeData.children.length == 0;
      },
      isRoot() {
          if (!this.nodeData) return false;

          return this.nodeData.isRoot;
      },
      dcxaTreeNodeClasses() {
          const classList = {
            'is-leaf': this.isLeaf,
            'is-parent': !this.isLeaf,
            'expanded' : this.expanded,
            'is-leaf-expanded' : this.labelsOnly
          };
          classList['level-'+this.level] = true
          return classList;
      },
      allChildrenShown() {
          if (this.nodeData && this.nodeData.children) {
              return this.nodeData.children.length <= this.visibleChildrenCount;
          } else {
              return true;
          }
      },

      childrenToShow() {
          const items = _.slice(this.nodeData.children, 0);

          // There is no component based sorting on root level
          if (this.nodeData.isRoot) {
              return items;
          }

          return _.orderBy(items, [function(o) { return o.children.length }, function(o) { return o.title }], ['desc', 'asc']);
      }
      
  },
  methods: {
      seeMore() {
          let count = 0;
          if (this.nodeData && this.nodeData.children) count = this.nodeData.children.length;
          this.visibleChildrenCount = count;
          this.setupHeights();
      },
      seeLess() {
          this.visibleChildrenCount = DEFAULT_VISIBLE_CHILDREN_COUNT;
          this.setupHeights(); 
      },
      changeSelection(node) {
          if (!this.isLeaf && !this.expanded && node.selected) {
              this.expanded = true;
              this.setupHeights();
          }
          this.selectionChanged(node);
      },
      titleClicked(node) {
          node.selected = !node.selected;
          this.changeSelection(node);
      },
      selectionChanged(nodeData) {
          this.$emit('change', nodeData);
      },
      emitExpanderModifiedEvent(nodeData) {
          this.$emit('expanderModified',nodeData);
      },
      expanderClicked() {
          this.expanded = !this.expanded;
          this.setupHeights();
          this.nodeData.isExpanded = this.expanded;
          this.$emit('expanderModified',this.nodeData);
      },
      setupHeights() {
          Vue.nextTick(()=>{
              Utilities.dom.setupHeights();
          })
      },
      collapseNode() {
          this.visibleChildrenCount = DEFAULT_VISIBLE_CHILDREN_COUNT;
          this.expanded = false;
      },
      expandNode() {
          this.seeMore();
          this.expanded = true;
      }
  }

}
