var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "info-row",
      class: { "enable-multiline": _vm.enableMultiline }
    },
    [
      _c("span", { staticClass: "property" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._t("custom-value", function() {
        return [
          _c("div", { staticClass: "value" }, [
            _c("span", { class: { missing: !_vm.isProvided } }, [
              _vm._v(_vm._s(_vm.valueToShow))
            ])
          ])
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-97d308d6", { render: render, staticRenderFns: staticRenderFns })
  }
}