var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$screensFrom("md") || !_vm.responsive
    ? _c(
        "div",
        {
          staticClass: "simple-table-view-cell simple-table-row-expander-cell",
          style: _vm.cellStyle
        },
        [
          _c(
            "dcxa-text-link",
            {
              attrs: {
                id: _vm.contextPrefixId + "row_" + _vm.rowIdx + "_expander",
                caret: true,
                "caret-direction": _vm.expanderIconTypeClass,
                "caret-position": "after"
              },
              on: { click: _vm.toggleRow }
            },
            [_c("span")]
          )
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "expansion-panel-toggler" },
        [
          _c(
            "dcxa-text-link",
            {
              attrs: {
                id: _vm.contextPrefixId + "row_" + _vm.rowIdx + "_expander",
                caret: true,
                "caret-direction": _vm.expanderIconTypeClass,
                "caret-position": "before"
              },
              on: { click: _vm.toggleRow }
            },
            [_vm._v("Further details")]
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-bca7093c", { render: render, staticRenderFns: staticRenderFns })
  }
}