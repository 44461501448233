var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-page-header", class: _vm.mainClasses },
    [
      _c(
        "div",
        {
          staticClass: "main",
          style: { "background-image": "url(" + _vm.config.background + ")" }
        },
        [
          _c("div", { staticClass: "dcxa-grid-container" }, [
            _c("div", { staticClass: "dcxa-row content-row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "dcxa-column dcxa-xs-full-width dcxa-md-12 dcxa-lg-8"
                },
                [
                  _vm.config.eyebrow
                    ? _c("div", { staticClass: "eyebrow" }, [
                        _vm._v(_vm._s(_vm.config.eyebrow))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h1", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.config.title))
                  ]),
                  _vm._v(" "),
                  _vm.config.subheader
                    ? _c("h4", { staticClass: "subheader" }, [
                        _vm._v(_vm._s(_vm.config.subheader))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.paragraph
                    ? _c("p", { staticClass: "paragraph" }, [
                        _vm._v(_vm._s(_vm.config.paragraph))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.mode == "cta" &&
                  _vm.config.cta &&
                  _vm.config.cta.primary &&
                  _vm.config.level < 3
                    ? [
                        _c(
                          "div",
                          { staticClass: "ctas" },
                          [
                            _vm.config.cta.primary || _vm.config.cta.secondary
                              ? _c(
                                  "div",
                                  { staticClass: "buttons" },
                                  [
                                    _vm.config.cta.primary
                                      ? _c(
                                          "dcxa-button",
                                          { attrs: { type: "primary" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.config.cta.primary.title
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.config.cta.secondary
                                      ? _c(
                                          "dcxa-button",
                                          {
                                            staticClass: "alternate",
                                            attrs: { type: "secondary" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.config.cta.secondary.title
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.config.cta.tertiary
                              ? _c(
                                  "dcxa-text-link",
                                  { staticClass: "tertiary-text-link" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.config.cta.tertiary.title)
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.mode == "search"
                    ? [_c("div", { staticClass: "search" })]
                    : _vm._e()
                ],
                2
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _vm.config.cards && _vm.config.cards.length === 3 && _vm.config.level < 3
        ? _c("div", { staticClass: "cards" }, [
            _c("div", { staticClass: "dcxa-grid-container" }, [
              _c(
                "div",
                { staticClass: "dcxa-row" },
                _vm._l(_vm.config.cards, function(card, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      staticClass:
                        "dcxa-column dcxa-xs-full-width dcxa-md-full-width dcxa-lg-4"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "card" },
                        [
                          _c("h4", { staticClass: "title" }, [
                            _vm._v(_vm._s(card.header))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "paragraph" }, [
                            _vm._v(_vm._s(card.paragraph))
                          ]),
                          _vm._v(" "),
                          _c("dcxa-text-link", { staticClass: "cta" }, [
                            _vm._v(_vm._s(card.cta.title))
                          ])
                        ],
                        1
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d82b89b8", { render: render, staticRenderFns: staticRenderFns })
  }
}