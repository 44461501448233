var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dcxa-combobox", class: _vm.classes },
    [
      _c(
        "el-popover",
        {
          attrs: {
            disabled: !_vm.popover,
            trigger: "focus",
            placement: "bottom",
            effect: "light",
            "popper-class": "dcxa-tooltip"
          }
        },
        [
          _vm._t("popoverContent"),
          _vm._v(" "),
          _c(
            "template",
            { slot: "reference" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    id: _vm.id,
                    name: _vm.name,
                    value: _vm.value,
                    disabled: _vm.disabled,
                    filterable: true,
                    "no-match-text": "Item not found",
                    "no-data-text": "No selectable item",
                    placeholder: _vm.placeholder,
                    "popper-class": "dcxa-combobox-dropdown"
                  },
                  on: {
                    change: _vm.updateValue,
                    "visible-change": _vm.dropdownVisibility
                  }
                },
                [_vm._t("default")],
                2
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      !_vm.hideFloatingLabel
        ? _c(
            "label",
            {
              staticClass: "floating-label-element",
              class: { visible: _vm.showFloatingLabel }
            },
            [_vm._v(_vm._s(_vm.placeholderText))]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6a4833b6", { render: render, staticRenderFns: staticRenderFns })
  }
}