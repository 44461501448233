//
//
//
//
//
//

    export default {
        name: "CommentListRow",
        props: {
            data: {
                type: Object,
                default: () => {}
            }
        }
    }
