<template>
  <div class="dcxa-tag-list" v-if="items.length > 0">
    <el-tag
      v-for="item in items"
      :key="item.id"
      :closable="true"
      :type="type"
      :size="size"
      @close="removeTag(item)">{{ titleFormatter(item,title) }}</el-tag>
    <dcxa-text-link class="clear-filters" @click="clearAllTags()">Clear All Filters</dcxa-text-link>
  </div>
</template>
<script>
// TODO: depricate, migrate this to dcxa-tags
export default {
  name: "TagList",
  props: {
    titleFormatter: {
      type: Function,
      default: (item, title) => {
        return (item || {})[title] || '';
      }
    },
    items: {
      type: Array,
      default: []
    },
    size: {
      type: String,
      default: "medium"
    },
    type: {
      type: String,
      default: "info" // success/info/warning/danger
    },
    title: {
      type: String,
      default: "title"
    }
  },
  methods: {
    clearAllTags() {
      this.$store.dispatch("UI_CLEAR_FILTER");
    },
    removeTag(item) {
       item.node.selected = false
       this.$store.dispatch('UI_FACET_SELECTION_CHANGED', item.node);
    }
  }
};
</script>
 
<style lang="scss">

@import "../../../../styles/_colors.scss";
.dcxa-tag-list {
  margin: 4px 0;
  .el-tag {
    margin: 0 8px 8px 0;
    font-size: 16px;
    color: $black;
    .el-tag__close {
      color: shade($mint,120);
      font-weight: bold;
      font-size: 14px;
      &:hover {
        background: none;
        opacity: 0.8;
      }
    }
  }
  .clear-filters {
    color: shade($mint,120);
    font-size: 16px;
  }
}
</style>